import React from 'react';
import './help.css';
import StaticHeader from '../StaticHeader/staticHeader.js';
import Header from '../header/header';
import Footer from '../footer/footer.js';
import LeftPanel from './leftPanel.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.js";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Config from '../shared/config.js';


class ActivationSteps extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Header active={"activationsteps"} showmenuitem={true}/>
        <div className='activation-container'>

          <div className='activation-head-block'>
            <h2>Device Activation.</h2>
            <p>Enjoy unlimited entertainment of favourite movies, Web Series , TV Serials , Informative Documentaries, Real Time Breaking News with Live Sports from across the World and much more. Below are the steps to activate your subscription, </p>
            <p>By default, mobile number is registered with OTT’s and customer just need to login with that registered mobile number.</p>
            <h3>Please select device type on which you want to watch the content.</h3>
          </div>

          <div className='row'>

            <div className='col-md-3'>
              <div className='activation-block android-block'>
                <img src='https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/main_androidtv.png' className='android-tab' />
                <img src='https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/android.png' className='icon-bot-pos' />
                <h3>Smart TVs - Android TV, Amazon Firestick, Android STB (Set-Top-Box)</h3>
                <a href={Config.BaseRootURL + 'help/androidtv'}>View Activation Steps</a>

              </div>
            </div>
            <div className='col-md-3'>
              <div className='activation-block smart-block'>
                <img src='https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/main-smarttv.png' />
                <img src='https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/smarttv.png' className='icon-bot-pos' />
                <h3>Smart TVs <span>SONY TV, SAMSUNG TV, LG TV</span></h3>
                <a href={Config.BaseRootURL + 'help/smarttv'}>View Activation Steps</a>

              </div>
            </div>
            <div className='col-md-3'>
              <div className='activation-block web-desk-block'>
                <img src='https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/main_desktop.png' className='web-icon-tab' />
                <img src='https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/web.png' className='icon-bot-pos' />
                <h3>Desktop Computer or Laptop</h3>
                <a href={Config.BaseRootURL + 'help/web'}>View Activation Steps</a>

              </div>

            </div>

            <div className='col-md-3'>
              <div className='activation-block mobile-device-block'>
                <img src='https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/mobile-device.png' />
                <img src='https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/mobile-corner.png' className='icon-bot-pos' />
                <h3>Mobile Phone’s</h3>
                <a href={Config.BaseRootURL + 'help/mobile'}>View Activation Steps</a>

              </div>

            </div>

          </div>

        </div>


        <Footer />
      </div>
    )
  }
}

export default ActivationSteps;