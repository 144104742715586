import Cookies from 'universal-cookie';
import Config from '../shared/config.js';

class DataService {

async httpGet(url) {
    return  fetch(url,{
            method: 'GET',
        })
        .then((response) => response.json())
        .then((responseData) => {
            if (!responseData.ok) {
                //this.handleResponseError(responseData);
            }
            //console.log(responseData);
            return responseData
        })
        .catch(error => {
            console.log(error);
          });
    }
    async httpGetwithToken(url) {
        var cookies = new Cookies();
        var sessionid= cookies.get('sessionid');
        var boxid = cookies.get('boxid');

        //sessionid="4b7e71ce-812a-43b6-b0dd-04ccca6057e5";
        //boxid="testboxrec123456789";

        return  fetch(url,{
                method: 'GET',
                headers: {  'session-id': sessionid,
                'box-id': boxid,    
                'tenant-code': 'bott',
                'Content-Type': 'application/json' }
            })
            .then((response) => response.json())
            .then((responseData) => {
                if (!responseData.ok) {
                    //this.handleResponseError(responseData);
                }
                //console.log(responseData);
                return responseData
            })
            .catch(error => {
                console.log(error);
              });
        }    
    httpPostWithHeaderData(url, postData)
    {
        var cookies = new Cookies();
        var sessionid= cookies.get('sessionid');
        var boxid = cookies.get('boxid');

        if (boxid===undefined && sessionid===undefined)
        {
            boxid =  this.createGuid().replace("-", "").substring(0, 16)
            cookies.set('boxid', boxid, { path: '/' });
            let  URL1 = Config.APIURL + "service/api/v1/get/token"
            let postData1 = "box_id=" + boxid + "&device_id=5&tenant_code=" + Config.TenentCode + "&device_sub_type=&product=bott&display_lang_code=eng&timezone=Asia%2FKolkata"
            return this.httpGet(URL1 +"?" + postData1)
            .then(responseData1=> {
                    if(responseData1!== undefined)
                    {
                        if(responseData1.status===true)
                        {
                            cookies.set('sessionid', responseData1.response.sessionId, { path: '/' });
                            //sessionid =  responseData1.response.sessionId;
                            return this.getData(url, postData)
                            .then((responseData) => {
                                // console.log(responseData);
                                return responseData;
                            })
                            //return  responseData1;
                        } 
                    }
            })
        }  
        else
        {
            return this.getData(url, postData);
        } 
    }

    httpPostWithFormData(url, postData)
    {
        var cookies = new Cookies();
        var sessionid= cookies.get('sessionid');
        var boxid = cookies.get('boxid');

        
        return fetch(url, { 
            method: 'POST' ,
            headers: {  'session-id': sessionid,
                        'box-id': boxid,    
                        'tenant-code': 'bott',
                        'Content-Type': 'application/x-www-form-urlencoded'},
            body: postData                       
           //body: "package_id=11&gateway='razorpay'&reason='testing%20testig'"
        })
        .then((response) => response.json())
        .then((responseData) => {
           // if (!responseData.ok) {
                //this.handleResponseError(responseData);
           // }
            //console.log(responseData);
            return responseData
        })
        .catch(error => {
            console.log(error);
    
          });

    }


 
    getDatawithouttoken(url, postData)
    {
        var cookies = new Cookies();
        //var sessionid= cookies.get('sessionid');
        var boxid = cookies.get('boxid');

        boxid =  this.createGuid().replace("-", "").substring(0, 16)
        cookies.set('boxid', boxid, { path: '/' });
        let  URL1 = Config.APIURL + "service/api/v1/get/token"
        let postData1 = "box_id=" + boxid + "&device_id=5&tenant_code=" + Config.TenentCode + "&device_sub_type=&product=bott&display_lang_code=eng&timezone=Asia%2FKolkata"
        //let data = ""; 
        this.httpGet(URL1 +"?" + postData1)
        .then(responseData1=> {
        if(responseData1!== undefined)
        {
            if(responseData1.status===true)
            {
                cookies.set('sessionid', responseData1.response.sessionId, { path: '/' });
               // sessionid =  responseData1.response.sessionId;
                return  responseData1;
            } 
        }
        })
    }

    getData(url, postData)
    {
        var cookies = new Cookies();
        var sessionid= cookies.get('sessionid');
        var boxid = cookies.get('boxid');
        //var tenantCode = "bott"; 

        return fetch(url, { 
            method: 'POST' ,
            headers: {  'session-id': sessionid,
                        'box-id': boxid,    
                        'tenant-code': 'bott',
                        'Content-Type': 'application/json', },
            body: postData
        })
        .then((response) => response.json())
        .then((responseData) => {
           // if (!responseData.ok) {
                //this.handleResponseError(responseData);
           // }
        
            //console.log(responseData);
            return responseData
        })
        .catch(error => {
            console.log(error);
    
          });
    }


    //Get Token 
    getToken() 
    {
        const cookies = new Cookies();
        var boxid = cookies.get('boxid');
        let  URL = Config.APIURL + "service/api/v1/get/token"
            
        let postData = "box_id=" + boxid + "&device_id=5&tenant_code=" + Config.TenentCode + "&device_sub_type=&product=bott&display_lang_code=eng&timezone=Asia%2FKolkata"
        this.httpGet(URL +"?" + postData)
        .then(responseData=> {
            if(responseData!== undefined)
            {
                if(responseData.status===true)
                {
                    cookies.set('sessionid', responseData.response.sessionId, { path: '/' });
                    return true;
                } 
                
            }
            } 
        )
    }
                
    //Create boxid
    createGuid() 
    {  
        function _p8(s) {  
            var p = (Math.random().toString(16)+"000000000").substr(2,8);  
            return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;  
        }  
        return _p8() + _p8(true) + _p8(true) + _p8();  
    }  
}
export default DataService;