import React from 'react';
import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import DataService from '../shared/service.js';
import Config from '../shared/config.js';
import OTTPackage from './package.js'
import Cookies from 'universal-cookie';

class OTTPackages extends React.Component{
    constructor(props)
    {
        super(props); 
        this.dataService = new DataService();
        this.state = {   
            packages:[]
         };

    }

    componentDidMount()  
    {
        //getting partner packages
        const cookies = new Cookies();
        var partnerId = cookies.get('partnerId');
        var partnerCookie = cookies.get('partnerCookie');
        if(partnerCookie!=="1" )
        {
          document.getElementById("myModal").style.display= 'block'; 
        }
        else{
        let domain = Config.APIURL;  
        this.dataService.httpGet(domain + "package/api/v1/packages/info/external?skip_package_content=true&partner_id=" + partnerId )
          .then(responseData=> {
            if(responseData!== undefined)
            {
                this.setState({
                    packages : responseData.response
                })
            } 
            })
        }    
        }

    render(){
    return(
        <div>
            <Header active={"packages"} showmenuitem={true}/> 
            <OTTPackage packagesList={this.state.packages}/> 
            <Footer/> 
    </div>
     )}
}
export default OTTPackages; 
