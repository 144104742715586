import React from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";


const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        position: "relative"
    },
    pdfcontainer: {
        padding: 20,
        flexGrow: 1
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "50%",
        borderStyle: "solid",
        // borderWidth: 1,
        // borderLeftWidth: 1,
        borderTopWidth: 0,
        borderRight: 1,
        borderBottomWidth: 1

    },
    tableCell: {
        // margin: "auto",
        marginTop: 5,
        fontSize: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 8,
        paddingBottom: 8,
    },


});

export function PdfDocument(props) {
    console.log("pdf props", props.data);
    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <View style={{ display: "flex", flexDirection: "row", marginBottom: 10, backgroundColor: "#222", paddingBottom: 20, paddingTop: 20, paddingLeft: 40, paddingRight: 40, }}>
                    <Image style={{ width: 100, }} src={"https://yuppstatic.akamaized.net/yupptv/yupptvscope/28feb2023/videonewlogo.png"} />
                    <Text style={{ flex: 1, textAlign: "right", color: "#fff", fontSize: "12px", paddingTop: "10px" }}>Date: {props.regifo.date}</Text>
                </View>
                <View style={{ fontSize: 22, textAlign: "center", color: "#333", marginBottom: 5, marginTop:15, fontWeight:"bold" }}>
                    <Text>
                        Registration Details
                    </Text>
                </View>
                <View style={styles.pdfcontainer}>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>Registration Number</Text></View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{props.regifo.id}</Text>
                            </View>
                        </View>
                        {props.data
                            ? Object.entries(props.data).map(([key, value], index) => {
                                return (
                                    <View key={index}>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}><Text style={styles.tableCell}>{key}</Text></View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{value}</Text>
                                            </View>
                                        </View>
                                    </View>
                                );
                            })
                            : ""}

                    </View>
                </View>
                <View style={{ top: "-300px", left: "160px", position: "relative" }}>
                    <Image style={{ width: 260, height: 275, opacity: "0.1" }} src={"https://yuppstatic.akamaized.net/yupptv/yupptv_media/pdfwatermark2.png"} />
                </View>
                <View style={{ display: "flex", flexDirection: "row", marginBottom: 0, backgroundColor: "#222", paddingBottom: 10, paddingTop: 10, paddingLeft: 40, paddingRight: 40, }}>
                    <Text style={{ flex: 1, textAlign: "left", color: "#fff", fontSize: "12px", paddingTop: "0px" }}>Reach us: support@scopevideo.com</Text>
                    <Text style={{ flex: 1, textAlign: "right", color: "#fff", fontSize: "12px", paddingTop: "0px" }}>www.scopevideo.com</Text>
                </View>

            </Page>
        </Document>
    );
}