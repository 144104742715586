import nookies from 'nookies';
import { config } from './app.config';

class StorageService {
    constructor(domain) {
        this.domain = domain || config.DomainURL;
        this.versionKey = config.versionKey;
    }

    getData(key, ctx = {}, path = '') {
        let cookies = nookies.get(ctx);
        let cookie = cookies[this.versionKey + key];
        return (!!cookie) ? JSON.parse(cookie) : "";
    }

    setData(key, value, ctx = {}, expires = 30 * 24, path = '') {
        nookies.set(ctx, this.versionKey + key, JSON.stringify(value), {
            maxAge: expires * 60 * 60,
            path: '/',
        })
        return true;
    }
    remove(key, ctx, path = '') {
        nookies.destroy(ctx, this.versionKey + key);
        return true;
    }

    removeAll() {
        this.remove("isLoggedIn", {});
        this.remove("SessionData", {});
        try {
            localStorage.clear();
        }
        catch (e) {
            //if it comes here then, this is server..
        }
    }

    getLocalData(id) {
        let key = this.versionKey + id.toLowerCase();
        let data = ''
        try {
            data = localStorage?.getItem(key);
        } catch (error) {
            console.log(error)
        }
        return (!!data) ? JSON.parse(data) : '';
        // return JSON.parse(localStorage.getItem(key));
    }

    setLocalData(id, value) {
        let key = this.versionKey + id.toLowerCase();
        let data = JSON.stringify(value);
        localStorage.setItem(key, data);
    }

    removeLocalData(id) {
        let key = this.versionKey + id.toLowerCase();
        localStorage.removeItem(key);
    }

    getSessionData(id) {
        let key = this.versionKey + id.toLowerCase();
        let data = ''
        try {
            data = sessionStorage?.getItem(key);
        } catch (error) {
            console.log(error)
        }
        return (!!data) ? JSON.parse(data) : '';
    }

    removeSessionData(id) {
        let key = this.versionKey + id.toLowerCase();
        sessionStorage.removeItem(key);
    }

    setSessionData(id, value) {
        let key = this.versionKey + id.toLowerCase();
        let data = JSON.stringify(value);
        sessionStorage.setItem(key, data);
    }

    clearSession(ctx) {
        try {
            localStorage.clear();
            var cookies = nookies.get(ctx);
            for (var key in cookies) {
                if (cookies.hasOwnProperty(key)) {
                    nookies.destroy(ctx, key, {
                        path: '/', // THE KEY IS TO SET THE SAME PATH
                    });
                }
            }
            sessionStorage.clear();
        }
        catch (e) {
        }
    }
}

export default StorageService;