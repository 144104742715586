import React from 'react';
import '../shared/css/style.css';
import './footer.css';
import Config from '../shared/config';



class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resp: null,
            isLoaded: true,
            items: []
        };
    }

    render() {
        return (

            <div>
                <footer>
                    <div className="footer-wrap pt-50 pb-0 ">
                        <div className="custom-container">

                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 bor_rig">
                                    <div className="footer-widget ">
                                        <div className="footer-text">
                                            <img src="https://yuppstatic.akamaized.net/yupptv/yupptvscope/28feb2023/videonewlogo.png" className="img-fluid" style={{ height: "50px;" }} />
                                            <p>With single subscription, watch both Live TV & OTT content. Watch 200+ Live channels, 500+ TV Shows & 5000+ Movies from our partner apps.</p>
                                        </div>
                                        <div className="footer-social">
                                            <div className="row">
                                                <div className="col-xl-5 col-lg-5 col-md-5 padtab0" style={{ paddingRight: "0" }}>
                                                    <h6 className="sup-txt">For Superior Experience </h6>
                                                    <p className="sup-sub-txt">Download our App available on</p>
                                                </div>
                                                <div className="col-xl-7 col-lg-7 col-md-7  py-0">
                                                    <div className="f-support-content row d-flex my-0 py-0">
                                                        <a className="f-download-btn" href="https://apps.apple.com/in/app/yupptv-scope/id1542906453" target="_blank" ><img src={"https://yuppstatic.akamaized.net/yupptv/bsnl/20220308/app-store.png"} class="img-fluid col-xs-6" style={{ height: "30px" }} alt="img" /></a>
                                                        <a className="f-download-btn" href="https://play.google.com/store/apps/details?id=com.yupptv.scope" target="_blank"><img src={"https://yuppstatic.akamaized.net/yupptv/bsnl/20220308/google-play.png"} class="img-fluid col-xs-6" style={{ height: "30px" }} alt="img" /></a>
                                                        <a className="f-download-btn" href="https://play.google.com/store/apps/details?id=com.yupptv.scope" target="_blank"><img src={"https://yuppstatic.akamaized.net/yupptv/bsnl/20220308/androidtv.png"} class="img-fluid col-xs-6" style={{ height: "30px" }} alt="img" /></a>
                                                        <a className="f-download-btn" href="https://www.amazon.in/gp/product/B08PP774FZ" target="_blank"><img src={"https://yuppstatic.akamaized.net/yupptv/bsnl/20220308/firetv.png"} class="img-fluid col-xs-6" style={{ height: "30px" }} alt="img" /></a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 bor_rig bor_lef  pt-0">
                                    <div className="footer-widget ">
                                        <div className="fw-title mb-30 ">
                                            <h5>What we have?</h5>
                                        </div>
                                        <div className="fw-link">
                                            <ul>
                                                <li><a href="https://www.scopevideo.com/livetv" target="_blank"><i className="fa fa-caret-right"></i> Live TV</a></li>
                                                <li><a href="https://www.scopevideo.com/catchup" target="_blank"><i className="fa fa-caret-right"></i> Catchup</a></li>
                                                <li><a href="https://www.scopevideo.com/movies" target="_blank"><i className="fa fa-caret-right"></i> Movies</a></li>
                                                <li><a href="https://www.scopevideo.com/tvshows" target="_blank"><i className="fa fa-caret-right"></i> TV Shows</a></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 bor_rig bor_lef">
                                    <div className="footer-widget ">
                                        <div className="fw-title mb-30">
                                            <h5>Connect with us</h5>
                                        </div>
                                        <div className="fw-link">
                                            <ul>
                                                <li><a style={{ cursor: 'pointer' }}><i className="fa fa-facebook-f"></i> Facebook</a></li>
                                                <li><a style={{ cursor: 'pointer' }}><i className="fa fa-instagram"></i> Instagram</a></li>
                                                <li><a style={{ cursor: 'pointer' }}><i className="fa fa-youtube" target="_blank"></i> Youtube</a></li>
                                                <li><a style={{ cursor: 'pointer' }}><i className="fa fa-rss" aria-hidden="true"></i> Blog</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-6 bor_lef">
                                    <div className="footer-widget ">
                                        <div className="fw-title mb-30">
                                            <h5>Need help?</h5>
                                        </div>
                                        <div className="fw-link">
                                            <ul>

                                                <li><a href={Config.BaseRootURL + "help/activationsteps"} target="_blank"><i className="fa fa-caret-right" ></i> Activation Steps</a></li>
                                                <li><a href={Config.BaseRootURL + "help/faqs"} target="_blank"><i className="fa fa-caret-right"></i> FAQ's</a></li>
                                                <li><a href={Config.BaseRootURL + "help/privacypolicy"} target="_blank"><i className="fa fa-caret-right"></i> Privacy Policy</a></li>
                                                <li><a href={Config.BaseRootURL + "help/termsandconditions"} target="_blank"><i className="fa fa-caret-right"></i> Terms and Conditions</a></li>
                                                <li><a href={Config.BaseRootURL + "help/contactus"} target="_blank"><i className="fa fa-caret-right"></i> Contact Us</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>
            </div>

        );
    }
}

export default Footer;