import React from "react";
import { useState, useEffect } from "react";
import Header from "../header/header";
import Footer from '../footer/footer.js';
import Terms from "./terms";
import ISPDetails from "./ispdetails";
import ISPPreview from "./isppreview";
import Confirmation from "./confirmation";
import AuthenticationService from "../shared/utils/auth.service";
import { config } from "../shared/utils/app.config";

export default function Registration() {
    const [showregistrationprocess, setRegistrationprocess] = useState({ showtrems: true, showispdetails: false, showisppreview: false, showconfirm: false })
    const [registrationdetails, setRegistrationdetails] = useState({})
    const [regNumber, setRegnumber] = useState('');
    const [regError, setRegError] = useState(false);
    const [regErrorDetails, setregErrorDetails] = useState('');

    // useEffect(() => {
    //     let service = new AuthenticationService();
    //     service.getSessionID();
    // }, [])

    function showispdetails() {
        setRegistrationprocess({ ...showregistrationprocess, showtrems: false, showispdetails: true })
    }

    async function submitisppreview(type, obj) {
        if (type == "edit") {
            setRegError(false);
            setRegistrationdetails(obj)
            setRegistrationprocess({ ...showregistrationprocess, showtrems: false, showispdetails: true, showisppreview: false })
        } else if (type == "submit") {
            let service = new AuthenticationService();
            let registrationdetails = {}
            if (obj["CIN Number"] != "")
                registrationdetails = { "termsConditions": true, "companyName": obj["Company Name"], "emailId": obj["Email ID"], "rmn": obj["Phone Number"], "ispLicense": obj["ISP License"], "panCardNumber": obj["PAN Number"], "gstNumber": obj["GST Number"], "companyAddress": obj["Company Address"], "authorityName": obj["Authority"], "cinNumber": obj["CIN Number"], "state": obj["State"], "confirmDetail": true }
            else
                registrationdetails = { "termsConditions": true, "companyName": obj["Company Name"], "emailId": obj["Email ID"], "rmn": obj["Phone Number"], "ispLicense": obj["ISP License"], "panCardNumber": obj["PAN Number"], "gstNumber": obj["GST Number"], "companyAddress": obj["Company Address"], "authorityName": obj["Authority"], "state": obj["State"], "confirmDetail": true }
            let response = await service.postData(config.APIURL + config.ispregisterapi, registrationdetails)
            if (response?.data?.status) {
                setRegnumber(response?.data?.response.registrationId)
                setRegistrationprocess({ ...showregistrationprocess, showtrems: false, showispdetails: false, showisppreview: false, showconfirm: true })
            } else {
                setRegError(true);
                setregErrorDetails(response?.data?.error?.type)
                setRegistrationprocess({ ...showregistrationprocess, showtrems: false, showispdetails: false, showisppreview: true, showconfirm: false })
            }
        }
    }

    function RegisterISP(type, obj) {
        window.scrollTo(0, 0);
        setRegistrationdetails(obj);
        if (type == "previous") {
            setRegistrationprocess({ ...showregistrationprocess, showtrems: true, showispdetails: false })
        } else {
            setRegistrationprocess({ ...showregistrationprocess, showtrems: false, showispdetails: false, showisppreview: true })
        }
    }
    return (
        <div>
            <Header showmenuitem={false} />
            <div>
                {
                    showregistrationprocess.showtrems &&
                    <Terms termsnext={showispdetails} />
                }
                {
                    showregistrationprocess.showispdetails &&
                    <ISPDetails submitisp={RegisterISP} details={registrationdetails} />
                }
                {
                    showregistrationprocess.showisppreview &&
                    <ISPPreview previewnext={submitisppreview} details={registrationdetails} regerror={regError} errormsg={regErrorDetails} />
                }
                {
                    showregistrationprocess.showconfirm &&
                    <Confirmation details={registrationdetails} registrationnum={regNumber} />
                }
            </div>
            <Footer />
        </div>
    )
}