import React from 'react';
import './AccountSummary.css';
import Header from './header/header.js';
import Footer from './footer/footer.js';
import Config from './shared/config';
import DataService from './shared/service';
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js';

class AccountSummary extends React.Component {

  constructor(props) {
    super(props);
    this.pkg = this.props.location.state.OTTPackage;
    this.state = { seconds: 20 };
    //this.seconds= 20;
    this.timer = 0;
    this.refId = "-1";
    this.otpRefId = "-1";
    this.dataService = new DataService();
    this.orderId = "-1";
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    const cookies = new Cookies();
    this.boxId = cookies.get('boxid');
    this.sessionId = cookies.get('sessionid');
  }

  componentDidMount() {
    const cookies = new Cookies();
    var token = cookies.get('sessionid');
    var BoxId = cookies.get('boxid');
    var encnumber = cookies.get('number');
    //Token verification 
    if (BoxId == null || token == null) {
      BoxId = this.dataService.createGuid().replace("-", "").substring(0, 16)
      cookies.set('boxid', BoxId, { path: '/' });
      this.dataService.getToken();
    }

    if (encnumber != null) {
      var bytes = CryptoJS.AES.decrypt(encnumber.toString(), Config.secreteKey);
      var number = bytes.toString(CryptoJS.enc.Utf8);
      //document.getElementById("txtMobileNumber").value = cookies.get('number');
      document.getElementById("accountchk").style.display = "block";
      document.getElementById("headMNumber").innerHTML = number;
      document.getElementById("actDet").style.display = "none";
      document.getElementById("pay-active").classList.add("pay-active-cl");
      document.getElementById("line-change").classList.add("line-active-c2");
      document.getElementById("acc-line").classList.add("line-active-c3");
      document.getElementById("paymentdet").style.display = "block";
      document.getElementById("logout").style.display = "block";
      document.getElementById("txtMobileNumber").readOnly = false;
      //if(cookies.get('package')!== undefined)
      //{
      //  this.pkg = cookies.get('package'); 
      // }
    }
    else {
      document.getElementById("btnSubmit").value = "Sign In";
      document.getElementById("txtMobileNumber").style.display = "none";
      document.getElementById("country-block").style.display = "none";
      document.getElementById("txtOTP").style.display = "none";
      document.getElementById("txtMobileNumber").readOnly = true;

    }
  }

  // display pacakge features 
  getFeatures(packageFeatures) {
    if (packageFeatures !== null) {
      return (
        <ul>
          {packageFeatures.map(item => (
            <li key={item.name}>
              <h4>{item.name}</h4>
              <p>{item.description}</p>
            </li>
          )
          )}
        </ul>
      )
    }
  }

  //signin/send otp button text changes
  signinClick() {
    if (document.getElementById("btnSubmit").value === "Sign In") {
      document.getElementById("btnSubmit").value = "Send OTP";
      document.getElementById("txtMobileNumber").style.display = "block";
      document.getElementById("country-block").style.display = "block";
      document.getElementById("txtMobileNumber").readOnly = false;
      document.getElementById("headMNumber").innerHTML = "Please enter your mobile number"
    }
    else if (document.getElementById("btnSubmit").value === "Send OTP") {
      if (document.getElementById("txtMobileNumber").value === "") {
        document.getElementById("lblerror").style.display = "block";
        document.getElementById("lblerror").innerHTML = "Please enter your mobile number";

        return;
      }
      this.signinInit();
    }
  }
  // verify click button 
  verifyClick() {
    if (document.getElementById("txtOTP").value === "") {
      document.getElementById("lblerror").style.display = "block";
      document.getElementById("lblerror").innerHTML = "Please enter OTP";

      return;
    }
    this.signinComplete("0");
  }


  signinInit() {
    var res = this.phoneValidation();
    if (res) {
      this.signin();
    }
  }
  //sending OTP
  signin() {
    let URL = "";
    let postData = "";
    URL = Config.APIURL + "service/api/auth/v2/signup";
    let mno = document.getElementById("txtMobileNumber").value;
    let CountryCode = document.getElementById("country-block").innerHTML;
    // mno = CountryCode +   mno;
    mno = CountryCode.replace("+", "") + mno;
    postData = JSON.stringify({ mobile: mno, })
    //var cookies = new Cookies();
    //var sessionid= cookies.get('sessionid');
    //var boxid = cookies.get('boxid');
    document.getElementById("lblerror").innerHTML = "";
    document.getElementById("txtOTP").value = "";
    this.timer = 0;
    this.setState({ seconds: 20, });
    // var responseData= JSON.parse('{"status": "false", "error": {"code": "-40",  "details": {"referenceId": "0", "otpreferenceId": "1689", "phoneNumber": "91-9949957358"}}}');
    this.dataService.httpPostWithHeaderData(URL, postData)
      .then(responseData => {
        if (responseData !== undefined) {
          if (responseData.error !== undefined) {
            if (responseData.error.details.referenceId !== undefined) {
              { this.refId = responseData.error.details.referenceId }
              { this.otpRefId = responseData.error.details.otpreferenceId }
              document.getElementById("txtOTP").style.display = "block";
              document.getElementById("btnSubmit").style.display = "none";
              document.getElementById("btnVerify").style.display = "block";
              document.getElementById("txtMobileNumber").readOnly = true;

              document.getElementById("logout").style.display = "block";
              //document.getElementsByClassName("country-code").style.display="none";
              document.getElementById("btnresendOTP").style.display = "block";
              document.getElementById("spnTimer").style.display = "block";
              //document.getElementsByClassName("country-code").style.display="block";
              document.getElementById("headMNumber").innerHTML = "Enter the One-Time-Password (OTP) sent to your mobile number "
              document.getElementById("lblNumber").style.display = "block";
              document.getElementById("txtMobileNumber").classList.add("mob-border");
              document.getElementById("country-block").classList.add("country-mob-border");
              document.getElementById("lblerror").innerHTML = "";


              /* Timer function*/
              //this.state.seconds= 20;
              //clearInterval(this.timer);
              this.startTimer();
            }
            else {
              if (responseData.error.message !== undefined) {
                document.getElementById("lblerror").style.display = "block";
                document.getElementById("lblerror").innerHTML = responseData.error.message;
              }
            }
          }
        }
      })
  }

  //verifing OTP
  signinComplete(confirm) {
    const cookies = new Cookies();
    let URL = "";
    let postData = "";

    URL = Config.APIURL + "service/api/auth/v2/signup/complete";
    let OTP = Number(document.getElementById("txtOTP").value);
    let mno = document.getElementById("txtMobileNumber").value;
    let CountryCode = document.getElementById("country-block").innerHTML;
    mno = CountryCode + "-" + mno;
    if (confirm === "1") {
      postData = JSON.stringify({ mobile: mno, reference_id: Number(this.refId), otp: OTP, additional_info: "f_web_login" })
    }
    else {
      postData = JSON.stringify({ mobile: mno, reference_id: Number(this.refId), otp: OTP })
    }
    this.dataService.httpPostWithHeaderData(URL, postData)
      .then(responseData => {
        if (responseData !== undefined) {
          if (responseData.status === true) {
            document.getElementById("accountchk").style.display = "block";
            document.getElementById("headMNumber").innerHTML = mno;
            document.getElementById("actDet").style.display = "none";
            document.getElementById("paymentdet").style.display = "block";
            document.getElementById("pay-active").classList.add("pay-active-cl");
            document.getElementById("line-change").classList.add("line-active-c2");
            document.getElementById("acc-line").classList.add("line-active-c3");
            document.getElementById("paymentdet").style.display = "block";
            var ciphertext = CryptoJS.AES.encrypt(mno.trim(), Config.secreteKey).toString();
            cookies.set('number', ciphertext, { path: '/' });
            document.getElementById("ancSignin1").style.display = "none";
            document.getElementById("ancSignin2").style.display = "none";
            document.getElementById("ancPhone1").innerHTML = mno;
            document.getElementById("ancPhone2").innerHTML = mno;
            document.getElementById("divhdr1").style.display = "block";
            document.getElementById("divhdr2").style.display = "block";
            document.getElementById("divLogoutAll").style.display = "none";
          }
          else {
            if (responseData.error.code !== 101) {
              document.getElementById("lblerror").style.display = "block";
              document.getElementById("lblerror").innerHTML = responseData.error.message;
              return;
            }
            else {
              document.getElementById("actDet").style.display = "none";
              document.getElementById("divLogoutAll").style.display = "block";
            }

          }
        }
      })
  }

  // signout 
  signoutClick() {
    const cookies = new Cookies();
    let URL = "";
    //let postData="";
    URL = Config.APIURL + "service/api/auth/signout";
    cookies.remove('boxid');
    cookies.remove('sessionid');
    cookies.remove('number');
    cookies.remove('partnerId');
    cookies.remove('partnerCookie');
    clearInterval(this.timer);
    this.dataService.httpPostWithHeaderData(URL, "null")
      .then(responseData => {
        if (responseData !== undefined) {
          if (responseData.status === true) {
            cookies.remove('boxid');
            cookies.remove('sessionid');
            cookies.remove('number');
            cookies.remove('partnerId');
            cookies.remove('partnerCookie');
          }
          else {
            if (responseData.error.message !== undefined) {
              //document.getElementById("lblerror").style.display="block";
              //document.getElementById("lblerror").innerHTML= responseData.error.message; 
            }
          }
        }
      })

    document.getElementById("btnSubmit").value = "Send OTP";
    document.getElementById("txtMobileNumber").style.display = "block";
    document.getElementById("txtMobileNumber").readOnly = false;
    document.getElementById("country-block").style.display = "block";
    document.getElementById("btnSubmit").style.display = "block";
    document.getElementById("txtOTP").style.display = "none";
    document.getElementById("btnVerify").style.display = "none";
    document.getElementById("btnresendOTP").style.display = "none";
    document.getElementById("spnTimer").style.display = "none";
    //document.getElementsByClassName("country-code").style.display="none";
    document.getElementById("logout").style.display = "none";
    document.getElementById("actDet").style.display = "block";
    document.getElementById("accountchk").style.display = "none";
    document.getElementById("headMNumber").innerHTML = "To place your order now, sign in to your account.";
    document.getElementById("lblerror").innerHTML = "";
    document.getElementById("paymentdet").style.display = "none";
    document.getElementById("divLogoutAll").style.display = "none";
    document.getElementById("lblNumber").style.display = "none";
    document.getElementById("txtMobileNumber").classList.remove("mob-border");  // mobpad
    document.getElementById("country-block").classList.remove("country-mob-border"); //country-code
    document.getElementById("txtMobileNumber").classList.add("mobpad");  // mobpad
    document.getElementById("country-block").classList.add("country-code"); //country-code



  }
  //buy button -- purchase package click 
  buybtnClick() {
    const cookies = new Cookies();
    let domain = Config.APIURL;
    var partvalue;

    for (var i = 0; i < document.getElementsByName("custom-radio-btn").length; i++) {
      if (document.getElementsByName("custom-radio-btn")[i].checked) {
        partvalue = document.getElementsByName("custom-radio-btn")[i].value;
      }
    }

    let postData = JSON.stringify({ packages: this.pkg.packageInfo.packages[0].id.toString(), gateway: "razorpay", rzpaytype: partvalue })
    //let postData = JSON.stringify({packages:"11",gateway:"razorpay",rzpaytype:partvalue})
    var encnumber = cookies.get('number');
    var bytes = CryptoJS.AES.decrypt(encnumber.toString(), Config.secreteKey);
    var number = bytes.toString(CryptoJS.enc.Utf8);
    number = number.replace('+', '');
    number = number.replace('91-', '');
    var emailadd = number + "@yupptv.com";
    this.dataService.httpPostWithHeaderData(domain + "payment/api/v1/order/checkout", postData)
      .then(responseData => {
        if (responseData !== undefined) {
          if (responseData.status === true) {
            cookies.set('packageId', this.pkg.packageInfo.packages[0].id.toString(), { path: '/' })
            cookies.set('package', this.pkg, { path: '/' });
            if (partvalue !== "recur") {
              // hosted checkout page redirection 
              this.orderId = responseData.response.orderId;
              cookies.set('orderId', this.orderId, { path: '/' });
              cookies.remove('boxid');
              cookies.remove('sessionid');
              cookies.remove('packageId');
              cookies.remove('package');
              cookies.set('orderId', this.orderId, { path: '/', maxAge: 360000 });
              cookies.set('boxid', this.boxId, { path: '/', maxAge: 360000 });
              cookies.set('sessionid', this.sessionId, { path: '/' });
              cookies.set('packageId', this.pkg.packageInfo.packages[0].id.toString(), { path: '/' })
              cookies.set('package', this.pkg, { path: '/' });

              document.getElementById("orderid").value = responseData.response.targetParams.razorpay_order_id;
              document.getElementById("companyname").value = "YuppTV";
              document.getElementById("desc").value = this.pkg.packageInfo.master.name;
              document.getElementById("userName").value = "";
              document.getElementById("userContact").value = number;
              document.getElementById("userEmail").value = emailadd;
              document.getElementById("userAdd").value = "";
              document.getElementById("payform").submit();
            }
            else {
              this.orderId = responseData.response.orderId;
              cookies.set('orderId', this.orderId, { path: '/' });
              // custome checkout page redirection 
              var options = {
                "key": Config.razorKey, // Enter the Key ID generated from the Dashboard
                "name": "YuppTV",
                "description": this.pkg.packageInfo.master.name,
                "image": "https://yuppstatic.akamaized.net/yupptv/roku/images/yupptv_black.svg",
                "order_id": responseData.response.targetParams.razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "subscription_id": responseData.response.targetParams.razorpay_subscription_id,
                "handler": function (response) {
                  window.location.href = Config.BaseRootURL + "thankyou?result=success"
                },
                "prefill": {
                  "name": "",
                  "email": emailadd,
                  "contact": number,
                },
                "notes": {
                  "address": ""
                },
                "theme": {
                  "color": "#3745a9"
                }
              };
              var rzp1 = new window.Razorpay(options);
              rzp1.open();
            }
          }
          else {
            document.getElementById("lblpaymenterror").style.display = "block";
            document.getElementById("lblpaymenterror").innerHTML = responseData.error.message;
          }

        }
        else {
          document.getElementById("lblpaymenterror").style.display = "block";
          document.getElementById("lblpaymenterror").innerHTML = responseData.error.message;
        }
        //} 
      })
  }

  resendClick() {
    let URL = "";
    let postData = "";
    URL = Config.APIURL + "service/api/auth/resend/otp";
    let ref = this.otpRefId;
    postData = JSON.stringify({ reference_id: Number(ref), })
    document.getElementById("lblerror").innerHTML = "";
    document.getElementById("txtOTP").value = "";

    this.dataService.httpPostWithHeaderData(URL, postData)
      .then(responseData => {
        //console.log("resend Response");
        //console.log(responseData);

        // var responseData= JSON.parse('{"status": true}');

        if (responseData !== undefined) {
          if (responseData.status === true) {
            //clearInterval(this.timer);
            this.timer = 0;
            this.startTimer();
          }
          else {
            document.getElementById("lblerror").style.display = "block";
            document.getElementById("lblerror").innerHTML = responseData.error.message;
          }
        }
      })
  }

  startTimer() {

    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown.bind(this), 1000);
      document.getElementById("btnresendOTP").style.color = "#888";
    }
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      seconds: seconds,
    });
    var button = document.getElementById("btnresendOTP");
    //document.getElementById("btnSubmit").value= "Verify OTP"; 
    if (seconds.toString().length === 2) {
      document.getElementById("spnTimer").innerHTML = "(00:" + seconds + ")";
      button.disabled = true;
    } else {
      document.getElementById("spnTimer").innerHTML = "(00:0" + seconds + ")";
      button.disabled = true;
    }
    // Check if we're at zero.
    if (seconds === 0) {
      document.getElementById("spnTimer").innerHTML = "";
      button.disabled = false;
      button.style.color = "#d52912";
      this.setState({ seconds: 20, });
      clearInterval(this.timer);

      //document.getElementById("btnSubmit").value= "Verify OTP"; 
    }

  }
  phoneValidation() {
    if (document.getElementById('txtMobileNumber').value !== "") {
      var Phno;
      var valid = false;
      Phno = document.getElementById('txtMobileNumber').value
      //var validChars = "0123456789-+ "
      //var i = 0
      if (Phno.length == 10) {
        if (!Phno.match(/^[0-9]+$/)) {
          valid = false;
        }
        else
          valid = true;
      }
      if (valid === false) {
        //document.getElementById('lblEmailMsg').innerHTML = ""
        document.getElementById("lblerror").style.display = "block";
        document.getElementById("lblerror").innerHTML = "Please enter a valid Mobile Number";
        return (false);
      }
    }
    return true;
  }



  onRadioOnChange(partnerName) {
    var part = document.getElementById(partnerName);
    part.checked = true;
    document.getElementById("lblpaymenterror").innerHTML = "";
  }

  //payment Buttons 
  getButtons(displayButtons) {

    if (displayButtons !== null) {
      return (
        <ul>
          <li className="payment-card-block payment-botmar" style={{ display: displayButtons.BtnRecurShow === true ? "block" : "none", cursor: "pointer" }} onClick={this.onRadioOnChange.bind(this, "recur")}>
            <div className="cred-icon">
              <span className="cred-head-text">Credit &amp; Debit cards</span>
              <span className="cred-sub-text">{displayButtons.BtnRecurText} </span>
              <span className="radio-wrap"><div className="form-group">
                <input id="recur" type="radio" name="custom-radio-btn" value={displayButtons.BtnRecur} onChange={this.onRadioOnChange.bind(this, "recur")} defaultChecked />
                <label className="custom-radio" htmlFor="recur"></label></div> </span>
            </div>
          </li>

          <li className="payment-card-block" style={{ display: displayButtons.BtnNonRecurShow === true ? "block" : "none", cursor: "pointer" }} onClick={this.onRadioOnChange.bind(this, "nonrecur")} >
            <div className="wallet-pay">
              <span className="cred-head-text">Other Payments</span>
              <span className="cred-sub-text">{displayButtons.BtnNonRecurText} </span>
              <span className="radio-wrap"><div className="form-group">
                <input id="nonrecur" type="radio" name="custom-radio-btn" value={displayButtons.BtnNonRecur} onChange={this.onRadioOnChange.bind(this, "nonrecur")} />
                <label className="custom-radio" htmlFor="nonrecur"></label></div> </span>
            </div>
          </li>

        </ul>

      )
    }

  }


  /* handling enter key */
  handleKeyPressMobile = (event) => {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode = 47 && charCode > 31 && (charCode < 48 || charCode > 57))
      event.preventDefault();
    if (event.key === "Enter") {
      this.signinClick();
    }
    return true;
  }
  handleKeyup = (event) => {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
    return false;
  }
  handleKeyPressOTP = (event) => {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode = 47 && charCode > 31 && (charCode < 48 || charCode > 57))
      event.preventDefault();
    if (event.key === "Enter") {
      this.verifyClick();
    }
    return true;
  }
  handleKeyupOTP = (event) => {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
    return false;
  }
  render() {
    return (
      <div className="account-summary">
        <Header showmenuitem={true} />
        <div className="account-container">
          <div className="row">
            <div className="col-md-8 pr5">
              <div className="progress-bar-active">

                <div className="accounts-block ">
                  <div className="account-progress" id="acc-line"></div>
                  <div className="line-prograssbar" id="line-change"></div>
                  <h2 className="account-headding">Account
                    <div className="checkmark-circle1" id="accountchk" style={{ display: 'none' }}>
                      <div className="background"></div>
                      <div className="checkmark1 draw" ></div>
                    </div>
                  </h2>
                  <h4 id="headMNumber" className="account-sub-text">To place your order now, sign in to your account.</h4>
                  {/* <h4 id="logout"className="account-sub-text accept-tc" style={{display:'none'}} onClick={this.signoutClick.bind(this)} > login with different number</h4> */}

                  <div id="actDet" className="account-input">
                    <div className="form-group form-group-parent">
                      <span id="lblNumber" style={{ display: 'none' }} >Mobile number</span>
                      <input type="tel" id="txtMobileNumber" style={{ backgroundColor: '#fff' }} className="form-control mobpad" placeholder="Enter Your Mobile Number" onKeyPress={this.handleKeyPressMobile} onKeyUp={this.handleKeyupOTP} maxLength="10" />
                      <span className="country-code" id="country-block">+91</span>
                      {/* <span className="chng-txt" id="change-block">Change */}
                      <span id="logout" className="chng-txt" style={{ display: 'none' }} onClick={this.signoutClick.bind(this)} >Change</span>
                      <input type="password" id="txtOTP" className="form-control" placeholder="Enter Your OTP" onKeyPress={this.handleKeyPressOTP} onKeyUp={this.handleKeyupOTP} style={{ display: 'none' }} maxLength="4" />
                    </div>
                    <div className="form-group form-group-parent btns-mart">
                      <input type="button" id="btnSubmit" className="form-control btn btn-danger" onClick={this.signinClick.bind(this)} value="Send OTP" />
                      <input type="button" id="btnVerify" className="form-control btn btn-danger" style={{ display: 'none' }} onClick={this.verifyClick.bind(this)} value="Verify OTP" />
                      <p id="lblerror" className="error-msg" style={{ display: 'none' }}>Please enter your mobile number</p>
                      <input type="button" className="resend-btn" id="btnresendOTP" onClick={this.resendClick.bind(this)} value="Resend OTP" style={{ display: 'none' }} />
                      <span id="spnTimer" style={{ color: '#d52912', display: 'none' }}></span>
                      <span className="accept-tc">By creating an account, I accept the <a href="help/termsandconditions">Terms & Conditions</a></span>
                    </div>
                  </div>

                  <div id="divLogoutAll" style={{ display: "none" }}>

                    <div className="">
                      You have logged in another devices also, login here will automatically logout from all other login devices.
                      Please confirm.
                    </div>


                    <div className="">
                      <label id="lblError"></label>
                    </div>


                    <div className="account-both-button">
                      <input type="submit" id="btnContinue" className="btn confirm-btn" onClick={this.signinComplete.bind(this, "1")} value="Confirm" />
                      <input type="submit" id="btnCancel" className="btn cancel-btn" onClick={this.signoutClick.bind(this)} value="Cancel" />
                    </div>


                  </div>

                </div>
                <div className="payement-block">
                  <div className="payment-progress" id="pay-active"></div>
                  <h2 className="account-headding">Payment</h2>
                  <div id="paymentdet" style={{ display: 'none' }}>
                    <div className="account-input">
                      <div className="channel-total-block">
                        {/* <ul>
                       <li className="payment-card-block payment-botmar">
                       <div className="cred-icon">
                       <span className="cred-head-text">Credit & Debit cards</span>
                       <span className="cred-sub-text">Pay with any Credit/Debit cards</span>
                       <span className="radio-wrap"><div class="form-group"><input id="cc" type="radio" name="custom-radio-btn"/><label class="custom-radio" for="cc"></label></div> </span>
                       </div>
                       </li>

                       <li className="payment-card-block">
                       <div className="wallet-pay">
                       <span className="cred-head-text">Other Payments</span>
                       <span className="cred-sub-text">Pay via Net Banking, Wallet, or UPI</span>
                       <span className="radio-wrap"><div class="form-group"><input id="other-pay" type="radio" name="custom-radio-btn"/><label class="custom-radio" for="other-pay"></label></div> </span>
                       </div>
                       </li>
             
                       </ul> */}

                        <div className="form-group">

                          {this.getButtons(this.pkg.displayButtons)}
                          <input type="button" id="btnPayment" className="form-control pay-now-btn" onClick={this.buybtnClick.bind(this)} value="Pay Now" />
                          <p id="lblpaymenterror" className="error-msg" style={{ display: 'none' }}> </p>
                        </div>

                      </div>

                    </div>
                  </div>
                  <form method="POST" action="https://api.razorpay.com/v1/checkout/embedded" id="payform">
                    <input type="hidden" id="keyid" name="key_id" value={Config.razorKey} />
                    <input type="hidden" id="orderid" name="order_id" value="order_EGC2mjUNz1DhC2" />
                    {/* <input type="hidden" id="subscription_id"name="subscription_id" value="order_EGC2mjUNz1DhC2"/> */}
                    <input type="hidden" id="companyname" name="name" value="" />
                    <input type="hidden" id="desc" name="description" value="" />
                    <input type="hidden" id="image" name="image" value="https://yuppstatic.akamaized.net/yupptv/roku/images/yupptv_black.svg" />
                    <input type="hidden" id="userName" name="prefill[name]" value="" />
                    <input type="hidden" id="userContact" name="prefill[contact]" value="" />
                    <input type="hidden" id="userEmail" name="prefill[email]" value="" />
                    <input type="hidden" id="userAdd" name="notes[shipping address]" value="" />
                    <input type="hidden" id="callback" name="callback_url" value={Config.callbackURL + 'payment'} />
                    <input type="hidden" id="cancelurl" name="cancel_url" value={Config.BaseRootURL} />
                    <input type="hidden" id="paymethod" name="method[card]" value="false" />

                  </form>
                  {/* <input type="button" id="btnPayment" className="form-control btn btn-danger" onClick={this.buybtnClick.bind(this)} value="Payment"/> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 order-mob">
              <div className="summary-block">
                <div className="order-head"><span>Order Summary</span> <span>Price</span></div>
                <div className="order-price"><span>{this.pkg.packageInfo.master.name} Plan</span> <span>{this.pkg.packageInfo.packages[0].attributes.CurrencySymbol.value} {this.pkg.packageInfo.packages[0].listPrice}/Month </span></div>
                <div className="offer-content">
                  {this.getFeatures(this.pkg.packageInfo.packages[0].features)}
                </div>
                <div className="order-foot"><span>To Pay</span> <span>{this.pkg.packageInfo.packages[0].attributes.CurrencySymbol.value} {this.pkg.packageInfo.packages[0].listPrice}/Month </span></div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}


export default AccountSummary;