import React from 'react';
import './staticHeader.css';
import Config from '../shared/config.js';
import DataService from '../shared/service';
import Cookies from 'universal-cookie';
import '../popup/popup.css';
import CryptoJS from 'crypto-js';
import $ from 'jquery';

class StaticHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resp: null,
      isLoaded: true,
      partners: []
    };
    this.dataService = new DataService();
  }

  componentDidMount() {
    const cookies = new Cookies();
    var token = cookies.get('sessionid');
    var BoxId = cookies.get('boxid');

    //Token verification 
    if (BoxId == null && token == null) {
      // BoxId =  this.dataService.createGuid().replace("-", "").substring(0, 16);
      // cookies.set('boxid', BoxId, { path: '/' });
      // this.dataService.getToken()
    }

    //Encrypted number 
    var encnumber = cookies.get('number');

    if (encnumber == null) {
      // document.getElementById("ancSignin1").style.display="block";
      // document.getElementById("ancSignin2").style.display="block";
      // document.getElementById("divhdr1").style.display = "none";
      // document.getElementById("divhdr2").style.display="none";
    }
    else {
      var bytes = CryptoJS.AES.decrypt(encnumber.toString(), Config.secreteKey);
      var number = bytes.toString(CryptoJS.enc.Utf8);

      // document.getElementById("ancSignin1").style.display="none";
      // document.getElementById("ancSignin2").style.display="none";
      document.getElementById("ancPhone1").innerHTML = number;
      document.getElementById("ancPhone2").innerHTML = number;
      // document.getElementById("divhdr1").style.display = "block";
      // document.getElementById("divhdr2").style.display="block";
    }

    //Get package partners list 
    // let domain = Config.APIURL;  
    // this.dataService.httpGet(domain + "package/api/external/v2/partner/list")
    //     .then(responseData=> {
    //     if(responseData!== undefined)
    //     { 
    //             console.log(responseData.response);
    //         this.setState({
    //             partners : responseData.response
    //         })
    //     } 
    //     })

  }


  //toggle pakage partners popup
  togglePopup1() {
    const cookies = new Cookies();
    var partnerCookie = cookies.get('partnerCookie');

    if (partnerCookie !== "1") {
      document.getElementById("myModal").style.display = 'block';
    }
    else {
      window.location.href = Config.BaseRootURL + "packages";
    }
  }

  //setting cookie for package partners 
  proceedClick() {
    const cookies = new Cookies();
    var partvalue;

    for (var i = 0; i < document.getElementsByName("partner").length; i++) {
      if (document.getElementsByName("partner")[i].checked) {
        partvalue = document.getElementsByName("partner")[i].classList[0];
      }
    }
    cookies.set('partnerCookie', "1", { path: '/' });
    // cookies.set('partnerCode',partvalue, { path: '/' });
    cookies.set('partnerId', partvalue, { path: '/' });
    window.location.href = "/packages";
  }

  // close for package partners popup 
  closeClick() {
    document.getElementById("myModal").style.display = 'none';
  }

  myFunction() {
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  //Radio change for header text 
  radioOnChange(partnerName) {
    var part = document.getElementById(partnerName);
    if (part !== null && part.checked) {
      document.getElementById("headText").innerHTML = 'You selected "' + part.value + '" Tap on proceed to view plans';
    }
  }

  //displaying package partners
  getPartners(partner) {
    if (partner !== null) {
      if (partner.code === "bsnl") {
        return (

          <li className="round" key={partner.id}>{partner.name}
            <input type="radio" id={partner.code} name="partner" className={partner.id} value={partner.name} defaultChecked onChange={this.radioOnChange.bind(this, partner.code)} />
            <label htmlFor={partner.code}></label>
          </li>
        )
      }
      else {
        return (

          <li className="round" key={partner.id}>{partner.name}
            <input type="radio" id={partner.code} name="partner" className={partner.id} value={partner.name} onChange={this.radioOnChange.bind(this, partner.code)} />
            <label htmlFor={partner.code}></label>
          </li>
        )
      }
    }
  }

  //after click on Signin in header 
  headerSigninClik() {
    var signText = document.getElementById("ancSignin1").innerHTML;
    if (signText === "Sign in") {
      //document.getElementById("myModal").style.display= 'block'; 
      window.location.href = Config.BaseRootURL + "signin";
    }
    else {
      window.location.href = Config.BaseRootURL + "myaccount";
    }
  }

  //signout and redirected to home page 
  signoutClick() {
    const cookies = new Cookies();
    let URL = "";
    //let postData="";
    URL = Config.APIURL + "service/api/auth/signout";
    this.dataService.httpPostWithHeaderData(URL, "null")
      .then(responseData => {
        if (responseData !== undefined) {
        }
        // window.location.href=Config.BaseRootURL; 
      })
    cookies.remove('boxid');
    cookies.remove('sessionid');
    cookies.remove('number');
    cookies.remove('partnerId');
    cookies.remove('partnerCookie');
    window.location.href = Config.BaseRootURL;
  }
  openNav() {
    document.getElementById("mySidenav").classList.add("small-slider");
    document.getElementsByTagName("body")[0].style = "overflow:hidden";

    document.getElementById("mobbgclose").classList.add("mob-body-bg");
    document.getElementsByClassName("mob-body-bg")[0].style = "display:block";
  }

  closeNav() {
    //document.getElementById("mySidenav").style.width = "0";
    document.getElementsByTagName("body")[0].style = "overflow:normal";
    document.getElementById("mySidenav").classList.remove("small-slider");
    // document.getElementsByTagName("body")[0].classList.remove("mob-body-bg");
    document.getElementById("mobbgclose").classList.remove("mob-body-bg");
  }
  render() {
    return (
      <div>

        {/* <div className="header-top">
      <div className="container-fluid">
      <ul className="top-nav">
        <li><a href="../help/waystowatch">Ways to watch</a></li>  <li> <a href="../help/whyus">Why Us</a></li>  <li>Call Us: 07411774117</li></ul>

      </div>

     </div> */}
        <div className="header-bottom" style={{ background: "#222", boxShadow: "none" }}>
          <div className="staticheadermenu">
            <div className="row mx-0" style={{ marginBottom: "0" }}>
              <div className="menu-mobile" id="mySidenav">
                <div className="mobile-top-nav">
                  <a href={Config.BaseRootURL}> <img src="https://yuppstatic.akamaized.net/yupptv/yupptvscope/28feb2023/videonewlogo.png" alt="Logo" className="mob-logo" /></a>
                  <div><a className="close" onClick={this.closeNav.bind(this)}></a></div>

                  {/* <div><a className="btn signin-btn" onClick={this.headerSigninClik.bind(this)} id="ancSignin1">Sign in</a></div>  */}

                  {/* <div className="dropdown" id="divhdr1">
                    <a href="#" onClick={this.myFunction}><img src={require('../images/user.svg')} />
                      <div className="dropdown-user" id="ancPhone1"></div>
                      <span><img src={require('../images/down-arrow.svg')} /></span>
                    </a>
                    <div className="dropdown-container">
                      <a href={Config.BaseRootURL + "myaccount"}>My Account</a>
                      <a onClick={this.signoutClick.bind(this)}>Log out</a>
                    </div>
                  </div> */}
                </div>
                <div>
                  <ul className="mob-left-menu">
                    {/* <li><a href="waystowatch">Ways to Watch</a></li> */}
                    {/* <li><a href="whyus">Why Us</a></li> */}
                    <li><a href="faqs">FAQs</a></li>
                    {/* <li><a>Call us: 
            07411774117</a></li> */}
                  </ul>
                </div>
              </div>
              {/* <div className="col-1 col-sm-1 col-md-1 px-0 ham-menu">
                    <div className="logo float-left">
                    <a href="#" onClick={this.openNav.bind(this)}> <img src={require('../images/hamburg-menu.png')} className="ham-fix" alt="Logo"/></a>
                    </div>u
                </div> */}

              <div className="col-2 col-sm-2 col-md-2 col-lg-2 px-0 logo-fix ">
                <div className="logo mx-auto ">
                  <a href={Config.BaseRootURL}> <img src="https://yuppstatic.akamaized.net/yupptv/yupptvscope/28feb2023/videonewlogo.png" alt="Logo" className="logo-fix " /></a>
                </div>
              </div>
              <div className="col-7 col-sm-7 col-md-10 col-lg-10 ">
                <ul className="nav static-nav float-left menu-pad">
                  <li className="nav-item"><a className={this.props.active === "home" ? "nav-link yupptv-link active" : "nav-link yupptv-link"} href="/home">Home</a></li>
                  <li className="nav-item"><a className={this.props.active === "livetvchannels" ? "nav-link yupptv-link active" : "nav-link yupptv-link"} href="/LiveTVChannels">LiveTV Channels</a></li>
                  {/* <li className="nav-item"><a href="#" className={this.props.active==="packages" ? "nav-link yupptv-link active" : "nav-link yupptv-link"}  onClick={this.togglePopup1.bind(this)} >OTT PLANS</a></li>  */}
                </ul>
              </div>
              {/* <div className="col-4 col-sm-4 col-md-5 pt10lr0" style={{ display: "none" }}>
                <div className="header-right">
                  <div className="dropdown" id="divhdr2">
                    <a href="#"><img src={require('../images/user.svg')} className="pro-person-icon" />
                      <div className="dropdown-user" id="ancPhone2"></div>
                      <span><img src={require('../images/down-arrow.svg')} className="arrow-menu-icon" /></span>
                    </a>
                    <div className="dropdown-container">
                      <a href={Config.BaseRootURL + "myaccount"}>My Account</a>
                      <a onClick={this.signoutClick.bind(this)}>Log out</a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="modal show" id="myModal" aria-modal="true">
            <div className="modal-dialog">
              <div className="modal-content">


                <div className="modal-header">
                  <h4 className="modal-title">Choose your Operator</h4>
                  <h5 id="headText" className="popsub-headding">You selected “BSNL” Click on proceed to view plans</h5>
                  <input type="button" className="close" onClick={this.closeClick.bind(this)} />
                </div>


                <div className="modal-body">
                  <ul className="operator-btns-container">
                    {this.state.partners.map(partner => (
                      this.getPartners(partner)
                    ))
                    }
                  </ul>
                  <div className="form-group">
                    <input type="button" className="form-control btn btn-danger" value="Proceed" onClick={this.proceedClick.bind(this)} />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default StaticHeader;

var $trigger = $(".ham-fix");
$('body').click(function (evt) {
  if (evt.target.id == $trigger[0])
    return;
  //For descendants of menu_content being clicked, remove this check if you do not want to put constraint on descendants.
  if ($(evt.target).closest('#mySidenav').length)
    return;

  if (document.getElementById("mySidenav") != null) {
    document.getElementById("mySidenav").classList.remove("small-slider");
  }

  if (document.getElementById("mobbgclose") != null) {
    document.getElementById("mobbgclose").classList.remove("mob-body-bg");
    document.getElementsByTagName("body")[0].style = "overflow:normal";
  }

});