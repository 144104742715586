import React from 'react';
import './Thankyou.css';
import queryString from 'query-string'
import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import Cookies from 'universal-cookie';
import Config from '../shared/config';
import { Link } from "react-router-dom";

class Thankyou extends React.Component{

    constructor(props)
    {
        super(props); 
        this.values = queryString.parse(this.props.location.search)
        //console.log(this.values.result) // "top"
    } 

    render(){
    return( 
        <div>
            <Header active={"home"} showmenuitem={true}/>
            {getResult(this.values.result)}
            <Footer/>
       </div>  
        )}
}

function  getResult(result)
{
        const cookies = new Cookies();

        var pkg =  cookies.get('package'); 
        if (result==="success")
        {
        var orderid =  cookies.get('orderId'); 
        var dt = new Date();
        dt.setDate(dt.getDate() + 30);
        dt= dt.toDateString("dd-MM-yyyy");
        if(pkg!== undefined)
        {
         return(  
            <div id="divSuccess" className="thankyou-block">               
                <div className="thankyou-content">
                    <div class="checkmark-circle">
                        <div class="background"></div>
                        <div class="checkmark draw"></div>
                    </div>
                    <h1 className="thankyou-headding">You have Successfully subscribed to ‘Classic Plan’ </h1>
                    <div className="">
                        <div className="">
                            <span className="channel-block"><img className="img-fluid" src= {require('../images/yupp-logo.png')} alt="YuppTV"/></span><span className="channel-block"><img className="img-fluid" src= {require('../images/Zee5.png')} alt="YuppTV"/></span>
                            <span className="channel-block"><img className="img-fluid" src= {require('../images/sony liv.png')} alt="YuppTV"/></span>
                        </div>
                        <p className="bill-txt"> Your billing cycle of {pkg.packageInfo.packages[0].attributes.CurrencySymbol.value}{ pkg.packageInfo.packages[0].listPrice}/month will start from {dt.toString()} </p>
                        <p className="bill-id">Transaction ID :{orderid}</p>
                        <a className=" btn btn-danger" href={Config.BaseRootURL}> Back to Home </a>
                    </div>
                </div>
            </div>
            )
         }
        }
        else{
              return(
                <div id="divFailure" className="thankyou-block"> 
                    <div className="thankyou-content"> 
                    <img src={require('../images/caution.svg')} />
                    <h2 className="payment-not-headding">Payment not Received!</h2>
                    <h4 className="payment-not-subheadding">Your payment might not have been processed.</h4>
                    <p className="payment-not-content">If money was debited from your account, It will be  refunded automatically in 5-7 business days.  </p>
                     <Link to={{ pathname:'/AccountSummary', state:{OTTPackage:pkg}}}><span className="btn retry-btn"  >Retry Payment</span></Link>
                    </div> 
                </div>
            )
        } 
}


export default Thankyou;