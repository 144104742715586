import React from 'react';
import './package.css';
import 'react-multi-carousel/lib/styles.css';
import Config from '../shared/config.js';
import Cookies from 'universal-cookie';
import DataService from '../shared/service';
import PackDetails from './packDetails'

class OTTPackage extends React.Component{

    constructor(props)
    {
        super(props); 
        this.state = {   
            ott:null,
            partners:[],
            packages:[],
            gotpackages:"0"
        };
        this.dataService = new DataService();
    } 
    componentDidMount()  
    {
        //partners list 
        let domain = Config.APIURL;  
        this.dataService.httpGet(domain + "package/api/external/v2/partner/list")
            .then(responseData=> {
            if(responseData!== undefined)
            { 
                this.setState({
                    partners : responseData.response
                })
            } 
            })
    }

    //click on partners tab 
    partnerClick(partner)
    {
        const cookies = new Cookies();
        var partvalue;
        partvalue = partner;

        cookies.set('partnerCookie',"1", { path: '/' });
        cookies.set('partnerId',partvalue, { path: '/' });


        var partnerId = cookies.get('partnerId');
        let domain = Config.APIURL;  
        this.dataService.httpGet(domain + "package/api/v1/packages/info/external?skip_package_content=true&partner_id=" + partnerId )
          .then(responseData=> {
            if(responseData!== undefined)
            {
                this.setState({
                    packages : responseData.response,
                    gotpackages:"1"
                });

            } 
            })

    } 

getPartnersList(partner) 
{
    const cookies = new Cookies();
    var partnerId = cookies.get('partnerId');
    if (partner!==null)
    {
        return(
            <div key={partner.id}>
                <li className={partner.id.toString()=== partnerId ? "active" :  "" }>
                    <input type="button"  id={partner.code} onClick={this.partnerClick.bind(this,partner.id)} value={partner.name}/>
                </li>
            </div>)
    }
}

//get partner packages
getPacks()
{
    if (this.state.packages.length=== 0 && this.state.gotpackages==="0")
    {
    return( 
        <PackDetails packagesList={this.props.packagesList}/> 
    )}
    else
    {
        return(
         <PackDetails packagesList={this.state.packages}/> 
        )        
    }
}
render(){
          return(
              <div className="package-block">
                    {/* <div className="package-add-block">
                    <img className="img-fluid" src= {require('../images/pack-add.jpg')} alt="ad1"/><img className="img-fluid" src = {require('../images/pack-add.jpg')} alt="ad2"/> <img className="img-fluid" src={require('../images/pack-add.jpg')} alt="ad3"/>
                    </div> */}

<div className="package-operator-block">
                    <div className="operator-container">
                    <ul className="operator-list">
                    {this.state.partners.map(partner=>( 


                                this.getPartnersList(partner)
                            ))
                        }
                        </ul>
                        </div>
                     </div>
                        {this.getPacks()}
                    {/* <PackDetails packagesList={this.state.packages}/> */}
             </div>
            )}
        }


 export default OTTPackage; 
 
 
    
    
