import React from 'react';
import './signin.css';
import Config from '../shared/config.js';
import Cookies from 'universal-cookie';
import DataService from '../shared/service.js';
import CryptoJS  from 'crypto-js';

class Signin extends React.Component{

  constructor(props)
  {
     super(props); 
     this.state = { seconds: 20 };
     this.timer = 0;
     this.refId="-1";
     this.otpRefId ="-1"; 
     this.dataService = new DataService();
     this.orderId="-1";
     this.startTimer = this.startTimer.bind(this);
     this.countDown = this.countDown.bind(this);
  }

  componentDidMount()
  {
    const cookies = new Cookies();

   cookies.remove('boxid');
   cookies.remove('sessionid');
   cookies.remove('number');
   cookies.remove('partnerId');
   cookies.remove('partnerCookie');


    // this.dataService.httpDelete("https://api.razorpay.com/v1/apps/logout?key_id=rzp_test_b9CstKDIkAfNcU&logout=all")
    // .then(responseData=> {
    //     if(responseData!== undefined)
    //     {
    //     }});




           var BoxId =  this.dataService.createGuid().replace("-", "").substring(0, 16)
           cookies.set('boxid', BoxId, { path: '/' });
           this.dataService.getToken();

        document.getElementById("divSignin").style.display="block";
        document.getElementById("divOTP").style.display="none";
        document.getElementById("txtmnumber").value =document.getElementById("txtMobileNumber");
        document.getElementById("txtMobileNumber").focus();
        
        //document.getElementById("txtmnumber").readOnly= true;
  }

  signinClick()
  {
        if(document.getElementById("txtMobileNumber").value=== "") 
        {
          document.getElementById("lblerror").style.display="block";
          document.getElementById("lblerror").innerHTML= "Please enter your mobile number"; 
          return; 
        } 
        this.signinInit();
  } 

  verifyClick()
  {
        if(document.getElementById("txtOTP").value=== "") 
        {
          document.getElementById("lblOTPerror").style.display="block";
          document.getElementById("lblOTPerror").innerHTML= "Please enter OTP"; 
          
          return; 
        } 
        this.signinComplete("0");
  } 

    signinInit()
    {
      var res = this.phoneValidation(); 
      if(res)
      {
        this.signin() ;
      }
    }

    // sending otp to mobile number 
    signin() 
    {
      let  URL = "";
      let postData="";
      URL= Config.APIURL + "service/api/auth/v2/signup"; 
      let mno = document.getElementById("txtMobileNumber").value; 
      let CountryCode =  document.getElementById("country-block").innerHTML; 
      mno = CountryCode.replace("+","") + mno;
      postData = JSON.stringify({mobile: mno,})
  
    //  var responseData= JSON.parse('{"status": "false", "error": {"code": "-40",  "details": {"referenceId": "0", "otpreferenceId": "1689", "phoneNumber": "91-9949957358"}}}');
    this.dataService.httpPostWithHeaderData(URL, postData)
    .then(responseData=> {
        if(responseData!== undefined)
        {
            if(responseData.error!== undefined) 
            {
                if(responseData.error.details.referenceId!== undefined)
                {
                    {this.refId = responseData.error.details.referenceId}
                    {this.otpRefId = responseData.error.details.otpreferenceId}
                    document.getElementById("divSignin").style.display="none";
                    document.getElementById("divOTP").style.display="block";
                    document.getElementById("txtmnumber").value =document.getElementById("txtMobileNumber").value;
                    document.getElementById("txtOTP").focus();
                    /* Timer function*/
                    this.startTimer();
                } 
                else
                {
                  if(responseData.error.message!== undefined)
                  {
                    document.getElementById("lblerror").style.display="block";
                    document.getElementById("lblerror").innerHTML= responseData.error.message; 
                  }
                } 
            } 
        }
    })
  } 
 
//verifing signin with OTP
  signinComplete(confirm)
  {
    const cookies = new Cookies();
    let  URL = "";
    let postData="";

    URL= Config.APIURL + "service/api/auth/v2/signup/complete";  
    let OTP = Number(document.getElementById("txtOTP").value); 
    let mno = document.getElementById("txtMobileNumber").value; 
    let CountryCode =  document.getElementById("country-block").innerHTML; 
    mno = CountryCode + "-" + mno;

    if(confirm==="1")
  {
    postData = JSON.stringify({mobile: mno,reference_id:Number(this.refId), otp:OTP, additional_info:"f_web_login"})
  }
  else
  {
    postData = JSON.stringify({mobile: mno,reference_id:Number(this.refId), otp:OTP})
  }
    
        this.dataService.httpPostWithHeaderData(URL, postData)
        .then(responseData=> {
            if(responseData!== undefined)
            {
                if(responseData.status=== true) 
                {
                var ciphertext = CryptoJS.AES.encrypt(mno.trim(), Config.secreteKey).toString();
                cookies.set('number',ciphertext, { path: '/' });
                window.location.href= Config.BaseRootURL;

                } 
                else
                {
                  if (responseData.error.code!==101)
                  {
                    document.getElementById("lblOTPerror").style.display="block";
                    document.getElementById("lblOTPerror").innerHTML= responseData.error.message; 
                    return; 
                  }
                  else
                  {
                    document.getElementById("divOTP").style.display="none";
                    document.getElementById("divLogoutAll").style.display="block";
                  } 
                    
                }
            }
        }) 
  } 
  
// logout 
 signoutClick()
 {
   const cookies = new Cookies();
   let  URL = "";
   //let postData="";
   URL= Config.APIURL + "service/api/auth/signout"; 
   cookies.remove('boxid');
   cookies.remove('sessionid');
   cookies.remove('number');
   cookies.remove('partnerId');
   cookies.remove('partnerCookie');
   this.dataService.httpPostWithHeaderData(URL, "null")
   .then(responseData=> {
       if(responseData!== undefined)
       {
           if(responseData.status=== true) 
           {
               cookies.remove('boxid');
               cookies.remove('sessionid');
               cookies.remove('number');
               cookies.remove('partnerId');
               cookies.remove('partnerCookie');
           } 
           else
           {
             if(responseData.error.message!== undefined)
             {
               //document.getElementById("lblerror").style.display="block";
               //document.getElementById("lblerror").innerHTML= responseData.error.message; 
             }
           } 
       } 
   })

   document.getElementById("divSignin").style.display="block";
   document.getElementById("divOTP").style.display="none";
   //document.getElementById("txtMobileNumber").value="";
   document.getElementById("txtOTP").value="";
   document.getElementById("divLogoutAll").style.display="none";
   document.getElementById("lblOTPerror").innerHTML= ""; 
  //  document.getElementById("btnSubmit").value= "Send OTP"; 
  //  document.getElementById("txtMobileNumber").style.display="block";
  //  document.getElementById("txtMobileNumber").readOnly= false ;
  //  document.getElementById("country-block").style.display="block";
  //  document.getElementById("btnSubmit").style.display="block";
  //  document.getElementById("txtOTP").style.display="none";
  //  document.getElementById("btnVerify").style.display="none";
  //  document.getElementById("btnresendOTP").style.display="none";
  //  document.getElementById("spnTimer").style.display="none";
  //  document.getElementsByClassName("country-code").style.display="none";
  //  document.getElementById("logout").style.display="none";
  //  document.getElementById("actDet").style.display="block";
  //  document.getElementById("accountchk").style.display="none";
  //  document.getElementById("headMNumber").innerHTML="Please Enter Your Mobile Number"; 
    document.getElementById("lblerror").innerHTML= ""; 
  //  document.getElementById("paymentdet").style.display="none";
    
   

 } 
  
 //resend OTP
 resendClick()
      {
        let  URL = "";
        let postData="";
        URL= Config.APIURL + "service/api/auth/resend/otp"; 
        let ref = this.otpRefId; 
        postData = JSON.stringify({reference_id: Number(ref),})

        this.dataService.httpPostWithHeaderData(URL, postData)
      .then(responseData=> {
          //console.log("resend Response");
         //console.log(responseData);

        // var responseData= JSON.parse('{"status": true}');
         
          if(responseData!== undefined)
          {
            if(responseData.status=== true)
            { 
                

              this.timer=0;
              this.startTimer();
            } 
          }
     })
  }
//timer for resend OTP
      startTimer() {
       
        if (this.timer === 0 && this.state.seconds > 0) {
          this.timer = setInterval(this.countDown.bind(this), 1000);
        }
      }
        countDown() {
          // Remove one second, set state so a re-render happens.
          let seconds = this.state.seconds - 1;
          this.setState({
            seconds: seconds,
          });
          var button = document.getElementById("btnresendOTP");
        if (seconds.toString().length === 2) {
            //document.getElementById("spnTimer").innerHTML = "(00:" + seconds + ")";  
            button.value = "Resend OTP (00:" + seconds + ")";  
            button.disabled = true;
        } else {
            button.value = "Resend OTP (00:0" + seconds + ")";  
            button.disabled = true;
        }
          // Check if we're at zero.
          if (seconds === 0) { 
            button.value = "Resend OTP";  
            button.disabled = false;
            button.style.color="white";
            clearInterval(this.timer);
            this.setState({seconds: 20, });
            //document.getElementById("btnSubmit").value= "Verify OTP"; 
          }

        }

  phoneValidation() {
          if (document.getElementById('txtMobileNumber').value !== "") {
              var Phno;
              var valid = false;
              Phno = document.getElementById('txtMobileNumber').value
              //var validChars = "0123456789-+ "
              //var i = 0
              if (Phno.length == 10) {
                  if (!Phno.match(/^[0-9]+$/)) {
                      valid = false;
                  }
                  else
                      valid = true;
              }
              if (valid === false) {
                  document.getElementById("lblerror").style.display="block";
                  document.getElementById("lblerror").innerHTML= "Please enter a valid Mobile Number"; 
                  return (false);
              }
          }
         return true;
      }   

      handleKeyPressMobile = (event) => {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode = 47 && charCode > 31 && (charCode < 48 || charCode > 57))
          event.preventDefault();
        if(event.key==="Enter")
        {
          this.signinClick();
        }
        return true;
      } 
      handleKeyup =(event)  =>  {
        event.target.value = event.target.value.replace(/[^\d]/g, '');
        return false;
    }
      handleKeyPressOTP = (event) => {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode = 47 && charCode > 31 && (charCode < 48 || charCode > 57))
          event.preventDefault();
        if(event.key==="Enter")
        {
          this.verifyClick();
        }
        return true;
      }               
      handleKeyupOTP =(event) => {
        event.target.value = event.target.value.replace(/[^\d]/g, '');
        return false;
    }  


    render(){
      return (
        // <ReactEncrypt encryptKey={Config.secreteKey}>
        <div className="signin-bg">

          <div className="signin-logo-container">
              <div className="singn-logo"><img src={require('../images/signin-logo.png')} /></div>
          </div>        
          <div className="signin-container">
            <h2 className="signin-headding">Sign in to your Account</h2>
            <div className="signin-content-block">
            <div style={{display:'block'}} id="divSignin">  
            <h3 className="signin-top-headdig">Please Enter Mobile Number</h3>
            <h4 className="signin-sub-headdig">We will send an One-Time-password (OTP) to verify your number.</h4>
              
              <div className="sign-element-block">
              <div className="form-group sign-pos">
              <label className="text-headding">Mobile Number</label>
              <input type="text" className="form-control form-field" id="txtMobileNumber" onKeyPress={this.handleKeyPressMobile} onKeyUp={this.handleKeyup} maxLength="10"  placeholder="Mobile Number"/>
              <span className="signin-country-code" id="country-block">+91</span>
              <span className="signin-country-code-border"></span>
              </div>
              <div><input type="submit" className="btn sent-otp-btn" onClick={this.signinClick.bind(this)}  value="Send OTP"/></div>
              <p id="lblerror" className="error-msg" style={{display:'none'}}>Please enter your mobile number</p>
              </div>
              </div>


              <div style={{display:'none'}} id="divOTP">   
            <h3 className="signin-top-headdig">Please Enter OTP</h3>
            <h4 className="signin-sub-headdig-one">One-Time-password (OTP) has been sent to your number.</h4>
              
              <div className="sign-element-block">
              <div className="form-group sign-pos">
              <label className="text-headding">Mobile Number</label>
              <input type="text" id="txtmnumber" className="form-control form-field" placeholder="Mobile Number" readOnly={true}/>
              <span className="signin-country-code">+91</span>
              <span className="signin-country-code-border"></span>
              <span id="logout" className="signin-change" onClick={this.signoutClick.bind(this)}>Change</span>
              </div>
              <div className="form-group sign-pos signin-mb0">
              <label className="text-headding">OTP</label>
              <input type="password" className="form-control form-field-one" id="txtOTP" maxLength="4" onKeyPress={this.handleKeyPressOTP} onKeyUp={this.handleKeyupOTP}/>
              </div>
              <div className="btn-container">
                <input type="submit" className="btn resen-otp-btn" onClick={this.resendClick.bind(this)}  value="Resend OTP " id="btnresendOTP"/>
                <input type="submit" className="btn verify-btn" value="Verify OTP" id="btnVerify" onClick={this.verifyClick.bind(this)}  /></div>
              </div>
              <p id="lblOTPerror" className="error-msg" style={{display:'none'}}>Please enter your OTP</p>
              </div>

              <div id="divLogoutAll" style={{display: "none"}}>
                <div className="sign-element-block device-exeed-padding">
                    <div className="intimate-text">
                        You have logged in another devices also, login here will automatically logout from all other login devices.
                        Please confirm.
                    </div>
                
                <div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 remove-padding yupptv-validation-text">
                        <label id="lblError"></label>
                    </div>
                </div>
                <div>
                    <div className="btn-container">
                        <input type="submit" id="btnContinue" className="btn verify-btn" onClick={this.signinComplete.bind(this,"1")}  value="Confirm"/>
                        <input type="submit" id="btnCancel" className="btn resen-otp-btn" onClick={this.signoutClick.bind(this)}  value="Cancel"/>
                    </div>
      
                </div>
                </div>

            </div>
         
            </div>

          </div> 
            </div>
        )
    }
  }
export default Signin;