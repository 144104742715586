import React from 'react';
import './help.css';
import StaticHeader from '../StaticHeader/staticHeader.js';
import Footer from '../footer/footer.js';
import LeftPanel from './leftPanel.js'

class WaystoWatch extends React.Component{

    constructor(props)
    {
        super(props); 
    } 

    render(){
    return( 
        <div>
            <StaticHeader active={"home"}/>
            <div className="static-page row ">
                <LeftPanel active={"waystowatch"} name={"Ways to Watch"}/>
                <div className="col-sm-10 ways-to-watch right-content"> 
                <h3 className="static-page-title ways-towatch-title">Ways to Watch</h3>
                <h5 className="waysto-description">Download YuppTV app from respective app stores and watch on the devices</h5>
                <h4 className="ways-towatch-sub-title">Watch on your TV</h4>
                <div className="device-entire-row">
                <div className="waystodevice">
                <img src={require('../images/samsung.png')} className="img-fluid"/>
                <span className="waysto-device-title">SAMSUNG</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>

                <div className="waystodevice">
                <img src={require('../images/LGTV.png')} className="img-fluid"/>
                <span className="waysto-device-title">LG TV</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                <div className="waystodevice">
                <img src={require('../images/sony.png')} className="img-fluid"/>
                <span className="waysto-device-title">SONY</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                <div className="waystodevice">
                <img src={require('../images/operatv.png')} className="img-fluid"/>
                <span className="waysto-device-title">Opera TV Store</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                <div className="waystodevice">
                <img src={require('../images/android-tv-store.png')} className="img-fluid"/>
                <span className="waysto-device-title">Android TV</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                <div className="waystodevice">
                <img src={require('../images/XBox.png')} className="img-fluid"/>
                <span className="waysto-device-title">XBOX</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                <div className="waystodevice">
                <img src={require('../images/apple_tv.png')} className="img-fluid"/>
                <span className="waysto-device-title">Apple TV</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                <div className="waystodevice">
                <img src={require('../images/android-tv-store.png')} className="img-fluid"/>
                <span className="waysto-device-title">Android TV</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                <div className="waystodevice">
                <img src={require('../images/apple_tv.png')} className="img-fluid"/>
                <span className="waysto-device-title">LG TV</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                <div className="waystodevice">
                <img src={require('../images/apple_tv.png')} className="img-fluid"/>
                <span className="waysto-device-title">LG TV</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                <div className="waystodevice">
                <img src={require('../images/apple_tv.png')} className="img-fluid"/>
                <span className="waysto-device-title">LG TV</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                <div className="waystodevice">
                <img src={require('../images/apple_tv.png')} className="img-fluid"/>
                <span className="waysto-device-title">LG TV</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                
                </div>
                <h4 className="ways-towatch-sub-title">Watch on your Mobile & Tablets</h4>
                <div className="device-entire-row mob-tab">
                <div className="waystodevice">
                <img src={require('../images/ipnone-and-tblt.png')} className="img-fluid"/>
                <span className="waysto-device-title">Iphone and Ipad</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>

                <div className="waystodevice">
                <img src={require('../images/android-phone-and-tablt.png')} className="img-fluid"/>
                <span className="waysto-device-title">Android tabs & mobile</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                </div>

                <h4 className="ways-towatch-sub-title">Watch on your Computers</h4>
                <div className="device-entire-row mob-tab">
                <div className="waystodevice">
                <img src={require('../images/responsive.png')} className="img-fluid device-desktop"/>
                <span className="waysto-device-title">Computers</span>
                <a href="#" className="waysto-knowmore">Know more</a>
                </div>
                </div>
                 </div>
                 
            </div>
        <Footer/>

       </div>  
        )}
}

export default WaystoWatch;