import React from 'react';
import './help.css';
import StaticHeader from '../StaticHeader/staticHeader.js';
import Footer from '../footer/footer.js';
import LeftPanel from './leftPanel.js'

class Whyus extends React.Component{

    constructor(props)
    {
        super(props); 
    } 

    render(){
    return( 
        <div>
        <StaticHeader active={"home"}/>
        <div className="static-page row ">
        <LeftPanel active={"whyus"} name={"Why Us"}/>
        <div className="col-sm-10 right-content">
        <h3 className="static-page-title">Why Us</h3>
        <h4 className="static-page-sub-title">About Scope Video</h4>
        <p className="static-page-content">Scope Video, a pioneer and one of the world’s largest online Over-the-top (OTT) entertainment solutions provider has made the consumer witness the renaissance in TV watching experience by making the best use of technology growth and advancement. Scope Video enables its consumers to experience the convenience of virtual home entertainment anytime, anywhere.</p>
        <p className="static-page-content">Scope Video has a worldwide reach of its Live TV, Catch-up TV, TV SHOWS, Movies and much more video content through a powerful engine built for propagating the video content through six screens namely - Connected TVs, Internet STBs, Smart Blu-ray Player, PCs, Smart Phones and Tablets.</p>
        <h4 className="static-page-sub-title title-seperaton">What You Get</h4>
        <p className="static-page-content">TV at your fingertips anytime everywhere. Stream your favourite Indian channels and watch Live TV, Catchup TV, Movies and more</p>

        <div className="what-you-get-block">
        <div className="row">
            <div className="col-md-3 item-flex">
                <div className="what-you-get-block-item">
                    <img src={require('../images/live-tv-icon.svg')}/>
                    <p><span>Live TV</span>- Watch your favorite Indian channels live in your region among hundreds at your convenience  </p>
                </div>
            </div>
            <div className="col-md-3 item-flex">
            <div className="what-you-get-block-item">
                    <img src={require('../images/catchup-icon.svg')}/>  
                    <p><span>Catchup TV</span> – Never miss a program again, catch up watching any show and channel for up to 10 days.</p>
                </div>
                
            </div>
            <div className="col-md-3 item-flex">
            <div className="what-you-get-block-item">
                    <img src={require('../images/mini-theatre-icon.svg')}/>
                    <p><span>Mini Theatre</span> – Watch exclusively the latest movie releases as they appear in theatres</p>
                </div>

            </div>
            <div className="col-md-3 item-flex">
            <div className="what-you-get-block-item">
                    <img src={require('../images/tv-shows-icon.svg')}/>
                    <p><span>Seamless access</span> – YuppTV provides access to your content across all your devices</p>
                </div>

            </div>
        </div>
        </div>

        <h4 className="static-page-sub-title">How it Works</h4>
        <p className="static-page-content">1. if you are an existing YuppTV customer, then Sign In with the same account else create a new YuppTV account</p>
        <p className="static-page-content">2. Enjoy 14 days of free access to YuppFlix’s catalogue of blockbuster movies.you will be charged only once the 14 Days free trial has ended.We will inform You before the free trail ends</p>
        <p className="static-page-content">3. if you love YuppFlix experience then go ahead and subscribe, else we have a hassle-free cancellation process</p>



        </div>
            
        </div>





            <Footer/>
       </div>  
        )}
}

export default Whyus;