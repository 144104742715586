import React from 'react';
import './help.css';
import StaticHeader from '../StaticHeader/staticHeader.js';
import Footer from '../footer/footer.js';
import LeftPanel from './leftPanel.js'

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contactinfo: [{ country: 'USA/Canada', contact: ['1866-663-7557', '1855-498-7788'] },
            { country: 'Australia', contact: ['1800-612-952'] },
            { country: 'New Zealand', contact: ['0800-452442'] },
            { country: 'Singapore', contact: ['800-852-3026'] },
            { country: 'Malaysia', contact: ['1800-816-557'] },
            { country: 'United Kingdom', contact: ['0800-086-8286'] },
            { country: 'Europe', contact: ['33-182-886-238'] },
            { country: 'UAE', contact: ['8000-182449'] }
            ]
        }
    }

    render() {
        var ifrm;
        ifrm = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.54131368223!2d78.39679931435387!3d17.433786706055155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb914f7a9c9303%3A0xeeb419ceeea154a9!2sYuppTV%20Corporate%20Office!5e0!3m2!1sen!2sin!4v1590559643910!5m2!1sen!2sin" width="100%" height="403" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>';
        return (
            <div>
                <StaticHeader active={"home"} />
                <div className="static-page row " >
                    <LeftPanel active={"contactus"} name={"Contact Us"} />
                    <div className="col-sm-10 right-content">
                        <div className="row">
                            <div className="col-md-8 contact-content" style={{ height: "100vh" }}>
                                <h3 className="static-page-title">CONTACT US</h3>
                                <>
                                    <div className="contact-info">
                                        <div className="contact-address">Email</div>                                        
                                        <div className="call-icon fa fa-envelope" style={{ fontSize: '22px', color: '#666666' }}></div>
                                        <a href="mailto:support@yupptvscope.com">support@scopevideo.com</a>
                                    </div>
                                    <div className="contact-info">
                                        <div className="contact-address">Address</div>                                       
                                        <div className="call-icon fa fa-home" style={{ fontSize: '25px', color: '#666666' }}></div>
                                        <div>8-2-293/82/A/773/1, Road No.44, Jubilee Hills, Hyderabad, Telangana, 500033</div>
                                    </div>
                                    {/* {
                                        !!this.state.contactinfo && this.state.contactinfo.map((item, index) =>
                                            <div className="contact-info" key={index}>
                                                <div className="contact-address">{item.country}</div>
                                                <div></div>
                                                <div className="call-icon"><img src={require('../images/call.svg')} /></div>
                                                <div>
                                                    {
                                                        item.contact.length > 0 && item.contact.map((itm, idx) =>
                                                            <span key={idx}>{itm}<br /></span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )

                                    } */}
                                </>
                                <div className="contact-info-mobile">
                                    <div className="contact-info-mob-descriptin">
                                        <span className="contact-address"><strong>Email:</strong></span>
                                    </div>
                                    <div className="contact-info-mob-descriptin">
                                    <div className="fa fa-envelope" style={{ fontSize: '18px', color: '#666', marginRight: '13px' }}> </div>
                                        <a href="mailto:support@yupptvscope.com">support@scopevideo.com</a>
                                    </div>
                                </div>
                                <div className="contact-info-mobile">
                                    <div className="contact-info-mob-descriptin">
                                        <span className="contact-address"><strong>Address:</strong></span>
                                    </div>
                                    <div className="contact-info-mob-descriptin">
                                        <div className="fa fa-home" style={{ fontSize: '20px', color: '#666', marginRight: '13px' }}> </div>
                                        <div>8-2-293/82/A/773/1, Road No.44, Jubilee Hills, Hyderabad, Telangana, 500033</div>
                                    </div>
                                </div>
                                {/* {
                                    !!this.state.contactinfo && this.state.contactinfo.map((item, index) =>
                                        <div className="contact-info-mobile" key={index}>
                                            <div className="contact-info-mob-descriptin">
                                                <span className="contact-address">{item.country}
                                                </span>
                                            </div>
                                            {
                                                item.contact.length > 0 && item.contact.map((itm, idx) =>
                                                    <div className="contact-info-mob-descriptin" key={idx}>
                                                        <span> <img src={require('../images/call.svg')} /></span>
                                                        <span className="contact-address">{itm}<br />
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                } */}
                                {/* <div style={{display:'block',width:'100%',padding:'50px 0 0 0'}}>
                   { <div dangerouslySetInnerHTML={{ __html: ifrm}}></div> }
                  
                    </div>  */}
                            </div>
                            {/* <div className="col-md-4 contact-content">
                                <h3 className="static-page-title">SEND US A MESSAGE</h3>
                                <div className="contact-form">
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control" id="name" placeholder="Enter your name" />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control" id="name" placeholder="Enter your email" />
                                    </div>
                                    <div class="form-group msg-mb">
                                        <label for="name" class="control-label">Message</label>
                                        <textarea name="w3review" rows="4" cols="40" />
                                    </div>
                                    <div className="btn-container"><input type="submit" value="Submit" className="submit-btn" /></div>
                                </div>

                            </div> */}
                        </div>

                    </div>
                </div>
                <Footer />

            </div>
        )
    }
}

export default ContactUs;