import React from 'react';
import './help.css';


class LeftPanel extends React.Component {

    constructor(props) {
        super(props);
    }

    closeProfile() {
        document.getElementById("mobULHead").style.display = 'none';
    }

    openProfile() {
        if (document.getElementById("mobULHead").style.display === 'block') {
            document.getElementById("mobULHead").style.display = 'none'
        } else {
            document.getElementById("mobULHead").style.display = 'block';
        }
    }
    render() {
        return (
            // <div>
            //     <li id="whyus" className={this.props.active ==="whyus" ? "nav-link yupptv-link active" : "nav-link yupptv-link"}><a href="whyus">why us</a></li>
            //     <li id="waystowatch" className={this.props.active ==="waystowatch" ? "nav-link yupptv-link active" : "nav-link yupptv-link"}><a href="waystowatch">ways to watch</a></li>
            // </div> 

            <div className="col-sm-2 left-sidebar">
                <ul className="static-web-nav">
                    {/* <li className="left-panel-link" id="aboutus" ><a href="aboutus" className={this.props.active === "aboutus" ? "left-bar-selected" : ""}>About Us</a></li> */}
                    <li className="left-panel-link" id="privacy" ><a href="privacypolicy" className={this.props.active === "privacypolicy" ? "left-bar-selected" : ""}>Privacy Policy</a></li>
                    <li className="left-panel-link" id="terms" ><a href="termsandconditions" className={this.props.active === "termsandconditions" ? "left-bar-selected" : ""}>Terms and Conditions</a></li>
                    <li className="left-panel-link" id="activation" ><a href="activationsteps" className={this.props.active === "activationsteps" ? "left-bar-selected" : ""}>Activation Steps</a></li>
                    {/* <li className="left-panel-link" id="whyus" ><a  href="whyus" className={this.props.active ==="whyus" ? "left-bar-selected" : ""}>Why Us</a></li>
                    <li className="left-panel-link" id="waystowatch" ><a href="waystowatch" className={this.props.active ==="waystowatch" ? "left-bar-selected" : ""}>Ways to Watch</a></li> */}
                    <li className="left-panel-link" id="faqs" ><a href="faqs" className={this.props.active === "faqs" ? "left-bar-selected" : ""}>FAQ’s</a></li>
                    <li className="left-panel-link" id="contactus"><a href="contactus" className={this.props.active ==="contactus" ? "left-bar-selected" : ""}>Contact Us</a></li>
                </ul>
                <div className="mobile-static-menu">
                    <div id="mobileHead" className="static-menu-headding" onClick={this.openProfile.bind(this)}>{this.props.name}</div>
                    <ul id="mobULHead" style={{ display: 'none' }}>
                        {/* <li className="left-panel-link" id="aboutus" onClick={this.closeProfile.bind(this)} ><a href="aboutus" className={this.props.active === "aboutus" ? "left-bar-selected" : ""}>About Us</a></li> */}
                        <li className="left-panel-link" id="privacy" onClick={this.closeProfile.bind(this)}  ><a href="privacypolicy" className={this.props.active === "privacypolicy" ? "left-bar-selected" : ""}>Privacy Policy</a></li>
                        <li className="left-panel-link" id="terms" onClick={this.closeProfile.bind(this)}  ><a href="termsandconditions" className={this.props.active === "termsandconditions" ? "left-bar-selected" : ""}>Terms and Conditions</a></li>
                        <li className="left-panel-link" id="activation" onClick={this.closeProfile.bind(this)}> <a href="activationsteps" className={this.props.active === "activationsteps" ? "left-bar-selected" : ""}>Activation Steps</a></li>
                        {/* <li className="left-panel-link" id="whyus" onClick={this.closeProfile.bind(this)} ><a  href="whyus" className={this.props.active ==="whyus" ? "left-bar-selected" : ""}>Why Us</a></li>
                        <li className="left-panel-link" id="waystowatch" onClick={this.closeProfile.bind(this)} ><a href="waystowatch" className={this.props.active ==="waystowatch" ? "left-bar-selected" : ""}>Ways to Watch</a></li> */}
                        <li className="left-panel-link" id="faqs" onClick={this.closeProfile.bind(this)}  ><a href="faqs" className={this.props.active === "faqs" ? "left-bar-selected" : ""}>FAQ’s</a></li>
                        <li className="left-panel-link" id="contactus" onClick={this.closeProfile.bind(this)} ><a href="contactus" className={this.props.active ==="contactus" ? "left-bar-selected" : ""}>Contact Us</a></li>
                    </ul>
                </div>

            </div>
        )
    }

}

export default LeftPanel;




