import React from 'react';
import StaticHeader from './StaticHeader/staticHeader.js';
import Footer from './footer/footer.js';

class LiveTVChannels extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <StaticHeader active={"livetvchannels"} />
                <div className="live-tv-channels">
                    <h2>Watch 300+ Live TV Channels</h2>
                    <h3>Subscribe to Scope Video and watch your favourite channels from our partner apps.</h3>
                    <div className="channels-container">
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/Hindi_Channels.png'} />
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/Tamil_Channels.png'} />
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/Telugu_Channels.png'} />
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/Malayalam_Channels.png'} />
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/English_Channels.png'} />
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/Kannada_Channels.png'} />
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/Marathi_Channels.png'} />
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/Bengali_Channels.png'} />
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/punjabi_channels.png'} />
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/Oriya_Channels.png'} />
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/Bhojpuri_Channels.png'} />
                        <img src={'https://yuppstatic.akamaized.net/yupptv/images/16Feb2023/Gujarathi_Channels.png'} />


                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default LiveTVChannels;