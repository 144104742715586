import React from 'react';
import Config from '../shared/config';
import './profile.css';
import DataService from '../shared/service';
import Cookies from 'universal-cookie';

class Myprofile extends React.Component{

    constructor(props)
    {
        super(props); 
        this.state = {   
            userinfo:[],
        };
        this.Name ="";
        this.email="";
        this.dataService = new DataService();
    } 
    componentDidMount()  
    {
        const cookies = new Cookies();
        var encnumber =  cookies.get('number'); 
        if(encnumber===undefined)
        {
            //redirecting to singin 
            window.location.href=Config.BaseRootURL+ "signin";
        }
        //getting profile data 
        let domain = Config.APIURL;  
        this.dataService.httpGetwithToken(domain + "service/api/auth/user/info")
            .then(responseData=> {
            if(responseData!== undefined)
            { 
                if(responseData.status ===true)
                {
                    this.setState({
                        userinfo : responseData.response
                    })
                    this.name= this.state.userinfo.firstName;
                    this.eMail=this.state.userinfo.email;
                    var elems ;
                    if(this.name!=="")
                    {
                        //document.getElementById("lblName").innerHTML= '+ Edit Name'; 
                        elems = document.getElementsByClassName("lblName");
                        Array.from(elems).forEach(item => item.innerHTML= '+ Edit Name');
                    }
                    else
                    {
                        //document.getElementById("lblName").innerHTML= '+ Add your Name'; 
                        elems = document.getElementsByClassName("lblName");
                        Array.from(elems).forEach(item => item.innerHTML= '+ Add your Name');
                    }
                    
                    if(this.eMail!=="")
                    {
                        //document.getElementById("lblEmail").innerHTML= '+ Edit Email'; 
                        elems = document.getElementsByClassName("lblEmail");
                        Array.from(elems).forEach(item => item.innerHTML= '+ Edit Email');
                    }
                    else
                    {
                        //document.getElementById("lblEmail").innerHTML= '+ Add your Email'; 
                        elems = document.getElementsByClassName("lblEmail");
                        Array.from(elems).forEach(item => item.innerHTML= '+ Add your Email');
                    }
                }
                else
                {
                    window.location.href=Config.BaseRootURL+ "signin";
                }
            } 
            })
    }

    closeClick()
    {
      //document.getElementsByClassName("profileEdit").style="display:none"
      var elems = document.getElementsByClassName("profileEdit");
      Array.from(elems).forEach(item => item.style="display:none");
    } 
    isvalidemail() {
        if (document.getElementById("txtEmail").value == "") {
            document.getElementById("lblError").innerHTML = "Please enter a valid Email Id "
            return (false);
        }
        if (document.getElementById("txtEmail").value != "") {
            //var emailreg = /^([\w-\.]+@@([\w-]+\.)+[\w-]{2,4})?$/;
            //var emailreg = /^(\S+)@(\S+)\.(\S+)$/;
            //var emailreg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var emailreg = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
            if(emailreg.test(document.getElementById("txtEmail").value)){
                document.getElementById('lblError').innerHTML = "";
            }
            else{
                document.getElementById('lblError').innerHTML = "Please enter a valid Email Id ";
                document.getElementById("txtEmail").select();
                document.getElementById("txtEmail").focus();
                return (false);
            }
        }

        //document.getElementById('lblEmailError').innerHTML = "";
        return true;
    }
    saveClick()
    {
        
        // sending user data for saving to api 
        URL= Config.APIURL + "service/api/auth/update/user/profile";  
        let Name = document.getElementById("txtName").value; 
        let email = document.getElementById("txtEmail").value; 
        if(Name.toString().trim()==="" )
        {
            document.getElementById("lblError").innerHTML="Please enter valid Name";
            return false;
        }
        else if (Name.toString().trim().length<6) 
        {
            document.getElementById("lblError").innerHTML="Please enter valid Name";
            return false;
        } 

        if(email.toString().trim()==="" )
        {
            document.getElementById("lblError").innerHTML="Please enter valid Email";
            return false; 
        } 
        else {
           let res= this.isvalidemail()
            if(res==false)
            {
                return false; 
            } 
        }
        let postData = JSON.stringify({userName: Name,email:email})
        this.dataService.httpPostWithHeaderData(URL, postData)
        .then(responseData=> {
            if(responseData!== undefined)
            {
                if(responseData.status=== true) 
                {
                    this.dataService.httpGetwithToken(Config.APIURL + "service/api/auth/user/info")
                    .then(responseData=> {
                    if(responseData!== undefined)
                    { 
                        this.setState({
                            userinfo : responseData.response
                          
                        })
                        this.name= this.state.userinfo.firstName;
                        this.eMail=this.state.userinfo.email;
                        document.body.classList.remove("modal-open"); 
                        //document.getElementsByClassName("profileEdit").style="display:none"
                    var elems = document.getElementsByClassName("profileEdit");
                    Array.from(elems).forEach(item => item.style="display:none");
                    if(this.name!=="")
                    {
                        //document.getElementById("lblName").innerHTML= '+ Edit Name'; 
                        elems = document.getElementsByClassName("lblName");
                        Array.from(elems).forEach(item => item.innerHTML= '+ Edit Name');
                    }
                    else
                    {
                        //document.getElementById("lblName").innerHTML= '+ Add your Name'; 
                        elems = document.getElementsByClassName("lblName");
                        Array.from(elems).forEach(item => item.innerHTML= '+ Add your Name');
                    }
                    
                    if(this.eMail!=="")
                    {
                        //document.getElementById("lblEmail").innerHTML= '+ Edit Email'; 
                        elems = document.getElementsByClassName("lblEmail");
                        Array.from(elems).forEach(item => item.innerHTML= '+ Edit Email');
                    }
                    else
                    {
                        //document.getElementById("lblEmail").innerHTML= '+ Add your Email'; 
                        elems = document.getElementsByClassName("lblEmail");
                        Array.from(elems).forEach(item => item.innerHTML= '+ Add your Email');
                    }
                    } 
                    })
                }
            }
        })
    } 
    profilePopup() {
          //document.getElementsByClassName("profileEdit").style="display:block"
          document.body.classList.add("modal-open"); 
          var elems = document.getElementsByClassName("profileEdit");
          Array.from(elems).forEach(item => item.style="display:block");
          document.getElementById("txtName").value=this.name;
          document.getElementById("txtEmail").value= this.eMail;
          document.getElementById("lblError").value="";
      }     
    render(){
    return( 
        <div>
        <div className="detail-head"> Profile </div> 
        <div className="detail-block"> 
        <h2 className="basic-headding">Basic Information</h2>
            <div className="profile-totla"><span className="profile-inner-headding">
                <span className="name-txt">Name :</span><span>{this.state.userinfo.firstName}</span></span> 
                <span onClick={this.profilePopup.bind(this)} className="add-urname lblName" id="lblName"></span>
            </div>
            <div className="profile-totla"><span className="profile-inner-headding">
                <span className="name-txt">Email:</span><span>{this.state.userinfo.email}</span></span> 
                <span onClick={this.profilePopup.bind(this)} className="add-urname lblEmail"id="lblEmail"></span>
            </div>

            <div className="profile-inner-headding"><span className="name-txt">Mobile:</span> {"+" + this.state.userinfo.phoneNumber}</div>
       </div> 

<div className="profile-popup">
<div className="modal show profileEdit " id="profileEdit" aria-modal="true">
<div className="modal-dialog">
  <div className="modal-content">
    <div className="modal-header">
    <button type="button" className="close" data-dismiss="modal" onClick={this.closeClick.bind(this)}></button>
      <h4 className="modal-title">Edit Profile</h4>
    </div>
    <div className="modal-body">
    <label className="text-headding-one">Name</label>
    <input type="text" id="txtName" className="form-control" maxLength="30" placeholder="Enter your name"/>
    <label className="text-headding text-headding-two">Email</label>
    <input type="text" className="form-control" id="txtEmail" maxLength="100" placeholder="Enter your email"/>
                <label id="lblError" className="error-msg"></label>

   <div className="form-group btns-full">
          <input type="button"  className="btn btn-cancel" value="cancel" onClick={this.closeClick.bind(this)} />
          <input type="button"  className="btn btn-save" value="save" onClick={this.saveClick.bind(this)} />
          
    </div>     
      </div>
    </div>
   </div>
    </div> 
    </div>
    </div>
        )}
}
export default Myprofile;