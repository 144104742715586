import React from "react";
import { useState } from "react";

export default function Terms(props) {
    const [termschecked, setTermschecked] = useState(false)
    const [countryCode, setcountryCode] = useState("IN")

    function acceptterms() {
        if (termschecked) {
            document.getElementById("chkAgree").classList.remove("login-error-msg");
            return props.termsnext();
        }
        else {
            document.getElementById("chkAgree").classList.add("login-error-msg");
        }
    }

    function handleChange() {
        setTermschecked(!termschecked)
        document.getElementById("chkAgree").classList.remove("login-error-msg");
    }

    return (
        <div className="register_container mb-4">
            <div className="steper_block mobile_help_class">
                <h4>Partner with us</h4>
                <p>Please follow the steps for registration</p>
                <div className="steps_content">
                    <div className="register_step step_progress">
                        <div className="register_num">1</div> <span>Terms & Conditions ----------</span>
                    </div>
                    <div className="register_step">
                        <div className="register_num">2</div> <span>Enter Details ----------</span>
                    </div>
                    <div className="register_step">
                        <div className="register_num">3</div> <span>Preview ----------</span>
                    </div>
                    <div className="register_step">
                        <div className="register_num">4</div> <span>Confirmation</span>
                    </div>
                </div>
            </div>
            <div className="steps_content mobile_step">
                <div className="register_step step_progress">
                    <div className="register_num">1</div> <span>Terms & Conditions</span>
                </div>
            </div>
            <div className="register_card">
                <h3>Terms & Conditions</h3>
                <div className="content_scroll">
                    <div className="static-page">
                        {/* <h3 className="static-page-title">Terms and Conditions</h3> */}
                        {
                            countryCode != "" && countryCode == "IN" &&
                            <>
                                <ul className="decimal">
                                    <li>
                                        <h4 className="privacy-subheadding">Introduction</h4>
                                        <p className="static-page-content">This Terms & Conditions defines the terms on which you can subscribe, avail or access the services (defined below) and/or device (defined below) from YuppTV India Private Limited. YuppTV provides the services in the name and style of Scope Video and the services are accessible  in the manner and mode as defined in these terms. </p>
                                        <p className="static-page-content">By accessing or using the site, application or services or by downloading any content from the site or via the applications, you would be indicating that you have read, and that you understand and agree to be bound by these terms and receive our services (“terms”), whether or not you have registered with the site and/or application. </p>
                                        <p className="static-page-content">Therefore, please read these terms before accessing or using the site, application or services or downloading any content from the site or via the application or through the services, carefully as they contain important information regarding your legal rights, remedies and obligations. If you do not agree to these terms, then you have no right to access or use the site, application, services, or content (as defined below).</p>
                                        <p className="static-page-content">If you are using the site, application or services then these terms of service are binding between you and YuppTV.  These terms shall be governed by the TRAI rules and regulations, for the time being in force, wherever applicable. </p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Definitions</h4>
                                        <p className="static-page-content">In addition to other words and expressions that may be defined elsewhere in these terms, unless the context otherwise requires, the following capitalized terms wherever used in these terms shall have the meanings as ascribed hereunder:</p>
                                        <ul className="lower-alpha">
                                            <li>“Application” means the Scope Video App; </li>
                                            <li>“Approved Vendors” means the list of vendors in Annexure-II;</li>
                                            <li>“Content” means the content provided by the content providers which is made available on the site, application or the device; </li>
                                            <li>“Content Providers” means those entities with whom YuppTV has partnered for provision of content and such content shall be provided to the users/subscribers in the mode mentioned herein. YuppTV may add, delete or modify these content providers, at any time; </li>
                                            <li>“Device” means the Scope Video set-top-box functioning on user’s/subscrirber’s internet connection; </li>
                                            <li>“Discovery Service” means where the user (as defined below) may use the site or application for the purposes of discovery of content. Users shall not be allowed to consume/stream content that is made available on the site and/or application;</li>
                                            <li>“Fees” means the amount charged for your continued Subscription with Scope Video, which shall be charged to you. The fee is dependent on the packages and related prices determined by Scope Video. </li>
                                            <li>“Services” means the streaming service and the discovery service. </li>
                                            <li>“Partner App” means the content provider’s mobile application or website through which final content consumption takes place;  </li>
                                            <li>“Site” means the Scope Video website through which the content can be accessed by the user/subscriber; </li>
                                            <li>“Streaming Services” means where the subscriber (as defined herein) may stream/consume content that is made available on the Site and/or the partner app in the manner provided herein; </li>
                                            <li>“Subscriber/You” means any person who has subscribed to services herein (including discovery and streaming services)by way of subscribing to mobile/telecommunication services offered by approved vendors as mentioned in Annexure-II ;</li>
                                            <li>“Subscription” means the process of registering and availing the services through the method mentioned in section 3 hereinbelow;</li>
                                            <li>“User/You” is any person who accesses the application or the site. The user is bound by these terms and privacy policy; </li>
                                        </ul>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding"> Service <br /> How to avail the Services</h4>
                                        <p className="static-page-content"> Scope Video functions on the TV everywhere solution whereby the content collected from different content providers is made available for access to users.</p>
                                        <p className="static-page-content">: The Service may be availed in the following manner: </p>
                                        <ul className="roman">
                                            <li>A User may only avail discovery services where he/she may only discover content on the site/application and its availability on various partner apps. However, a user will not be allowed to stream/consume content unless such user subscribes to the service through an approved vendor. Upon such subscription, the user is converted to a subscriber. Further, a subscriber (as defined above) shall be provided with discovery services and streaming services where the subscriber may discover content and stream/consume such content on partner apps; </li>
                                            <li>Upon authentication by YuppTV that the user has availed the service through an approved vendor, the subscriber shall become eligible to avail the streaming service. </li>
                                            <li>Each subscriber there in shall have the option to access or use the discovery services and streaming services from a separate, identifiable, thumbnail within the site and/or application for each piece of the content (the “thumbnail”), </li>
                                            <li>Upon clicking on the thumbnail, the subscriber will be redirected to the relevant partner app or website. </li>
                                            <li>By accepting the terms and conditions of YuppTV, each user also accepts the terms and conditions and the privacy policies of partner apps (as provided in Annexure-1)</li>
                                            <li>Further, such content consumption is available only to subscribers and not to users. In the event a user wishes to avail the streaming services here in but has not purchased the services through an approved vendor, such user will be redirected during signing-in process to the approved vendor’s portal where, such user may purchase services for the prices and packages made available. </li>
                                            <p className="static-page-content">Such use of the services shall be for the purposes of non-commercial use only. The data provided at the time of registration will be governed by the Privacy Policy. </p>
                                            <p className="static-page-content">As mentioned above, the user/subscriber must accept these terms before availing the services. The acceptance of these terms deems that you have read and understood the terms and agree to be bound by these terms along with the terms and conditions and the privacy policies of partner apps. </p>
                                            <p className="static-page-content">The services here in may provided either in the form of an Over-The-Top content streaming model or through a device.  You agree that the services availed by any of the modes mentioned above, has to be accessed through an internet connection i.e., an internet service provider (the charges for which may be individual to such internet service provider subject to the terms & conditions and privacy policy of such service provider).  You are advised to read the internet service provider’s terms & conditions and privacy policy and you will be bound by the terms and conditions and privacy policy of your internet service provider. YuppTV will not bear any responsibility or liability towards any action of such internet service provider and you acknowledge that YuppTV is independently providing services and thereby, content in collaboration with the content providers. The scope of Services is limited to providing information on the content available  on partner apps and redirecting you to partner app by way of a thumbnail. The service does not allow content consumption on the site or application. </p>
                                            <p className="static-page-content">By using the site, applications or services, you agree to comply with and be legally bound by the terms and conditions of these terms, whether or not you become a registered user of the services. These terms govern your access to and use of the site, application, services and content, including the content integrated into the device (defined above), and constitute a binding legal agreement between you and YuppTV. The user further accepts to allow YuppTV to reach them through call for providing existing services and for providing information on new products and services and will not be subject to DND regulations for such calls.</p>
                                            <p className="static-page-content">In addition, certain areas of the site and application (and your access to or use of certain aspects of the  services or content) may have different terms and conditions, standards, guidelines, or policies posted or may require you to agree with and accept additional terms and conditions. If there is a conflict between these terms and terms and conditions posted for a specific area of the site, application, services, or content, the latter terms and conditions will take precedence with respect to your use of or access to that area of the site, application, services, or content.</p>
                                            <p className="static-page-content">You agree that YuppTV will not be responsible for any claims related to promotional materials on site and/or application and any such claim should be directed to the relevant content provider whose content such promotional material relates. Furthermore, YuppTV shall not provide any customer service and a user/subscriber should avail such customer service from the relevant content provider and/or approved vendor. </p>
                                            <p className="static-page-content">If you do not agree to these terms, you have no right to obtain information from or otherwise continue using the site, application or services. Failure to use the site, application or services in accordance with these terms may subject you to civil and criminal liabilities.</p>
                                            <p className="static-page-content">You acknowledge and agree that, by accessing or using the site, application, or services or purchase and installation of the device and accessing the content provided there in, you are indicating that you have read and understood  and agree to be bound by these terms and receive our services. If you do not agree to these terms, then you have no right to access or use the site, application, services, or content. If you accept or agree to these terms on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to these terms and, in such event, "you" and "your" will refer and apply to that company or other legal entity.</p>
                                        </ul>
                                        <p className="static-page-content">In all the above cases, YuppTV reserves the right to modify the content and/or content providers from time to time, without requiring prior consent of the user/subscriber. The user/subscriber may access and avail such services at the price and terms and conditions set out in these terms on subscription basis, depending on their eligibility.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Registration</h4>
                                        <p className="static-page-content">The subscriber will be required to register with Scope Video and the services. Subscriber will be required to provide such information as required during the time of registration and as and when required by YuppTV provided a subscriber has purchased the services from an approved vendor. Such subscriber will be assigned an account containing a username and password. The subscriber agrees that the account is specific to that subscriber and is non-transferable. Such registration allows the subscriber to access services and content and there by gaining rights with consuming the content through the mode mentioned here in above. If the subscriber fails to provide the required information at the time of registration, such registration shall be deemed invalid. Further, if the subscriber fails to provide the required information as and when required by YuppTV, YuppTV shall have the right to discontinue/suspend the services to such subscriber, until such information is provided or in the case where information is not provided at all, such registration can be cancelled by YuppTV in its sole discretion, without any prior notice to the subscriber. </p>
                                        <p className="static-page-content">Any user availing only discovery services on the site and/or application shall also be bound by these terms. Any access to the site or downloading of the application requires acceptance of these terms and such access to the site and downloading of the application will mean that you have read and accepted and will be bound by these terms.</p>
                                        <p className="static-page-content">The subscriber agrees to provide accurate, complete and valid information at the time of registration and/or subscription and undertakes to update such information as and when required. You are responsible for safeguarding your password and you agree that you will not disclose your password to any third party and that you will take sole responsibility for any activities or actions under your account with Scope Video, whether or not you have authorized such activities or actions. You will immediately notify YuppTV of any unauthorized use of your account. Further, YuppTV reserves the right to terminate the services, without notice to the subscriber, in case any information provided by any subscriber is found to be false, inaccurate, or misleading. </p>
                                        <p className="static-page-content">The subscriber acknowledges that YuppTV will retain and maintain the details provided at the time of registration. The user consents to retention and use of such information by YuppTV. </p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Subscription Charges/Fees </h4>
                                        <p className="static-page-content">For the purposes of availing the services and content therein, the subscriber  will be charged  with the fees determined as per the packages and related prices selected and purchased through the approved vendor. The packages and related prices may be modified at any time, without the requiring prior consent of the subscriber. </p></li>
                                    <li>
                                        <h4 className="privacy-subheadding">No Endorsement</h4>
                                        <p className="static-page-content">By using the site, application or services, you agree that any legal remedy or liability that you seek to obtain for actions or omissions of other users/subscribers or other third parties will be limited to a claim against the particular user/subscriber or other third parties who caused you harm. You agree not to attempt to impose liability on or seek any legal remedy from YuppTV with respect to such actions or omissions.</p>
                                    </li>

                                    <li>
                                        <h4 className="privacy-subheadding">Third Party Websites</h4>
                                        <p className="static-page-content">The site, application and services may contain links to partner apps, third-party websites or resources. You acknowledge and agree that YuppTV is not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement by YuppTV of such websites or resources or the content, products, or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources or the content, products or services on or available from such websites or resources.</p>
                                        <p className="static-page-content">YuppTV shall not be responsible for the contents of the partner apps nor shall YuppTV be held responsible for any damages incurred while using or streaming content on such partner apps. Services is limited to providing information on the content available from various content providers including its availability on relevant partner apps and redirecting to partner app by way of a thumbnail.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">User Conduct</h4>
                                        <p className="static-page-content">You understand and agree that you are solely responsible for compliance with any and all laws, rules, regulations, and tax obligations that may apply to your use of the site, application, services and content. In connection with your use of the site, application, services and content, you agree that you will not:</p>
                                        <ul className="lower-alpha" style={{ listStyleType: 'upper-alpha' }} >
                                            <li>violate any local, state, national, or other law or regulation, or any order of a court, including, without limitation, tax regulations;</li>
                                            <li>use manual or automated software, devices, scripts, robots, backdoors or other means or processes to access, "scrape," "crawl" or "spider" any web pages or other services contained in the site, application, services or content;</li>
                                            <li>access or use our site, application, services expose or allow to be used or exposed, any Scope Video content: (i) in any way that is inconsistent with these terms or privacy policy; or (iii) in any way that otherwise violates the privacy rights or any other rights of other users/subscribers of Scope Video or any other third party;</li>
                                            <li>use the site, application, services or content for any commercial or other purposes that are not expressly permitted by these terms or in a manner that falsely implies Scope Video endorsement, partnership or otherwise misleads others as to your affiliation with Scope Video;</li>
                                            <li>dilute, tarnish or otherwise harm the Scope Video brand in any way, including through unauthorized use of content, registering and/or using Scope Video or derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domains names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to Scope Video domains, trademarks, taglines, promotional campaigns or content;</li>
                                            <li>copy, store or otherwise access or use any information contained on the site, application, services or content for purposes not expressly permitted by these terms;</li>
                                            <li>infringe the rights of Scope Video or the rights of any other person or entity, including without limitation, their intellectual property, privacy, publicity or contractual right;</li>
                                            <li>interfere with or damage our site, application or services, including, without limitation, through the use of viruses, cancel bots, trojan horses, harmful code, flood pings, denial-of-service attacks, backdoors, packet or IP spoofing, forged routing or electronic mail address information or similar methods or technology;</li>
                                            <li>use our site, application or services to transmit, distribute, post or submit any information concerning any other person or entity, including without limitation, photographs of others without their permission, personal contact information or credit, debit, calling card or account numbers;</li>
                                            <li>use our site, application, services or content in connection with the distribution of unsolicited commercial email ("spam") or unsuitable advertisements;</li>
                                            <li>"stalk" or harass any other users of our site, application, services or content, or collect or store any personally identifiable information about any other  user; </li>
                                            <li>register for more than one Scope Video account or register for an Scope Video account on behalf of an individual other than yourself;</li>
                                            <li>impersonate any person or entity, or falsify or otherwise misrepresent yourself or your affiliation with any person or entity;</li>
                                            <li>engage in disruptive, circumventive, abusive or harassing behaviour in any area or aspect of our site, application, content or services;</li>
                                            <li>use, display, mirror or frame the site, application, services or content, or any individual element within the site, application, services or content, Scope Video name, any Scope Video, or any content provider’s trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page in the site, application or services, without YuppTVs express written consent;</li>
                                            <li>attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the site, services, application or content;</li>
                                            <li>advocate, encourage, or assist any third party in doing any of the foregoing;</li>

                                        </ul>
                                        <p className="static-page-content">YuppTV has the right to investigate and prosecute violations of any of the above to the fullest extent of the law. In addition, and as set in these terms, YuppTV may take a range of actions against you, including but not limited to deactivating or cancelling account, for a violation of this section or these terms.</p>
                                        <p className="static-page-content">YuppTV may access, preserve and disclose any of your information if we are required to do so by law, or if we believe in good faith that it is reasonably necessary to (i) respond to claims asserted against Scope Video or to comply with legal process (for example, summons or warrants), (ii) enforce or administer our agreements with  users, such as these terms, (iii) for fraud prevention, risk assessment, investigation, customer support, product development and debugging purposes, or (iv) protect the rights, property or safety of Scope Video, its content providers, its users/subsribers, or members of the public.</p>
                                        <p className="static-page-content">You acknowledge that YuppTV has no obligation to monitor your access to or use of the site, application, services or content, but has the right to do so for the purpose of operating and improving the site, application and services (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes), to ensure your compliance with these terms, to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body, to respond to content that it determines is otherwise objectionable or as set forth in these terms.</p>
                                        <p className="static-page-content">YuppTV reserves the right, at any time and without prior notice, to remove or disable access to any content that YuppTV, at its sole discretion, considers to be objectionable for any reason, in violation of these terms or otherwise harmful to the site, application or services.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Copyright Policy</h4>
                                        <p className="static-page-content">Scope Video respects copyright law and expects its users to do the same. It is YuppTV's policy to terminate in appropriate circumstances the accounts of subscribers or other account holders who repeatedly infringe or are believed to be repeatedly infringing the rights of copyright holders.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Term and Termination, Suspension and Other Measures</h4>
                                        <p className="static-page-content">10.1	Term</p>
                                        <p className="static-page-content">10.2	These terms shall be effective for till the time users/subscribers access or use the site, application or services on partner apps until such time when you or YuppTV terminate the terms as described below.Termination  for convenience</p>
                                        <p className="static-page-content">You may terminate this agreement at any time via the cancel account feature on the site or by sending us an email. If you cancel your Scope Video  account, any confirmed subscription  will be automatically cancelled and any refund will depend upon the terms of the applicable cancellation policy. Without limiting our rights specified below, YuppTV  may terminate this agreement for convenience at any time by giving you notice via email to your registered email address.</p>
                                        <p className="static-page-content">10.3	Termination for breach, suspension and other measures</p>
                                        <p className="static-page-content">YuppTV may immediately, without notice terminate this agreement if (i) you have materially breached these terms or our policies, including but not limited to any breach of your warranties outlined in these terms or breach of the "User Conduct" provisions in these terms, (ii) you have provided inaccurate, fraudulent, outdated or incomplete information during the  account registration, or thereafter, (iii) you have violated applicable laws, regulations or third party rights, or (iv) YuppTV  believes in good faith that such action is reasonably necessary to protect the safety or property of other users/subscribers, Scope Video, content providers  or third parties, for fraud prevention, risk assessment, security or investigation purposes.</p>
                                        <p className="static-page-content">In case of non-material breaches and where appropriate, you will be given notice of any measure by Scope Video  and an opportunity to resolve the issue to YuppTV's reasonable satisfaction.</p>
                                        <p className="static-page-content">10.4 Consequences of Termination</p>
                                        <p className="static-page-content">If you or we terminate this agreement, your access to and use of the services and content therein shall be stopped, along with cancellation of your registration. When this agreement has been terminated your Scope Video account will stand deactivated. If your access to or use of the site, application and services has been limited or your account has been suspended or this agreement has been terminated by us, you may not register a new  account or attempt to access and use the site, application and services through other Scope Video accounts.</p>
                                    </li>
                                    <li> <h4 className="privacy-subheadding">Disclaimer</h4>
                                        <p className="static-page-content">The site, application, services and content are provided on an "as is" basis, without warranty of any kind, either express or implied. Without limiting the foregoing, Scope Video explicitly disclaims any warranties of merchantability, fitness for a particular purpose, quiet enjoyment or non-infringement, and any warranties arising out of course of dealing or usage of trade. Scope Video makes no warranty that the site, application, services, content on Scope Video, including that partner apps, will meet your requirements or be available on an uninterrupted, secure, or error-free basis. Scope Video makes no warranty regarding the quality of any content or the accuracy, timeliness, truthfulness, completeness or reliability of any content obtained through the site, application, services or partner apps.</p>
                                        <h4 className="privacy-subheadding">Survival</h4>
                                        <p className="static-page-content">If you or we terminate this agreement, the clauses of these terms that reasonably should survive termination of the agreement will remain in effect.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Limitation of Liability</h4>
                                        <p className="static-page-content">You acknowledge and agree that, to the maximum extent permitted by law, the entire risk arising out of your access to and use of the site, application, services and content, your registration and access to the site, application and services, and any contact you have with other users/subscribers of Scope Video whether in person or online remains with you. Neither Scope Video nor any other party involved in creating, producing, or delivering the site, application, services, content will be liable for any incidental, special, exemplary or consequential damages, including lost profits, loss of data or loss of good will, service interruption, computer damage or system failure, device failure or the cost of substitute products or services, or for any damages for personal or bodily injury or emotional distress arising out of or in connection with these terms, from the use of or inability to use the site, application, services or content, from any communications, whether based on warranty, contract, tort (including negligence), product liability or any other legal theory, and whether or not Scope Video has been informed of the possibility of such damage, even if a limited remedy set forth herein is found to have failed of its essential purpose.</p>
                                        <p className="static-page-content">In no event will Scope Video’s aggregate liability arising out of or in connection with these terms and your use of the site, application and services including, but not limited to, your subscription, access of the site, application and services, or from the use of or inability to use the site, application, services, or content and in connection with any other users/subscribers, exceed the amounts you have paid or owe for subscription via the approved vendor’s portal  as a user/subscriber in the three (3) month period prior to the event giving rise to the liability for the services. The limitations of damages set forth above are fundamental elements of the basis of the bargain between Scope Video and you.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Indemnification</h4>
                                        <p className="static-page-content">You agree to release, defend, indemnify, and hold Scope Video and its affiliates and subsidiaries, and their officers, directors, employees and agents, harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with (a) your access to or use of the site, application, services, or content or your violation of these terms; (b) loss of your account details, such as username and password without any fault of Scope Video (ii) any issues related to the Device, without any fault of Scope Video; including but not limited to any injuries, losses, or damages (compensatory, direct, incidental, consequential or otherwise) of any kind arising in connection with or as a result of subscription and access to the services.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Entire Agreement</h4>
                                        <p className="static-page-content">Except as they may be supplemented by additional Scope Video policies, guidelines, standards, or terms for a specific product, feature, service or offering, these terms constitute the entire and exclusive understanding and agreement between Scope Video and you regarding the site, application, services, content and these terms supersede and replace any and all prior oral or written understandings or agreements between Scope Video and you regarding registrations, subscription, the site, application, services, and content.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Modifications</h4>
                                        <p className="static-page-content">Scope Video reserves the right to modify any of the terms and conditions contained in these terms, as and when deemed necessary, without the prior written consent of the user/subscriber. However, such modified terms will be updated on the site and application as and when such modifications take place for user’s/subscriber’s benefit and the user/subscriber is advised to check the site and application regularly for modifications and read such modified terms. </p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Notices</h4>
                                        <p className="static-page-content">Any notices or other communications permitted or required here under, including those regarding modifications to these terms, will be in writing and given by Scope Video (i) via email (in each case to the address that you provide) or (ii) by posting to the site or via the application. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted. </p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Governing Law and Jurisdiction</h4>
                                        <p className="static-page-content">These terms and your use of the services will be interpreted in accordance with the laws of India excluding its rules on conflicts of laws. You and we agree to submit any dispute arising under these terms to the personal jurisdiction of a court located in Hyderabad for any actions for which the parties retain the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents, or other intellectual property rights.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding"> No Waiver</h4>
                                        <p className="static-page-content">The failure of Scope Video to enforce any right or provision of these terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Scope Video. Except as expressly set forth in these terms, the exercise by either party of any of its remedies under these terms will be without prejudice to its other remedies under these terms or otherwise. If for any reason a court of competent jurisdiction finds any provision of these terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these terms will remain in full force and effect.</p>
                                    </li>
                                </ul>
                                <h4 className="privacy-subheadding annexure-txt-head1">ANNEXURE-I</h4>
                                <h4 className="privacy-subheadding annexure-txt-head">APPROVED VENDORS</h4>
                                <p className="static-page-content">The following are the Approved Vendors (defined hereinabove) for the purposes of providing Services hereunder and the same may be updated from time to time. You are requested to read these terms and conditions for any updates regarding the same.</p>
                                <p className="static-page-content">1. Bharat Sanchar Nigam Limited (BSNL)</p>

                                <h4 className="privacy-subheadding annexure-txt-head1">ANNEXURE-II</h4>
                                <h4 className="privacy-subheadding annexure-txt-head">PARTNER APPS/CONTENT PROVIDERS</h4>
                                <p className="static-page-content">The following are the content providers for the purposes of providing YuppTV Content and Content for consumption on their related Partner Apps and/or Application or Site:</p>
                                <p className="static-page-content">1. SonyLIV </p>
                                <p className="static-page-content">2. ZEE5</p>
                                <p className="static-page-content">3. SunNXT</p>
                                {/* <p className="static-page-content">3. Voot Select</p> */}
                                <p className="static-page-content">4. Scope Video</p>

                                <h4 className="privacy-subheadding annexure-txt-head1">ANNEXURE-III</h4>
                                <h4 className="privacy-subheadding annexure-txt-head">CONTENT PROVIDER TERMS AND CONDITIONS AND PRIVACY POLICIES</h4>
                                <p className="static-page-content">As stated in paragraph 3(v) mentioned above, by accepting YuppTV’s terms and conditions, you will be accepting the terms and conditions and privacy policies of the Content Providers. Below are the links to the terms and conditions and privacy policies of our Content Providers:</p>
                                <ul className="decimal">
                                    <li>
                                        <p className="privacy-subheadding">SonyLIV</p>
                                        <p className="static-page-content">
                                            Terms and Conditions may be accessed at: <a href="https://www.sonyliv.com/terms-of-use" target="_blank"> https://www.sonyliv.com/terms-of-use</a><br />
                                            Privacy Policy may be accessed at:<a href="https://www.sonyliv.com/privacy-policy" target="_blank"> https://www.sonyliv.com/privacy-policy</a></p>
                                    </li>

                                    <li>
                                        <p className="privacy-subheadding">ZEE5</p>
                                        <p className="static-page-content">
                                            Terms and Conditions may be accessed at: <a href="https://www.zee5.com/termsofuse" target="_blank">https://www.zee5.com/termsofuse</a><br />
                                            Privacy Policy may be accessed at:<a href="https://www.zee5.com/privacypolicy" target="_blank">https://www.zee5.com/privacypolicy</a></p>
                                    </li>
                                    <li>
                                        <p className="privacy-subheadding">SunNXT</p>
                                        <p className="static-page-content">
                                            •	Terms Of Use may be accessed at:  <a href="https://www.sunnxt.com/termsOfuse" target="_blank">https://www.sunnxt.com/termsOfuse</a><br />
                                            •	Privacy Policy may be accessed at: <a href="https://www.sunnxt.com/privacypolicy" target="_blank">https://www.sunnxt.com/privacypolicy</a></p>
                                    </li>

                                    {/* <li>
                                <p className="privacy-subheadding">Voot Select</p>
                                <p className="static-page-content">
                                    Terms and Conditions may be accessed at: <a href="https://www.voot.com/terms-of-use" target="_blank">https://www.voot.com/terms-of-use </a><br />
                                    Privacy Policy may be accessed at:<a href="https://www.voot.com/privacy-policy " target="_blank">https://www.voot.com/privacy-policy </a></p>
                                    </li> */}

                                </ul>
                            </>
                        }
                        {
                            countryCode != "" && countryCode == "SG" &&
                            <>
                                <ul className="decimal">
                                    <li>
                                        <h4 className="privacy-subheadding">Agreement</h4>
                                        <p className="static-page-content">This Agreement sets out the terms and conditions for a User (defined below) intending to subscribe, avail or access or receiving services and/or equipment of YuppTV USA Inc in the name and style of “Scope Video”. The Services provided by Scope Video pursuant to this Agreement are services which would be made available to a User upon payment of a charge for such Services, for such Term (defined below) in such Territory (defined below) where Scope Video offers its Services (defined below). The User availing the Services shall be deemed to have read, understand and accepted these Terms and Conditions.</p>
                                        <p className="static-page-content">Any non-recurring package, after expiration, gets converted to respective duration package. Please see paragraph 6.D below for further details. </p>

                                    </li>
                                </ul>
                                <ul className="decimal">
                                    <li>
                                        <h4 className="privacy-subheadding">A. Definition:</h4>
                                        <ul className="lower-alpha">
                                            <li>“Content Provider(s)” shall mean and include content owners, channel owners and any other licensors of content on the Site.</li>
                                            <li>“Fees” shall mean subscription fees payable for various packages on monthly, quarterly, half-yearly, yearly basis, Account Activation Fees, Device Activation Fees, service fee, restart fee, restoral fee, late fee including but not limited to any other charges, fees and subscription rents mentioned in this Agreement that any User is liable to pay for continued use of Services here under. </li>
                                            <li>“Partner Apps” shall mean applications of certain Content Providers, more particularly described in Annexure-A.</li>
                                            <li>“YuppTV” shall mean YuppTV USA Inc. </li>
                                            <li>“Services” shall mean and include the Services as mentioned in Clause 2 hereinafter.</li>
                                            <li>“Site” shall mean <a href=" https://scopevideo.com/" target="_blank"> https://scopevideo.com/</a> </li>
                                            <li>“Subscription Term” shall mean the total duration of any subscription package availed by any User, at a given point of time. For example, if a User subscribes to yearly package, the Subscription Term here shall be for 12 months from the date of such subscription.</li>
                                            <li>“Registration Term” shall mean the total duration commencing from the date any User registers on the Site by providing his/her details to avail the Services as described under Clause 3 and shall continue till the time such registration is cancelled either by Scope Video or User. For clarity, Registration Term shall include Subscription Term when availed.</li>
                                            <li>“Term” shall mean Subscription Term and Registration Term collectively. </li>
                                            <li>“Territory” shall mean the locations where Scope Video offers its Services to User/s.</li>
                                            <li>“User” shall mean and include all the existing and future users who avails the Services of Scope Video. </li>
                                        </ul>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding"> The Services</h4>
                                        <p className="static-page-content">Scope Video provides online entertainment services (“Services”) such as access to digital content, including but not limited to audiovisual programs, movies, music videos, linear television channels, events, soaps, serials, trailers and other audio visual programs (“Content”) at such Fees, for such Term, in the Territory and on the terms and conditions as set out in this Agreement. User hereby agrees that Services are provided based on the rights provided to Scope Video by Content Provider.</p>
                                        <p className="static-page-content"><strong>Services shall include the following:</strong> </p>
                                        <ul className="lower-alpha">
                                            <li>Free content for which a User may be required to register on the Site by providing details such as name, phone number and email id, in order to consume content on the Site; </li>
                                            <li>Subscription packages for which any User may subscribe to such packages made available by Scope Video, by paying such Fees as fixed by Scope Video, for the Subscription Term in the Territory. Registration is mandatory for any User in order to subscribe to any subscription packages mentioned hereinabove;</li>


                                            <p className="static-page-content">Scope Video offers various subscription packages in addition to free content available on the Site. Any User intending to purchase or subscribe to any subscription package can subscribe to Services hereunder by visiting YuppTV website contained at <a target="_blank" href="https://www.yupptv.com/">https://www.yupptv.com/</a> . A User’s use of Services hereunder and any other issue related hereunder shall be governed by the present Terms and Conditions.The subscription package consists of content made available by Content Providers where any User upon subscribing to subscription packages, as aforesaid, will finally view/stream content on the Partner Apps. Such content made available on Partner Apps will be governed by the terms and conditions of the said Partner Apps (mentioned in Annexure A below).Any subscription packages so availed shall be valid for the Subscription Term in the Territory. However, due to Content Providers’ discretion, certain subscription packages may be altered in accordance with terms and conditions of the Content Providers’ products/services, including alteration in Fees, Subscription Term including such other terms that may be impacted at Content Providers’ discretion and Scope Video may take such action/s in order to align these Terms vis-à-vis the changes in terms and conditions made by any Content Providers. Scope Video shall not be responsible for alterations made due to Content Providers’ discretion.</p>
                                            <p className="static-page-content">By agreeing to these Terms & Conditions, Scope Video grants you a non-exclusive, non-sub licensable, non-transferable, limited right to access and use this Site, and view the channel(s) subscribed to by you, for the Term in the Territory, by way of computer (or other applicable device) owned or controlled by you, connected to the Site over the Internet at any given time and in strict conformity with these Terms & Conditions. Scope Video platform, enabled through any device, should be accessed by only one IP address per user. Sharing of accounts is strictly prohibited. You shall not publicly display any portion of the Site, Content or any films or other audiovisual or digital works available at this Site. Any other use or exploitation of this Site, including the content on the Site (the "Site Content") and the software used by Scope Video to provide the Site (the "Site Code"), is strictly prohibited.</p>
                                            <p className="static-page-content">The Site Content, Site Code and Partner Apps are owned by Scope Video and/or its licensors and Content Providers, as applicable, and are protected by applicable domestic and international copyright and other intellectual property laws. Unless expressly permitted elsewhere in the Site by Scope Video, you shall not record, copy, distribute, publish, perform, modify, download, transmit, transfer, sell, license, reproduce, create derivative works from or based upon, distribute, post, publicly display, frame, link, or in any other way exploit any of the Site Content or Site Code, in whole or in part. Any rights not expressly granted to you are reserved. Any violation of copyright laws may result in severe civil and criminal penalties. Violators will be prosecuted to the maximum extent possible.</p>
                                            <p className="static-page-content">By accessing content provided by Content Provider(s) on their respective Partner Apps, you hereby accept the terms and conditions and privacy policies of those Partner Apps as listed in Annexure-A.</p>

                                        </ul>


                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Registration</h4>
                                        <p className="static-page-content"> Every User is required to register with Scope Video and the Services. The User will be required to provide certain information at the time of registration as required from time to time by Scope Video or any other authority and will be assigned an account (“Account”) with a username and a password. Registration permits the User to subscribe to the Services. If the User fails to provide the information to Scope Video as required from time to time, Scope Video shall have the right to discontinue the Services without any further notices or cost.</p>
                                        <p className="static-page-content"> The User agrees to provide accurate, complete and valid information at the time of registration and/or subscription and undertakes to keep such information current and updated. The User also undertakes to keep details of the Account and the password secret and safe from unauthorized and inappropriate use. The User acknowledges that Scope Video shall not be liable to the User or any third party for loss or misuse of the information relating to the Account or the password.</p>
                                        <p className="static-page-content">Scope Video has the right to terminate the Service to any User if any information provided by such User is false, inaccurate or misleading without any further notice or cost. </p>
                                        <p className="static-page-content">The User acknowledges that Scope Video will retain and maintain the registration information (including information relating to the credit/debit card of the User) to maintain the Users accounts and for billing purposes and the User expressly consents to the retention and use of such information by Scope Video. </p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">The Account</h4>
                                        <p className="static-page-content">The User is solely responsible for maintaining the confidentiality and security of the Account and is advised against revealing any Account information to any other person. The User is also singularly responsible for all activities that occur on the Account and the User shall immediately notify Scope Video of any unauthorized use of the Account or any other breach of security. The User may change or retrieve a forgotten password at any time by following the instructions on the login page.</p>
                                        <p className="static-page-content">Scope Video shall not be responsible for any losses arising from the unauthorized use of the Account. The User is responsible in all respects for any material purchased and received by the User and Scope Video shall not be liable in the event of any loss, destruction, or damage of any such material purchased by the User.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Transfer of Account & Assignment Rights</h4>
                                        <p className="static-page-content">It is hereby expressly agreed and acknowledged by the User that Scope Video has absolute rights to assign all its rights under this Agreement or such other documents connected with Service(s), to third party or parties without execution of any further deed(s) or document(s) with User.</p>
                                        <p className="static-page-content">It is hereby expressly agreed and acknowledged by the User that in the eventuality of Scope Video been acquired by any third Party pursuant to an order of Court or by agreement(s) or otherwise resulting into change of ownership/management of Scope Video in such a case each of the existing contracts, rights and commitments of Scope Video towards the User, shall stand transferred/assigned, including but not limited to mode of subscription, right to collect monthly subscription rents, Fees, charges and all other dues, from the User shall be vested upon such acquirer from the date of such acquisition without execution of any further deed or documents.</p>

                                    </li>


                                    <li>
                                        <h4 className="privacy-subheadding">Subscription</h4>

                                        <ul className="lower-alpha" >
                                            <li>User may subscribe to various packages offered by Scope Video by making such payment as stipulated for each of such packages for the Subscription Term in their respective Territory.</li>
                                            <li>User may apply for termination of the monthly subscription anytime. His/her subscription would get terminated at the end of that subscription month and would not be charged from next month onwards.</li>
                                            <li>Scope Video will not refund any unutilized price for any part of the Services. However, termination of the subscription shall not relieve the User of any obligations (including, but not limited to the payment for the Services) as set forth in this Agreement.
                                                Cancellations of quarterly, half-yearly and yearly packages with and without Devices shall not be permitted under any circumstances as they are available to User at discounted prices.</li>
                                            <li>Quarterly, Half-Yearly & Yearly Expiry Conversions
                                                To provide Users with uninterrupted services, all Quarterly, Half-yearly and Yearly packages, as applicable, would be automatically renewed however, Scope Video shall,</li>
                                            <li>days’ prior to the date of expiry of a User’s quarterly or half-yearly or yearly package, provide notice regarding expiry of his/her package. The User may inform Scope Video of his/her intention not to renew his/her package within the 7 days’ notice period. If the User does not confirm non-renewal within the 7 days’ notice period, such User’s package shall be automatically renewed for the duration of the package.</li>
                                            <li>Account Activation Fee
                                                A non-refundable Account Activation Fee of approx. $10, which may vary from country to country, shall be paid by the customer as part of expenses associated for setting up and maintaining the Account.
                                                This Fee will be charged for every year that your subscription is renewed until the time you withdraw your subscription. However, if a customer cancels the package and comes back, then the customer must pay the non-refundable Account Activation Fee again.</li>
                                            <li> Service Fee
                                                For all monthly packages, customer will be charged a service fee of approximately $2.99 per month, which may vary from country to country.</li>
                                            <li>Scope Video reserves the right to change the Fees and any other fees/charges mentioned in the Terms & Conditions, at any time.</li>

                                        </ul>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Payment</h4>
                                        <p className="static-page-content">The User shall provide Scope Video with a valid credit/debit card for the payment of all monies due and payable for the use of the Services and by agreeing to these Terms & Conditions, the User expressly authorizes Scope Video to bill, charge and collect against the said credit/debit card for purchases and other transactions in respect of the Services, from the credit/debit card provided by the User</p>
                                        <p className="static-page-content">The User agrees to pay for all Services and subscriptions purchased through the Services as and when they become due and payable. Scope Video may bill /charge the User’s credit/debit card for any Fees or purchases or other charges and for any additional amounts (including any taxes and late Fees, as applicable) as may have accrued to or incurred by or in connection with the Account and the use of the Services.</p>
                                        <p className="static-page-content">The User’s use of the Services includes the ability to enter into agreements and/or to make purchases electronically. The User expressly acknowledges and undertakes that any and all electronic submissions by the User shall be binding on the User and shall constitute the User’s intent to be bound by the terms of this Agreement and to comply with the payment requirements including payments for the purchase of any of the Services or charges in respect thereof and be liable to pay monies which may become due and payable in accordance with the terms of this Agreement. The User shall also be bound by electronic submissions in respect of all records relating to all transactions including notices of cancellation/ termination, policies, contracts, and applications.</p>
                                        <p className="static-page-content">The User may request for information related to purchase transactions at any time by contacting info@yupptv.com. However, Scope Video shall not send physical copies of any statements to any User.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Transmission</h4>
                                        <p className="static-page-content">Scope Video will make all reasonable efforts to ensure that the the User is redirected to Partner Apps to view the content. The User acknowledges that at times this may be affected by things beyond the control of Scope Video due to force majeure including interruptions due to storm, fire, casualty, unanticipated work stoppage, power outage, satellite failure, strike, lockout, labor dispute, civil disturbance, riot, war, national emergency, act of Government, act of public enemy, or other cause of similar or dissimilar nature or due to any issues in the Partner Apps or from the Content Providers and agrees that Scope Video shall not be liable for any delay or disruption caused by such interruptions.</p>
                                        <p className="static-page-content">On occasion, technical problems may delay or prevent delivery of the product or services requisitioned by the User. The User acknowledges that while using the Services, the User may encounter content that may be deemed offensive, indecent, or objectionable. The User agrees to use the Services at the sole risk and discretion of the User and that Scope Video shall not be liable to the User for any Content that may be found to be offensive, indecent, or objectionable, including any content found on Partner Apps.</p>
                                        <p className="static-page-content">The User acknowledges that some aspects of the Services entail the ongoing involvement of Scope Video and/or Content Providers and that interruptions or discontinuation may occur when Scope Video and/or Content Providers is implementing any changes to the Services and/or Partner Apps and that the User may not be able to use the Services or content on Partner Apps to the same extent as prior to such change or discontinuation and that Scope Video shall have no liability to the User in such case. Any disputes or issues arising out of the Content Providers’ terms and conditions or the services or in relation to Partner Apps will be resolved directly with such Content Provider by the User without the involvement of Scope Video. </p>
                                        <p className="static-page-content">Scope Video reserves the right to change, suspend, remove, or disable access to any Services, Content, Partner Appsor other materials comprising any part of the Services at any time without notice. In no event will Scope Video be liable for the removal of or disabling of access to any such Services, Content, Partner Apps or materials under this Agreement. Scope Video may also impose limits on the use of or access to certain features or portions of the Services, in any case and without notice or liability.Scope Video reserves the right to modify the content and/or Content Providers from time to time, without requiring consent of the User.</p>
                                    </li>
                                    <li> <h4 className="privacy-subheadding">Restrictions</h4>
                                        <p className="static-page-content">The User acknowledges that the Services and the products purchased through the Services include a security framework which uses technology that protects digital information and limits the usage of the products and the Services to certain usage rules established by Scope Video and its licensors. The User shall be authorized to use the products and the Services only for personal, in the house, and non-commercial use. The User shall not to attempt to, or assist another person to circumvent, reverse-engineer, decompile, disassemble, or otherwise tamper with any of the security components related to such Usage Rules for any reason whatsoever. Usage Rules may be controlled and monitored by Scope Video for compliance purposes and Scope Video reserves the right to enforce the Usage Rules with or without notice to the User.</p>
                                        <p className="static-page-content">Changes in Services Offered. Scope Video may, including at Content Providers’ discretion, add, delete, rearrange, alter, interrupt, change and/or eliminate any and all programming, programming packages and/or other Services that Scope Video offers, as well as the prices and Fees, Subscription Term related to such programming, programming packages and/or other services that Scope Video offers, at any time and from time to time, including without limitation during any term commitment period to which User has agreed. In addition to the above, Scope Video may at any time, alter, rearrange or change the Subscription Term of any programming package at any time. In the event that Scope Video adds, deletes, rearranges, alters, interrupts, changes and/or eliminates any programming, programming packages and/or other Services, that Scope Video offers, Scope Video shall have no obligation to replace or supplement such programming, programming packages and/or other Services that Scope Video offers. User shall not be entitled to any refund because Scope Video adds, deletes, rearranges, alters, interrupts, changes and/or eliminates any programming, programming packages, any Subscription Term in any Territory and/or other Services that Scope Video offers.</p>
                                        <p className="static-page-content">User expressly acknowledges and agrees that additions, deletions, rearrangements, alterations, interruptions, changes and/or eliminations to any programming, programming packages and/or other Services that Scope Video offers are at the discretion of the Content Providers including those that arise in connection with the termination or suspension of Scope Video's access to all or any portion of any programming, programming packages and/or other Services that Scope Video offers is not a discretionary act by Scope Video and is dependant on Content Providers providing access to the same.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Promotions</h4>
                                        <p className="static-page-content">Scope Video may offer Users certain promotional offers from time to time. Entitlement to promotional offers shall be subject to the eligibility criteria set out in such offers. Scope Video reserves the right to change or terminate the promotional offers any time, at its own discretion. A User who takes up a promotional benefit by virtue of his/her registration for a purchase plan and who subsequently terminates such purchase plan before the expiry of the plan shall be charged for the promotional benefit received by the User.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">System Requirements</h4>
                                        <p className="static-page-content">Use of the Services to access movies, music videos/audios or television programs requires a compatible device, Internet access and certain software and may require obtaining updates or upgrades from time to time. The ability of the User to use the Services may be affected by the performance of these factors. High speed Internet access/Broadband is recommended for availing the use of the Services.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Privacy Policy</h4>
                                        <p className="static-page-content">The Privacy Policy is incorporated into and forms a part of this Agreement. Scope Video may use any personal information provided pursuant to this agreement in accordance with the provisions of the Privacy Policy.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Third Party materials and websites</h4>
                                        <p className="static-page-content">The User acknowledges that certain content, products and services available via the Services may include materials from third parties. In addition, Scope Video may provide links to certain third party websites. The User acknowledges and agrees that Scope Video shall not be responsible for examining or evaluating the content or accuracy of any such third-party material or websites. Scope Video does not warrant or endorse and does not assume and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third parties.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Enforcement</h4>
                                        <p className="static-page-content">Scope Video reserves the right to take all necessary and appropriate steps to enforce and/or verify compliance with any part of this Agreement (including but not limited to Scope Video’s right to cooperate with any legal process relating to the User’s use of the Services and/or products, and/or a third party claim that the User’s use of the Services and/or products is unlawful and/or infringes such third party's rights). The User agrees that Scope Video has the right, without liability, to disclose any registration data and/or account information to law enforcement authorities, government officials, and/or a third party, as Scope Video believes to be reasonably necessary or appropriate.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Minor</h4>
                                        <p className="static-page-content">If the User is a minor under the age of 18, the terms of this Agreement should be reviewed and accepted by his/her parent/guardian to ensure that the User and the parent/guardian understands these terms and conditions. Purchase of the Services by the User would require an authorized credit/debit card holder (parent/guardian) to take responsibility and liability for payment of the Services used.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Proprietary Rights</h4>
                                        <p className="static-page-content">The User acknowledges and agrees that the Services, including but not limited to graphics, audio and film clips and editorial content, contains proprietary information and material that is owned by Scope Video and/or its Content Providers and is protected by applicable intellectual property and other laws, including but not limited to copyright, trademark and other proprietary rights. The User shall not use such proprietary information or materials of Scope Video and/or Content Providers in any way whatsoever except for use of the Services in compliance with the terms of this Agreement. No portion of the Services may be reproduced in any form or by any means</p>
                                        <p className="static-page-content">The User agrees not to, in any manner, modify, rent, lease, loan, sell, distribute, or create derivative works based on the Services, and shall not exploit the Services in any unauthorized way whatsoever, including but not limited to, trespass or burdening the network capacity.</p>
                                        <p className="static-page-content">All copyright in and to the Services, including but not limited to Services and products (including the compilation of content, postings, links to other Internet resources, and descriptions of those resources), audio visual clips, films, graphics, images, animations, illustrations, editorial content and software are owned by Scope Video and/or its licensors. Any duplication or use of these in other electronic or printed publications is not permitted. The use of the software or any part of the Services, except for use of the Services as permitted in this Agreement is strictly prohibited and shall infringe on the intellectual property rights of Scope Video and/or its licensors. Infringement of these copyrights may subject the User to civil and criminal penalties, including monetary damages.</p>
                                        <p className="static-page-content">Scope Video, the Scope Video logo and other Scope Video trademarks, service marks, graphics, and logos used in connection with the Services are trademarks or registered trademarks of Scope Video. The User is not granted any right or license with respect to any of the aforesaid trademarks and any use of such trademarks, in any manner whatsoever</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Disclaimers</h4>
                                        <p className="static-page-content">Scope Video does not guarantee, represent, or warrant that the use of the Services will be uninterrupted or error-free and the User agrees that from time to time, Scope Video may remove or suspend the Services for indefinite periods of time, or cancel or terminate the Services at any time, with or without notice to the User.</p>
                                        <p className="static-page-content">The User expressly agrees that the use of, or inability to use, the Services is at sole risk of the User. The Services and all products and services delivered to the User through the Services are provided on an “as is” and “as available” basis for the User’s use, without warranties of any kind, either express or implied, including all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.</p>
                                        <p className="static-page-content">Due to Content Providers’ discretion, Scope Video can add / remove/ replace any existing channel/content or those that are part of the package, with some other channel/content at any point of time. Scope Video has the right to vary ongoing Term of the user. User agrees to abide by any such addition/removal/alteration made by Scope Video.</p>
                                        <p className="static-page-content">"Prices for the packages and the respective Term are subject to change"</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Liability</h4>
                                        <p className="static-page-content">You acknowledge and agree that, to the maximum extent permitted by law, the entire risk arising out of your access to and use of the Site, Services, Content, your registration and access to the Site and Services and any contact you have with other Users of Scope Video whether in person or online remains with you. Neither Scope Video nor any other party involved in creating, producing, or delivering the Site, Services, Content will be liable for any incidental, special, exemplary or consequential damages, including lost profits, loss of data or loss of good will, service interruption, computer damage or system failure, device failure or the cost of substitute products or services, or for any damages for personal or bodily injury or emotional distress arising out of or in connection with these terms, from the use of or inability to use the Site, Services, Content, from any communications, whether based on warranty, contract, tort (including negligence), product liability or any other legal theory, and </p>
                                        <p className="static-page-content">whether or not Scope Video has been informed of the possibility of such damage, even if a limited remedy set forth herein is found to have failed of its essential purpose.</p>
                                        <p className="static-page-content">In no event will Scope Video’s aggregate liability arising out of or in connection with these terms and your use of the Site, Services, Content, including, but not limited to, your subscription, access of the Site and Services, or from the use of or inability to use the Site, Services, Content, and in connection with any other users, exceed the amounts you have paid or owe for subscription hereunder as a user/subscriber in the three (3) month period prior to the event giving rise to the liability for the services. The limitations of damages set forth above are fundamental elements of the basis of the bargain between Scope Video and you.</p>
                                        <p className="static-page-content">Scope Video shall use reasonable efforts to protect information submitted by the User in connection with the Services. The User, however, acknowledges and agrees that the submission of such information is at the sole risk of the User and Scope Video hereby disclaims any and all liability to the User for any loss or liability relating to such information in any way.</p>
                                        <p className="static-page-content">Scope Video does not represent or guarantee that the Services will be free from loss, corruption, attack, viruses, interference, hacking, or other security intrusion and Scope Video disclaims any liability relating thereto and the User shall be solely responsible for backing up the User’s system.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Waiver & Indemnity</h4>
                                        <p className="static-page-content">By using the Services, the User agrees to indemnify and hold Scope Video, its directors, officers, employees, affiliates, agents, contractors and licensors, harmless with respect to any claims arising out of the User’s breach of this agreement, use of the Services,or any action taken by Scope Video as part of its investigation of a suspected violation of this agreement or as a result of its finding that a violation of this agreement has occurred. The User shall not sue or recover any damages from Scope Video, its directors, officers, employees, affiliates, agents, contractors, and licensors as a result of its decision to remove or refuse to process any information or content, to warn you, to suspend or terminate your access to the Services, or to take any other action during the investigation of a suspected violation or as a result of Scope Video’s conclusion that a violation of this agreement has occurred. This waiver and indemnity provision shall apply to all violations described in or contemplated by this agreement.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Termination</h4>
                                        <p className="static-page-content">This Agreement or the User’s subscription may be terminated by Scope Video: (i) at any time, without notifying the User, with immediate effect to the same or (ii) if the User fails or Scope Video suspects that the User has failed to comply with the any of the provisions, including but not limited to failure to make payment of Fees due, failure to provide Scope Video with a valid credit/debit card or with accurate and complete registration data, failure to safeguard Account information, or violation of the usage rules or any license to the software, of this Agreement. Alternatively, Scope Video may, at its discretion terminate the license to the software; and/or preclude access to the Services (or any part thereof). Scope Video may or may not give the User a notice of such termination. The User will remain liable for all amounts due under the Account up to and including the date of termination.</p>
                                        <p className="static-page-content">In addition to all other rights that Scope Video may have to disconnect Services at any time without notifying User if: (i) User fails to pay for any such Services in advance; (ii) Scope Video receives confirmation that User has received Services, or any part of the Services, without paying for them; (iii) User otherwise violates the terms and conditions of this Agreement; (iv) User assigns or attempts to assign any of User’s rights, duties or obligations under this Agreement or any applicable promotion Agreement; (v) User is receiving Services through a third-party billing agent and becomes ineligible to receive applicable Services provided by such third-party billing agent; (vi) User receiving Services through equipment provided by or purchased from a third-party equipment provider and becomes ineligible to receive applicable services provided by such third-party equipment provider; or (vii) User commences any act or filing of bankruptcy or bankruptcy proceedings are commenced against User.</p>
                                        <p className="static-page-content">If User Services are cancelled or disconnected for any reason, User still must pay all outstanding balances accrued, including without limitation, any applicable Fees. Charges for Services, once charged to User account, are non-refundable, and no refunds or credits will be provided in connection with the cancellation of Services. Without limitation to the foregoing, Scope Video will not issue refunds or credits for partially used services and/or periods. If User received a discounted price due to a promotion, and he/she cancels prior to any applicable expiration of that promotion, User shall not be entitled to any refund or credit for the unused portions of such discounted price.</p>
                                        <p className="static-page-content">Scope Video reserves the right to modify, suspend, or discontinue the Services (or any part or content thereof) at any time with or without notice to the User and Scope Video shall not be liable to the User or to any third party upon exercise of such right</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Modifications</h4>
                                        <p className="static-page-content">Scope Video reserves the unilateral right to, at any time, update, revise, supplement, and otherwise modify this Agreement and to impose new or additional rules, policies, terms, or conditions on the use of the Services. Such updates, revisions, supplements, modifications, and additional rules, policies, terms, and conditions will be effective immediately and shall be deemed to be incorporated into this Agreement by reference. The User is advised to periodically review this Agreement to remain updated on the terms and conditions of this Agreement. The User’s continued use of the Services following such incorporation will be deemed to constitute the User’s acceptance of any and all such modifications.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Grievance Officer</h4>
                                        <p className="static-page-content">A User from India may contact the below mentioned Grievance Officer appointed by Scope Video for any content related concerns, complaints or any grievances in the format provided below:</p>
                                        <ol className="lower-alpha" >
                                            <li>Name: Mr. Roop Kumar Reddy </li>
                                            <li>Email Address: roop@yupptv.com</li>
                                            <li>Format:</li>
                                            <li>Content Name:</li>
                                            <li>Date and time of streaming:</li>
                                            <li>Concerns:</li>
                                        </ol>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Notices</h4>
                                        <p className="static-page-content">Scope Video may notify the User with respect to the Service by sending an email message to the email address or by sending a letter via postal mail to the contact address listed in the Account contact information.</p>
                                        <p className="static-page-content">For Users located outside India, notices to Scope Video must be sent to info@yupptv.com and/or yupptv_legal@yupptv.com and all notices must clearly bear the name, account name, address, telephone number and e-mail of the User.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Governing Law</h4>
                                        <p className="static-page-content">This Agreement is governed by the laws of the United States and the federal state of Georgia and is subject to the exclusive jurisdiction of the courts at Georgia. The Users use of the Services may also be subject to other local, state, national, or international laws. The User expressly agrees that exclusive jurisdiction for any claim or dispute with Scope Video or relating in any way to the use of the Services shall be of the courts of Georgia, United States of America.</p>
                                    </li>

                                    <li>
                                        <h4 className="privacy-subheadding">Limitation of Liability<br />LIMITATION OF OUR LIABILITY</h4>
                                        <p className="static-page-content">A User from India may contact the below mentioned Grievance Officer appointed by Scope Video for any content related concerns, complaints or any grievances in the format provided below:</p>
                                        <ol className="lower-alpha" >
                                            <li>INTERRUPTIONS AND DELAYS
                                                <p className="static-page-content">NEITHER SCOPE VIDEO NOR ITS THIRD-PARTY BILLING AGENTS OR DONGLE/EQUIPMENT PROVIDERS, NOR ANY OF SCOPE VIDEO OR THEIR AFFILIATES, WILL BE LIABLE FOR ANY ADDITION, DELETION, REARRANGEMENT, ALTERATION, INTERRUPTION, CHANGE AND/OR ELIMINATION IN ANY SERVICE OR FOR ANY DELAY, FOR THE AVOIDANCE OF DOUBT INCLUDING WITHOUT LIMITATION: (i) IF SUCH ADDITION, DELETION, REARRANGEMENT, ALTERATION, INTERRUPTION, CHANGE AND/OR ELIMINATION OR DELAY ARISES IN CONNECTION WITH THE TERMINATION OR SUSPENSION OF SCOPE VIDEO'S ACCESS TO ALL OR ANY PORTION OF SERVICES FOR ANY REASON; (ii) THE RELOCATION OF ALL OR ANY PORTION OF THE SERVICES TO DIFFERENT SERVER(S); (iii) AN ADDITION, DELETION, REARRANGEMENT, ALTERATION, INTERRUPTION, CHANGE AND/OR ELIMINATION IN THE FEATURES AND/OR FUNCTIONALITY AVAILABLE WITH YOUR EQUIPMENT; (iv) ANY SOFTWARE OR OTHER DOWNLOADS INITIATED BY SCOPE VIDEO; (v) ISSUES RESULTING FROM YOUR BROADBAND CONNECTION, INCLUDING WITHOUT LIMITATION, LACK OF SUFFICIENT BANDWIDTH; OR (vi) ANY ACTS OF GOD, FIRES, EARTHQUAKES, FLOODS, POWER OR TECHNICAL FAILURE, SERVER OR INTERNET FAILURE, ACTS OF ANY GOVERNMENTAL BODY OR ANY OTHER CAUSE BEYOND SCOPE VIDEO’S REASONABLE CONTROL.</p>
                                            </li>
                                            <li>ALTERATIONS TO EQUIPMENT.
                                                <p className="static-page-content">NONE OF SCOPE VIDEO OR ANY THIRD-PARTY BILLING AGENTS OR DONGLE/EQUIPMENT PROVIDERS OR ANY OF OUR OR THEIR AFFILIATES, WILL BE LIABLE FOR ANY ADDITION, DELETION, REARRANGEMENT, ALTERATION, INTERRUPTION, CHANGE AND/OR ELIMINATION OF OR TO ANY EQUIPMENT, FOR THE AVOIDANCE OF DOUBT INCLUDING WITHOUT LIMITATION, REMOVING OR DISABLING SOFTWARE, FEATURES AND/OR FUNCTIONALITY.</p>
                                            </li>
                                            <li>NO WARRANTIES.
                                                <p className="static-page-content">EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY BY APPLICABLE STATE LAW, NEITHER SCOPE VIDEO NOR ANY THIRD-PARTY BILLING AGENTS, APPOINTED BY SCOPE VIDEO, OR DONGLE/EQUIPMENT PROVIDERS, NOR ANY OF OUR OR THEIR AFFILIATES, MAKE ANY WARRANTY, EITHER EXPRESSED OR IMPLIED, REGARDING YOUR SCOPE VIDEO DONGLE OR ANY OTHER EQUIPMENT OR ANY SERVICES FURNISHED TO USER. ALL SUCH WARRANTIES, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT ARE HEREBY EXPRESSLY DISCLAIMED AND EXCLUDED.</p>
                                            </li>
                                            <li>CONTENT RESTRICTIONS
                                                <p className="static-page-content">IT IS USER'S RESPONSIBILITY TO IMPOSE ANY PROGRAMMING, INTERNET OR OTHER CONTENT RESTRICTIONS ON HIMSELF/HERSELF, MEMBERS OF USER'S FAMILY AND HOUSEHOLD, AND GUESTS, AS USER DEEM APPROPRIATE. NONE OF SCOPE VIDEO OR ITS THIRD-PARTY BILLING AGENTS OR /DONGLE/EQUIPMENT PROVIDERS OR ITS AND THEIR AFFILIATES WILL BE LIABLE TO ANYONE DUE TO, OR BASED UPON, ANY CONTENT (INCLUDING WITHOUT LIMITATION, ANY INACCURACIES, ERRORS IN, OR OMISSIONS FROM SUCH CONTENT): (i) CONTAINED IN ANY OF THE SERVICES FURNISHED TO USER; OR (ii) ACCESSED USING THE SERVICES OR EQUIPMENT FURNISHED TO USER.</p>
                                            </li>
                                            <li>DAMAGES LIMITATION
                                                <p className="static-page-content">NEITHER SCOPE VIDEO NOR ITS THIRD-PARTY BILLING AGENTS OR DONGLE PROVIDERS, NOR ANY OF ITS OR THEIR AFFILIATES, SHALL HAVE ANY LIABILITY WHATSOEVER FOR ANY SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR RELATING TO: (i) SCOPE VIDEO DONGLE OR ANY OTHER EQUIPMENT; (ii) SCOPE VIDEO FURNISHING OR FAILURE TO FURNISH ANY SERVICES OR EQUIPMENT TO USER; OR (iii) ANY FAULT, FAILURE, DEFICIENCY OR DEFECT IN SERVICES OR EQUIPMENT FURNISHED TO USER.</p>
                                            </li>
                                        </ol>

                                    </li>

                                    <li>
                                        <h4 className="privacy-subheadding">General</h4>
                                        <ol className="lower-alpha" >
                                            <li><strong>Age </strong>
                                                <p className="static-page-content"> Physical Address/Change of Address. User must be at least 18 years of age to enter into this Agreement. When setting up your Scope Video account, User must provide us with complete and correct personal information related to his/her account, including User email address, the physical address where User Dongle will be located and your Services will be provided. A post office box does not meet this requirement. User must give us immediate notice of any change of name, your email address,mailing address, telephone number, or physical address where your Scope Video Dongle/Equipment is located. </p>
                                                <p className="static-page-content">User may do this by notifying Scope Video customer service center by telephone or in writing at the phone number, mailing address, or e-mail address set forth at the top of this Agreement.</p>
                                            </li>
                                            <li>
                                                <strong>Online Account Information. </strong>
                                                <p className="static-page-content"> User shall be responsible for maintaining the confidentiality of his/her account username and password of his/her online account and for all activities that occur under his/her account username and/or password. User must: (i) keep his/her account username and password confidential and, except as explicitly provided in this Agreement, not share them with anyone else; and (ii) immediately notify Scope Video of any unauthorized use of your password and/or account username or other breach of security. </p>
                                            </li>
                                            <li>
                                                <strong>Third-Party Billing Agents </strong>
                                                <p className="static-page-content"> Scope Video may enter into relationships with third parties to provide billing and other services on its behalf, in which case the terms and conditions of this Agreement shall apply to such third parties as applicable under the circumstances. Additional terms and conditions imposed by such third-party billing agents may apply. For example and without limitation: (i) late fees imposed by such third-party billing agents may be administered according to the third-party billing agent's billing procedures and applicable state tariffs and regulations; (ii) Scope Video third-party billing agents may require that User shall pay all past due charges for Services, a restart fee, and/or a prepayment before Scope Video reconnects his/her Services; and (iii) other services provided by such third-party billing agents, including without limitation, local telephone service, may need to be restored before Scope Video Services can be restored, and a restoral fee and/or deposit may be required to restore third-party billing agent services. Partial payments on third party billing agent bills may be applied first to the balance due for other services billed on User third party billing agent bill, including without limitation, local telephone service, according to the third-party billing agent's billing procedures and applicable state statutes and regulations. Please contact your third party billing agents for details. Failure to pay all or any part of your third-party billing agent bill may result in disconnection of Services. </p>
                                            </li>
                                            <li>
                                                <strong>Credit Checks </strong>
                                                <p className="static-page-content">You authorize Scope Video to investigate your financial responsibility and creditworthiness, including without limitation, acquiring credit reports and histories, and to report any payment defaults to credit reporting agencies.</p>
                                            </li>
                                            <li>
                                                <strong>Remedies Cumulative. </strong>
                                                <p className="static-page-content">The rights and remedies provided under this Agreement to Scope Video in case of User's default or breach of this Agreement are cumulative and without prejudice to any other rights and remedies that Scope Video may have by reason of such default or breach at law, in equity, under contract or otherwise (all of which are expressly reserved).
                                                </p>
                                            </li>
                                            <li>
                                                <strong>Other </strong>
                                                <p className="static-page-content">No salesperson, installer, customer service representative, authorized retailer, or other similarly situated individual is authorized to change or override this Agreement. Scope Video may, however, change this Agreement at any time and from time to time. The terms and conditions of this Agreement that either are expressly stated to survive or by their nature would logically be expected to survive its expiration or termination will continue thereafter. This Agreement is in addition to any other written agreement(s), if any, between you and Scope Video, and except as provided to the contrary herein, all such written agreements shall remain in full force and effect. Except as expressly set forth in this Agreement to the contrary, this Agreement replaces and supersedes any and all prior Agreements in their entirety, and such prior Agreements shall be of no further force or effect whatsoever.
                                                </p>
                                            </li>
                                        </ol>

                                    </li>

                                </ul>

                                <h4 className="privacy-subheadding annexure-txt-head1">ANNEXURE-A</h4>
                                <h4 className="privacy-subheadding annexure-txt-head">PARTNER APPS’ TERMS AND CONDITIONS AND PRIVACY POLICIES</h4>
                                <p className="static-page-content">As stated in paragraph 2 above, by accessing content provided by Partner Apps, you hereby accept the terms and conditions and privacy policies of those Partner Apps as listed below.</p>
                                <ul className="decimal">
                                    <li>
                                        <p className="privacy-subheadding">YuppTV </p>
                                        <p className="static-page-content">
                                            •	Terms Of Use may be accessed at: <a href="https://www.yupptv.com/help/terms-and-conditions" target="_blank"> https://www.yupptv.com/help/terms-and-conditions</a><br />
                                            •	Privacy Policy may be accessed at: <a href="https://www.sonyliv.com/privacy-policy" target="_blank"> https://www.yupptv.com/help/privacy-terms</a></p>
                                    </li>
                                    <li>
                                        <p className="privacy-subheadding">AHA</p>
                                        <p className="static-page-content">
                                            •	Terms Of Use may be accessed at:  <a href="https://www.aha.video/tc" target="_blank">https://www.aha.video/tc</a><br />
                                            •	Privacy Policy may be accessed at:  <a href="https://www.aha.video/privacy-policy" target="_blank">https://www.aha.video/privacy-policy</a></p>
                                        <p className="privacy-subheadding">Last Update On: 1<sup>st</sup> April, 2023</p>
                                    </li>
                                </ul>
                            </>
                        }
                        {
                            countryCode != "" && countryCode == "Others" &&
                            <>
                                <ul className="decimal">
                                    <li>
                                        <h4 className="privacy-subheadding">Agreement</h4>
                                        <p className="static-page-content">This Agreement sets out the terms and conditions for a User (defined below) intending to subscribe, avail or access or receiving services and/or equipment of YuppTV USA Inc in the name and style of “Scope Video”. The Services provided by Scope Video pursuant to this Agreement are services which would be made available to a User upon payment of a charge for such Services, for such Term (defined below) in such Territory (defined below) where Scope Video offers its Services (defined below). The User availing the Services shall be deemed to have read, understand and accepted these Terms and Conditions.</p>
                                        <p className="static-page-content">Any non-recurring package, after expiration, gets converted to respective duration package. Please see paragraph 6.D below for further details. </p>

                                    </li>
                                </ul>
                                <ul className="decimal">
                                    <li>
                                        <h4 className="privacy-subheadding">A. Definition:</h4>
                                        <ul className="lower-alpha">
                                            <li>“Content Provider(s)” shall mean and include content owners, channel owners and any other licensors of content on the Site.</li>
                                            <li>“Fees” shall mean subscription fees payable for various packages on monthly, quarterly, half-yearly, yearly basis, Account Activation Fees, Device Activation Fees, service fee, restart fee, restoral fee, late fee including but not limited to any other charges, fees and subscription rents mentioned in this Agreement that any User is liable to pay for continued use of Services here under. </li>
                                            <li>“Partner Apps” shall mean applications of certain Content Providers, more particularly described in Annexure-A.</li>
                                            <li>“YuppTV” shall mean YuppTV USA Inc. </li>
                                            <li>“Services” shall mean and include the Services as mentioned in Clause 2 hereinafter.</li>
                                            <li>“Site” shall mean <a href=" https://scopevideo.com/" target="_blank"> https://scopevideo.com/</a> </li>
                                            <li>“Subscription Term” shall mean the total duration of any subscription package availed by any User, at a given point of time. For example, if a User subscribes to yearly package, the Subscription Term here shall be for 12 months from the date of such subscription.</li>
                                            <li>“Registration Term” shall mean the total duration commencing from the date any User registers on the Site by providing his/her details to avail the Services as described under Clause 3 and shall continue till the time such registration is cancelled either by Scope Video or User. For clarity, Registration Term shall include Subscription Term when availed.</li>
                                            <li>“Term” shall mean Subscription Term and Registration Term collectively. </li>
                                            <li>“Territory” shall mean the locations where Scope Video offers its Services to User/s.</li>
                                            <li>“User” shall mean and include all the existing and future users who avails the Services of Scope Video. </li>
                                        </ul>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding"> The Services</h4>
                                        <p className="static-page-content">Scope Video provides online entertainment services (“Services”) such as access to digital content, including but not limited to audiovisual programs, movies, music videos, linear television channels, events, soaps, serials, trailers and other audio visual programs (“Content”) at such Fees, for such Term, in the Territory and on the terms and conditions as set out in this Agreement. User hereby agrees that Services are provided based on the rights provided to Scope Video by Content Provider.</p>
                                        <p className="static-page-content"><strong>Services shall include the following:</strong> </p>
                                        <ul className="lower-alpha">
                                            <li>Free content for which a User may be required to register on the Site by providing details such as name, phone number and email id, in order to consume content on the Site; </li>
                                            <li>Subscription packages for which any User may subscribe to such packages made available by Scope Video, by paying such Fees as fixed by Scope Video, for the Subscription Term in the Territory. Registration is mandatory for any User in order to subscribe to any subscription packages mentioned hereinabove;</li>


                                            <p className="static-page-content">Scope Video offers various subscription packages in addition to free content available on the Site. Any User intending to purchase or subscribe to any subscription package can subscribe to Services hereunder by visiting YuppTV website contained at <a target="_blank" href="https://www.yupptv.com/">https://www.yupptv.com/</a> . A User’s use of Services hereunder and any other issue related hereunder shall be governed by the present Terms and Conditions.The subscription package consists of content made available by Content Providers where any User upon subscribing to subscription packages, as aforesaid, will finally view/stream content on the Partner Apps. Such content made available on Partner Apps will be governed by the terms and conditions of the said Partner Apps (mentioned in Annexure A below).Any subscription packages so availed shall be valid for the Subscription Term in the Territory. However, due to Content Providers’ discretion, certain subscription packages may be altered in accordance with terms and conditions of the Content Providers’ products/services, including alteration in Fees, Subscription Term including such other terms that may be impacted at Content Providers’ discretion and Scope Video may take such action/s in order to align these Terms vis-à-vis the changes in terms and conditions made by any Content Providers. Scope Video shall not be responsible for alterations made due to Content Providers’ discretion.</p>
                                            <p className="static-page-content">By agreeing to these Terms & Conditions, Scope Video grants you a non-exclusive, non-sub licensable, non-transferable, limited right to access and use this Site, and view the channel(s) subscribed to by you, for the Term in the Territory, by way of computer (or other applicable device) owned or controlled by you, connected to the Site over the Internet at any given time and in strict conformity with these Terms & Conditions. Scope Video platform, enabled through any device, should be accessed by only one IP address per user. Sharing of accounts is strictly prohibited. You shall not publicly display any portion of the Site, Content or any films or other audiovisual or digital works available at this Site. Any other use or exploitation of this Site, including the content on the Site (the "Site Content") and the software used by Scope Video to provide the Site (the "Site Code"), is strictly prohibited.</p>
                                            <p className="static-page-content">The Site Content, Site Code and Partner Apps are owned by Scope Video and/or its licensors and Content Providers, as applicable, and are protected by applicable domestic and international copyright and other intellectual property laws. Unless expressly permitted elsewhere in the Site by Scope Video, you shall not record, copy, distribute, publish, perform, modify, download, transmit, transfer, sell, license, reproduce, create derivative works from or based upon, distribute, post, publicly display, frame, link, or in any other way exploit any of the Site Content or Site Code, in whole or in part. Any rights not expressly granted to you are reserved. Any violation of copyright laws may result in severe civil and criminal penalties. Violators will be prosecuted to the maximum extent possible.</p>
                                            <p className="static-page-content">By accessing content provided by Content Provider(s) on their respective Partner Apps, you hereby accept the terms and conditions and privacy policies of those Partner Apps as listed in Annexure-A.</p>

                                        </ul>


                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Registration</h4>
                                        <p className="static-page-content"> Every User is required to register with Scope Video and the Services. The User will be required to provide certain information at the time of registration as required from time to time by Scope Video or any other authority and will be assigned an account (“Account”) with a username and a password. Registration permits the User to subscribe to the Services. If the User fails to provide the information to Scope Video as required from time to time, Scope Video shall have the right to discontinue the Services without any further notices or cost.</p>
                                        <p className="static-page-content"> The User agrees to provide accurate, complete and valid information at the time of registration and/or subscription and undertakes to keep such information current and updated. The User also undertakes to keep details of the Account and the password secret and safe from unauthorized and inappropriate use. The User acknowledges that Scope Video shall not be liable to the User or any third party for loss or misuse of the information relating to the Account or the password.</p>
                                        <p className="static-page-content">Scope Video has the right to terminate the Service to any User if any information provided by such User is false, inaccurate or misleading without any further notice or cost. </p>
                                        <p className="static-page-content">The User acknowledges that Scope Video will retain and maintain the registration information (including information relating to the credit/debit card of the User) to maintain the Users accounts and for billing purposes and the User expressly consents to the retention and use of such information by Scope Video. </p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">The Account</h4>
                                        <p className="static-page-content">The User is solely responsible for maintaining the confidentiality and security of the Account and is advised against revealing any Account information to any other person. The User is also singularly responsible for all activities that occur on the Account and the User shall immediately notify Scope Video of any unauthorized use of the Account or any other breach of security. The User may change or retrieve a forgotten password at any time by following the instructions on the login page.</p>
                                        <p className="static-page-content">Scope Video shall not be responsible for any losses arising from the unauthorized use of the Account. The User is responsible in all respects for any material purchased and received by the User and Scope Video shall not be liable in the event of any loss, destruction, or damage of any such material purchased by the User.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Transfer of Account & Assignment Rights</h4>
                                        <p className="static-page-content">It is hereby expressly agreed and acknowledged by the User that Scope Video has absolute rights to assign all its rights under this Agreement or such other documents connected with Service(s), to third party or parties without execution of any further deed(s) or document(s) with User.</p>
                                        <p className="static-page-content">It is hereby expressly agreed and acknowledged by the User that in the eventuality of Scope Video been acquired by any third Party pursuant to an order of Court or by agreement(s) or otherwise resulting into change of ownership/management of Scope Video in such a case each of the existing contracts, rights and commitments of Scope Video towards the User, shall stand transferred/assigned, including but not limited to mode of subscription, right to collect monthly subscription rents, Fees, charges and all other dues, from the User shall be vested upon such acquirer from the date of such acquisition without execution of any further deed or documents.</p>

                                    </li>


                                    <li>
                                        <h4 className="privacy-subheadding">Subscription</h4>

                                        <ul className="lower-alpha" >
                                            <li>User may subscribe to various packages offered by Scope Video by making such payment as stipulated for each of such packages for the Subscription Term in their respective Territory.</li>
                                            <li>User may apply for termination of the monthly subscription anytime. His/her subscription would get terminated at the end of that subscription month and would not be charged from next month onwards.</li>
                                            <li>Scope Video will not refund any unutilized price for any part of the Services. However, termination of the subscription shall not relieve the User of any obligations (including, but not limited to the payment for the Services) as set forth in this Agreement.
                                                Cancellations of quarterly, half-yearly and yearly packages with and without Devices shall not be permitted under any circumstances as they are available to User at discounted prices.</li>
                                            <li>Quarterly, Half-Yearly & Yearly Expiry Conversions
                                                To provide Users with uninterrupted services, all Quarterly, Half-yearly and Yearly packages, as applicable, would be automatically renewed however, Scope Video shall,</li>
                                            <li>days’ prior to the date of expiry of a User’s quarterly or half-yearly or yearly package, provide notice regarding expiry of his/her package. The User may inform Scope Video of his/her intention not to renew his/her package within the 7 days’ notice period. If the User does not confirm non-renewal within the 7 days’ notice period, such User’s package shall be automatically renewed for the duration of the package.</li>
                                            <li>Account Activation Fee
                                                A non-refundable Account Activation Fee of approx. $10, which may vary from country to country, shall be paid by the customer as part of expenses associated for setting up and maintaining the Account.
                                                This Fee will be charged for every year that your subscription is renewed until the time you withdraw your subscription. However, if a customer cancels the package and comes back, then the customer must pay the non-refundable Account Activation Fee again.</li>
                                            <li> Service Fee
                                                For all monthly packages, customer will be charged a service fee of approximately $2.99 per month, which may vary from country to country.</li>
                                            <li>Scope Video reserves the right to change the Fees and any other fees/charges mentioned in the Terms & Conditions, at any time.</li>

                                        </ul>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Payment</h4>
                                        <p className="static-page-content">The User shall provide Scope Video with a valid credit/debit card for the payment of all monies due and payable for the use of the Services and by agreeing to these Terms & Conditions, the User expressly authorizes Scope Video to bill, charge and collect against the said credit/debit card for purchases and other transactions in respect of the Services, from the credit/debit card provided by the User</p>
                                        <p className="static-page-content">The User agrees to pay for all Services and subscriptions purchased through the Services as and when they become due and payable. Scope Video may bill /charge the User’s credit/debit card for any Fees or purchases or other charges and for any additional amounts (including any taxes and late Fees, as applicable) as may have accrued to or incurred by or in connection with the Account and the use of the Services.</p>
                                        <p className="static-page-content">The User’s use of the Services includes the ability to enter into agreements and/or to make purchases electronically. The User expressly acknowledges and undertakes that any and all electronic submissions by the User shall be binding on the User and shall constitute the User’s intent to be bound by the terms of this Agreement and to comply with the payment requirements including payments for the purchase of any of the Services or charges in respect thereof and be liable to pay monies which may become due and payable in accordance with the terms of this Agreement. The User shall also be bound by electronic submissions in respect of all records relating to all transactions including notices of cancellation/ termination, policies, contracts, and applications.</p>
                                        <p className="static-page-content">The User may request for information related to purchase transactions at any time by contacting info@yupptv.com. However, Scope Video shall not send physical copies of any statements to any User.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Transmission</h4>
                                        <p className="static-page-content">Scope Video will make all reasonable efforts to ensure that the the User is redirected to Partner Apps to view the content. The User acknowledges that at times this may be affected by things beyond the control of Scope Video due to force majeure including interruptions due to storm, fire, casualty, unanticipated work stoppage, power outage, satellite failure, strike, lockout, labor dispute, civil disturbance, riot, war, national emergency, act of Government, act of public enemy, or other cause of similar or dissimilar nature or due to any issues in the Partner Apps or from the Content Providers and agrees that Scope Video shall not be liable for any delay or disruption caused by such interruptions.</p>
                                        <p className="static-page-content">On occasion, technical problems may delay or prevent delivery of the product or services requisitioned by the User. The User acknowledges that while using the Services, the User may encounter content that may be deemed offensive, indecent, or objectionable. The User agrees to use the Services at the sole risk and discretion of the User and that Scope Video shall not be liable to the User for any Content that may be found to be offensive, indecent, or objectionable, including any content found on Partner Apps.</p>
                                        <p className="static-page-content">The User acknowledges that some aspects of the Services entail the ongoing involvement of Scope Video and/or Content Providers and that interruptions or discontinuation may occur when Scope Video and/or Content Providers is implementing any changes to the Services and/or Partner Apps and that the User may not be able to use the Services or content on Partner Apps to the same extent as prior to such change or discontinuation and that Scope Video shall have no liability to the User in such case. Any disputes or issues arising out of the Content Providers’ terms and conditions or the services or in relation to Partner Apps will be resolved directly with such Content Provider by the User without the involvement of Scope Video. </p>
                                        <p className="static-page-content">Scope Video reserves the right to change, suspend, remove, or disable access to any Services, Content, Partner Appsor other materials comprising any part of the Services at any time without notice. In no event will Scope Video be liable for the removal of or disabling of access to any such Services, Content, Partner Apps or materials under this Agreement. Scope Video may also impose limits on the use of or access to certain features or portions of the Services, in any case and without notice or liability.Scope Video reserves the right to modify the content and/or Content Providers from time to time, without requiring consent of the User.</p>
                                    </li>
                                    <li> <h4 className="privacy-subheadding">Restrictions</h4>
                                        <p className="static-page-content">The User acknowledges that the Services and the products purchased through the Services include a security framework which uses technology that protects digital information and limits the usage of the products and the Services to certain usage rules established by Scope Video and its licensors. The User shall be authorized to use the products and the Services only for personal, in the house, and non-commercial use. The User shall not to attempt to, or assist another person to circumvent, reverse-engineer, decompile, disassemble, or otherwise tamper with any of the security components related to such Usage Rules for any reason whatsoever. Usage Rules may be controlled and monitored by Scope Video for compliance purposes and Scope Video reserves the right to enforce the Usage Rules with or without notice to the User.</p>
                                        <p className="static-page-content">Changes in Services Offered. Scope Video may, including at Content Providers’ discretion, add, delete, rearrange, alter, interrupt, change and/or eliminate any and all programming, programming packages and/or other Services that Scope Video offers, as well as the prices and Fees, Subscription Term related to such programming, programming packages and/or other services that Scope Video offers, at any time and from time to time, including without limitation during any term commitment period to which User has agreed. In addition to the above, Scope Video may at any time, alter, rearrange or change the Subscription Term of any programming package at any time. In the event that Scope Video adds, deletes, rearranges, alters, interrupts, changes and/or eliminates any programming, programming packages and/or other Services, that Scope Video offers, Scope Video shall have no obligation to replace or supplement such programming, programming packages and/or other Services that Scope Video offers. User shall not be entitled to any refund because Scope Video adds, deletes, rearranges, alters, interrupts, changes and/or eliminates any programming, programming packages, any Subscription Term in any Territory and/or other Services that Scope Video offers.</p>
                                        <p className="static-page-content">User expressly acknowledges and agrees that additions, deletions, rearrangements, alterations, interruptions, changes and/or eliminations to any programming, programming packages and/or other Services that Scope Video offers are at the discretion of the Content Providers including those that arise in connection with the termination or suspension of Scope Video's access to all or any portion of any programming, programming packages and/or other Services that Scope Video offers is not a discretionary act by Scope Video and is dependant on Content Providers providing access to the same.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Promotions</h4>
                                        <p className="static-page-content">Scope Video may offer Users certain promotional offers from time to time. Entitlement to promotional offers shall be subject to the eligibility criteria set out in such offers. Scope Video reserves the right to change or terminate the promotional offers any time, at its own discretion. A User who takes up a promotional benefit by virtue of his/her registration for a purchase plan and who subsequently terminates such purchase plan before the expiry of the plan shall be charged for the promotional benefit received by the User.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">System Requirements</h4>
                                        <p className="static-page-content">Use of the Services to access movies, music videos/audios or television programs requires a compatible device, Internet access and certain software and may require obtaining updates or upgrades from time to time. The ability of the User to use the Services may be affected by the performance of these factors. High speed Internet access/Broadband is recommended for availing the use of the Services.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Privacy Policy</h4>
                                        <p className="static-page-content">The Privacy Policy is incorporated into and forms a part of this Agreement. Scope Video may use any personal information provided pursuant to this agreement in accordance with the provisions of the Privacy Policy.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Third Party materials and websites</h4>
                                        <p className="static-page-content">The User acknowledges that certain content, products and services available via the Services may include materials from third parties. In addition, Scope Video may provide links to certain third party websites. The User acknowledges and agrees that Scope Video shall not be responsible for examining or evaluating the content or accuracy of any such third-party material or websites. Scope Video does not warrant or endorse and does not assume and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third parties.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Enforcement</h4>
                                        <p className="static-page-content">Scope Video reserves the right to take all necessary and appropriate steps to enforce and/or verify compliance with any part of this Agreement (including but not limited to Scope Video’s right to cooperate with any legal process relating to the User’s use of the Services and/or products, and/or a third party claim that the User’s use of the Services and/or products is unlawful and/or infringes such third party's rights). The User agrees that Scope Video has the right, without liability, to disclose any registration data and/or account information to law enforcement authorities, government officials, and/or a third party, as Scope Video believes to be reasonably necessary or appropriate.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Minor</h4>
                                        <p className="static-page-content">If the User is a minor under the age of 18, the terms of this Agreement should be reviewed and accepted by his/her parent/guardian to ensure that the User and the parent/guardian understands these terms and conditions. Purchase of the Services by the User would require an authorized credit/debit card holder (parent/guardian) to take responsibility and liability for payment of the Services used.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Proprietary Rights</h4>
                                        <p className="static-page-content">The User acknowledges and agrees that the Services, including but not limited to graphics, audio and film clips and editorial content, contains proprietary information and material that is owned by Scope Video and/or its Content Providers and is protected by applicable intellectual property and other laws, including but not limited to copyright, trademark and other proprietary rights. The User shall not use such proprietary information or materials of Scope Video and/or Content Providers in any way whatsoever except for use of the Services in compliance with the terms of this Agreement. No portion of the Services may be reproduced in any form or by any means</p>
                                        <p className="static-page-content">The User agrees not to, in any manner, modify, rent, lease, loan, sell, distribute, or create derivative works based on the Services, and shall not exploit the Services in any unauthorized way whatsoever, including but not limited to, trespass or burdening the network capacity.</p>
                                        <p className="static-page-content">All copyright in and to the Services, including but not limited to Services and products (including the compilation of content, postings, links to other Internet resources, and descriptions of those resources), audio visual clips, films, graphics, images, animations, illustrations, editorial content and software are owned by Scope Video and/or its licensors. Any duplication or use of these in other electronic or printed publications is not permitted. The use of the software or any part of the Services, except for use of the Services as permitted in this Agreement is strictly prohibited and shall infringe on the intellectual property rights of Scope Video and/or its licensors. Infringement of these copyrights may subject the User to civil and criminal penalties, including monetary damages.</p>
                                        <p className="static-page-content">Scope Video, the Scope Video logo and other Scope Video trademarks, service marks, graphics, and logos used in connection with the Services are trademarks or registered trademarks of Scope Video. The User is not granted any right or license with respect to any of the aforesaid trademarks and any use of such trademarks, in any manner whatsoever</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Disclaimers</h4>
                                        <p className="static-page-content">Scope Video does not guarantee, represent, or warrant that the use of the Services will be uninterrupted or error-free and the User agrees that from time to time, Scope Video may remove or suspend the Services for indefinite periods of time, or cancel or terminate the Services at any time, with or without notice to the User.</p>
                                        <p className="static-page-content">The User expressly agrees that the use of, or inability to use, the Services is at sole risk of the User. The Services and all products and services delivered to the User through the Services are provided on an “as is” and “as available” basis for the User’s use, without warranties of any kind, either express or implied, including all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.</p>
                                        <p className="static-page-content">Due to Content Providers’ discretion, Scope Video can add / remove/ replace any existing channel/content or those that are part of the package, with some other channel/content at any point of time. Scope Video has the right to vary ongoing Term of the user. User agrees to abide by any such addition/removal/alteration made by Scope Video.</p>
                                        <p className="static-page-content">"Prices for the packages and the respective Term are subject to change"</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Liability</h4>
                                        <p className="static-page-content">You acknowledge and agree that, to the maximum extent permitted by law, the entire risk arising out of your access to and use of the Site, Services, Content, your registration and access to the Site and Services and any contact you have with other Users of Scope Video whether in person or online remains with you. Neither Scope Video nor any other party involved in creating, producing, or delivering the Site, Services, Content will be liable for any incidental, special, exemplary or consequential damages, including lost profits, loss of data or loss of good will, service interruption, computer damage or system failure, device failure or the cost of substitute products or services, or for any damages for personal or bodily injury or emotional distress arising out of or in connection with these terms, from the use of or inability to use the Site, Services, Content, from any communications, whether based on warranty, contract, tort (including negligence), product liability or any other legal theory, and </p>
                                        <p className="static-page-content">whether or not Scope Video has been informed of the possibility of such damage, even if a limited remedy set forth herein is found to have failed of its essential purpose.</p>
                                        <p className="static-page-content">In no event will Scope Video’s aggregate liability arising out of or in connection with these terms and your use of the Site, Services, Content, including, but not limited to, your subscription, access of the Site and Services, or from the use of or inability to use the Site, Services, Content, and in connection with any other users, exceed the amounts you have paid or owe for subscription hereunder as a user/subscriber in the three (3) month period prior to the event giving rise to the liability for the services. The limitations of damages set forth above are fundamental elements of the basis of the bargain between Scope Video and you.</p>
                                        <p className="static-page-content">Scope Video shall use reasonable efforts to protect information submitted by the User in connection with the Services. The User, however, acknowledges and agrees that the submission of such information is at the sole risk of the User and Scope Video hereby disclaims any and all liability to the User for any loss or liability relating to such information in any way.</p>
                                        <p className="static-page-content">Scope Video does not represent or guarantee that the Services will be free from loss, corruption, attack, viruses, interference, hacking, or other security intrusion and Scope Video disclaims any liability relating thereto and the User shall be solely responsible for backing up the User’s system.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Waiver & Indemnity</h4>
                                        <p className="static-page-content">By using the Services, the User agrees to indemnify and hold Scope Video, its directors, officers, employees, affiliates, agents, contractors and licensors, harmless with respect to any claims arising out of the User’s breach of this agreement, use of the Services,or any action taken by Scope Video as part of its investigation of a suspected violation of this agreement or as a result of its finding that a violation of this agreement has occurred. The User shall not sue or recover any damages from Scope Video, its directors, officers, employees, affiliates, agents, contractors, and licensors as a result of its decision to remove or refuse to process any information or content, to warn you, to suspend or terminate your access to the Services, or to take any other action during the investigation of a suspected violation or as a result of Scope Video’s conclusion that a violation of this agreement has occurred. This waiver and indemnity provision shall apply to all violations described in or contemplated by this agreement.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Termination</h4>
                                        <p className="static-page-content">This Agreement or the User’s subscription may be terminated by Scope Video: (i) at any time, without notifying the User, with immediate effect to the same or (ii) if the User fails or Scope Video suspects that the User has failed to comply with the any of the provisions, including but not limited to failure to make payment of Fees due, failure to provide Scope Video with a valid credit/debit card or with accurate and complete registration data, failure to safeguard Account information, or violation of the usage rules or any license to the software, of this Agreement. Alternatively, Scope Video may, at its discretion terminate the license to the software; and/or preclude access to the Services (or any part thereof). Scope Video may or may not give the User a notice of such termination. The User will remain liable for all amounts due under the Account up to and including the date of termination.</p>
                                        <p className="static-page-content">In addition to all other rights that Scope Video may have to disconnect Services at any time without notifying User if: (i) User fails to pay for any such Services in advance; (ii) Scope Video receives confirmation that User has received Services, or any part of the Services, without paying for them; (iii) User otherwise violates the terms and conditions of this Agreement; (iv) User assigns or attempts to assign any of User’s rights, duties or obligations under this Agreement or any applicable promotion Agreement; (v) User is receiving Services through a third-party billing agent and becomes ineligible to receive applicable Services provided by such third-party billing agent; (vi) User receiving Services through equipment provided by or purchased from a third-party equipment provider and becomes ineligible to receive applicable services provided by such third-party equipment provider; or (vii) User commences any act or filing of bankruptcy or bankruptcy proceedings are commenced against User.</p>
                                        <p className="static-page-content">If User Services are cancelled or disconnected for any reason, User still must pay all outstanding balances accrued, including without limitation, any applicable Fees. Charges for Services, once charged to User account, are non-refundable, and no refunds or credits will be provided in connection with the cancellation of Services. Without limitation to the foregoing, Scope Video will not issue refunds or credits for partially used services and/or periods. If User received a discounted price due to a promotion, and he/she cancels prior to any applicable expiration of that promotion, User shall not be entitled to any refund or credit for the unused portions of such discounted price.</p>
                                        <p className="static-page-content">Scope Video reserves the right to modify, suspend, or discontinue the Services (or any part or content thereof) at any time with or without notice to the User and Scope Video shall not be liable to the User or to any third party upon exercise of such right</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Modifications</h4>
                                        <p className="static-page-content">Scope Video reserves the unilateral right to, at any time, update, revise, supplement, and otherwise modify this Agreement and to impose new or additional rules, policies, terms, or conditions on the use of the Services. Such updates, revisions, supplements, modifications, and additional rules, policies, terms, and conditions will be effective immediately and shall be deemed to be incorporated into this Agreement by reference. The User is advised to periodically review this Agreement to remain updated on the terms and conditions of this Agreement. The User’s continued use of the Services following such incorporation will be deemed to constitute the User’s acceptance of any and all such modifications.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Grievance Officer</h4>
                                        <p className="static-page-content">A User from India may contact the below mentioned Grievance Officer appointed by Scope Video for any content related concerns, complaints or any grievances in the format provided below:</p>
                                        <ol className="lower-alpha" >
                                            <li>Name: Mr. Roop Kumar Reddy </li>
                                            <li>Email Address: roop@yupptv.com</li>
                                            <li>Format:</li>
                                            <li>Content Name:</li>
                                            <li>Date and time of streaming:</li>
                                            <li>Concerns:</li>
                                        </ol>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Notices</h4>
                                        <p className="static-page-content">Scope Video may notify the User with respect to the Service by sending an email message to the email address or by sending a letter via postal mail to the contact address listed in the Account contact information.</p>
                                        <p className="static-page-content">For Users located outside India, notices to Scope Video must be sent to info@yupptv.com and/or yupptv_legal@yupptv.com and all notices must clearly bear the name, account name, address, telephone number and e-mail of the User.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Governing Law</h4>
                                        <p className="static-page-content">This Agreement is governed by the laws of the United States and the federal state of Georgia and is subject to the exclusive jurisdiction of the courts at Georgia. The Users use of the Services may also be subject to other local, state, national, or international laws. The User expressly agrees that exclusive jurisdiction for any claim or dispute with Scope Video or relating in any way to the use of the Services shall be of the courts of Georgia, United States of America.</p>
                                    </li>

                                    <li>
                                        <h4 className="privacy-subheadding">Limitation of Liability<br />LIMITATION OF OUR LIABILITY</h4>
                                        <p className="static-page-content">A User from India may contact the below mentioned Grievance Officer appointed by Scope Video for any content related concerns, complaints or any grievances in the format provided below:</p>
                                        <ol className="lower-alpha" >
                                            <li>INTERRUPTIONS AND DELAYS
                                                <p className="static-page-content">NEITHER SCOPE VIDEO NOR ITS THIRD-PARTY BILLING AGENTS OR DONGLE/EQUIPMENT PROVIDERS, NOR ANY OF SCOPE VIDEO OR THEIR AFFILIATES, WILL BE LIABLE FOR ANY ADDITION, DELETION, REARRANGEMENT, ALTERATION, INTERRUPTION, CHANGE AND/OR ELIMINATION IN ANY SERVICE OR FOR ANY DELAY, FOR THE AVOIDANCE OF DOUBT INCLUDING WITHOUT LIMITATION: (i) IF SUCH ADDITION, DELETION, REARRANGEMENT, ALTERATION, INTERRUPTION, CHANGE AND/OR ELIMINATION OR DELAY ARISES IN CONNECTION WITH THE TERMINATION OR SUSPENSION OF SCOPE VIDEO'S ACCESS TO ALL OR ANY PORTION OF SERVICES FOR ANY REASON; (ii) THE RELOCATION OF ALL OR ANY PORTION OF THE SERVICES TO DIFFERENT SERVER(S); (iii) AN ADDITION, DELETION, REARRANGEMENT, ALTERATION, INTERRUPTION, CHANGE AND/OR ELIMINATION IN THE FEATURES AND/OR FUNCTIONALITY AVAILABLE WITH YOUR EQUIPMENT; (iv) ANY SOFTWARE OR OTHER DOWNLOADS INITIATED BY SCOPE VIDEO; (v) ISSUES RESULTING FROM YOUR BROADBAND CONNECTION, INCLUDING WITHOUT LIMITATION, LACK OF SUFFICIENT BANDWIDTH; OR (vi) ANY ACTS OF GOD, FIRES, EARTHQUAKES, FLOODS, POWER OR TECHNICAL FAILURE, SERVER OR INTERNET FAILURE, ACTS OF ANY GOVERNMENTAL BODY OR ANY OTHER CAUSE BEYOND SCOPE VIDEO’S REASONABLE CONTROL.</p>
                                            </li>
                                            <li>ALTERATIONS TO EQUIPMENT.
                                                <p className="static-page-content">NONE OF SCOPE VIDEO OR ANY THIRD-PARTY BILLING AGENTS OR DONGLE/EQUIPMENT PROVIDERS OR ANY OF OUR OR THEIR AFFILIATES, WILL BE LIABLE FOR ANY ADDITION, DELETION, REARRANGEMENT, ALTERATION, INTERRUPTION, CHANGE AND/OR ELIMINATION OF OR TO ANY EQUIPMENT, FOR THE AVOIDANCE OF DOUBT INCLUDING WITHOUT LIMITATION, REMOVING OR DISABLING SOFTWARE, FEATURES AND/OR FUNCTIONALITY.</p>
                                            </li>
                                            <li>NO WARRANTIES.
                                                <p className="static-page-content">EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY BY APPLICABLE STATE LAW, NEITHER SCOPE VIDEO NOR ANY THIRD-PARTY BILLING AGENTS, APPOINTED BY SCOPE VIDEO, OR DONGLE/EQUIPMENT PROVIDERS, NOR ANY OF OUR OR THEIR AFFILIATES, MAKE ANY WARRANTY, EITHER EXPRESSED OR IMPLIED, REGARDING YOUR SCOPE VIDEO DONGLE OR ANY OTHER EQUIPMENT OR ANY SERVICES FURNISHED TO USER. ALL SUCH WARRANTIES, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT ARE HEREBY EXPRESSLY DISCLAIMED AND EXCLUDED.</p>
                                            </li>
                                            <li>CONTENT RESTRICTIONS
                                                <p className="static-page-content">IT IS USER'S RESPONSIBILITY TO IMPOSE ANY PROGRAMMING, INTERNET OR OTHER CONTENT RESTRICTIONS ON HIMSELF/HERSELF, MEMBERS OF USER'S FAMILY AND HOUSEHOLD, AND GUESTS, AS USER DEEM APPROPRIATE. NONE OF SCOPE VIDEO OR ITS THIRD-PARTY BILLING AGENTS OR /DONGLE/EQUIPMENT PROVIDERS OR ITS AND THEIR AFFILIATES WILL BE LIABLE TO ANYONE DUE TO, OR BASED UPON, ANY CONTENT (INCLUDING WITHOUT LIMITATION, ANY INACCURACIES, ERRORS IN, OR OMISSIONS FROM SUCH CONTENT): (i) CONTAINED IN ANY OF THE SERVICES FURNISHED TO USER; OR (ii) ACCESSED USING THE SERVICES OR EQUIPMENT FURNISHED TO USER.</p>
                                            </li>
                                            <li>DAMAGES LIMITATION
                                                <p className="static-page-content">NEITHER SCOPE VIDEO NOR ITS THIRD-PARTY BILLING AGENTS OR DONGLE PROVIDERS, NOR ANY OF ITS OR THEIR AFFILIATES, SHALL HAVE ANY LIABILITY WHATSOEVER FOR ANY SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR RELATING TO: (i) SCOPE VIDEO DONGLE OR ANY OTHER EQUIPMENT; (ii) SCOPE VIDEO FURNISHING OR FAILURE TO FURNISH ANY SERVICES OR EQUIPMENT TO USER; OR (iii) ANY FAULT, FAILURE, DEFICIENCY OR DEFECT IN SERVICES OR EQUIPMENT FURNISHED TO USER.</p>
                                            </li>
                                        </ol>

                                    </li>

                                    <li>
                                        <h4 className="privacy-subheadding">General</h4>
                                        <ol className="lower-alpha" >
                                            <li><strong>Age </strong>
                                                <p className="static-page-content"> Physical Address/Change of Address. User must be at least 18 years of age to enter into this Agreement. When setting up your Scope Video account, User must provide us with complete and correct personal information related to his/her account, including User email address, the physical address where User Dongle will be located and your Services will be provided. A post office box does not meet this requirement. User must give us immediate notice of any change of name, your email address,mailing address, telephone number, or physical address where your Scope Video Dongle/Equipment is located. </p>
                                                <p className="static-page-content">User may do this by notifying Scope Video customer service center by telephone or in writing at the phone number, mailing address, or e-mail address set forth at the top of this Agreement.</p>
                                            </li>
                                            <li>
                                                <strong>Online Account Information. </strong>
                                                <p className="static-page-content"> User shall be responsible for maintaining the confidentiality of his/her account username and password of his/her online account and for all activities that occur under his/her account username and/or password. User must: (i) keep his/her account username and password confidential and, except as explicitly provided in this Agreement, not share them with anyone else; and (ii) immediately notify Scope Video of any unauthorized use of your password and/or account username or other breach of security. </p>
                                            </li>
                                            <li>
                                                <strong>Third-Party Billing Agents </strong>
                                                <p className="static-page-content"> Scope Video may enter into relationships with third parties to provide billing and other services on its behalf, in which case the terms and conditions of this Agreement shall apply to such third parties as applicable under the circumstances. Additional terms and conditions imposed by such third-party billing agents may apply. For example and without limitation: (i) late fees imposed by such third-party billing agents may be administered according to the third-party billing agent's billing procedures and applicable state tariffs and regulations; (ii) Scope Video third-party billing agents may require that User shall pay all past due charges for Services, a restart fee, and/or a prepayment before Scope Video reconnects his/her Services; and (iii) other services provided by such third-party billing agents, including without limitation, local telephone service, may need to be restored before Scope Video Services can be restored, and a restoral fee and/or deposit may be required to restore third-party billing agent services. Partial payments on third party billing agent bills may be applied first to the balance due for other services billed on User third party billing agent bill, including without limitation, local telephone service, according to the third-party billing agent's billing procedures and applicable state statutes and regulations. Please contact your third party billing agents for details. Failure to pay all or any part of your third-party billing agent bill may result in disconnection of Services. </p>
                                            </li>
                                            <li>
                                                <strong>Credit Checks </strong>
                                                <p className="static-page-content">You authorize Scope Video to investigate your financial responsibility and creditworthiness, including without limitation, acquiring credit reports and histories, and to report any payment defaults to credit reporting agencies.</p>
                                            </li>
                                            <li>
                                                <strong>Remedies Cumulative. </strong>
                                                <p className="static-page-content">The rights and remedies provided under this Agreement to Scope Video in case of User's default or breach of this Agreement are cumulative and without prejudice to any other rights and remedies that Scope Video may have by reason of such default or breach at law, in equity, under contract or otherwise (all of which are expressly reserved).
                                                </p>
                                            </li>
                                            <li>
                                                <strong>Other </strong>
                                                <p className="static-page-content">No salesperson, installer, customer service representative, authorized retailer, or other similarly situated individual is authorized to change or override this Agreement. Scope Video may, however, change this Agreement at any time and from time to time. The terms and conditions of this Agreement that either are expressly stated to survive or by their nature would logically be expected to survive its expiration or termination will continue thereafter. This Agreement is in addition to any other written agreement(s), if any, between you and Scope Video, and except as provided to the contrary herein, all such written agreements shall remain in full force and effect. Except as expressly set forth in this Agreement to the contrary, this Agreement replaces and supersedes any and all prior Agreements in their entirety, and such prior Agreements shall be of no further force or effect whatsoever.
                                                </p>
                                            </li>
                                        </ol>

                                    </li>

                                </ul>

                                <h4 className="privacy-subheadding annexure-txt-head1">ANNEXURE-A</h4>
                                <h4 className="privacy-subheadding annexure-txt-head">PARTNER APPS’ TERMS AND CONDITIONS AND PRIVACY POLICIES</h4>
                                <p className="static-page-content">As stated in paragraph 2 above, by accessing content provided by Partner Apps, you hereby accept the terms and conditions and privacy policies of those Partner Apps as listed below.</p>
                                <ul className="decimal">
                                    <li>
                                        <p className="privacy-subheadding">YuppTV </p>
                                        <p className="static-page-content">
                                            •	Terms Of Use may be accessed at: <a href="https://www.yupptv.com/help/terms-and-conditions" target="_blank"> https://www.yupptv.com/help/terms-and-conditions</a><br />
                                            •	Privacy Policy may be accessed at: <a href="https://www.sonyliv.com/privacy-policy" target="_blank"> https://www.yupptv.com/help/privacy-terms</a></p>
                                    </li>

                                    <li>
                                        <p className="privacy-subheadding">SunNXT</p>
                                        <p className="static-page-content">
                                            •	Terms Of Use may be accessed at:  <a href="https://www.sunnxt.com/termsOfuse" target="_blank">https://www.sunnxt.com/termsOfuse</a><br />
                                            •	Privacy Policy may be accessed at: <a href="https://www.sunnxt.com/privacypolicy" target="_blank">https://www.sunnxt.com/privacypolicy</a></p>
                                    </li>
                                    <li>
                                        <p className="privacy-subheadding">AHA</p>
                                        <p className="static-page-content">
                                            •	Terms Of Use may be accessed at:  <a href="https://www.aha.video/tc" target="_blank">https://www.aha.video/tc</a><br />
                                            •	Privacy Policy may be accessed at:  <a href="https://www.aha.video/privacy-policy" target="_blank">https://www.aha.video/privacy-policy</a></p>
                                        <p className="privacy-subheadding">Last Update On: 1<sup>st</sup> April, 2023</p>
                                    </li>
                                </ul>
                            </>
                        }
                    </div>
                </div>
                <div className="terms_check">
                    <input type="checkbox" className="form-check-input customcheckbox" name="check" id="chkAgree" onChange={handleChange.bind()} />
                    <label className="form-check-label" htmlFor="chkAgree">I accept all the Terms and Conditions.</label>
                </div>
                <div className="next_btn_block ">
                    <button className="next_btn" onClick={acceptterms}>NEXT</button>
                </div>
            </div>
            <div className="steps_content mobile_step">
                <div className="register_step">
                    <div className="register_num">2</div> <span>Enter Details </span>
                </div>
                <div className="register_step">
                    <div className="register_num">3</div> <span>Preview</span>
                </div>
                <div className="register_step">
                    <div className="register_num">4</div> <span>Confirmation</span>
                </div>

            </div>
        </div>
    )
}