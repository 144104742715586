import React from 'react';
import './package.css';
import 'react-multi-carousel/lib/styles.css';
import {style} from 'typestyle';
import { Link } from "react-router-dom";
import DataService from '../shared/service';

class PackDetails extends React.Component{



    constructor(props)
    {
        super(props); 
        this.state = {   
            ott:null,
            partners:[],
            Packages:[]
        };
        this.dataService = new DataService();
    } 
    componentDidMount()  
    {
    }
    //package features 
    getFeatures(packageFeatures) 
    {
        if (packageFeatures!==null)
        {
            return(  
                <div> 
                      {packageFeatures.map(item=> (
                        <div className="description-pack" key={item.name}>
                            <span className="description-total"><span className="description-headding">{item.name}</span>
                            <span className="description-sub-headding">{item.description}</span>
                            </span>
                        </div>
                        )
                    )}  
                </div> 
            )
        }
    }

    setIsShown(set, id)
    {
        if (set === "true" )
        {
        document.getElementById(id).style.display="block"; }
        else
        {document.getElementById(id).style.display="none"; }
    }
    getButtons(ottpackage)
    {

        if(ottpackage !== null)
        {
             if((ottpackage.packageInfo.master.attributes.helpText!==undefined) && (ottpackage.packageInfo.master.attributes.helpText!==null))
             {
                return (
                    <span> 
                    <span id={"packtxt" + ottpackage.packageInfo.packages[0].id} className="pack-dirct-info" style={{display:"none"}}> {ottpackage.packageInfo.master.attributes.helpText}</span>
                    <a onMouseOver={this.setIsShown.bind(this,"true", "packtxt" + ottpackage.packageInfo.packages[0].id)} 
                        onMouseLeave={this.setIsShown.bind(this,"false", "packtxt" + ottpackage.packageInfo.packages[0].id)} href={ottpackage.packageInfo.master.attributes.url}><button className="buy-now-btn" >Buy Now </button> </a>
                    </span>
                )
             }
             else
             {
                return (
                    <Link to={{ pathname:'/AccountSummary',  state:{OTTPackage:ottpackage}}}><button className="buy-now-btn" >Buy Now </button> </Link>
                )
             }
        }
    } 

  
    //binding package
    getPackageData(ottpackage)
    {
        const pkghovr = style({
            $nest: {
                '&:hover': {
                    '&::before' :{ 
                        content: `""`, 
                        background: ottpackage.packageInfo.master.attributes.bgcolor, 
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius:"6px",
                        width: "100%",
                        display:"block",
                        height: "4px",
                        left: "0",
                        top: "0",
                        position:"absolute",
                    },
                  '&::after' :{ 
                    content: `""`, 
                    background: ottpackage.packageInfo.master.attributes.bgcolor,
                    borderBottomLeftRadius: "6px",
                    borderBottomRightRadius:"6px",
                    width: "100%",
                    display:"block", 
                    height: "4px",
                    left: "0",
                    bottom: "0",
                    position:"absolute",                       
                    },
                    '.buy-now-btn':{
                        background: ottpackage.packageInfo.master.attributes.bgcolor,      
                    }
                  }
        }});

        const pkgcls = style({
            $nest: {
                    '&::before' :{ 
                        content: `""`, 
                        background: ottpackage.packageInfo.master.attributes.bgcolor, 
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius:"6px",
                        width: "100%",
                        display:"block",
                        height: "4px",
                        left: "0",
                        top: "0",
                        position:"absolute",
                    },
                  '&::after' :{ 
                    content: `""`, 
                    background: ottpackage.packageInfo.master.attributes.bgcolor,
                    borderBottomLeftRadius: "6px",
                    borderBottomRightRadius:"6px",
                    width: "100%",
                    display:"block", 
                    height: "4px",
                    left: "0",
                    bottom: "0",
                    position:"absolute",                       
                    },
                    '.buy-now-btn':{
                        background: ottpackage.packageInfo.master.attributes.bgcolor,      
                    }
                  }
        });



    return(
        <div className={ "cards-item " + ( window.innerWidth >= 990 ? pkghovr: pkgcls) } key={ottpackage.packageInfo.master.id} > 
        <div className="most-popular" style={{display:ottpackage.packageInfo.master.attributes.isMostPopular==true?"blocK":"none"}}>Most Popular</div>
        <div className="cards-item-inner">
            <div><img src={ ottpackage.packageInfo.master.attributes.iconPath } alt="packageIcon"/></div>
            <div className="livetv-cards-content-wrap" >
                <span className="pack-title">{ottpackage.packageInfo.master.name}</span>
                <div> 
                <div className="pack-price"> 
                {ottpackage.packageInfo.packages[0].attributes.CurrencySymbol.value} {ottpackage.packageInfo.packages[0].listPrice } <span className="month-txt">/Month</span>
                </div> 
                <div className="pack-header-parent">
                <div className={(ottpackage.packageInfo.master.attributes.headerText=== undefined || ottpackage.packageInfo.master.attributes.headerText.trim()=== "") ? " " : "pack-header-text"}>{ottpackage.packageInfo.master.attributes.headerText == null ? " " : ottpackage.packageInfo.master.attributes.headerText }</div>
                </div>
                <div> 
                {this.getFeatures(ottpackage.packageInfo.packages[0].features)} 
                {/* {ottpackage.packageInfo.master.description}  */}
                {/* <span><a href={ottpackage.packageInfo.master.attributes.url == null ? "#" : ottpackage.packageInfo.master.attributes.url } className="buy-now-btn">Buy Now</a></span> */}
                {/* <span><a href="click" className="buy-now-btn" onClick={openCheckout} > Buy Now </a></span> */}
                {/* <button onClick={openCheckout}>Pay Rs. 100 </button>  */}
                {/* <button onClick={openHostCheckout}>Pay Rs. 100 </button>  */}
                <div> 
                {this.getButtons(ottpackage)}
                
        </div> 

                </div> 
                </div>
            </div>
        </div>
        </div> 
          )
    } 


render(){
          return(
                    <div className="package-container-block">
                        <h2 className="package-headding">Choose a plan that’s right for you!</h2>
                        <div className="package-item">
                        {this.props.packagesList.map(ottpackage=>( 
                                this.getPackageData(ottpackage)
                            ))
                        }
                        </div>
                    </div>
            )}
        }


 export default PackDetails;