import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { config } from './app.config';
import StorageService from '../utils/storage.service'

class AuthenticationService {

    async getSessionID() {
        let storageService = new StorageService();
        let sessionData = storageService.getData("session-data", {});
        if (!sessionData) {
            storageService.clearSession({});
            let boxid = uuidv4();
            var data = {
                boxId: boxid
            }

            var getSessionData = await axios({
                method: "GET",
                url: config.BaseRootURL + "get/token?box_id=" + data.boxId + "&device_id=" + config.deviceid + "&tenant_code=" + config.TenentCode + "&device_sub_type=&product=" + config.TenentCode + "&display_lang_code=eng&timezone=Asia%2FKolkata",
                headers: { "Content-Type": "application/json" }
            });

            let sessionresData = getSessionData.data.response;

            let sessionData = {
                "Content-Type": "application/json",
                "session-id": sessionresData.sessionId,
                "box-id": data.boxId
            }
            storageService.setData("session-data", sessionData, {}, 30 * 24);

            return sessionData;
        }
        else {
            return sessionData;
        }
    }

    async postData(url, data) {
        let headers = {};
        let response;
        try {
            let sessiondata = await this.getSessionID();
            if (!!sessiondata) {
                headers = {
                    "Box-Id": sessiondata["box-id"],
                    "Session-Id": sessiondata["session-id"],
                    "Content-Type": "application/json"
                }
                response = await axios({
                    method: "POST",
                    url: url,
                    headers: headers,
                    data: data
                });
                return response;
            }
        } catch (err) { }
    }

    async getData(url) {
        let headers = {};
        let response;
        try {
            let sessiondata = await this.getSessionID();
            if (!!sessiondata) {
                headers = {
                    "Box-Id": sessiondata["box-id"],
                    "Session-Id": sessiondata["session-id"],
                    "tenant-code": "bott"
                }
                response = await axios({
                    method: "GET",
                    url: url,
                    headers: headers
                });
                if (!!response.data && !response.data.status && response.data.error.code === 401) {
                    let storageService = new StorageService();
                    storageService.clearSession({});
                    sessiondata = await this.getSessionID({});
                    headers = {
                        "box-id": sessiondata["box-id"],
                        "session-id": sessiondata["session-id"],
                        "tenant-code": sessiondata["tenant-code"]
                    }

                    response = await axios({
                        method: "GET",
                        url: url,
                        headers: headers
                    });
                }
                return response;
            }
        } catch (err) { }
    }
}

export default AuthenticationService;
