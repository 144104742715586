import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "./document";

export default function Confirmation(props) {
    let date = new Date();
    const regDate = date.getDate().toString().padStart(2, '0') + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getFullYear();
    return (
        <div className="register_container mb-4">
            <div className="steper_block  mobile_help_class">
                <h4>Partner with us</h4>
                <p>Please follow the steps for registration</p>
                <div className="steps_content">
                    <div className="register_step step_complete ">
                        <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Terms & Conditions ----------</span>
                    </div>
                    <div className="register_step step_complete">
                        <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Enter Details ----------</span>
                    </div>
                    <div className="register_step step_complete">
                        <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Preview ----------</span>
                    </div>
                    <div className="register_step step_complete">
                        <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Confirmation</span>
                    </div>

                </div>
            </div>
            <div className="steps_content mobile_step">
                <div className="register_step step_complete ">
                    <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Terms & Conditions </span>
                </div>
                <div className="register_step step_complete">
                    <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Enter Details</span>
                </div>
                <div className="register_step step_complete">
                    <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Preview</span>
                </div>
                <div className="register_step step_complete">
                    <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Confirmation</span>
                </div>
            </div>
            <div className="register_card_sucesss">
                <div className="sucess_header">
                    <div className="text-center">
                        <h4>Your Registration is Completed Successfully.</h4>
                        <p>our supporting team will get back to you shortly.</p>
                    </div>
                </div>
                <div className="sucess_content">
                    <h4>Registration Details</h4>
                    <table className="table table-bordered   ">
                        <tr>
                            <th>Registration Number</th>
                            <th>{props.registrationnum}</th>
                        </tr>
                        <tr>
                            <th>Company Name</th>
                            <th>{props.details["Company Name"]}</th>
                        </tr>
                        <tr>
                            <th>Authority Name</th>
                            <th>{props.details["Authority"]}</th>
                        </tr>
                        <tr>
                            <th>Email ID</th>
                            <th>{props.details["Email ID"]}</th>
                        </tr>
                        <tr>
                            <th>Phone Number</th>
                            <th>{props.details["Phone Number"]}</th>
                        </tr>

                        <tr>
                            <th>CIN Number</th>
                            <th>{props.details["CIN Number"]}</th>
                        </tr>
                        <tr>
                            <th>ISP License</th>
                            <th>{props.details["ISP License"]}</th>
                        </tr>
                        <tr>
                            <th>PAN Card Number</th>
                            <th>{props.details["PAN Number"]}</th>
                        </tr>
                        <tr>
                            <th>GST Number</th>
                            <th>{props.details["GST Number"]}</th>
                        </tr>
                        <tr>
                            <th>State</th>
                            <th>{props.details["State"]}</th>
                        </tr>
                        <tr>
                            <th>Company Address</th>
                            <th>{props.details["Company Address"]}</th>
                        </tr>

                    </table>
                </div>
                <div className="ispregisterbtns">
                    <a href="/"><button className="next_btn">Done</button></a>
                    <div className="download_pdf">
                        {Object.keys(props.details).length > 0 &&
                            <PDFDownloadLink
                                document={<PdfDocument data={props.details} regifo={{ id: props.registrationnum, date: regDate }} />}
                                fileName="Registration.pdf"

                            >Download PDF <img src="https://yuppstatic.akamaized.net/yupptv/bsnl/4aug2023/download_pdf_icon.svg" /></PDFDownloadLink>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}