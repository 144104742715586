import React from "react";
import { useState, useEffect } from "react";
import AuthenticationService from "../shared/utils/auth.service";
import { config } from "../shared/utils/app.config";

export default function ISPDetails(props) {
    const [states, setStates] = useState('')
    const [selectedState, setselectedState] = useState('0')

    useEffect(() => {
        if (Object.keys(props.details).length > 0) {
            document.getElementById("txtCompany").value = props.details["Company Name"];
            document.getElementById("txtAuthority").value = props.details["Authority"];
            document.getElementById("txtEmail").value = props.details["Email ID"];
            document.getElementById("txtPhone").value = props.details["Phone Number"];
            document.getElementById("txtCIN").value = props.details["CIN Number"]
            document.getElementById("txtISPLicense").value = props.details["ISP License"];
            document.getElementById("txtPancard").value = props.details["PAN Number"];
            document.getElementById("txtGST").value = props.details["GST Number"];
            document.getElementById("txtAddress").value = props.details["Company Address"];
            setselectedState(props.details["State"]);
        }
        (async () => {
            let service = new AuthenticationService();
            let response = await service.getData(config.BaseRootURL + config.statesapi)
            if (!!response && response?.data?.status) {
                setStates(response?.data?.response)
            }
        })()
    }, [])

    function Register(type, obj) {
        if (type == "previous") {
            return props.submitisp(type, obj);
        } else {
            let obj = {
                "Company Name": "",
                "Authority": "",
                "Email ID": "",
                "Phone Number": "",
                "CIN Number": "",
                "ISP License": "",
                "PAN Number": "",
                "GST Number": "",
                "State": "",
                "Company Address": ""
            };

            let companyname = document.getElementById("txtCompany").value;
            let companyaddress = document.getElementById("txtAddress").value;
            let cin = document.getElementById("txtCIN").value;
            let license = document.getElementById("txtISPLicense").value;
            let gst = document.getElementById("txtGST").value.toUpperCase();
            let pan = document.getElementById("txtPancard").value.toUpperCase();
            let authority = document.getElementById("txtAuthority").value;
            let email = document.getElementById("txtEmail").value;
            let mobile = document.getElementById("txtPhone").value;

            let emailreg = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
            let panregEx = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$/;
            let gstregex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
            // obj["Company Name"] = "BSNL";
            // obj["Company Address"] = "No 2-41/2/A, Pavan Priyanka Plaza,Prashanth Nagar Colony, Kondapur";
            // obj["CIN Number"] = "U74899DL2000GOI107739";
            // obj["ISP License"] = "107739";
            // obj["GST Number"] = "32AABCB5576G5ZQ";
            // obj["PAN Number"] = "AABCB5576G";
            // obj["Authority"] = "Pravin Kumar Purwar";
            // obj["Email ID"] = "abcd@bsnl.co.in";
            // obj["Phone Number"] = "9876543210";

            if (companyname == "") {
                document.getElementById("txtCompany").classList.add("login-error-msg");
                document.getElementById("lblCNameerror").innerHTML = "Enter Company Name";
                return false;
            } else {
                document.getElementById("txtCompany").classList.remove("login-error-msg");
                document.getElementById("lblCNameerror").innerHTML = "";
                obj["Company Name"] = companyname;//"BSNL";
            }
            if (authority == "") {
                document.getElementById("txtAuthority").classList.add("login-error-msg");
                document.getElementById("lblANameerror").innerHTML = "Enter Authority Name";
                return false;
            } else {
                document.getElementById("txtAuthority").classList.remove("login-error-msg");
                document.getElementById("lblANameerror").innerHTML = "";
                obj["Authority"] = authority;//"Pravin Kumar Purwar";
            }
            if (email == "" || !emailreg.test(email)) {
                document.getElementById("txtEmail").classList.add("login-error-msg");
                document.getElementById("lblEmaileerror").innerHTML = "Enter a valid Email Id";
                return false;
            } else {
                document.getElementById("txtEmail").classList.remove("login-error-msg");
                document.getElementById("lblEmaileerror").innerHTML = "";
                obj["Email ID"] = email;//"abcd@bsnl.co.in";
            }
            if (mobile == "") {
                document.getElementById("txtPhone").classList.add("login-error-msg");
                document.getElementById("lblMobileerror").innerHTML = "Enter a valid mobile number";
                return false;
            } else {
                if (mobile.length < 6) {
                    document.getElementById("txtPhone").classList.add("login-error-msg");
                    document.getElementById("lblMobileerror").innerHTML = "Enter a valid mobile number";
                    return false;
                } else {
                    document.getElementById("txtPhone").classList.remove("login-error-msg");
                    document.getElementById("lblMobileerror").innerHTML = "";
                    obj["Phone Number"] = mobile;//"9876543210";
                }
            }
            if (cin == "") {
                document.getElementById("txtCIN").classList.remove("login-error-msg");
                obj["CIN Number"] = "";
            } else {
                if (isAlphanumeric(cin) && cin.length == 21) {
                    document.getElementById("txtCIN").classList.remove("login-error-msg");
                    document.getElementById("lblCINerror").innerHTML = "";
                    obj["CIN Number"] = cin;//"U74899DL2000GOI107739";
                } else {
                    document.getElementById("txtCIN").classList.add("login-error-msg");
                    document.getElementById("lblCINerror").innerHTML = "Enter a valid CIN Number Ex:U74899DL2000GOI107739";
                    return false;
                }
            }
            if (license == "") {
                document.getElementById("txtISPLicense").classList.add("login-error-msg");
                document.getElementById("lblLicenseerror").innerHTML = "Enter License Number";
                return false;
            } else {
                document.getElementById("txtISPLicense").classList.remove("login-error-msg");
                document.getElementById("lblLicenseerror").innerHTML = "";
                obj["ISP License"] = license;//"107739";
            }

            if (pan == "" || !panregEx.test(pan)) {
                document.getElementById("txtPancard").classList.add("login-error-msg");
                document.getElementById("lblPanerror").innerHTML = "Enter a valid PAN Number Ex:AABCB5576G";
                return false;
            } else {
                document.getElementById("txtPancard").classList.remove("login-error-msg");
                document.getElementById("lblPanerror").innerHTML = "";
                obj["PAN Number"] = pan;//"AABCB5576G";
            }
            if (gst == "" || !gstregex.test(gst) || gst.length < 15) {
                document.getElementById("txtGST").classList.add("login-error-msg");
                document.getElementById("lblGsterror").innerHTML = "Enter a valid GST Number";
                return false;
            } else {
                document.getElementById("txtGST").classList.remove("login-error-msg");
                document.getElementById("lblGsterror").innerHTML = "";
                obj["GST Number"] = gst;//"32AABCB5576G5ZQ";
            }
            if (selectedState == "0") {
                document.getElementById("ddlStates").classList.add("login-error-msg");
                document.getElementById("lblStateserror").innerHTML = "Select state";
                return false;
            } else {
                document.getElementById("ddlStates").classList.remove("login-error-msg");
                document.getElementById("lblStateserror").innerHTML = "";
                obj["State"] = selectedState;
            }
            if (companyaddress == "") {
                document.getElementById("txtAddress").classList.add("login-error-msg");
                document.getElementById("lblCAddresserror").innerHTML = "Enter Company Address";
                return false;
            } else {
                document.getElementById("txtAddress").classList.remove("login-error-msg");
                document.getElementById("lblCAddresserror").innerHTML = "";
                obj["Company Address"] = companyaddress;//"No 2-41/2/A, Pavan Priyanka Plaza,Prashanth Nagar Colony, Kondapur";
            }
            return props.submitisp(type, obj);
        }
    }

    function isAlphanumeric(str) {
        return /^[a-zA-Z0-9]+$/.test(str);
    }

    function handleKeyPressMobile(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        document.getElementById("txtPhone").classList.remove("login-error-msg");
        document.getElementById("lblMobileerror").innerHTML = "";
        if (charCode = 47 && charCode > 31 && (charCode < 48 || charCode > 57))
            event.preventDefault();

    }

    function handleKeyupMobile(event) {
        event.target.value = event.target.value.replace(/[^\d]/g, '');
        return false;
    }

    function handleChange(e) {
        setselectedState(e.target.value)
        if (e.target.value == "0") {
            document.getElementById("ddlStates").classList.add("login-error-msg");
            document.getElementById("lblStateserror").innerHTML = "Select state";
            return false;
        } else {
            document.getElementById("ddlStates").classList.remove("login-error-msg");
            document.getElementById("lblStateserror").innerHTML = "";
        }
    }

    function handleKeyup(id) {
        let companyname = document.getElementById("txtCompany").value;
        let companyaddress = document.getElementById("txtAddress").value;
        let license = document.getElementById("txtISPLicense").value;
        let gst = document.getElementById("txtGST").value.toUpperCase();
        let pan = document.getElementById("txtPancard").value.toUpperCase();
        let authority = document.getElementById("txtAuthority").value;
        let email = document.getElementById("txtEmail").value;
        let mobile = document.getElementById("txtPhone").value;

        let emailreg = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
        let panregEx = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$/;
        let gstregex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
        if (id == "txtCompany") {
            if (companyname == "") {
                document.getElementById("txtCompany").classList.add("login-error-msg");
                document.getElementById("lblCNameerror").innerHTML = "Enter Company Name";
                return false;
            } else {
                document.getElementById("txtCompany").classList.remove("login-error-msg");
                document.getElementById("lblCNameerror").innerHTML = "";
            }
        }
        else if (id == "txtAuthority") {
            if (authority == "") {
                document.getElementById("txtAuthority").classList.add("login-error-msg");
                document.getElementById("lblANameerror").innerHTML = "Enter Authority Name";
                return false;
            } else {
                document.getElementById("txtAuthority").classList.remove("login-error-msg");
                document.getElementById("lblANameerror").innerHTML = "";
            }
        }
        else if (id == "txtEmail") {
            if (email == "" || !emailreg.test(email)) {
                document.getElementById("txtEmail").classList.add("login-error-msg");
                document.getElementById("lblEmaileerror").innerHTML = "Enter a valid Email Id";
                return false;
            } else {
                document.getElementById("txtEmail").classList.remove("login-error-msg");
                document.getElementById("lblEmaileerror").innerHTML = "";
            }
        }
        else if (id == "txtPhone") {
            if (mobile == "") {
                document.getElementById("txtPhone").classList.add("login-error-msg");
                document.getElementById("lblMobileerror").innerHTML = "Enter a valid mobile number";
                return false;
            } else {
                if (mobile.length < 6) {
                    document.getElementById("txtPhone").classList.add("login-error-msg");
                    document.getElementById("lblMobileerror").innerHTML = "Enter a valid mobile number";
                    return false;
                } else {
                    document.getElementById("txtPhone").classList.remove("login-error-msg");
                    document.getElementById("lblMobileerror").innerHTML = "";
                }
            }
        }
        else if (id == "txtISPLicense") {
            if (license == "") {
                document.getElementById("txtISPLicense").classList.add("login-error-msg");
                document.getElementById("lblLicenseerror").innerHTML = "Enter License Number";
                return false;
            } else {
                document.getElementById("txtISPLicense").classList.remove("login-error-msg");
                document.getElementById("lblLicenseerror").innerHTML = "";
            }
        }
        else if (id == "txtPancard") {
            if (pan == "" || !panregEx.test(pan)) {
                document.getElementById("txtPancard").classList.add("login-error-msg");
                document.getElementById("lblPanerror").innerHTML = "Enter a valid PAN Number Ex:AABCB5576G";
                return false;
            } else {
                document.getElementById("txtPancard").classList.remove("login-error-msg");
                document.getElementById("lblPanerror").innerHTML = "";
            }
        }
        else if (id == "txtGST") {
            if (gst == "" || !gstregex.test(gst) || gst.length < 15) {
                document.getElementById("txtGST").classList.add("login-error-msg");
                document.getElementById("lblGsterror").innerHTML = "Enter a valid GST Number Ex:22AAAAA0000A1Z5";
                return false;
            } else {
                document.getElementById("txtGST").classList.remove("login-error-msg");
                document.getElementById("lblGsterror").innerHTML = "";
            }
        }
        else if (id == "txtAddress") {
            if (companyaddress == "") {
                document.getElementById("txtAddress").classList.add("login-error-msg");
                document.getElementById("lblCAddresserror").innerHTML = "Enter Company Address";
                return false;
            } else {
                document.getElementById("txtAddress").classList.remove("login-error-msg");
                document.getElementById("lblCAddresserror").innerHTML = "";
            }
        }
    }

    return (
        <div className="register_container mb-4">
            <div className="steper_block mobile_help_class">
                <h4>Partner with us</h4>
                <p>Please follow the steps for registration</p>
                <div className="steps_content ">
                    <div className="register_step step_complete ">
                        <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Terms & Conditions ----------</span>
                    </div>
                    <div className="register_step step_progress">
                        <div className="register_num">2</div> <span>Enter Details ----------</span>
                    </div>
                    <div className="register_step">
                        <div className="register_num">3</div> <span>Preview ----------</span>
                    </div>
                    <div className="register_step">
                        <div className="register_num">4</div> <span>Confirmation</span>
                    </div>
                </div>
            </div>
            <div className="steps_content mobile_step">
                <div className="register_step step_complete ">
                    <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Terms & Conditions</span>
                </div>
                <div className="register_step step_progress">
                    <div className="register_num">2</div> <span>Enter Details </span>
                </div>
            </div>
            <div className="register_card">
                <div className="row">
                    <div className="col-md-12">
                        <h3>Enter Organization Details</h3>
                    </div>
                </div>
                <div className="row ispinputs">
                    <div className="col-md-6">
                        <label>Company Name*</label>
                        <input type="text" id='txtCompany' className="form-control" maxLength={250} onKeyUp={handleKeyup.bind(this, 'txtCompany')} />
                        <p id="lblCNameerror" className="errorMsg" />
                    </div>
                    <div className="col-md-6">
                        <label>Authority Name*</label>
                        <input type="text" className="form-control" id='txtAuthority' maxLength={250} onKeyUp={handleKeyup.bind(this, 'txtAuthority')} />
                        <p id="lblANameerror" className="errorMsg" />
                    </div>
                    <div className="col-md-6">
                        <label>Email ID*</label>
                        <input type="text" className="form-control" id='txtEmail' onKeyUp={handleKeyup.bind(this, 'txtEmail')} />
                        <p id="lblEmaileerror" className="errorMsg" />
                    </div>
                    <div className="col-md-6">
                        <label>Phone Number*</label>
                        <input type="text" pattern="[0-9]+" inputMode="numeric" className="form-control" id='txtPhone'
                            onKeyPress={handleKeyPressMobile.bind(this)} onKeyUp={handleKeyupMobile.bind(this)} autoComplete="off" minLength="6" maxLength="18" />
                        <p id="lblMobileerror" className="errorMsg" />
                    </div>
                    <div className="col-md-6">
                        <label>CIN Number</label>
                        <input type="text" className="form-control" id='txtCIN' style={{ textTransform: 'uppercase' }} maxLength={21} />
                        <p id="lblCINerror" className="errorMsg" />
                    </div>
                    <div className="col-md-6">
                        <label>ISP License*</label>
                        <input type="text" className="form-control" id='txtISPLicense' style={{ textTransform: 'uppercase' }} maxLength={30} onKeyUp={handleKeyup.bind(this, 'txtISPLicense')} />
                        <p id="lblLicenseerror" className="errorMsg" />
                    </div>
                    <div className="col-md-6">
                        <label>PAN Card Number*</label>
                        <input type="text" className="form-control" id='txtPancard' style={{ textTransform: 'uppercase' }} onKeyUp={handleKeyup.bind(this, 'txtPancard')} />
                        <p id="lblPanerror" className="errorMsg" />
                    </div>

                    <div className="col-md-6">
                        <label>GST Number*</label>
                        <input type="text" className="form-control" id='txtGST' style={{ textTransform: 'uppercase' }} maxLength={15} onKeyUp={handleKeyup.bind(this, 'txtGST')} />
                        <p id="lblGsterror" className="errorMsg" />
                    </div>
                    <div className="col-md-6">
                        <label>State*</label>
                        <select class="form-control" id="ddlStates" value={selectedState} onChange={handleChange.bind(this)}>
                            <option value="0">Select State</option>
                            {
                                states.length > 0 && states.map((item, index) =>
                                    <option value={item.name} key={index}>{item.name}</option>
                                )
                            }
                        </select>
                        <p id="lblStateserror" className="errorMsg" />
                    </div>
                    <div className="col-md-6">
                        <label>Company Address*</label>
                        <textarea id='txtAddress' className="form-control" rows={2} onKeyUp={handleKeyup.bind(this, 'txtAddress')} />
                        <p id="lblCAddresserror" className="errorMsg" />
                    </div>

                </div>
                <div className="ispregisterbtns">
                    <button className="pervious_btn" onClick={Register.bind(this, "previous", props.details)}>Previous</button>
                    <button className="next_btn" onClick={Register.bind(this, "next")}>NEXT</button>
                </div>
            </div>
            <div className="steps_content mobile_step">
                <div className="register_step">
                    <div className="register_num">3</div> <span>Preview</span>
                </div>
                <div className="register_step">
                    <div className="register_num">4</div> <span>Confirmation</span>
                </div>
            </div>
        </div>
    )
}