import React from 'react';
import './help.css';
import StaticHeader from '../StaticHeader/staticHeader.js';
import Footer from '../footer/footer.js';
import LeftPanel from './leftPanel.js'
import Config from '../shared/config';

class PrivacyPolicy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countryCode: ""
        }
    }

    componentDidMount() {
        fetch(Config.LocationAPI + "?tenant_code=bott&product=bott&client=Web",)
            .then((res) => res.json()).then((response) => {
                if (!!response) {
                    this.setState({ countryCode: response?.ipInfo?.countryCode == "IN" ? response?.ipInfo?.countryCode : ((response?.ipInfo?.countryCode == "SG" || response?.ipInfo?.countryCode == "MY") ? "SG" : "Others") })
                }
            })
    }

    render() {
        return (
            <div>
                <StaticHeader active={"home"} />
                <div className="static-page row ">
                    <LeftPanel active={"privacypolicy"} name={"Privacy Policy"} />
                    <div className="col-sm-10 right-content">
                        <h3 className="static-page-title">Privacy Policy for Scope Video Users & Subscribers</h3>
                        {
                            this.state.countryCode != "" && this.state.countryCode == "IN" &&
                            <>
                                <p className="static-page-content">This Privacy Policy discloses the privacy practices for your use of the online platform accessible at <a href="https://partners.scopevideo.com" target="_blank">partners.scopevideo.com</a> </p>
                                <p className="static-page-content">This privacy policy applies to the <a href="https://partners.scopevideo.com" target="_blank">partners.scopevideo.com</a> website and all corresponding domains, subdomains, web pages and websites associated therewith (collectively, the “website”) and our video streaming service that provides live and time-shifted television programming delivered over the Internet via applications on consumer devices including desktop computers, mobile phones, tablets and connected television devices that link to or reference this privacy policy (collectively, with the website, the “services”). This privacy policy only governs the services and does not apply offline. This privacy policy does not alter the terms of any other agreement that you may have with us. By accessing or using the services, you agree to the terms of this privacy policy and consent to the collection and use of information as discussed in this privacy policy. If you do not agree to the terms of this privacy policy, do not access the services or provide your information to Scope Video.</p>
                                <p className="static-page-content">By visiting this website, you agree to be bound by the terms and conditions of this privacy policy. Please read this privacy policy carefully. By using the website, you indicate that you understand, agree and consent to this privacy policy. If you do not agree with the terms of this privacy policy, please do not use/access this website.</p>
                                <p className="static-page-content">We’ll review this privacy policy from time to time to make sure it is up-to-date. If you are just a visitor, then please note that this privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this Policy periodically. If you are our registered user, we will notify you before we make changes to this Policy and give you the opportunity to review the revised Policy before you choose to continue using our services.</p>
                                <p className="static-page-content">By personal data, we mean any information that can either itself identify you as an individual ("Personally Identifying Information ") or that can be connected to you indirectly by linking it to Personally Identifying Information</p>
                                <h4 className="privacy-subheadding"><span></span> COLLECTION OF INFORMATION</h4>
                                <p className="static-page-content">As a visitor, you can browse our website to find out more about our website. You are not required to provide us with any personal data as a visitor. When you visit the site, we collect and store certain information in order to improve security, analyse trends and administer the website with a view to assist us in improving customer experience. We use this information to evaluate traffic patterns on our website so that we can make it more useful to our visitors. We collect your personal data when you register with us, when you express an interest in obtaining information about us or our products and services, when you participate in activities on our website or otherwise contact us.</p>
                                <p className="static-page-content">We will only collect your personal data if we have a proper reason for doing so, e.g.: to comply with our legal and regulatory obligations; for the performance of our contract with you or to take steps at your request before entering into a contract; for our legitimate interests or those of a third party; or where you have given consent. A legitimate interest is when we have a business or commercial reason to use your information, so long as this is not overridden by your own rights and interests. In some cases, we may also have a legal obligation to collect personal information from you or may otherwise need the personal information to protect your vital interests or those of another person (for example, to prevent payment fraud or confirm your identity</p>
                                <p className="static-page-content">Our primary goal in collecting your Personal Data and other personal information is to provide you a safe, efficient, smooth and customized experience. This allows us to provide services that most likely meet your needs and to customize our Website to make your experience safer and easier. We also use your Personal Identifiable Information together with other Personally Non-Identifiable Information to help us better understand our users and to improve the content and functionality of our Website. The information we learn from you helps us personalize and continually improve your experience at our Website. We do not voluntarily make this information available to third parties, nor do we use it for any other purpose, except as set out herein.</p>
                                <ul className="lower-alpha">
                                    <li>When you visit our website, we may collect a variety of information that you will voluntarily provide to us via one of our contact forms, via a chat or phone session (Personal Identifying Information), such as:
                                        <ul className="sqare-symbol">
                                            <li>Contact Information, such as name, email address, display picture, mailing address, phone number, IP address, geographic location, or phone number</li>
                                            <li>Information to enable us to check and verify your identity, e.g. your date of birth, mark-sheets, Passport/Aadhar /Voter ID details</li>
                                            <li>Billing Information, such as debit card number, credit card number and billing address</li>
                                            <li>Unique Identifiers, such as username or password</li>
                                            <li>Reviews or ratings, account settings, (including preferences set in the "Account" section of our Website); and</li>
                                            <li>Information provided to us through our service, interaction with our customer service, participation in surveys or marketing promotions</li>
                                        </ul>
                                    </li>

                                </ul>
                                <p className="static-page-content">We collect information about you and your use of our service, your interactions with us as well as information regarding your computer or other device used to access our service).</p>
                                <h4 className="privacy-subheadding" >USAGE AND RETENTION OF INFORMATION</h4>
                                <p className="static-page-content">We use the personal information we collect, including your Personal Data where it is necessary to deliver the services you have requested, where it is necessary to exercise or comply with legal rights or obligations or for normal business purposes of the kind set out in this Policy. We will use your personal information to provide, analyse, administer and improve our services, to provide you with a personalized experience on our Website (especially, by offering you services that is best suited for you), to contact you about your account and our services, to provide you customer service, to provide you with personalized marketing and to detect, prevent, mitigate and investigate fraudulent or illegal activities. We further use your personal information to determine your general geographic location, provide localized content, provide you with customized and personalized content, recommendations, determine your Internet service provider , and help us quickly and efficiently respond to inquiries and requests and enforcing our terms (such as determining free trial eligibility) and communicate with you concerning our service (for example by email, push notifications, text messaging ,and online messaging channels), so that we can send you details about new features and content available on the Website, special offers, promotional announcements, surveys, and to assist you with operational requests such as password reset requests.</p>
                                <h4 className="privacy-subheadding" ><span></span>  COMMUNITY</h4>
                                <p className="static-page-content">Scope Video is a community. We offer several features that allow members to connect and communicate in public or semi-public spaces, such as Forums and Teams. Please be sensible and judge before posting in these community spaces or sharing your personal information with others on Scope Video. Be aware that any personal information you submit there can be read, collected, or used by others, or could be used to send you unsolicited messages.</p>
                                <h4 className="privacy-subheadding">SHARING AND DISCLOSING PERSONAL INFORMATION</h4>
                                <p className="static-page-content">We use other companies, agents or contractors ("Service Providers") to perform services on our behalf or to assist us with the provision of services to you. We engage Service Providers to provide marketing, advertising, communications, infrastructure and IT services, to personalize and optimize our service, to process credit card transactions or other payment methods, to provide customer service, to collect debts, to analyze and enhance data (including data about users’ interactions with our service), and to process and administer consumer surveys. In the course of providing such services, these Service Providers may have access to your personal or other information.</p>
                                <p className="static-page-content">We do not authorize them to use or disclose your personal information except in connection with providing their services.</p>
                                <p className="static-page-content"><strong>Note to our customers in Europe:</strong> We transfer your personal information from the European Economic Area and Switzerland to India. By submitting your data and/or using our services, you consent to the transfer, storing, and processing of your personal information in India.</p>
                                <h4 className="privacy-subheadding"><span></span> SECURITY</h4>
                                <p className="static-page-content">We shall try to take all precautions to protect the personal information both online and offline. We will try to protect your information using technical and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration. We have standard SSL certification which basically helps us create a secure connection between our server and user to render any information or action. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centres and information access authorization controls. Only user passwords are encrypted in 64-bit encryption and stored because generally users use the same password on multiple sites, to prevent any kind of theft, piracy or unauthorised access. If you believe your account has been abused or hacked, please contact us by sending us an email at <a href="mailto:legal@yupptvscope.com">legal@yupptvscope.com</a> </p>
                                <p className="static-page-content">We do not sell, transfer or rent your personal information to third parties for their marketing purposes without your explicit consent and we only use your information as described in the privacy policy. We view protection of your privacy as a very important community principle. We understand clearly that you and your Information is one of our most important assets. We store and process your personal information on computers located in India that are protected by physical as well as technological security devices. We use third parties to verify and certify our privacy principles. If you object to your Information being transferred or used in this way, please do not use this website.</p>
                                <p className="static-page-content">Under no circumstances, we rent, trade, transfer or share your personal information that we have collected with any other company for their marketing purposes without your consent. We reserve the right to communicate your personal information to any third party that makes a legally-compliant request for its disclosure.</p>
                                <p className="static-page-content"><strong>Keeping your personal data secure</strong></p>
                                <p className="static-page-content">We have appropriate security measures to prevent your personal data from being accidentally lost or used or accessed unlawfully. Processing your personal data will be done only in an authorised manner and subject to a duty of confidentiality. We also have procedures–in-place to deal with any suspected data security breach. We will notify you about any applicable regulator of a suspected data security breach where we are legally required to do so.</p>
                                <p className="static-page-content">Notwithstanding anything contained anywhere in this Agreement; we cannot assure absolute security to your personal data and by entering into or by logging into our website, you explicitly agree not to sue us for any data breach. </p>
                                <h4 className="privacy-subheadding"><span></span> COOKIES</h4>
                                <p className="static-page-content">We transfer cookies, which are small files containing a string of character, to your IP address, giving the browser distinct identification, in order to keep track of the user’s preferences. Furthermore, these files also help in logging-in faster and they act as a mechanism to determine user trends. The data thus retrieved from the user’s IP address, enables us to enhance our offers, including but not limited to more content in areas of greater interest to a majority of users. Our website uses "cookies" to identify the areas of our website that you have visited. A cookie is a small piece of data stored on your computer or mobile device by your web browser. We use cookies to personalize the Content that you see on our website. Most web browsers can be set to disable the use of cookies. However, if you disable cookies, you may not be able to access functionality on our website correctly or at all. We never place personally identifiable Information in cookies.</p>
                                <h4 className="privacy-subheadding">THIRD PARTIES AND LINKS</h4>
                                <p className="static-page-content">We may pass your details to other companies in our group. We may also pass your details to our agents and subcontractors to help us with any of our uses of your data set out in our privacy policy. For example, we may use third parties to assist us with delivering services to you, to help us to collect payments from you, to analyse data and to provide us with marketing or customer service assistance. We may exchange information with third parties for the purposes of fraud protection and credit risk reduction. We may transfer our databases containing your personal information if we sell our business or part of it. Other than as set out in this privacy policy, we shall NOT sell or disclose your personal data to third parties without obtaining your prior consent unless it is necessary for the purposes set out in this privacy policy or unless we are required to do so by law. The site may contain advertising of third parties and links to other sites or frames of other sites. Please be aware that we are not responsible for the privacy practices or content of those third parties or other sites, nor for any third party to whom we transfer your data in accordance with our privacy policy.</p>
                                <p className="static-page-content">Our website may contain links to other websites that are not under our direct control. These websites may have their own policies regarding privacy. We have no control of or responsibility for linked websites and provide these links solely for the convenience and information of our visitors. You accessing such linked websites shall be at your own risk. These websites are not subject to this privacy policy. You should check the privacy policies, if any, of those individual websites to see how the operators of those third-party websites will utilize your personal information. In addition, these websites may contain a link to websites of our affiliates. The websites of our affiliates are not subject to this privacy policy, and you should check their individual privacy policies to see how the operators of such websites will utilize your personal information.</p>
                                <h4 className="privacy-subheadding"><span></span> CONSULTING</h4>
                                <p className="static-page-content">We use third parties to help us provide services to you including the fulfilment of service, processing of payments, monitoring site activity, conducting surveys, maintaining our database, administering emails, and administering contents, and to provide aggregate, comparative information on the performance of our website to us and a select group.</p>
                                <h4 className="privacy-subheadding"><span></span> CHOICE</h4>
                                <p className="static-page-content">It is open for you to customize our usage of your personal information to communicate with you, to send you marketing information, how we provide you with customized and relevant advertising, and whether you want to stay signed into your account.</p>
                                <p className="static-page-content">If you do not wish to receive marketing communications from us, you can unsubscribe from the link in the email you would receive or change your communication preferences or indicate your communication preferences. You can also reach us via chat, WhatsApp, call or email to block promotional communication to you. Keep in mind, we do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent.</p>
                                <h4 className="privacy-subheadding"><span></span> OWNERSHIP OF RIGHTS</h4>
                                <p className="static-page-content">All rights, including copyright, in this Website are owned by or licensed to us. Any use of this Website or its contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use is prohibited without our permission. You are prohibited from modifying, copying, distributing, transmitting, displaying, printing, publishing, selling, licensing, creating derivative works or using any content available on or through our Website for commercial or public purposes. You may not modify, distribute or re-post something on this Website for any purpose.</p>
                                <p className="static-page-content">The site contains copyrighted material, trademarks and other proprietary information, including, but not limited to, text, software, photos, video, graphics, music, sound, and the entire contents of Scope Video is protected by copyright as a collective work under the applicable copyright laws. Scope Video owns a copyright in the selection, coordination, arrangement and enhancement of such content, as well as copyright or license to use in the content original to it. You may not modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any way exploit, any of the content, in whole or in part. You may download / print / save copyrighted material for your personal use only. Except as otherwise expressly stated under copyright law, no copying, redistribution, retransmission, publication or commercial exploitation of downloaded material without the express permission of Scope Video is permitted. If copying, redistribution or publication of copyrighted material is expressly permitted by Scope Video, then no changes in or deletion of author attribution, trademark legend or copyright notice shall be made.</p>
                                <p className="static-page-content">You acknowledge that you do not acquire any ownership rights by downloading copyrighted material. Trademarks that are located within or on our website or a website otherwise owned or operated in conjunction with Scope Video shall not be deemed to be in the public domain but rather the exclusive property of Scope Video, unless such site is under license from the trademark owner thereof in which case such license is for the exclusive benefit and use of Scope Video, unless otherwise stated.</p>
                                <p className="static-page-content">Scope Video does not have any express burden or responsibility to provide you with indications, markings or anything else that may aid you in determining whether the material in question is copyrighted or trademarked. You shall be solely liable for any damage resulting from any infringement of copyrights, trademarks, proprietary rights or any other harm resulting from such a submission. By submitting material to any public area of the website, you warrant that the owner of such material has expressly granted Scope Video the royalty-free, perpetual, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate and distribute such material (in whole or in part) worldwide and/or to incorporate it in other works in any form, media or technology now known or hereafter developed for the full term of any copyright that may exist in such material. You also permit any other end user to access, view, store or reproduce the material for that end user's personal use. You hereby grant Scope Video, the right to edit, copy, publish and distribute any material made available on the website by you.</p>
                                <p className="static-page-content">If you come across any abuse or violation of these terms, please report to <a href="mailto:legal@yupptvscope.com">legal@yupptvscope.com</a></p>
                                <h4 className="privacy-subheadding"><span></span> CONSENT</h4>
                                <p className="static-page-content">By submitting data to us or our agent or using the site, you consent to our use of your data in the manner set out in this privacy policy. Not with standing anything to the contrary contained herein or elsewhere, you hereby consent to Scope Video to disclose and/or advertise to the public that you have or had subscribed to a particular course with Scope Video in the event you have or had subscribed to a course or Listing with Scope Video for the purposes of showing that the rank was secured as a result of the courses offered by Scope Video.</p>
                                <p className="static-page-content"><strong>YuppTV Legal</strong></p>
                                <p className="static-page-content"><a href="mailto:legal@yupptvscope.com">legal@yupptvscope.com</a></p>
                                <h4 className="privacy-subheadding"><span></span> CONSENT TO THIS POLICY</h4>
                                <p className="static-page-content">The Terms of Use Agreement is incorporated herein by reference in its entirety.</p>
                                <h4 className="privacy-subheadding"><span></span> GENERAL</h4>
                                <p className="static-page-content"><strong>Modification:</strong></p>
                                <p className="static-page-content">We may at any time modify the Terms of Use of our website without any prior notification to you. Should you wish to terminate your account due to a modification to the Terms or the privacy policy, you may do so email us at <a href="mailto:support@yupptvscope.com">support@YuppTV Scope.com.</a> However, if you continue to use the service you shall be deemed to have agreed to accept and abide by the modified Terms of this website.</p>
                                <p className="static-page-content"><strong>PRIVILEGED/EXCLUSIVE SERVICE:</strong></p>
                                <p className="static-page-content">By having a Scope Video account, you have explicitly given consent for us to capture images (followed by analysis), camera/mic permissions to make video calls and record the same.</p>
                                <h4 className="privacy-subheadding">SOPE VIDEO MAY DISCLOSE INFORMATION:</h4>
                                <ul className="lower-alpha" style={{ listStyleType: "none" }}>
                                    <li>
                                        <ul style={{ listStyleType: "decimal" }}>
                                            <li>as required by law, such as to comply with a subpoena, or similar legal process;</li>
                                            <li>to enforce applicable TOU (Terms of Use), including investigation of potential violations thereof;</li>
                                            <li>when we believe in good faith that the disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, address security or technical issues or respond to a government request;</li>
                                            <li>with our trusted service providers who work on our behalf and do not have an independent use of the information we disclose to them and have agreed to and adhered to the rules set forth in this policy;</li>
                                            <li>to protect against imminent harm to the rights, property or safety of the Application/website or our users or the public as required or permitted by law;</li>
                                            <li>with third party service providers in order to personalize the Application/website/services/products for a better user experience and to perform behavioural analysis;</li>
                                        </ul>
                                    </li>

                                </ul>

                                <h4 className="privacy-subheadding">Governing Law and Jurisdiction:</h4>
                                <p className="static-page-content">In the event of any dispute arising between the parties with respect to this agreement, the same shall be referred to the sole arbitrator and the arbitration shall be in accordance with arbitration and conciliation Act of 1996. The language of arbitration proceeding shall be english. The seat and place of arbitration shall be hyderabad and the decision of the arbitrator shall be final and binding on both parties herein.</p>
                                <p className="static-page-content">This contract shall be subject to the exclusive jurisdiction of courts in Hyderabad, Telangana, India and shall be governed by the Indian laws.</p>
                            </>
                        }
                        {
                            this.state.countryCode != "" && this.state.countryCode == "SG" &&
                            <>
                                <ul style={{ listStyleType: "disc" }}>
                                    <li>
                                        <h4 className="privacy-subheadding">Objective</h4>
                                        <p className="static-page-content">This Privacy Policy sets out how YuppTV USA Inc. and its affiliated companies within the YuppTV Group ("YuppTV", "we", "our" or "us") collect, use, disclose, process, store, transfer and protect any personal information that you give us when you use our services and platform in the name and style of “Scope Video”. This Privacy Policy ("Privacy Policy") governs the use of Personal Information shared (as defined below) with or collected from the subscriber ("you") ofScope Video,but excludes from its purview services that state that they are offered under a different privacy policy.</p>
                                        <p className="static-page-content">You acknowledge that your continued use of our Scope Video after we publish or send a notice about our changes to our Privacy Policy means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Scope</h4>
                                        <p className="static-page-content">This Privacy Policy describes the usage of information provided or collected on Scope Video where this Privacy Policy is posted. We follow this Privacy Policy in accordance with applicable law in the places where we operate. In some cases, we may provide additional data privacy notices specific to certain services or regions. Those terms are to be read in combination with this policy.</p>
                                        <p className="static-page-content">Please keep in mind that when you provide information to us via third-party sites or platforms (for example, registration or sign up using social media login) the information we collect is through those third party sites linked with our applications and is covered by this Privacy Policy, however the information third-party sites or platform collects is subject to the third-party site or platform’s privacy practices. Privacy choices you have made on the third-party sites or platforms will not apply to our use of the information we have collected directly through our Scope Video platform. Please also keep in observance that our Scope Video platform may contain links to Content Providers’ (defined hereinafter) Terms and Conditions and Privacy Policies governing your use of the Content Providers’ apps (“Partner Apps”) and to other platforms not owned or controlled by us and we are not responsible for the privacy practices of Content Providers’ or of other platforms. We encourage you to be aware when you leave ourScope Video platform; read the privacy policies of other platforms including Partner Apps that may collect your personal information carefully. </p>
                                        <p className="static-page-content">By using our services, you consent us to collect, use, and storage of your information (including such subscriber information provided to Scope Video, from third parties, in the course of migration subject to your approval) in the manner described in this Privacy Policy. This Privacy Policy applies only to personal information Scope Video and its group affiliates collect about the subscriber and does not apply to personal information about the subscriber collected by any other third party website and offerings linked to or otherwise accessible from Scope Video  website or its services. The information collected or received by third party website are subject to their own privacy policies.</p>
                                        <p className="static-page-content">As stated above, Scope Video provides any user with access to content provided by Content Providers whereby any user purchasing or subscribing to subscription package provided by Scope Video on YuppTV website available at <a target="_blank" href="https://www.yupptv.com/">https://www.yupptv.com/</a> , whereby the user will further be redirected to the Partner Apps for final content consumption and the same shall be governed by the Privacy Policies of such Partner Apps. By accepting the terms and conditions and privacy policy of Scope Video, you accept and consent to the terms and conditions and privacy policy of the Partner Apps mentioned in the Terms and Conditions. Any issue or dispute arising due to the Partner Apps or their collection, storage or use of your personal information will be resolved by you directly with the respective Content Provider without the involvement of Scope Video. Scope Video shall not be liable for any consequences, damages or loss arising due to the same. </p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Collection and use of personal information</h4>
                                        <p className="static-page-content">"Personal Information" or "PII" is defined as any information that identifies (whether directly or indirectly) to a particular individual or natural person, such as the individual's name, postal address, email address, and mobile number indicating to that particular person or is identifiable. When anonymous information is directly or indirectly associated with personal information, the resulting information also is treated as personal information. </p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">User consent</h4>
                                        <p className="static-page-content">"By using Scope Video service (for example, when you register for an account, purchase a service, communicate with us, on our sites or applications), you agree to provide consent to our collection, use and sharing of your personal information as described in this policy. In some cases, particularly if you reside in a country governed by a data protection regulation, we may ask you to provide explicit consent to access our services before proceeding for further operations. </p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Collection of information</h4>
                                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                                            <li><strong>Information you provide to us</strong>
                                                <p className="static-page-content">We may ask for and collect the following personal information about you when you use Scope Videoservice or platform. Without this information we may not be able to provide you with all the requested services;</p>
                                                <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
                                                    <li>First/Last Name</li>
                                                    <li>Mobile No.</li>
                                                    <li>Email id</li>
                                                    <li>Country</li>
                                                    <li>Billing address</li>
                                                    <li>Shipping address</li>
                                                </ul>
                                            </li>
                                            <li><strong>Information we collect automatically</strong>
                                                <p className="static-page-content">We collect information about you and your use of our service, your interactions with us, as well as information about your device used to access our service. The information includes;</p>
                                                <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
                                                    <li>Activities such as language preferences, type of content, watch history, search queries etc.</li>
                                                    <li>Your interactions with our email, customer care and messaging platform.</li>
                                                    <li>Geo-location information like IP addresses,</li>
                                                    <li>Device information like device id, device model etc. and other unique identifiers</li>
                                                    <li>Cookies</li>
                                                    <li>Age</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <p className="static-page-content">If you choose to post messages on social media accounts, message boards, chat rooms or other message areas or leave feedback, we will collect that information you provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems.
                                                    If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on Scope Video , we may collect and store such information,but we would notify you prior to any further processing of that information.
                                                </p>
                                            </li>
                                            <li>
                                                <p className="static-page-content">Information we collect through third parties
                                                    An agent or affiliate of Scope Video may, at times and on being so authorized, collect information on behalf of Scope Video,Third party providers featured on or linked to by the Scope Video sites, such as vendors of products and services offered through the sites, may be gathering the same kind of information.

                                                </p>
                                            </li>
                                            <li>
                                                <p className="static-page-content">Any personal information or video content that the user voluntarily discloses online (on discussion boards, in messages and chat areas, etc.) becomes publicly available and can be collected and used by others. The Users account name (not the email address) is displayed to other users when any videos are uploaded or messages are sent through the services and other users can contact the user through messages and comments. Billing Information

                                                </p>
                                                <p className="static-page-content">Based on your purchase of a subscription package, our payment gateway partners collect information with respect to your credit/debit card details or other bank account details. This information is stored by our payment gateway partners. For instance, Scope Video will be using third party payment gateway providers to process and facilitate the payment of your subscription fee to Scope Video for providing you access to the subscription package in the manner provided in Terms and Conditions. Please note that Scope Video does not directly collect any financial information such as credit card or debit card or net banking details from you. Such payment gateway partners are not controlled by us. When you visit such payment gateways you do so at your own risk. These payment gateways may have their own privacy policies in place, which we recommend you review if you visit any such payment gateways. We do not assume any responsibility or liability for such payment gateways, the content of such payment gateways and their privacy practices, nor do we endorse them. We encourage you to familiarize yourself with the privacy statements provided by such payment gateways prior to providing them with information about you or entering into any transactions with them.</p>
                                                <p className="static-page-content">While transacting with these payment gateways, you may provide your financial information including without limitation your bank account details, credit card account details or your details pertaining to any payment settlement or pre-paid instrument service provider. You understand, agree and acknowledge that Scope Videonever receives your financial and payment information from these payment gateways. Your Personal Information, Sensitive Personal Data or Information and financial information will be dealt with by these payment gateways in accordance with their respective privacy policies and other terms and conditions and Scope Video shall not be liable, accountable or responsible for Your Personal Information, Sensitive Personal Data or Information and financial information which you provide to these payment gateways.</p>
                                                <p className="static-page-content">Except as provided herein, we do not solicit any Sensitive Information about You. However, if you share such information with us voluntarily, we will not be liable for any actions, claims, costs, expenses or other liabilities that may arise as a consequence of such any unauthorized use or misuse of such information.</p>
                                            </li>
                                            <li>
                                                <p className="static-page-content">Any personal information or video content that the user voluntarily discloses online (on discussion boards, in messages and chat areas, etc.) becomes publicly available and can be collected and used by others. The Users account name (not the email address) is displayed to other users when any videos are uploaded or messages are sent through the services and other users can contact the user through messages and comments. Billing Information

                                                </p>
                                            </li>
                                            <li><strong>Purpose and lawfulness of processing</strong>
                                                <p className="static-page-content">We will only collect and process personal data about you where we have lawful bases. Lawful bases on which we would process your personal information includes obtaining explicit consent from you for processing your personal information or processing for "legitimate interests" where processing is necessary by us to provide you with customer support or process your data for providing premium services (For e.g. processing your information by our payment gateway service providers).</p>
                                                <p className="static-page-content">We use information to provide, analyse, administer, enhance and personalize our service and marketing efforts, to process your registration, your orders, payments, and to communicate with you related to below mentioned points. For example, we use information to:</p>
                                                <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                                                    <li>Determine your general geographic location, provide localized content, provide you with customized and personalized viewing recommendations for movies and TV shows we think will be of interest to you, determine your Internet service provider, and help us quickly and efficiently respond to inquiries and requests;</li>
                                                    <li>Prevent, detect and investigate potentially prohibited or illegal activities, including fraud, and enforcing our terms (such as determining free trial eligibility);</li>
                                                    <li>Analyze and understand our audience, improve our service (including our user interface experiences) and optimize content selection, recommendation algorithms and delivery;</li>
                                                    <li>Communicate with you concerning our service (for example by email, push notifications text messaging, and online messaging channels), so that we can send you news about Scope Video, details about new features and content available on Scope Video , and special offers, promotional announcements, and consumer surveys, and to assist you with operational requests such as password reset requests.</li>
                                                    <li>Notify you about changes inTerms and Conditions.</li>
                                                    <li>Allow you to participate in interactive features offered through our Services.</li>
                                                    <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing, chargeback and collection.</li>
                                                </ul>
                                                <p className="static-page-content">We may also use your information to contact you about our own and third-party services that may be of interest to you. If you do not want us to use your information in this way, please provide explicit consent for the same when we collect your data and/or adjust your user preferences in your account profile w.r.t. your choices/preferences provided.</p>
                                            </li>
                                        </ul>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Withdrawl of consent</h4>
                                        <p className="static-page-content">"You may withdraw your consent for the collection, use and/or disclosure of your personal information in our possession or control by submitting a request on our support e-mail or using the chat functionality or by directly contacting our support team (see contact us section). We will process your request within a reasonable time from when the request was made, and thereafter not process your personal information as per your request. </p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">How Scope Video responds to do not track signals:</h4>
                                        <p className="static-page-content">We may partner with third-party service providers to serve ads regarding goods or services that may be of interest to you when you access and use Scope Video platform and third party-platform. Some of the ads on this Site or on third party platform may be personalized, meaning that they are intended to be relevant to you based on what we or the online advertising network serving the ad know about you or your computer's browsing activity on both Scope Video platform and third-party platform. To do so, these companies may place or recognize a unique cookie on your browser (including through the use of pixel tags). </p>
                                        <p className="static-page-content">The Network Advertising Initiative offers useful information about internet advertising companies, including information about how to opt-out of interest-based advertising by their members. See <a target="_blank" href="http://www.networkadvertising.org ">http://www.networkadvertising.org </a> for general information about the NAI and<a target="_blank" href=" http://www.networkadvertising.org/managing/opt_out.asp"> http://www.networkadvertising.org/managing/opt_out.asp </a>for the opt-out page. You may also visit <a target="_blank" href=" http://www.aboutads.info/consumers/"> http://www.aboutads.info/consumers/</a> to learn about interest-based advertising and how to opt-out from ads served by some or all participating companies. </p>
                                        <p className="static-page-content">These opt-out mechanisms rely on cookies to remember your choices. If you delete your cookies, use another computer or device, or change browsers, you will need to repeat this process. In addition, opting out of interest-based ads will not opt you out of all ads, but rather only those ads that are personalized to your interests. </p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Usage of information</h4>
                                        <p className="static-page-content">Scope Video uses IP address information for systems administration and troubleshooting purposes. This information is also used to deliver web pages to the subscriber upon request; to tailor the site to the interests of the subscriber and to measure traffic within the Scope Video site. </p>
                                        <p className="static-page-content"> Any personally identifiable information provided by the subscriber is used to operate, maintain, and provide the subscriber with the features and functionality of Scope Video platform.</p>
                                        <p className="static-page-content"> Scope Video does not use the subscriber email address or other personally identifiable information to send commercial or marketing messages without the subscriber consent. Scope Video may not use both personally identifiable information and certain non-personally- identifiable information to improve the quality and design of the services and to create new features, promotions, functionality, and services by storing, tracking, and analyzing subscriber preferences and trends without the subscriber consent.</p>
                                        <p className="static-page-content"> Scope Video uses cookies, clear gifs, and log file information to: remember information so that the subscriber is not required to re-enter it during subsequent visits; provide custom and personalized content and information; monitor the effectiveness of marketing campaigns; monitor logs such as total number of visitors, pages viewed, etc.; and to track entries, submissions, and status in promotions, sweepstakes, and contests of the subscriber.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Sharing and disclosure of personal information</h4>
                                        <p className="static-page-content">We do not share your personal information with companies, organizations, or individuals outside of Scope Video or YuppTV entities except in the following cases:</p>
                                        <p className="static-page-content">We’ll share or disclose your personal information outside of Scope Video or YuppTV when we have your consent. For example, providing SMS and E-mail related services such as marketing newsletters, promotions and offers.</p>
                                        <p className="static-page-content">We provide personal information to our affiliates and other trusted businesses or persons to process it for us, based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures. For example, we disclose your personal information with the customer support team to help resolve your queries.</p>
                                        <p className="static-page-content">We shall endeavour that the third parties and agents engaged by us to whom we may provide access to your Personal Information or Sensitive Personal Data or Information for the purposes set out herein are under an contractual obligation to protect your Personal Information or Sensitive Personal Data or Information in accordance with the same standard that Scope Video  has agreed to be subject to in this Privacy Policy.</p>
                                        <p className="static-page-content">In the event of any requirement by court order, government or quasi-government agency to disclose your Personal Information, we will disclose information as may be legally required. We may also disclose your Personal Information if we, in the good faith believe that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process.</p>
                                        <p className="static-page-content">In the event Scope Video  is merged with or acquired by another company or in case of re-organization or re-structuring of business, we and our affiliates will share your Personal Information, wholly or in part, with  such other business entity as aforesaid. We will notify you via e-mail or update our privacy terms if any of the above merger/acquisition/restructuring does take place</p>
                                        <p className="static-page-content">We may share non-personally identifiable information publicly and with our partners - like publishers, advertisers, developers, or rights holders. For example, we share information publicly to show trends about the general use of our services. We also allow specific partners to collect information from your browser or device for advertising and measurement purposes using their own cookies or similar technologies.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Your controls and choices</h4>
                                        <p className="static-page-content">We provide you the ability to exercise certain controls and choices regarding our collection, use and sharing of your information. In accordance with applicable law, your controls and choices may include:</p>
                                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                                            <li>
                                                <h4 className="privacy-subheadding">Managing Your Information</h4>
                                                <p className="static-page-content">You can access and update some of your personal information through your account settings. If you have chosen to connect your Scope Video account to a third-party application, like Facebook or Google, you can change your settings and remove permission for the app by changing your account settings. You are responsible for keeping your personal information up-to-date. Scope Video  will send you periodic reminders via e-mail to maintain the accuracy of your personal information. We normally provide data access/update/rectification services for free but reserve the right to charge a reasonable fee if data subject request is unfounded or excessive in character.</p>
                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Rectification of Inaccurate or Incomplete Information</h4>
                                                <p className="static-page-content"> You can ask us to correct inaccurate or incomplete personal information concerning you (this is the category of information which you cannot update by yourself within your Scope Video  account) by sending us an e-mail.</p>
                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Data Access and Portability</h4>
                                                <p className="static-page-content">In some jurisdictions, applicable law may entitle you to request copies of your personal information held by us. You may also be entitled to request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format and/or request us to transmit this information to another service provider (where technically feasible).</p>
                                                <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
                                                    <li>We may retain some of your personal information as necessary for our legitimate business interests, such as fraud detection and prevention and enhancing safety. For example, if we suspend a Scope Video  account for fraud or safety reasons, we may retain certain information from that Scope Video  account to prevent that user from opening a new Scope Video account in the future.</li>
                                                    <li>We may retain and use your personal information to the extent necessary to comply with our legal obligations. For example, Scope Video may keep some of your information for tax, legal reporting and auditing obligations.</li>
                                                    <li>Information you have shared with others (e.g., reviews, forum postings) may continue to be publicly visible on the Scope Videoplatform, even after your Scope Video account is cancelled. However, attribution of such information to you will be removed. Additionally, some copies of your information (e.g., log records) may remain in our database, but are disassociated from personal identifiers.</li>
                                                    <li>Because we maintain the Scope Video platform to protect from accidental or malicious loss and destruction, residual copies of your personal information may not be removed from our backup systems for a limited period of time.</li>
                                                    <li>Data subject can request their right by contacting Scope Video  through customer support or email. Refer contact section for details.</li>
                                                </ul>

                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Data Retention and Erasure</h4>
                                                <p className="static-page-content">We retain your personal information as long as necessary for the performance of the contract between you and us to comply with our legal obligations. If you no longer want us to use your information then you can request that we erase your personal information and close your Scope Video  account. Please note that if you request for the erasure of your personal information; right to erasure will not be applicable under below conditions:</p>
                                                <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
                                                    <li>for exercising the right of freedom of expression and information;</li>
                                                    <li>for compliance with a legal obligation which requires processing by Union or Member State law to which the controller is subject or for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;</li>
                                                    <li>for reasons of public interest in the area of public health;</li>
                                                    <li>for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes;</li>
                                                    <li>For the establishment, exercise or defense of legal claims.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Objection to Processing</h4>
                                                <p className="static-page-content">Where your personal information is processed for direct marketing purposes, you may, at any time ask Scope Video  to cease processing your data for these direct marketing purposes by sending an e-mail to us.</p>

                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Lodging Complaints</h4>
                                                <p className="static-page-content">You have the right to lodge complaints about the data processing activities carried out by Scope Video  before the competent data protection authorities. You can lodge a complaint with our data protection officer or with the supervisory authority. Details of the supervisory authority can be obtained by contacting our DPO.</p>
                                                <p className="static-page-content">Please be aware that if you do not allow us to collect personal information from you, we may not be able to deliver certain experiences, products, and services to you, and some of our services may not be able to take account of your interests and preferences. If collection of personal information is mandatory, we will make that clear at the point of collection so that you can make an informed decision whether to participate. If you have questions about the specific personal information about you that we process or retain, and your rights regarding that personal information, please contact our customer support team.</p>

                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Withdrawal of Consent</h4>
                                                <p className="static-page-content">You may withdraw your consent for the collection, use and/or disclosure of your personal information in our possession or control by submitting a request on our support e-mail or using the chat functionality or by directly contacting our support team (see contact us section). We will process your request within a reasonable time from when the request was made, and thereafter not process your personal information as per your request.</p>
                                            </li>
                                        </ul>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Children's Consent</h4>
                                        <p className="static-page-content">Scope Video  platform is not intentionally designed for or directed at persons less than 18 years of age. Scope Video  does not knowingly permit any person who is under 18years of age to register with the services or to provide any other personally identifying information. If Scope Video  becomes aware that any personally identifiable information of persons less than 18 years of age has been collected on the Scope Video platform without verified parental consent, then Scope Video  will take the appropriate steps to delete any such information. However, we consider it the responsibility of parents to monitor their children's use of our services. Nevertheless, it is our policy not to require personal information from children below 18 years or offer to send any promotional materials to persons in that category. Scope Video  does not seek or intend to seek or to receive any personal information from children. Should a parent or guardian have reasons to believe that a minor has provided Scope Video with personal information without their prior consent, please contact our customer support team to ensure that the personal information is removed from Scope Video. </p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Data Transfers, Storage and Processing Globally</h4>
                                        <p className="static-page-content">We operate globally and may transfer your personal information to individual companies of Scope Video  or third parties in locations around the world for the purposes described in this Privacy Policy and which the countries may have data protection laws that differ from those of your country. Wherever your personal information is transferred, stored or processed by us, we will take reasonable steps to safeguard the privacy of your personal information. Additionally, when using or disclosing personal information transferred from the European Union, we use standard contractual clauses approved by the European Commission, adopt other means under European Union law for ensuring adequate safeguards.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Storage of Information</h4>
                                        <p className="static-page-content">Any information provided by the subscriber to Scope Video  shall be stored and held under generally accepted standards of technology and operational security in order to protect the subscriber personally identifiable information from loss, misuse, alteration, or destruction. Only authorized personnel are permitted access to the subscriber information and these personnel are required to treat this information as confidential. Despite these precautions, Scope Video  cannot guarantee that any and all unauthorized access to the subscriber information will be prevented.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Security of Information</h4>
                                        <p className="static-page-content">We are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorized access, loss, destruction, or alteration. Some of the safeguards we use to protect your information are firewalls and data encryption, and information access controls. If you know or have reason to believe that your Scope Video  account credentials have been lost, stolen, altered, or otherwise compromised or in case of any actual or suspected unauthorized use of your account, please contact us by contacting our customer support team. Please be aware that, despite our best efforts, no security measures are perfect or impenetrable. By continuing to access the Scope Video  platform you are aware of such risk.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Modifications</h4>
                                        <p className="static-page-content">Scope Video may update or revise this Privacy Policy from time to time. Please take a look at the "Effective Date" legend at the top of this page to see when this Privacy Policy was last revised. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy. Scope Video will also notify you of any material changes to this Privacy Policy. Your use of the Scope Video  platform following any changes to this Privacy Policy means you accept the revised Privacy Policy.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Change in Control</h4>
                                        <p className="static-page-content">In the event that Scope Video is acquired by or merged with a third party entity, Scope Video, subject to applicable law, reserves the right, in any of these circumstances, to transfer or assign the information collected from the users/subscribers as part of such merger, acquisition, sale, or other change of control. In the event of bankruptcy, insolvency, reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or equitable principles affecting creditors' rights generally, Scope Video  may have to share/transfer your personal information with the other party. In this scenario, we may not be able to control how the Personal Information is treated, transferred, or used.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Contact Information</h4>
                                        <p className="static-page-content">Scope Video  shall timely address and attempt to rectify any discrepancies and grievances of our users pertaining to the personal information furnished by them.</p>
                                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                                            <li>Subscriber may contact Scope Video  with any questions or comments about this Privacy Policy and may address such queries and / or comments to <a href="mailto:privacy@yupptv.com">privacy@yupptv.com</a></li>
                                            <li>If you have any queries or complaints regarding the collecting, processing, transfer of personal data/information please do contact us online through <a href="mailto:name@rapidtables.com">privacy@yupptv.com</a></li>
                                            <li>Residents in the countries in the European Union (EU), please note:
                                                <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
                                                    <li>You may contact our Data Protection Officer at  <a href="mailto:dpo@yupptv.com">dpo@yupptv.com.</a></li>
                                                    <li>The data controller for Scope Video  will be YuppTV USA Inc. based out of 11175 Cicero Dr, Suite #100, Alpharetta, GA 30022 USA</li>
                                                    <li>The data controller for Scope Video  will be YuppTV USA Inc. based out of 11175 Cicero Dr, Suite #100, Alpharetta, GA 30022 USA</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Residents in India may contact our Grievance Officer, Mr. Roop Kumar Reddy, at <a href="mailto:roop@yupptv.com">roop@yupptv.com.</a>  As per applicable Indian laws, the Grievance Officer shall redress the grievances of the users furnishing the Information within one (1) month from the date of receipt of the grievance.
                                    </li>

                                </ul>
                            </>
                        }
                        {
                            this.state.countryCode != "" && this.state.countryCode == "Others" &&
                            <>
                                <ul style={{ listStyleType: "disc" }}>
                                    <li>
                                        <h4 className="privacy-subheadding">Objective</h4>
                                        <p className="static-page-content">This Privacy Policy sets out how YuppTV USA Inc. and its affiliated companies within the YuppTV Group ("YuppTV", "we", "our" or "us") collect, use, disclose, process, store, transfer and protect any personal information that you give us when you use our services and platform in the name and style of “Scope Video”. This Privacy Policy ("Privacy Policy") governs the use of Personal Information shared (as defined below) with or collected from the subscriber ("you") ofScope Video,but excludes from its purview services that state that they are offered under a different privacy policy.</p>
                                        <p className="static-page-content">You acknowledge that your continued use of our Scope Video after we publish or send a notice about our changes to our Privacy Policy means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Scope</h4>
                                        <p className="static-page-content">This Privacy Policy describes the usage of information provided or collected on Scope Video where this Privacy Policy is posted. We follow this Privacy Policy in accordance with applicable law in the places where we operate. In some cases, we may provide additional data privacy notices specific to certain services or regions. Those terms are to be read in combination with this policy.</p>
                                        <p className="static-page-content">Please keep in mind that when you provide information to us via third-party sites or platforms (for example, registration or sign up using social media login) the information we collect is through those third party sites linked with our applications and is covered by this Privacy Policy, however the information third-party sites or platform collects is subject to the third-party site or platform’s privacy practices. Privacy choices you have made on the third-party sites or platforms will not apply to our use of the information we have collected directly through our Scope Video platform. Please also keep in observance that our Scope Video platform may contain links to Content Providers’ (defined hereinafter) Terms and Conditions and Privacy Policies governing your use of the Content Providers’ apps (“Partner Apps”) and to other platforms not owned or controlled by us and we are not responsible for the privacy practices of Content Providers’ or of other platforms. We encourage you to be aware when you leave ourScope Video platform; read the privacy policies of other platforms including Partner Apps that may collect your personal information carefully. </p>
                                        <p className="static-page-content">By using our services, you consent us to collect, use, and storage of your information (including such subscriber information provided to Scope Video, from third parties, in the course of migration subject to your approval) in the manner described in this Privacy Policy. This Privacy Policy applies only to personal information Scope Video and its group affiliates collect about the subscriber and does not apply to personal information about the subscriber collected by any other third party website and offerings linked to or otherwise accessible from Scope Video  website or its services. The information collected or received by third party website are subject to their own privacy policies.</p>
                                        <p className="static-page-content">As stated above, Scope Video provides any user with access to content provided by Content Providers whereby any user purchasing or subscribing to subscription package provided by Scope Video on YuppTV website available at <a target="_blank" href="https://www.yupptv.com/">https://www.yupptv.com/</a> , whereby the user will further be redirected to the Partner Apps for final content consumption and the same shall be governed by the Privacy Policies of such Partner Apps. By accepting the terms and conditions and privacy policy of Scope Video, you accept and consent to the terms and conditions and privacy policy of the Partner Apps mentioned in the Terms and Conditions. Any issue or dispute arising due to the Partner Apps or their collection, storage or use of your personal information will be resolved by you directly with the respective Content Provider without the involvement of Scope Video. Scope Video shall not be liable for any consequences, damages or loss arising due to the same. </p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Collection and use of personal information</h4>
                                        <p className="static-page-content">"Personal Information" or "PII" is defined as any information that identifies (whether directly or indirectly) to a particular individual or natural person, such as the individual's name, postal address, email address, and mobile number indicating to that particular person or is identifiable. When anonymous information is directly or indirectly associated with personal information, the resulting information also is treated as personal information. </p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">User consent</h4>
                                        <p className="static-page-content">"By using Scope Video service (for example, when you register for an account, purchase a service, communicate with us, on our sites or applications), you agree to provide consent to our collection, use and sharing of your personal information as described in this policy. In some cases, particularly if you reside in a country governed by a data protection regulation, we may ask you to provide explicit consent to access our services before proceeding for further operations. </p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Collection of information</h4>
                                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                                            <li><strong>Information you provide to us</strong>
                                                <p className="static-page-content">We may ask for and collect the following personal information about you when you use Scope Videoservice or platform. Without this information we may not be able to provide you with all the requested services;</p>
                                                <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
                                                    <li>First/Last Name</li>
                                                    <li>Mobile No.</li>
                                                    <li>Email id</li>
                                                    <li>Country</li>
                                                    <li>Billing address</li>
                                                    <li>Shipping address</li>
                                                </ul>
                                            </li>
                                            <li><strong>Information we collect automatically</strong>
                                                <p className="static-page-content">We collect information about you and your use of our service, your interactions with us, as well as information about your device used to access our service. The information includes;</p>
                                                <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
                                                    <li>Activities such as language preferences, type of content, watch history, search queries etc.</li>
                                                    <li>Your interactions with our email, customer care and messaging platform.</li>
                                                    <li>Geo-location information like IP addresses,</li>
                                                    <li>Device information like device id, device model etc. and other unique identifiers</li>
                                                    <li>Cookies</li>
                                                    <li>Age</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <p className="static-page-content">If you choose to post messages on social media accounts, message boards, chat rooms or other message areas or leave feedback, we will collect that information you provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems.
                                                    If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on Scope Video , we may collect and store such information,but we would notify you prior to any further processing of that information.
                                                </p>
                                            </li>
                                            <li>
                                                <p className="static-page-content">Information we collect through third parties
                                                    An agent or affiliate of Scope Video may, at times and on being so authorized, collect information on behalf of Scope Video,Third party providers featured on or linked to by the Scope Video sites, such as vendors of products and services offered through the sites, may be gathering the same kind of information.

                                                </p>
                                            </li>
                                            <li>
                                                <p className="static-page-content">Any personal information or video content that the user voluntarily discloses online (on discussion boards, in messages and chat areas, etc.) becomes publicly available and can be collected and used by others. The Users account name (not the email address) is displayed to other users when any videos are uploaded or messages are sent through the services and other users can contact the user through messages and comments. Billing Information

                                                </p>
                                                <p className="static-page-content">Based on your purchase of a subscription package, our payment gateway partners collect information with respect to your credit/debit card details or other bank account details. This information is stored by our payment gateway partners. For instance, Scope Video will be using third party payment gateway providers to process and facilitate the payment of your subscription fee to Scope Video for providing you access to the subscription package in the manner provided in Terms and Conditions. Please note that Scope Video does not directly collect any financial information such as credit card or debit card or net banking details from you. Such payment gateway partners are not controlled by us. When you visit such payment gateways you do so at your own risk. These payment gateways may have their own privacy policies in place, which we recommend you review if you visit any such payment gateways. We do not assume any responsibility or liability for such payment gateways, the content of such payment gateways and their privacy practices, nor do we endorse them. We encourage you to familiarize yourself with the privacy statements provided by such payment gateways prior to providing them with information about you or entering into any transactions with them.</p>
                                                <p className="static-page-content">While transacting with these payment gateways, you may provide your financial information including without limitation your bank account details, credit card account details or your details pertaining to any payment settlement or pre-paid instrument service provider. You understand, agree and acknowledge that Scope Videonever receives your financial and payment information from these payment gateways. Your Personal Information, Sensitive Personal Data or Information and financial information will be dealt with by these payment gateways in accordance with their respective privacy policies and other terms and conditions and Scope Video shall not be liable, accountable or responsible for Your Personal Information, Sensitive Personal Data or Information and financial information which you provide to these payment gateways.</p>
                                                <p className="static-page-content">Except as provided herein, we do not solicit any Sensitive Information about You. However, if you share such information with us voluntarily, we will not be liable for any actions, claims, costs, expenses or other liabilities that may arise as a consequence of such any unauthorized use or misuse of such information.</p>
                                            </li>
                                            <li>
                                                <p className="static-page-content">Any personal information or video content that the user voluntarily discloses online (on discussion boards, in messages and chat areas, etc.) becomes publicly available and can be collected and used by others. The Users account name (not the email address) is displayed to other users when any videos are uploaded or messages are sent through the services and other users can contact the user through messages and comments. Billing Information

                                                </p>
                                            </li>
                                            <li><strong>Purpose and lawfulness of processing</strong>
                                                <p className="static-page-content">We will only collect and process personal data about you where we have lawful bases. Lawful bases on which we would process your personal information includes obtaining explicit consent from you for processing your personal information or processing for "legitimate interests" where processing is necessary by us to provide you with customer support or process your data for providing premium services (For e.g. processing your information by our payment gateway service providers).</p>
                                                <p className="static-page-content">We use information to provide, analyse, administer, enhance and personalize our service and marketing efforts, to process your registration, your orders, payments, and to communicate with you related to below mentioned points. For example, we use information to:</p>
                                                <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                                                    <li>Determine your general geographic location, provide localized content, provide you with customized and personalized viewing recommendations for movies and TV shows we think will be of interest to you, determine your Internet service provider, and help us quickly and efficiently respond to inquiries and requests;</li>
                                                    <li>Prevent, detect and investigate potentially prohibited or illegal activities, including fraud, and enforcing our terms (such as determining free trial eligibility);</li>
                                                    <li>Analyze and understand our audience, improve our service (including our user interface experiences) and optimize content selection, recommendation algorithms and delivery;</li>
                                                    <li>Communicate with you concerning our service (for example by email, push notifications text messaging, and online messaging channels), so that we can send you news about Scope Video, details about new features and content available on Scope Video , and special offers, promotional announcements, and consumer surveys, and to assist you with operational requests such as password reset requests.</li>
                                                    <li>Notify you about changes inTerms and Conditions.</li>
                                                    <li>Allow you to participate in interactive features offered through our Services.</li>
                                                    <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing, chargeback and collection.</li>
                                                </ul>
                                                <p className="static-page-content">We may also use your information to contact you about our own and third-party services that may be of interest to you. If you do not want us to use your information in this way, please provide explicit consent for the same when we collect your data and/or adjust your user preferences in your account profile w.r.t. your choices/preferences provided.</p>
                                            </li>
                                        </ul>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Withdrawl of consent</h4>
                                        <p className="static-page-content">"You may withdraw your consent for the collection, use and/or disclosure of your personal information in our possession or control by submitting a request on our support e-mail or using the chat functionality or by directly contacting our support team (see contact us section). We will process your request within a reasonable time from when the request was made, and thereafter not process your personal information as per your request. </p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">How Scope Video responds to do not track signals:</h4>
                                        <p className="static-page-content">We may partner with third-party service providers to serve ads regarding goods or services that may be of interest to you when you access and use Scope Video platform and third party-platform. Some of the ads on this Site or on third party platform may be personalized, meaning that they are intended to be relevant to you based on what we or the online advertising network serving the ad know about you or your computer's browsing activity on both Scope Video platform and third-party platform. To do so, these companies may place or recognize a unique cookie on your browser (including through the use of pixel tags). </p>
                                        <p className="static-page-content">The Network Advertising Initiative offers useful information about internet advertising companies, including information about how to opt-out of interest-based advertising by their members. See <a target="_blank" href="http://www.networkadvertising.org ">http://www.networkadvertising.org </a> for general information about the NAI and<a target="_blank" href=" http://www.networkadvertising.org/managing/opt_out.asp"> http://www.networkadvertising.org/managing/opt_out.asp </a>for the opt-out page. You may also visit <a target="_blank" href=" http://www.aboutads.info/consumers/"> http://www.aboutads.info/consumers/</a> to learn about interest-based advertising and how to opt-out from ads served by some or all participating companies. </p>
                                        <p className="static-page-content">These opt-out mechanisms rely on cookies to remember your choices. If you delete your cookies, use another computer or device, or change browsers, you will need to repeat this process. In addition, opting out of interest-based ads will not opt you out of all ads, but rather only those ads that are personalized to your interests. </p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Usage of information</h4>
                                        <p className="static-page-content">Scope Video uses IP address information for systems administration and troubleshooting purposes. This information is also used to deliver web pages to the subscriber upon request; to tailor the site to the interests of the subscriber and to measure traffic within the Scope Video site. </p>
                                        <p className="static-page-content"> Any personally identifiable information provided by the subscriber is used to operate, maintain, and provide the subscriber with the features and functionality of Scope Video platform.</p>
                                        <p className="static-page-content"> Scope Video does not use the subscriber email address or other personally identifiable information to send commercial or marketing messages without the subscriber consent. Scope Video may not use both personally identifiable information and certain non-personally- identifiable information to improve the quality and design of the services and to create new features, promotions, functionality, and services by storing, tracking, and analyzing subscriber preferences and trends without the subscriber consent.</p>
                                        <p className="static-page-content"> Scope Video uses cookies, clear gifs, and log file information to: remember information so that the subscriber is not required to re-enter it during subsequent visits; provide custom and personalized content and information; monitor the effectiveness of marketing campaigns; monitor logs such as total number of visitors, pages viewed, etc.; and to track entries, submissions, and status in promotions, sweepstakes, and contests of the subscriber.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Sharing and disclosure of personal information</h4>
                                        <p className="static-page-content">We do not share your personal information with companies, organizations, or individuals outside of Scope Video or YuppTV entities except in the following cases:</p>
                                        <p className="static-page-content">We’ll share or disclose your personal information outside of Scope Video or YuppTV when we have your consent. For example, providing SMS and E-mail related services such as marketing newsletters, promotions and offers.</p>
                                        <p className="static-page-content">We provide personal information to our affiliates and other trusted businesses or persons to process it for us, based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures. For example, we disclose your personal information with the customer support team to help resolve your queries.</p>
                                        <p className="static-page-content">We shall endeavour that the third parties and agents engaged by us to whom we may provide access to your Personal Information or Sensitive Personal Data or Information for the purposes set out herein are under an contractual obligation to protect your Personal Information or Sensitive Personal Data or Information in accordance with the same standard that Scope Video  has agreed to be subject to in this Privacy Policy.</p>
                                        <p className="static-page-content">In the event of any requirement by court order, government or quasi-government agency to disclose your Personal Information, we will disclose information as may be legally required. We may also disclose your Personal Information if we, in the good faith believe that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process.</p>
                                        <p className="static-page-content">In the event Scope Video  is merged with or acquired by another company or in case of re-organization or re-structuring of business, we and our affiliates will share your Personal Information, wholly or in part, with  such other business entity as aforesaid. We will notify you via e-mail or update our privacy terms if any of the above merger/acquisition/restructuring does take place</p>
                                        <p className="static-page-content">We may share non-personally identifiable information publicly and with our partners - like publishers, advertisers, developers, or rights holders. For example, we share information publicly to show trends about the general use of our services. We also allow specific partners to collect information from your browser or device for advertising and measurement purposes using their own cookies or similar technologies.</p>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Your controls and choices</h4>
                                        <p className="static-page-content">We provide you the ability to exercise certain controls and choices regarding our collection, use and sharing of your information. In accordance with applicable law, your controls and choices may include:</p>
                                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                                            <li>
                                                <h4 className="privacy-subheadding">Managing Your Information</h4>
                                                <p className="static-page-content">You can access and update some of your personal information through your account settings. If you have chosen to connect your Scope Video account to a third-party application, like Facebook or Google, you can change your settings and remove permission for the app by changing your account settings. You are responsible for keeping your personal information up-to-date. Scope Video  will send you periodic reminders via e-mail to maintain the accuracy of your personal information. We normally provide data access/update/rectification services for free but reserve the right to charge a reasonable fee if data subject request is unfounded or excessive in character.</p>
                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Rectification of Inaccurate or Incomplete Information</h4>
                                                <p className="static-page-content"> You can ask us to correct inaccurate or incomplete personal information concerning you (this is the category of information which you cannot update by yourself within your Scope Video  account) by sending us an e-mail.</p>
                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Data Access and Portability</h4>
                                                <p className="static-page-content">In some jurisdictions, applicable law may entitle you to request copies of your personal information held by us. You may also be entitled to request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format and/or request us to transmit this information to another service provider (where technically feasible).</p>
                                                <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
                                                    <li>We may retain some of your personal information as necessary for our legitimate business interests, such as fraud detection and prevention and enhancing safety. For example, if we suspend a Scope Video  account for fraud or safety reasons, we may retain certain information from that Scope Video  account to prevent that user from opening a new Scope Video account in the future.</li>
                                                    <li>We may retain and use your personal information to the extent necessary to comply with our legal obligations. For example, Scope Video may keep some of your information for tax, legal reporting and auditing obligations.</li>
                                                    <li>Information you have shared with others (e.g., reviews, forum postings) may continue to be publicly visible on the Scope Videoplatform, even after your Scope Video account is cancelled. However, attribution of such information to you will be removed. Additionally, some copies of your information (e.g., log records) may remain in our database, but are disassociated from personal identifiers.</li>
                                                    <li>Because we maintain the Scope Videoplatform to protect from accidental or malicious loss and destruction, residual copies of your personal information may not be removed from our backup systems for a limited period of time.</li>
                                                    <li>Data subject can request their right by contacting Scope Video  through customer support or email. Refer contact section for details.</li>
                                                </ul>

                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Data Retention and Erasure</h4>
                                                <p className="static-page-content">We retain your personal information as long as necessary for the performance of the contract between you and us to comply with our legal obligations. If you no longer want us to use your information then you can request that we erase your personal information and close your Scope Video  account. Please note that if you request for the erasure of your personal information; right to erasure will not be applicable under below conditions:</p>
                                                <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
                                                    <li>for exercising the right of freedom of expression and information;</li>
                                                    <li>for compliance with a legal obligation which requires processing by Union or Member State law to which the controller is subject or for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;</li>
                                                    <li>for reasons of public interest in the area of public health;</li>
                                                    <li>for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes;</li>
                                                    <li>For the establishment, exercise or defense of legal claims.</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Objection to Processing</h4>
                                                <p className="static-page-content">Where your personal information is processed for direct marketing purposes, you may, at any time ask Scope Video  to cease processing your data for these direct marketing purposes by sending an e-mail to us.</p>

                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Lodging Complaints</h4>
                                                <p className="static-page-content">You have the right to lodge complaints about the data processing activities carried out by Scope Video  before the competent data protection authorities. You can lodge a complaint with our data protection officer or with the supervisory authority. Details of the supervisory authority can be obtained by contacting our DPO.</p>
                                                <p className="static-page-content">Please be aware that if you do not allow us to collect personal information from you, we may not be able to deliver certain experiences, products, and services to you, and some of our services may not be able to take account of your interests and preferences. If collection of personal information is mandatory, we will make that clear at the point of collection so that you can make an informed decision whether to participate. If you have questions about the specific personal information about you that we process or retain, and your rights regarding that personal information, please contact our customer support team.</p>

                                            </li>
                                            <li>
                                                <h4 className="privacy-subheadding">Withdrawal of Consent</h4>
                                                <p className="static-page-content">You may withdraw your consent for the collection, use and/or disclosure of your personal information in our possession or control by submitting a request on our support e-mail or using the chat functionality or by directly contacting our support team (see contact us section). We will process your request within a reasonable time from when the request was made, and thereafter not process your personal information as per your request.</p>
                                            </li>
                                        </ul>

                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Children's Consent</h4>
                                        <p className="static-page-content">Scope Video  platform is not intentionally designed for or directed at persons less than 18 years of age. Scope Video  does not knowingly permit any person who is under 18years of age to register with the services or to provide any other personally identifying information. If Scope Video  becomes aware that any personally identifiable information of persons less than 18 years of age has been collected on the Scope Video platform without verified parental consent, then Scope Video  will take the appropriate steps to delete any such information. However, we consider it the responsibility of parents to monitor their children's use of our services. Nevertheless, it is our policy not to require personal information from children below 18 years or offer to send any promotional materials to persons in that category. Scope Video  does not seek or intend to seek or to receive any personal information from children. Should a parent or guardian have reasons to believe that a minor has provided Scope Video with personal information without their prior consent, please contact our customer support team to ensure that the personal information is removed from Scope Video. </p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Data Transfers, Storage and Processing Globally</h4>
                                        <p className="static-page-content">We operate globally and may transfer your personal information to individual companies of Scope Video  or third parties in locations around the world for the purposes described in this Privacy Policy and which the countries may have data protection laws that differ from those of your country. Wherever your personal information is transferred, stored or processed by us, we will take reasonable steps to safeguard the privacy of your personal information. Additionally, when using or disclosing personal information transferred from the European Union, we use standard contractual clauses approved by the European Commission, adopt other means under European Union law for ensuring adequate safeguards.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Storage of Information</h4>
                                        <p className="static-page-content">Any information provided by the subscriber to Scope Video  shall be stored and held under generally accepted standards of technology and operational security in order to protect the subscriber personally identifiable information from loss, misuse, alteration, or destruction. Only authorized personnel are permitted access to the subscriber information and these personnel are required to treat this information as confidential. Despite these precautions, Scope Video  cannot guarantee that any and all unauthorized access to the subscriber information will be prevented.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Security of Information</h4>
                                        <p className="static-page-content">We are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorized access, loss, destruction, or alteration. Some of the safeguards we use to protect your information are firewalls and data encryption, and information access controls. If you know or have reason to believe that your Scope Video  account credentials have been lost, stolen, altered, or otherwise compromised or in case of any actual or suspected unauthorized use of your account, please contact us by contacting our customer support team. Please be aware that, despite our best efforts, no security measures are perfect or impenetrable. By continuing to access the Scope Video  platform you are aware of such risk.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Modifications</h4>
                                        <p className="static-page-content">Scope Video may update or revise this Privacy Policy from time to time. Please take a look at the "Effective Date" legend at the top of this page to see when this Privacy Policy was last revised. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy. Scope Video will also notify you of any material changes to this Privacy Policy. Your use of the Scope Video  platform following any changes to this Privacy Policy means you accept the revised Privacy Policy.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Change in Control</h4>
                                        <p className="static-page-content">In the event that Scope Video is acquired by or merged with a third party entity, Scope Video, subject to applicable law, reserves the right, in any of these circumstances, to transfer or assign the information collected from the users/subscribers as part of such merger, acquisition, sale, or other change of control. In the event of bankruptcy, insolvency, reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or equitable principles affecting creditors' rights generally, Scope Video  may have to share/transfer your personal information with the other party. In this scenario, we may not be able to control how the Personal Information is treated, transferred, or used.</p>
                                    </li>
                                    <li>
                                        <h4 className="privacy-subheadding">Contact Information</h4>
                                        <p className="static-page-content">Scope Video  shall timely address and attempt to rectify any discrepancies and grievances of our users pertaining to the personal information furnished by them.</p>
                                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                                            <li>Subscriber may contact Scope Video  with any questions or comments about this Privacy Policy and may address such queries and / or comments to <a href="mailto:privacy@yupptv.com">privacy@yupptv.com</a></li>
                                            <li>If you have any queries or complaints regarding the collecting, processing, transfer of personal data/information please do contact us online through <a href="mailto:name@rapidtables.com">privacy@yupptv.com</a></li>
                                            <li>Residents in the countries in the European Union (EU), please note:
                                                <ul style={{ listStyleType: "square", marginLeft: "20px" }}>
                                                    <li>You may contact our Data Protection Officer at  <a href="mailto:dpo@yupptv.com">dpo@yupptv.com.</a></li>
                                                    <li>The data controller for Scope Video  will be YuppTV USA Inc. based out of 11175 Cicero Dr, Suite #100, Alpharetta, GA 30022 USA</li>
                                                    <li>The data controller for Scope Video  will be YuppTV USA Inc. based out of 11175 Cicero Dr, Suite #100, Alpharetta, GA 30022 USA</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Residents in India may contact our Grievance Officer, Mr. Roop Kumar Reddy, at <a href="mailto:roop@yupptv.com">roop@yupptv.com.</a>  As per applicable Indian laws, the Grievance Officer shall redress the grievances of the users furnishing the Information within one (1) month from the date of receipt of the grievance.
                                    </li>

                                </ul>
                            </>
                        }
                    </div>
                </div>
                <Footer />

            </div>
        )
    }
}

export default PrivacyPolicy;