import React from "react";
import { useState } from "react";

export default function ISPPreview(props) {
    const [confirmchecked, setConfirmchecked] = useState(false)

    function handleChange() {
        setConfirmchecked(!confirmchecked)
        document.getElementById("chkConfirm").classList.remove("login-error-msg");
    }
    function submitform() {
        if (confirmchecked) {
            return props.previewnext("submit", props.details)
        } else {
            document.getElementById("chkConfirm").classList.add("login-error-msg");
            return false;
        }
    }
    return (
        <div className="register_container mb-4">
            <div className="steper_block mobile_help_class">
                <h4>Partner with us</h4>
                <p>Please follow the steps for registration</p>
                <div className="steps_content">
                    <div className="register_step step_complete ">
                        <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Terms & Conditions ----------</span>
                    </div>
                    <div className="register_step step_complete">
                        <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Enter Details ----------</span>
                    </div>
                    <div className="register_step step_progress">
                        <div className="register_num">3</div> <span>Preview ----------</span>
                    </div>
                    <div className="register_step">
                        <div className="register_num">4</div> <span>Confirmation</span>
                    </div>

                </div>
            </div>
            <div className="steps_content mobile_step">
                <div className="register_step step_complete ">
                    <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Terms & Conditions </span>
                </div>
                <div className="register_step step_complete">
                    <div className="register_num"><img src="https://yuppstatic.akamaized.net/yupptv/bsnl/1aug2023/success_step.svg" /></div> <span>Enter Details</span>
                </div>
                <div className="register_step step_progress">
                    <div className="register_num">3</div> <span>Preview </span>
                </div>
            </div>
            <div className="register_card">
                <div className="row">
                    <div className="col-md-12">
                        <h3>Organization Details</h3>
                    </div>
                </div>

                <div className="row ispinputs reg_details">
                    <div className="col-md-6">
                        <label>Company Name*</label>
                        <h5>{props.details["Company Name"]}</h5>
                    </div>
                    <div className="col-md-6">
                        <label>Authority Name*</label>
                        <h5 >{props.details["Authority"]}</h5>
                    </div>
                    <div className="col-md-6">
                        <label>Email ID*</label>
                        <h5 >{props.details["Email ID"]}</h5>
                    </div>
                    <div className="col-md-6">
                        <label>Phone Number*</label>
                        <h5 >{props.details["Phone Number"]}</h5>
                    </div>
                    <div className="col-md-6">
                        <label>CIN Number</label>
                        <h5>{props.details["CIN Number"]}</h5>
                    </div>
                    <div className="col-md-6">
                        <label>ISP License*</label>
                        <h5 >{props.details["ISP License"]}</h5>
                    </div>
                    <div className="col-md-6">
                        <label>PAN Card Number*</label>
                        <h5>{props.details["PAN Number"]}</h5>
                    </div>
                    <div className="col-md-6">
                        <label>GST Number*</label>
                        <h5 >{props.details["GST Number"]}</h5>
                    </div>
                    <div className="col-md-6">
                        <label>State*</label>
                        <h5 >{props.details["State"]}</h5>
                    </div>
                    <div className="col-md-6">
                        <label>Company Address*</label>
                        <h5 >{props.details["Company Address"]}</h5>
                    </div>
                    <div className="col-md-12">
                        <div className="terms_check">
                            <input type="checkbox" className="form-check-input customcheckbox" name="check" id="chkConfirm" onChange={handleChange.bind()} />
                            <label className="form-check-label" htmlFor="chkConfirm">Please confirm your details, so that we can save it in our system</label>
                        </div>
                    </div>
                    {
                        props.regerror &&
                        <div className="col-md-12 mt-4">
                            <div class="alert alert-warning" role="alert">
                                <i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i> {props.errormsg}
                                {/* //Your account is already registered. */}
                            </div>
                        </div>
                    }
                </div>
                <div className="ispregisterbtns">
                    <button className="pervious_btn" onClick={() => { return props.previewnext("edit", props.details) }} >EDIT</button>
                    <button className="next_btn" onClick={submitform.bind()}>SUBMIT</button>
                </div>
            </div>
            <div className="steps_content mobile_step">
                <div className="register_step">
                    <div className="register_num">4</div> <span>Confirmation</span>
                </div>
            </div>
        </div>
    )
}