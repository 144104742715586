import React from 'react';
import '../help/help.css';
import Header from '../header/header';
import Footer from '../footer/footer.js';
import { Link } from 'react-router-dom';
import Config from '../shared/config';
import $ from 'jquery';

class Isppartnerships extends React.Component {
    constructor(props) {
        super(props)
    }

    handleKeyPressMobile = (event) => {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode = 47 && charCode > 31 && (charCode < 48 || charCode > 57))
            event.preventDefault();

        return true;
    }

    handleKeyup = (event) => {
        event.target.value = event.target.value.replace(/[^\d]/g, '');
        return false;
    }

    sendmail(event) {
        event.preventDefault();
        let url = Config.EmailURL;
        let username = document.getElementById('username').value;
        let email = document.getElementById('email').value;
        let phone = document.getElementById('phoneno').value;
        let company = document.getElementById('company').value;
        //let message = document.getElementById('message').value;
        var contentdata = "<html><table><tr><td>User Name :</td><td>" + username + "</td></tr><tr><td>Email :</td><td>" + email + "</td></tr><tr><td>Phone No :</td><td>" + phone + "</td></tr><tr><td>Company :</td><td>" + company + "</td></tr></table></html>";
        var bodyFormData = new FormData();
        bodyFormData.append('content', contentdata);
        bodyFormData.append('context', 'request-demo');
        fetch(Config.EmailURL, {
            method: "POST",
            body: bodyFormData
        }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status == true) {
                    document.getElementById('username').value = ''
                    document.getElementById('email').value = ''
                    document.getElementById('phoneno').value = ''
                    document.getElementById('company').value = ''
                    //document.getElementById('message').value = ''
                }
                $('#message_success').modal('show');
            })
            .catch(error => {
                console.log(error);

            });
    }

    render() {
        return (
            <div>
                <Header showmenuitem={false} />
                <div>
                    <div className="partner_with_us">
                        <div className="container-partner">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="partner_main_tilte">Partner with us</h4>
                                    <p className="partner_desc">We are a pioneer in entertainment industry with over 15+ yrs of experience in  serving Live & OTT content globally. Features you’ll get:</p>
                                </div>
                            </div>
                            <div className="row partner_points">
                                <div className="col-md-6 divider_partner  pad_right_90 ">
                                    <ul>
                                        <li><h5>Extensive OTT Industry Experience:</h5>
                                            <p>With over 15+ years of experience, we are pioneers in the OTT industry, providing exceptional content globally.</p>
                                        </li>
                                        <li><h5>Simplified Subscription:</h5>
                                            <p>Access Live TV and OTT content from multiple apps through a single subscription, offering convenience and variety in one package.</p>
                                        </li>
                                        <li><h5>Co-Branded OTT Application:</h5>
                                            <p>Increase brand loyalty among your customers by having a co-branded OTT application, strengthening your brand identity and engagement.</p>
                                        </li>
                                        <li><h5>Diverse Content Library:</h5>
                                            <p>Enjoy 150+ Live TV channels from YuppTV, providing a traditional live TV experience. Additionally, access 300+ Premium Channels, 1000+ TV Shows, 500+ Originals, and 10,000+ Movies from our partner apps, ensuring a wide range of entertainment options.</p>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-6 pad_left_90">
                                    <ul>
                                        <li><h5>24x7 Customer Support:</h5>
                                            <p>Our dedicated customer support team is available round the clock to assist with subscription and device activation issues, ensuring a smooth and hassle-free experience for users.</p>
                                        </li>
                                        <li><h5>Clear Terms and Conditions:</h5>
                                            <p>We provide transparent legal and terms & conditions disclosures. Users must read and accept all the terms before proceeding, ensuring clarity and compliance.</p>
                                        </li>
                                        <li><h5>User-Friendly Sign-Up Process:</h5>
                                            <p>The sign-up process is straightforward. Users need to provide their details and go through the payment process (minimum: Rs 10,000 pay as you go). Upon completion, users receive a sign-in ID and a resettable password for easy access.</p>
                                        </li>
                                        <li><h5>User Manual:</h5>
                                            <p>A comprehensive user manual is available for reference, providing guidance on using our services effectively.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="partner_register_btn">
                                    <a href="/registration"> <div className="btn_orange">REGISTER NOW</div></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show bd-example-modal-lg" id="message_success" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog modal-sm-c" role="document">
                        <div className="modal-content">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                            <div className="modal-body">
                                <div className="text-center help_class">
                                    <img src={'https://yuppstatic.akamaized.net/yupptv/images/activation-success.png'} alt="Logo" className="mob-logo2" />
                                    <h4>Message sent successfully!</h4>
                                    <p className="mb-4 mt-2">Thanks for reaching out to us. Our team will get back to you shortly for further discussions.</p>
                                    <div className=" mt_50"><a href={Config.BaseRootURL} className="go_home ">Go to home</a></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Isppartnerships;