import React from 'react';
import './header.css';
import Config from '../shared/config.js';
import DataService from '../shared/service';
import Cookies from 'universal-cookie';
import '../popup/popup.css';
import CryptoJS from 'crypto-js';
import $ from 'jquery';
import ActivationSteps from '../help/activationsteps';
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resp: null,
      isLoaded: true,
      partners: []
    };
    this.dataService = new DataService();
  }
  componentDidMount() {
    const cookies = new Cookies();
    var token = cookies.get('sessionid');
    var BoxId = cookies.get('boxid');
    //Token verification 
    if (BoxId == null && token == null) {
      // BoxId =  this.dataService.createGuid().replace("-", "").substring(0, 16);
      // cookies.set('boxid', BoxId, { path: '/' });
      // this.dataService.getToken()
    }
    //Encrypted number 
    var encnumber = cookies.get('number');
    if (encnumber == null) {
      //document.getElementById("ancSignin1").style.display="block";
      //document.getElementById("ancSignin2").style.display="block";
      document.getElementById("divhdr1").style.display = "none";
      document.getElementById("divhdr2").style.display = "none";
    }
    else {
      var bytes = CryptoJS.AES.decrypt(encnumber.toString(), Config.secreteKey);
      var number = bytes.toString(CryptoJS.enc.Utf8);
      //document.getElementById("ancSignin1").style.display="none";
      //document.getElementById("ancSignin2").style.display="none";
      document.getElementById("ancPhone1").innerHTML = number;
      document.getElementById("ancPhone2").innerHTML = number;
      document.getElementById("divhdr1").style.display = "block";
      document.getElementById("divhdr2").style.display = "block";
    }
    //Get package partners list 
    // let domain = Config.APIURL;  
    // this.dataService.httpGet(domain + "package/api/external/v2/partner/list")
    //     .then(responseData=> {
    //     if(responseData!== undefined)
    //     { 
    //             console.log(responseData.response);
    //         this.setState({
    //             partners : responseData.response
    //         })
    //     } 
    //     })
  }
  //toggle pakage partners popup
  togglePopup() {
    const cookies = new Cookies();
    var partnerCookie = cookies.get('partnerCookie');
    if (partnerCookie !== "1") {
      document.getElementById("myModal").style.display = 'block';
      document.body.classList.add("modal-open");
    }
    else {
      window.location.href = Config.BaseRootURL + "packages";
    }
  }
  //setting cookie for package partners 
  proceedClick() {
    const cookies = new Cookies();
    var partvalue;
    for (var i = 0; i < document.getElementsByName("partner").length; i++) {
      if (document.getElementsByName("partner")[i].checked) {
        partvalue = document.getElementsByName("partner")[i].classList[0];
      }
    }
    cookies.set('partnerCookie', "1", { path: '/' });
    cookies.set('partnerId', partvalue, { path: '/' });
    document.body.classList.remove("modal-open");
    window.location.href = "/packages";
  }
  // close for package partners popup 
  closeClick() {
    document.body.classList.remove("modal-open");
    document.getElementById("myModal").style.display = 'none';
  }
  //Radio change for header text 
  radioOnChange(partnerName) {
    var part = document.getElementById(partnerName);
    part.checked = true;
    if (part !== null && part.checked) {
      document.getElementById("headText").innerHTML = 'You selected "' + part.value + '" Click on proceed to view plans';
    }
  }
  //displaying package partners
  getPartners(partner) {
    if (partner !== null) {
      if (partner.code === "bsnl") {
        return (
          <li className="round" key={partner.id} onClick={this.radioOnChange.bind(this, partner.code)}><span style={{ cursor: "pointer" }}>{partner.name}</span>
            <input type="radio" id={partner.code} value={partner.name} name="partner" className={partner.id} defaultChecked />
            <label htmlFor={partner.code}></label>
          </li>
        )
      }
      else {
        return (
          <li className="round" key={partner.id} onClick={this.radioOnChange.bind(this, partner.code)}><span style={{ cursor: "pointer" }}>{partner.name}</span>
            <input type="radio" id={partner.code} value={partner.name} name="partner" className={partner.id} />
            <label htmlFor={partner.code}></label>
          </li>
        )
      }
    }
  }
  //after click on Signin in header 
  headerSigninClik() {
    var signText = document.getElementById("ancSignin1").innerHTML;
    if (signText === "Sign in") {
      //document.getElementById("myModal").style.display= 'block'; 
      window.location.href = Config.BaseRootURL + "signin";
    }
    else {
      window.location.href = Config.BaseRootURL + "myaccount";
    }
  }
  //signout and redirected to home page 
  signoutClick() {
    const cookies = new Cookies();
    let URL = "";
    URL = Config.APIURL + "service/api/auth/signout";
    this.dataService.httpPostWithHeaderData(URL, "null")
      .then(responseData => {
        cookies.remove('boxid');
        cookies.remove('sessionid');
        cookies.remove('number');
        if (responseData !== undefined) {
        }
      })
    cookies.remove('boxid');
    cookies.remove('sessionid');
    cookies.remove('number');
    cookies.remove('partnerId');
    cookies.remove('partnerCookie');
    window.location.href = Config.BaseRootURL;
  }
  openNav() {
    document.getElementById("mySidenav").classList.add("small-slider");
    document.getElementsByTagName("body")[0].style = "overflow:hidden";
    document.getElementById("mobbgclose").classList.add("mob-body-bg");
    document.getElementsByClassName("mob-body-bg")[0].style = "display:block";
  }
  closeNav() {
    //document.getElementById("mySidenav").style.width = "0";
    document.getElementsByTagName("body")[0].style = "overflow:normal";
    document.getElementById("mySidenav").classList.remove("small-slider");
    // document.getElementsByTagName("body")[0].classList.remove("mob-body-bg");
    document.getElementById("mobbgclose").classList.remove("mob-body-bg");
  }
  myFunction() {
    var x = document.getElementById("myDIV");
    document.getElementById("arotate").classList.toggle("arotate1");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }
  changePosition(id) {
    if (id == 'feature') {
      var elmnt = document.getElementById("featuresBlock");
      elmnt.scrollIntoView();
      document.getElementById("featuretag").classList.add('active');
      document.getElementById("devicetag").classList.remove('active');
      document.getElementById("plantag").classList.remove('active');
      document.getElementById("hometag").classList.remove('active');
    } else if (id == 'device') {
      var elmnt = document.getElementById("devicesBlock");
      elmnt.scrollIntoView();
      document.getElementById("devicetag").classList.add('active');
      document.getElementById("featuretag").classList.remove('active');
      document.getElementById("plantag").classList.remove('active');
      document.getElementById("hometag").classList.remove('active');
    }
    else if (id == 'plan') {
      var elmnt = document.getElementById("scopeSubscription");
      elmnt.scrollIntoView();
      document.getElementById("plantag").classList.add('active');
      document.getElementById("featuretag").classList.remove('active');
      document.getElementById("devicetag").classList.remove('active');
      document.getElementById("hometag").classList.remove('active');
    }
    else {
      var elmnt = document.getElementById("tvChannelBlock");
      elmnt.scrollIntoView();
      document.getElementById("hometag").classList.add('active');
      document.getElementById("featuretag").classList.remove('active');
      document.getElementById("devicetag").classList.remove('active');
      document.getElementById("plantag").classList.remove('active');
    }
  }

  handleKeyPressMobile = (event) => {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode = 47 && charCode > 31 && (charCode < 48 || charCode > 57))
      event.preventDefault();

    return true;
  }

  handleKeyup = (event) => {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
    return false;
  }

  openpartnermodal() {
    document.getElementById('username').value = ''
    document.getElementById('email').value = ''
    document.getElementById('phoneno').value = ''
    document.getElementById('company').value = ''
    $('#isppartnership').modal('show');
  }
  sendmail(event) {
    event.preventDefault();
    let username = document.getElementById('username').value;
    let email = document.getElementById('email').value;
    let phone = document.getElementById('phoneno').value;
    let company = document.getElementById('company').value;
    let message = '';
    var contentdata = "<html><table><tr><td>User Name :</td><td>" + username + "</td></tr><tr><td>Email :</td><td>" + email + "</td></tr><tr><td>Phone No :</td><td>" + phone + "</td></tr><tr><td>Company :</td><td>" + company + "</td></tr><tr><td>Message :</td><td>" + message + "</td></tr></table></html>";
    var bodyFormData = new FormData();
    bodyFormData.append('content', contentdata);
    bodyFormData.append('context', 'request-demo');
    fetch(Config.EmailURL, {
      method: "POST",
      body: bodyFormData
    }).then((response) => response.json())
      .then((responseData) => {
        if (responseData.status == true) {
          document.getElementById('username').value = ''
          document.getElementById('email').value = ''
          document.getElementById('phoneno').value = ''
          document.getElementById('company').value = ''
          //document.getElementById('message').value = ''
        }
        $('#isppartnership').modal('hide');
        $('#message_success').modal('show');
      })
      .catch(error => {
      });
  }
  render() {
    return (
      <div>
        {/* 
   <div className="header-top">
      <div className="container-fluid">
         <ul className="top-nav">
            <li><a href="help/waystowatch">Ways to watch</a></li>
            <li> <a href="help/whyus">Why Us</a></li>
            <li>Call Us: 07411774117</li>
         </ul>
      </div>
   </div>
   */}
        <div className="menu-height">
          <div className="header-bottom">
            <div className="custom-container1">
              <div className="row mx-0 d-flex justify-content-between">
                <div className="menu-mobile d-xl-none" id="mySidenav">
                  <div className="mobile-top-nav">
                    <a href={Config.BaseRootURL}> <img src="https://yuppstatic.akamaized.net/yupptv/yupptvscope/28feb2023/videonewlogo.png" alt="Logo" className="mob-logo" /></a>
                    <div><a className="close" onClick={this.closeNav.bind(this)}></a></div>
                    {/* 
                     <div><a className="btn signin-btn" onClick={this.headerSigninClik.bind(this)} id="ancSignin1">Sign in</a></div>
                     */}
                    <div className="dropdown" id="divhdr1">
                      <a href="#" onClick={this.myFunction}>
                        <img src={require('../images/user.svg')} />
                        <div className="dropdown-user" id="ancPhone1"></div>
                        <span><img id="arotate" className="arotate1" src={require('../images/down-arrow.svg')} /></span>
                      </a>
                      <div className="dropdown-container" id="myDIV">
                        <a href={Config.BaseRootURL + "myaccount"}>My Account</a>
                        <a onClick={this.signoutClick.bind(this)}>Log out</a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ul className="mob-left-menu">
                      {/* 
                        <li><a onClick={()=>this.changePosition('home')} style={{cursor:"pointer"}}>Home</a></li>
                        <li><a onClick={()=>this.changePosition('feature')} style={{cursor:"pointer"}}>Features</a></li>
                        <li><a onClick={()=>this.changePosition('device')} style={{cursor:"pointer"}}>Devices</a></li>
                        <li><a onClick={()=>this.changePosition('plan')} style={{cursor:"pointer"}}>Plans</a></li>
                        */}
                      {/* 
                        <li><a href="/livetvchannels" className={this.props.active === "livetvchannels" ? "left-bar-selected" : ""}>LiveTV Channels</a></li>
                        <li><a href="help/activationsteps" className={this.props.active === "activationsteps" ? "left-bar-selected" : ""}>Activation Steps</a></li>
                        */}
                      {/* <a href="https://www.scopevideo.com/" target="_blank" className="watch-btn">eeee<i class="fa fa-play"></i> </a> */}
                      {/* 
                        <li><a>Call us: 07411774117</a></li>
                        */}
                      <li className="nav-item"><a className={"nav-link yupptv-link watch-btn"} style={{ cursor: 'pointer' }} href={Config.BaseRootURL + 'isppartnerships'}>ISP Partnership</a></li>
                      <li className="nav-item"><a className={"nav-link yupptv-link watch-btn"} style={{ cursor: 'pointer' }} href="https://www.scopevideo.com" target='_blank' >Watch Now</a></li>
                    </ul>
                  </div>
                </div>
                {/* 
               <div className="col-1 col-sm-1 col-md-1 px-0 ham-menu d-xl-none">
                  <div className="logo float-left"> 
                     <a style={{cursor:"pointer"}} onClick={this.openNav.bind(this)}> <img src={require('../images/hamburg-menu.png')} className="ham-fix" alt="Logo"/></a>
                  </div>
               </div>
               */}
                <div className="col-3 col-sm-3 col-xs-6 col-md-1 px-0 logo-fix">
                  <div className="logo float-left d-flex">
                    <a className="mr-3 d-lg-none" style={{ width: '60px' }} onClick={this.openNav.bind(this)}> <img src={'/menu_mobile.svg'} alt="Logo" className="mob-logo-fix" /></a>
                    <a href={Config.BaseRootURL} style={{ marginTop: '3px' }}> <img src="https://yuppstatic.akamaized.net/yupptv/yupptvscope/28feb2023/videonewlogo.png" alt="Logo" className="mob-logo-fix" /></a>
                  </div>
                </div>
                <div className="col-9 col-md-10 col-lg-10 right-header" style={{ paddingRight: 0 }}>
                  {
                    this.props.showmenuitem == true &&
                    <ul className="nav float-right">
                      {/* <li className="nav-item"><a id="hometag" className={this.props.active ==="home" ? "nav-link yupptv-link active" : "nav-link yupptv-link"} onClick={()=>this.changePosition('home')}>Home</a></li> */}
                      {/* <li className="nav-item"><a href="#"  className={this.props.active==="packages" ? "nav-link yupptv-link active" : "nav-link yupptv-link"}  onClick={this.togglePopup.bind(this)} >OTT PLANS</a></li>  */}
                      {/* <li className="nav-item"><a id="featuretag" className={"nav-link yupptv-link"} onClick={()=>this.changePosition('feature')} >Features</a></li>
                     <li className="nav-item"><a id="devicetag" className={"nav-link yupptv-link"} onClick={()=>this.changePosition('device')} >Devices</a></li>
                     <li className="nav-item"><a id="plantag" className={"nav-link yupptv-link"} onClick={()=>this.changePosition('plan')} >Plans</a></li> */}
                      <li className="nav-item"><a id="active" className={"nav-link yupptv-link watch-btn"} style={{ cursor: 'pointer' }} href={Config.BaseRootURL + 'registration'}>ISP Partnership</a></li>
                      <li className="nav-item"><a id="active" className={"nav-link yupptv-link watch-btn"} style={{ cursor: 'pointer' }} href="https://www.scopevideo.com" target='_blank'>Watch Now</a></li>
                    </ul>
                  }
                </div>
                <div className="col-1 col-sm-1 col-md-4 pt10lr0 d-none" style={{ display: 'none' }}>
                  <div className="header-right">
                    {/* 
                     <div><a className="btn signin-btn" onClick={this.headerSigninClik.bind(this)} id="ancSignin2">Sign in</a></div>
                     */}
                    <div className="dropdown" id="divhdr2">
                      <a href="#">
                        <img src={require('../images/user.svg')} className="pro-person-icon" />
                        <div className="dropdown-user" id="ancPhone2"></div>
                        <span><img src={require('../images/down-arrow.svg')} className="arrow-menu-icon" /></span>
                      </a>
                      <div className="dropdown-container">
                        <a href={Config.BaseRootURL + "myaccount"}>My Account</a>
                        <a onClick={this.signoutClick.bind(this)}>Log out</a>
                      </div>
                    </div>
                    {/* 
                     <div className="call-action">Call Us: 07411774117</div>
                     */}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal" role="dialog" id="myModal" aria-modal="true" data-toggle="model">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Choose your Operator</h4>
                    <h5 id="headText" className="popsub-headding">You selected “BSNL” Click on proceed to view plans</h5>
                    <input type="button" className="close" onClick={this.closeClick.bind(this)} />
                  </div>
                  <div className="modal-body">
                    <ul className="operator-btns-container">
                      {this.state.partners.map(partner => (
                        this.getPartners(partner)
                      ))
                      }
                    </ul>
                    <div className="form-group">
                      <input type="button" className="form-control btn btn-danger" value="Proceed" onClick={this.proceedClick.bind(this)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
        {/* partner popup */}
        {/* <div className="modal fade show bd-example-modal-lg" id="isppartnership" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6 pop_divider">
                    <p className="Partner-with-us mb-3">Partner with us</p>
                    <h4 className="Partner-with-us_head mb-2">We are a pioneer in OTT industry with over 15+ yrs of experience in serving content globally.</h4>

                    <ul className="partner-with-us_points">
                      <li>Single subscription to watch Live TV & OTT content from multiple apps.</li>
                      <li>Co-branded OTT application to increase brand loyalty among your customers.</li>
                      <li>150+ Live TV channels from YuppTV providing traditional live tv experience.</li>
                      <li>300+ Premium Channels, 1000+ TV Shows, 500+ Originals & 10000+ Movies from partner apps.</li>
                      <li>24x7 customer support to resolve subscription & device activation issues.</li>
                    </ul>
                  </div>
                  <div className="col-md-6 mob_mt_20">
                    <p className="partner_tit">Let's talk! Share your contact info. </p>
                    <form onSubmit={this.sendmail.bind(this)}>
                      <div >
                        <label for="username" class="col-form-label">Name*</label>
                        <input type="text" className="form-control" name="username" id="username" required />
                      </div>
                      <div >
                        <label for="email" className="col-form-label">Email Address*</label>
                        <input type="email" className="form-control" id="email" required />
                      </div>
                      <div >
                        <label for="phoneno" className="col-form-label">Phone number*</label>
                        <input type="text" className="form-control" autoComplete="off" onKeyPress={this.handleKeyPressMobile.bind(this)} onKeyUp={this.handleKeyup.bind(this)} maxLength={10} id="phoneno" required />
                      </div>
                      <div >
                        <label for="company" className="col-form-label">Company name*</label>
                        <input type="text" className="form-control" id="company" required />
                      </div>
                      <div className="form-group mt-4">
                        <button className="form_btn text-white" type="submit">Request Demo</button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div> */}
        {/* Message sent successfully! pop */}
        {/* <div className="modal fade show bd-example-modal-lg" id="message_success" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div className="modal-dialog modal-sm-c" role="document">
            <div className="modal-content">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
              <div className="modal-body">
                <div className="text-center help_class">
                  <img src={'https://yuppstatic.akamaized.net/yupptv/images/activation-success.png'} alt="Logo" className="mob-logo2" />
                  <h4>Message sent successfully!</h4>
                  <p className="mb-4 mt-2">Thanks for reaching out to us. Our team will get back to you shortly for further discussions.</p>
                  <div className=" mt_50"><a href="" className="go_home ">Go to home</a></div>
                </div>
              </div>

            </div>
          </div>
        </div> */}
      </div>
    )
  }
}
export default Header;
var $trigger = $(".ham-fix");
$('body').click(function (evt) {
  if (evt.target.id == $trigger[0])
    return;
  //For descendants of menu_content being clicked, remove this check if you do not want to put constraint on descendants.
  if ($(evt.target).closest('#mySidenav').length)
    return;
  if (document.getElementById("mySidenav") != null) {
    document.getElementById("mySidenav").classList.remove("small-slider");
  }
  if (document.getElementById("mobbgclose") != null) {
    document.getElementById("mobbgclose").classList.remove("mob-body-bg");
    document.getElementsByTagName("body")[0].style = "overflow:normal";
  }
});