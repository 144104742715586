import React from 'react';
import './home.css';
import Config from '../shared/config.js';
import Cookies from 'universal-cookie';
import { contains } from 'jquery';
import $ from 'jquery';
import data from '../shared/data/images.json';
import partnerdata from '../shared/data/contentpartners.json';

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      countryCode: ""
    }
  }

  componentDidMount() {
    fetch(Config.LocationAPI + "?tenant_code=bott&product=bott&client=Web",)
      .then((res) => res.json()).then((response) => {
        if (!!response) {
          this.setState({ countryCode: response?.ipInfo?.countryCode == "IN" ? response?.ipInfo?.countryCode : ((response?.ipInfo?.countryCode == "SG" || response?.ipInfo?.countryCode == "MY") ? "SG" : "Others") })
        }
      })
  }

  togglePopup() {
    const cookies = new Cookies();
    var partnerCookie = cookies.get('partnerCookie');

    if (partnerCookie !== "1") {
      document.getElementById("myModal").style.display = 'block';
    }
    else {
      window.location.href = Config.BaseRootURL + "packages";
    }
  }
  openpartnermodal() {
    document.getElementById('username').value = ''
    document.getElementById('email').value = ''
    document.getElementById('phoneno').value = ''
    document.getElementById('company').value = ''
    $('#isppartnership').modal('show');
  }
  sendmail(event) {
    event.preventDefault();
    let url = Config.EmailURL;
    let username = document.getElementById('username').value;
    let email = document.getElementById('email').value;
    let phone = document.getElementById('phoneno').value;
    let company = document.getElementById('company').value;
    let message = document.getElementById('message').value;
    var contentdata = "<html><table><tr><td>User Name :</td><td>" + username + "</td></tr><tr><td>Email :</td><td>" + email + "</td></tr><tr><td>Phone No :</td><td>" + phone + "</td></tr><tr><td>Company :</td><td>" + company + "</td></tr><tr><td>Message :</td><td>" + message + "</td></tr></table></html>";
    var bodyFormData = new FormData();
    bodyFormData.append('content', contentdata);
    bodyFormData.append('context', 'request-demo');
    fetch(Config.EmailURL, {
      method: "POST",
      body: bodyFormData
    }).then((response) => response.json())
      .then((responseData) => {
        if (responseData.status == true) {
          document.getElementById('username').value = ''
          document.getElementById('email').value = ''
          document.getElementById('phoneno').value = ''
          document.getElementById('company').value = ''
          document.getElementById('message').value = ''
        }
      })
      .catch(error => {
        console.log(error);

      });
  }


  render() {
    return (
      <div>
        <section className="slider-area slider-bg black-bg">
          <div className="custom-container1">
            <div className="slider-overflow">
              <div className="row d-flex justify-content-between">
                <div className="col-xl-6 col-lg-6  d-lg-none">
                  {
                    this.state.countryCode != "" &&
                    <div className="">
                      <img src={data.images.filter(obj => obj.country == this.state.countryCode)[0].data.filter(img => img.title == "banner")[0].data[0]} alt="Brand" className="img-fluid" />
                    </div>
                  }
                </div>
                <div className="col-xl-5 col-lg-5">
                  <div className="slider-content">
                    <h2 className="wow slideInLeft" data-wow-delay="0.2s">Enjoy Live TV & OTT in one app, one subscription. </h2>
                    <p className="wow slideInLeft lin_he_20" data-wow-delay="0.4s">Discover entertainment from 300+ Live channels, 500+ TV Shows, 5000+ Movie & 100+ Games.</p>
                    <a href={Config.BaseRootURL + 'registration'} className="btn wow slideInLeft">Partner With US</a>
                    <p className="pb-0 our_cntPart mt-4">Our content partners</p>
                    {
                      this.state.countryCode != "" &&
                      <div className="row cust_ch_logo justify-content-center d-lg-none md_hide">
                        {
                          partnerdata.partners.filter(obj => obj.country == this.state.countryCode)[0].data.map((item, index) =>
                            <img src={item.image} alt={item.title} key={index} />
                          )
                        }
                      </div>
                    }
                    {
                      this.state.countryCode != "" &&
                      <div className="row cust_ch_logo d-xs-none sm_hide ml-0">
                        {
                          partnerdata.partners.filter(obj => obj.country == this.state.countryCode)[0].data.map((item, index) =>
                            <img src={item.image} alt={item.title} key={index} />
                          )
                        }
                      </div>
                    }
                  </div>
                </div>


                <div className="col-xl-6 col-lg-6  d-xs-none text-right">
                  {
                    this.state.countryCode != "" &&
                    <div className="">
                      <img src={data.images.filter(obj => obj.country == this.state.countryCode)[0].data.filter(img => img.title == "banner")[0].data[0]} alt="Brand" className="img-fluid" />

                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section >
        <section className="brand-area2   pt-50 pb-20">
          <div className="custom-container1">
            <div className="row align-items-center d-flex justify-content-between">
              {
                this.state.countryCode != "" &&
                <div className="col-lg-6 wow slideInRight " data-wow-delay="0.2s">
                  <img src={data.images.filter(obj => obj.country == this.state.countryCode)[0].data.filter(img => img.title == "discover")[0].data[0]} alt="img" className="img-fluid" />
                </div>
              }
              <div className="col-lg-5 col-12   pl-80">
                <div className="section-title  expand py-0">
                  <p className="d-sm-block sec_tilte">Discover content.</p>
                  <h2 style={{ color: '#fff' }}>Find all your favourite content in one place.                  </h2>
                </div>
                <div className="software-content">
                  <p className="">No need to repeatedly switch between multiple apps to
                    discover content. You can find them all in Scope Video app. </p>
                  <a href={Config.BaseRootURL + 'registration'} className="watch_link">Partner With Us</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="brand-area2   pt-50 pb-20 ">
          <div className="custom-container1">
            <div className="row align-items-center d-flex justify-content-between">
              {
                this.state.countryCode != "" &&
                <div className="col-lg-6 wow slideInRight d-lg-none" data-wow-delay="0.2s">
                  <img src={data.images.filter(obj => obj.country == this.state.countryCode)[0].data.filter(img => img.title == "stayontop")[0].data[0]} alt="img" className="img-fluid" />
                </div>
              }
              <div className="col-lg-5 col-12   pl-80">
                <div className="section-title  expand py-0">
                  <p className="d-sm-block  sec_tilte">Stay on Top.</p>
                  <h2 style={{ color: '#fff' }}>Get the latest releases from multiple OTT apps.</h2>
                </div>
                <div className="software-content">
                  <p className="">You can easily discover new movies, tv shows, and web series across all our streaming app right on top.</p>
                  <a href={Config.BaseRootURL + 'registration'} className="watch_link">Partner With Us</a>
                </div>
              </div>
              {
                this.state.countryCode != "" &&
                <div className="col-lg-6 wow slideInRight d-xs-none text-right" data-wow-delay="0.2s">
                  <img src={data.images.filter(obj => obj.country == this.state.countryCode)[0].data.filter(img => img.title == "stayontop")[0].data[0]} alt="img" className="img-fluid" />
                </div>
              }
            </div>
          </div>
        </section>
        <section className="brand-area2   pt-50 pb-20">
          <div className="custom-container1">
            <div className="row align-items-center d-flex justify-content-between">
              {
                this.state.countryCode != "" &&
                <div className="col-lg-6 wow slideInRight " data-wow-delay="0.2s">
                  <img src={data.images.filter(obj => obj.country == this.state.countryCode)[0].data.filter(img => img.title == "traditionaltv")[0].data[0]} alt="img" className="img-fluid" />
                </div>
              }
              <div className="col-lg-5 col-12   pl-80">
                <div className="section-title  expand py-0">
                  <p className="d-sm-block  sec_tilte">Traditional TV experience.</p>
                  <h2 style={{ color: '#fff' }}>300+ premium Live TV channels in 10 Languages.</h2>
                </div>
                <div className="software-content">
                  <p className="">Scope Video app offers traditional Live TV experience just like any DTH service in the market. </p>
                  <a href={Config.BaseRootURL + 'registration'} className="watch_link">Partner With Us</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="brand-area2   pt-50 pb-20">
          <div className="custom-container1">
            <div className="row align-items-center d-flex justify-content-between">
              {
                this.state.countryCode != "" &&
                <div className="col-lg-6 wow slideInRight d-lg-none " data-wow-delay="0.2s">
                  <img src={data.images.filter(obj => obj.country == this.state.countryCode)[0].data.filter(img => img.title == "anytime")[0].data[0]} alt="img" className="img-fluid" />
                </div>
              }
              <div className="col-lg-5 col-12   pl-80">
                <div className="section-title  expand py-0">
                  <p className="d-sm-block  sec_tilte">Anytime, Anywhere.</p>
                  <h2 style={{ color: '#fff' }}>Watch it in any device laptop, mobile, iPad, TV...</h2>
                </div>
                <div className="software-content">
                  <p className="">You can watch content on PCs, Mobile, iPad, Tablets, Smart TVs, & STBs via Scope Video app or content partner’s apps.</p>
                  <a href={Config.BaseRootURL + 'registration'} className="watch_link">Partner With Us</a>
                </div>
              </div>
              {
                this.state.countryCode != "" &&
                <div className="col-lg-6 wow slideInRight d-xs-none text-right " data-wow-delay="0.2s">
                  <img src={data.images.filter(obj => obj.country == this.state.countryCode)[0].data.filter(img => img.title == "anytime")[0].data[0]} alt="img" className="img-fluid" />
                </div>
              }
            </div>
          </div>
        </section>
        <section className="brand-area   pt-50 pb-20">
          <div className="custom-container1">
            <div className="row align-items-center d-flex justify-content-between">
              {
                this.state.countryCode != "" &&
                <div className="col-lg-6 wow slideInRight " data-wow-delay="0.2s">
                  <img src={data.images.filter(obj => obj.country == this.state.countryCode)[0].data.filter(img => img.title == "isp")[0].data[0]} alt="img" className="img-fluid" />
                </div>
              }
              <div className="col-lg-5 col-12   pl-80">
                <div className="section-title  expand py-0">
                  <p className="d-sm-block  sec_tilte">Are you an ISP?</p>
                  <h2 style={{ color: '#fff' }}>Bundle OTT Apps & Live TV with your broadband plans.</h2>
                </div>
                <div className="software-content">
                  <p className="">Stay ahead of your competitor by bundling internet, OTT apps, and Live TV. Be the game changer!</p>
                  <a className="watch_link" href={Config.BaseRootURL + 'registration'}>Partner With Us</a>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div >
    )
  }



}
export default Home;