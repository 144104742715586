import React from 'react';
import './help.css';
import StaticHeader from '../StaticHeader/staticHeader.js';
import Footer from '../footer/footer.js';
import LeftPanel from './leftPanel.js'

class Aboutus extends React.Component{

    constructor(props)
    {
        super(props); 
    } 

    render(){
    return( 
        <div>
            <StaticHeader active={"home"}/>
            <div className="static-page row ">
                <LeftPanel active={"aboutus"} name={"About Us"}/>
                <div className="col-sm-10 right-content">
                <h3 className="static-page-title">About us</h3>
                <div>
                <h4 className="privacy-subheadding">What is Scope Video?</h4>
                <p className="static-page-content">Scope Video is a streaming video content aggregation and curation platform that helps users discover and watch content from across multiple OTT apps, using a single subscription.</p>
                <h4 className="privacy-subheadding">Our Mission</h4>
                <p className="static-page-content">We are on a mission to provide a one-stop destination for all types of content lovers, catering to all their individual needs. As part of this goal, we provide a unified catalog of OTT content by aggregating content from across multiple video streaming platforms. What differentiates us from others is that we provide personalized content recommendations to all our users, by maintaining a right mix of both manual content curation and AI-driven recommendations. We empower our users with additional content discovery capabilities which are beyond the reach of any individual OTT platforms.</p>
                <h4 className="privacy-subheadding">Our Vision</h4>
                <p className="static-page-content">Our vision is to empower every content lover with a complete home entertainment experience anytime, anywhere. </p>
                <h4 className="privacy-subheadding">Our Strengths</h4>
                <p className="static-page-content">Scope Video is a subsidiary of YuppTV. YuppTV is one of the world’s largest internet-based TV and On-demand service provider for South Asian content, offering more than 250+ TV channels, 5000+ Movies and 100+ TV Shows in 14 languages. Being a pioneer in the OTT segment for more than 10 years, YuppTV has expertise in technology, streaming infrastructure & customer experience. We relate to almost all the Indian content providers like broadcasters, content producers, media houses and OTT platforms. We also have existing business partnerships with companies in different domains like Telecom, Broadband, Entertainment, etc. </p>
                <p className="static-page-content">With digitization in India, Telecom operators and Broadband providers are looking for a solution to offer enhanced video services as part of their triple play offering to the users to keep up with the competition in the market. Building technology is difficult for most of these providers, we address this by providing a unified solution combining video content, software, and hardware with a seamless user experience at the most affordable price point. </p>
                <p className="static-page-content">We believe that we are destined to achieve our goals with all our domain expertise and strong strategic and content partnerships.</p>
                </div>


                
                </div>
               
            </div>
        <Footer/>

       </div>  
        )}
}

export default Aboutus;