import React from 'react';
import './help.css';
import StaticHeader from '../StaticHeader/staticHeader.js';
import Footer from '../footer/footer.js';
import LeftPanel from './leftPanel.js'
import data from '../shared/data/faqs.json'

class Faqs extends React.Component {

  constructor(props) {
    super(props);
    this.state = { countryCode: '' }
  }

  componentDidMount() {
    fetch("https://prod-api.yupptvscope.com/service/location/api/v1/locationinfo?tenant_code=bott&product=bott&client=Web",)
      .then((res) => res.json()).then((response) => {
        if (!!response) {
          this.setState({ countryCode: response?.ipInfo?.countryCode == "IN" ? response?.ipInfo?.countryCode : ((response?.ipInfo?.countryCode == "SG" || response?.ipInfo?.countryCode == "MY") ? "SG" : "Others") })
        }

      })
  }

  urlify(text) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return text.replace(urlRegex, function (url, b, c) {
      var url2 = (c == 'www.') ? 'http://' + url : url;
      return '<a href="' + url2 + '" target="_blank" style={cursor:pointer}">' + url + '</a>';
    })
  }

  render() {
    return (
      <div>
        <StaticHeader active={"home"} />
        <div className="static-page row ">
          <LeftPanel active={"faqs"} name={"FAQ's"} />
          <div className="col-sm-10 faq-content right-content">
            {this.state.countryCode != '' &&
              <div>
                {
                  data.faq.map((item, index) =>

                    <div className="faq-columns faqs" key={index}>
                      {
                        item.country == this.state.countryCode && item.sections.map((itm, indx) =>

                          <div className="faq-cards" key={indx}>
                            <h3 className="static-page-title faq-head-gap">{itm.title}</h3>
                            <div className="accordion-block ">
                              <div className="accordion" id="accordionExample">
                                {
                                  itm.data.map((ques, quesindex) =>
                                    <div className="card" key={quesindex}>
                                      <div className="card-head" id={"headingOne" + itm.title + quesindex}>
                                        <h2 className="mb-0" data-toggle="collapse" data-target={"#collapseOne" + itm.Id + quesindex} aria-expanded="true" aria-controls={"collapseOne" + itm.Id + quesindex}>{ques.Q}</h2>
                                      </div>
                                      <div id={"collapseOne" + itm.Id + quesindex} className="collapse" aria-labelledby={"headingOne" + itm.Id + quesindex} data-parent="#accordionExample">
                                        <div className="card-body">
                                          {ques.A.subtitle}
                                          {
                                            ques.A.subtitle !== "" &&
                                            <ul className="faq-points">
                                              {
                                                ques.A.data.map((ans, ansindex) =>
                                                  <li key={ansindex} dangerouslySetInnerHTML={{ __html: this.urlify(ans) }}></li>
                                                )
                                              }

                                            </ul>
                                          }
                                          {
                                            ques.A.subtitle == "" && ques.A.data.map((ans1, ansindex1) =>
                                              <>
                                                <div key={ansindex1} dangerouslySetInnerHTML={{ __html: this.urlify(ans1) }}></div>
                                                {
                                                  !!ques.A?.charges && ques.A?.charges.length > 0 &&
                                                  <>
                                                    Charges :
                                                    <ul className="faq-points">
                                                      {
                                                        ques.A?.charges.map((charges, chargindex) =>
                                                          <li key={chargindex} dangerouslySetInnerHTML={{ __html: this.urlify(charges) }}></li>
                                                        )
                                                      }
                                                    </ul>
                                                  </>

                                                }
                                              </>
                                            )
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
            }
          </div>
        </div>
        <Footer />

      </div >
    )
  }
}

export default Faqs;