import React from 'react';
import Config from '../shared/config';
import './ActivePartners.css';
import DataService from '../shared/service';

class ActivePartners extends React.Component{

    constructor(props)
    {
        super(props); 
        this.state = {   
            networks:[],
            userinfo:[]
        };
        this.dataService = new DataService();
    } 
    componentDidMount()  
    {
        //Total partners list  
        let domain = Config.APIURL;  
        this.dataService.httpGetwithToken(domain + "service/api/v1/system/config")
            .then(responseData=> {
            if(responseData!== undefined)
            { 
                this.setState({
                    networks : responseData.response.networks
                })
                //user partners list 
                this.dataService.httpGetwithToken(domain + "service/api/auth/user/info")
                .then(responseData=> {
                if(responseData!== undefined)
                { 
                    this.setState({
                        userinfo : responseData.response
                    })
                } 
                })
            } 
            })


    }      
partActivateClick(id)
{
    //Activate Partner
    let URL= Config.APIURL + "service/api/auth/external/process/network";
    
    let  postData = JSON.stringify({userId:this.state.userinfo.userId, networkId:id, operation:'activate'})
    this.dataService.httpPostWithHeaderData(URL, postData)
    .then(responseData=> {
    if(responseData!== undefined)
    { 
        
        if(responseData.status==true)
        {
            document.getElementById("imgSuccess").style.display= "block";
            document.getElementById("imgFail").style.display= "none";
            document.getElementById("activeHead").innerHTML="Activation Succesfull";
            document.getElementById("resMsg").innerHTML=responseData.response.description;
        }
        else
        {
            document.getElementById("imgSuccess").style.display= "none";
            document.getElementById("imgFail").style.display= "block";
            document.getElementById("activeHead").innerHTML="Activation Failed";
            document.getElementById("resMsg").innerHTML=responseData.error.message;
        }
        document.body.classList.add("modal-open");
        document.getElementById("activeSuccessPopup").style.display= "block";
    } 
    })
}

// get Active partners for user
getPartsList(ntwrk)
{
    if(this.state.userinfo.length!==0)
    {
        if(this.state.userinfo.allNetworks.length!==0)
        {
    let allntwrks= this.state.userinfo.allNetworks.split(",");
    let subntwrks= this.state.userinfo.subscribedNetworks.split(",");
    let j;

    for (var k in allntwrks)
    {
        if (allntwrks[k] == ntwrk.id)
        {
            //for (var i in allntwrks)
           // {
                for (j=0; j<subntwrks.length; j++)
                {
                    if (ntwrk.id== subntwrks[j])
                    {
                        return(
                            <tr key={ntwrk.id}>
                            <td>{ntwrk.name}</td>
                            <td>Active </td>
                            </tr>)
                        break;
                    }
                }
                if (j >= subntwrks.length)
                {
                    return(
                        <tr key={ntwrk.id}>
                        <td>{ntwrk.name}</td>
                        <td><span className="activate-btn" onClick={this.partActivateClick.bind(this, ntwrk.id)} >Activate</span></td>
                    </tr> 
                    )
                }
            //}
        }
        }
    }
            // else{
            //     document.getElementById("divpartnerblock").style.display="block";
            //     document.getElementById("divDetblock").style.display="none";
            // }
            }
    // else{
    //     document.getElementById("divpartnerblock").style.display="block";
    //     document.getElementById("divDetblock").style.display="none";
    // }
}

//Activation success/failure popup close
closeClick(popup)
{
  let domain = Config.APIURL;  
  this.dataService.httpGetwithToken(domain + "service/api/auth/user/info")
  .then(responseData=> {
  if(responseData!== undefined)
  { 
      this.setState({
          userinfo : responseData.response
      })
  } 
  })
  document.body.classList.remove("modal-open");
  document.getElementById(popup).style.display= 'none'; 
} 

render(){
return( 

<div>
<div className="detail-head">Active Partners</div>
<div className="detail-block-two">


<div id="divpartnerblock" className="content-partner-block" style={{display:"none"}}>
<h3 className="">You don’t have an active plan!</h3>
<h4>Content partners available as part of your plan will be listed here.</h4>
</div>

    <div id="divDetblock">
        <p className="partner-txt">Below is a list of content partners available as part of your subscription.</p> 

        <table className="table partner-table">
            <thead>
            <tr>
                <th>Partner Name</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
                {
                
                this.state.networks.map(ntwrk=>( 
                    this.getPartsList(ntwrk)
                ))

                }
            </tbody>
 
        </table>
        </div>
</div>
<div className="activesuccess-popup">
<div className="modal show" id="activeSuccessPopup" aria-modal="true">
    <div className="modal-dialog">
        <div className="modal-content"> 
        <div className="modal-header">
            <img src={require('../images/activation-success.png')} id="imgSuccess"/>
            <img  src={require('../images/caution.svg')} id="imgFail"/>
                <h4 className="modal-title" id='activeHead'></h4>
                <h6 id="resMsg"> </h6>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <input type="button"  className="form-control btn btn-danger" value="Okay" onClick={this.closeClick.bind(this,"activeSuccessPopup")}  />
                </div>     
            </div>
        </div>
    </div>
</div>
</div>
</div>
 
        )}
}
export default ActivePartners;