import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import AccountSummary from './AccountSummary';
import * as serviceWorker from './serviceWorker';
import {Route, BrowserRouter as Router}  from 'react-router-dom';
import OTTPackage from './packages/packages.js';
import Thankyou from './ThankYou/Thankyou.js';
import Popup from './popup/popup';
import Signin from './signin/signin.js';
import Myaccount from './account/account.js';
import Whyus from './help/whyus.js';
import WaystoWatch from './help/waystowatch.js';
import Aboutus from './help/aboutus';
import PrivacyPolicy from './help/privacypolicy';
import TermsandConditions from './help/termsandconditions';
import ActivationSteps from './help/activationsteps';
import LiveTVChannels from './livetvchannels';
import Faqs from './help/faqs';
import ContactUs from './help/contactus';
import AndroidTV from './help/androidtv';
import SmartTV from './help/smarttv';
import Web from './help/web';
import Mobile from './help/mobile';
import Isppartnerships from './isppartnerships/isppartnerships';
import Registration from './Registration/registration';

const routing =(
    <Router> 
        <div> 
            <Route exact path="/"  component={App} />
            <Route exat path="/home"  component={App} />
            <Route exat path="/packages" component={OTTPackage} />
            <Route exat path="/accountsummary" component={AccountSummary}/>
            <Route exat path="/thankyou" component={Thankyou}/>
            <Route exat path="/popup" component={Popup}/>
            <Route exat path="/signin" component={Signin}/>
            <Route exat path="/myaccount" component={Myaccount}/>
            <Route exat path="/help/whyus" component={Whyus}/>
            <Route exat path="/help/waystowatch" component={WaystoWatch}/>
            <Route exat path="/help/aboutus" component={Aboutus}/>
            <Route exat path="/help/privacypolicy" component={PrivacyPolicy}/>
            <Route exat path="/help/termsandconditions" component={TermsandConditions}/>
            <Route exat path="/help/activationsteps" component={ActivationSteps}/>
            <Route exat path="/livetvchannels" component={LiveTVChannels}/>
            <Route exat path="/help/faqs" component={Faqs}/>
            <Route exat path="/help/contactus" component={ContactUs}/>
            <Route exat path="/help/androidtv" component={AndroidTV}/>
            <Route exat path="/help/smarttv" component={SmartTV}/>
            <Route exat path="/help/web" component={Web}/>
            <Route exat path="/help/mobile" component={Mobile}/>
            <Route exat path="/isppartnerships" component={Isppartnerships}/>
            <Route exat path="/registration" component={Registration}/>
        </div> 
    </Router>     
)
ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();