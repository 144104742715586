import React from 'react';
import Config from '../shared/config.js';
import Header from '../header/header';
import Footer from '../footer/footer.js';
import data from '../shared/data/partners.json'

class Mobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Mobile Phone’s",
            partnerimage: '',
            activationdetails: [],
            activationimages: [],
            showpartners: true,
            showactivation: false,
            gobackpage: '',
            pageheader: '',
            partnertitle: '',
            countryCode: "Others"
        }
    }

    componentDidMount() {
        console.log("Mobile page");
        let pageurl = window.location.pathname;
        if (pageurl.includes('mobile')) {
            this.setState({
                pageheader: 'Mobile Phone’s Activation'
            })
        }
        fetch(Config.LocationAPI + "?tenant_code=bott&product=bott&client=Web",)
            .then((res) => res.json()).then((response) => {
                if (!!response) {
                    this.setState({ countryCode: response?.ipInfo?.countryCode == "IN" ? response?.ipInfo?.countryCode : "Others" })
                }
            })
    }

    goback() {
        if (this.state.gobackpage == '') {
            window.open(
                Config.BaseRootURL + 'help/activationsteps',
                '_self'
            );
        }
        else {
            this.setState({
                showpartners: true,
                showactivation: false,
                gobackpage: '',
                title: "Mobile Phone’s",
                partnertitle: ''
            })
        }

    }
    partneractivationinfo(partner) {

        var image = '';
        var activationsteps = [];
        var activationimages = [];
        var title = '';
        if (partner === "sonyliv") {
            title = 'SonyLIV Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/sonyliv.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'Please install YuppTV Scope app in your mobile',
                        'When you click on any SonyLIV content displayed on Scope Video app, you will be redirected to SonyLIV app to watch the content.',
                        'If app not already installed, you will be taken to respective app store.',
                        'Sign in with your registered mobile number (RMN) via OTP.',
                        'Enjoy watching content.'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_sony1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_sony2.png"
                    ]
                }
            ]
        }
        else if (partner === "zee5") {
            title = 'ZEE5 Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/zee5.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'Please install YuppTV Scope app in your mobile',
                        'When you click on any ZEE5 content displayed on Scope Video app, you will be redirected to ZEE5 app to watch the content.',
                        'If app not already installed, you will be taken to respective app store.',
                        'You will be automatically logged in to the ZEE5 application on mobile devices. For TV applications, login with your registered mobile number via OTP.',
                        'Enjoy watching content.'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_zee51.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_zee52.png"
                    ]
                }
            ]
        }
        else if (partner === "hotstar") {
            title = 'Hotstar Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/hotstar.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'Please install YuppTV Scope app in your mobile',
                        'Go to Hotstar app in Mobile phone (If not available, download from Google Play store),',
                        'Select your favourite languages and location to personalise your experience,',
                        'For one time login for Hotstar , Go to My Account,',
                        'Log in to continue,',
                        'Enter RMN, Enter the OTP Received on mobile, Verify.',
                        'Start Watching Disney+Hotstar on your Mobile.'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_hotstar1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_hotstar2.png"
                    ]
                }
            ]
        }
        else if (partner === "voot") {
            title = 'Voot  Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/voot.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'When you click on any Voot content displayed on Scope Video app, you will be redirected to Voot app to watch the content.',
                        'If app not already installed, you will be taken to respective app store',
                        'On Voot app, enter your registered mobile number. On the next screen, select “Need help logging in?” to setup a password for your Voot account',
                        'Enjoy watching content.'
                    ],
                    subdata: []
                }
            ]
            activationimages = []
        }
        else if (partner === "aha") {
            title = this.state.countryCode == "IN" ? "Aha Activation Steps ( Only For South Pack users )" : "Aha Activation Steps"
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/aha.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "",
                        data: [
                            "Open the app and go to the menu.",
                            "Click on Sign-in",
                            "Enter the E-mail address and select ‘next.’",
                            "Then click on ‘forgot password.’"
                        ],
                        subdata: [
                            " - Enter the OTP sent to the given E-mail address and verify.",
                            " - Enter the new password.",
                            " - Re-enter the password to confirm.",
                            " - Click on save password.",
                        ]
                    },
                    {
                        "title": "",
                        data: [
                            "Now login again with the new password."
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/mobilephone_aha_step1.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/mobilephone_aha_step2.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/mobilephone_aha_step3.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/mobilephone_aha_step4.png"
                        ]
                    }
                ]
            } else {
                activationsteps = [
                    {
                        "title": "",
                        data: [
                            'Please install YuppTV Scope app in your mobile',
                            'Go to Aha app in Mobile phone (If not available, download from Google Play store),',
                            'For one time login for Aha , Go to My Account,',
                            'Log in to continue,',
                            'Enter RMN, Enter the OTP Received on mobile, Verify,',
                            'Click on Start Watching  Aha on your Mobile.'
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_aha.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_aha1.png"
                        ]
                    }
                ]
            }
        }
        else if (partner === "lionsgateplay") {
            title = 'Lionsgate Play Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/lionsgate.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'Please install YuppTV Scope app in your mobile',
                        'Go to Lionsgate Play app in your Mobile phone (If not available, download from Google Play store),',
                        'Go to Sign Up enter Your email ID or Phone number.',
                        'Create a password. Click continue',
                        'Select your Gender and enter your Date of birth ',
                        'Click on Sign Up.',
                        'Enter OTP and Submit.',
                        'You will be able to watch our content on your Mobile.'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_lionsgate.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_lionsgate1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_lionsgate2.png"
                    ]
                }
            ]
        }
        else if (partner === "shemaroo") {
            title = 'Shemaroo Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/shemaroo.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        "Please install YuppTV Scope app in your mobile",
                        "When you click on any ShemarooMe content displayed on YuppTV scope app, you will be redirected to ShemarooMe app to watch the content.",
                        "If ShemarooMe app is not already installed, you will be taken to respective app store.",
                        "You’ll land on Home page, Click on Sign Up/ Sign In option,",
                        "Enter mobile number. Click on Get OTP,",
                        "Enter the OTP on registered mobile number.",
                        "Click on Verify OTP and you can start watching Shemaroo."
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_shemaroo.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_shemaroo1.png"
                    ]
                }
            ]
        }
        else if (partner === "hungamaplay") {
            title = 'Hungama Play Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/hungamaplay.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        "Please install YuppTV Scope app in your mobile",
                        'Go to Hungama Play app in Mobile phone (If not available, download from Google Play store),',
                        'For one time login for Hungama Play , Go to My Account,',
                        'Log in to continue,',
                        'Enter RMN, Enter the OTP Received on mobile, Verify,',
                        'Click on Start Watching  Hungama Play on your Mobile.'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_hungama.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_hungama1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_hungama2.png"
                    ]
                }
            ]
        }
        else if (partner === "ypptvscope") {
            title = 'Scope Video Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/15Feb2023/scopevideonew.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "",
                        data: [
                            "Download the app from the play store: https://play.google.com/store/apps/details?id=com.yupptv.scope",
                            "For first-time users : Registered Email/Mobile number (user name) and a Password will be sent via Mail/WhatsApp/SMS.",
                            "Now open the app and sign in with your registered number mobile number / E-mail address.",
                            "Please enter your Password to sign in."
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/mobilephone_scopevideo.png"
                        ]
                    }
                ]
            }
            else {
                activationsteps = [
                    {
                        "title": "",
                        data: [
                            'Subscribe to Scope Video via the portal',
                            'After successful purchase, you will receive an SMS confirmation on your registered mobile number (RMN).',
                            'Download and install Scope Video app on the device of your choice. Please refer to the supported devices',
                            'Sign in with your registered mobile number (RMN) using OTP validation.',
                            'Enjoy wide range of aggregated content from YuppTV, ZEE5, SonyLIV, Voot & Epic On.',
                            'You can watch Live TV channels, that are powered by YuppTV, on Scope Video app itself.',
                            'To watch the content powered by the partner apps, you will be asked to install individual apps.'
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_scopevide1.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/mobilephone_in_scopevide2.png",
                          
                        ]
                    }
                ]
            }
        }
        else if (partner === "sunnxt") {
            title = 'Sun NXT Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/bsnl/20220308/sunnxticon.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "Single sign-on",
                        data: [
                            "With this feature users need not sign in again in Sun NXT app.",
                            "Users must sign in scope video app with a registered mobile number.",
                            "Once you click on any Sunnxt content in the scope app, you’ll be navigated to the player page in the Sunnxt app.",
                            "Next content will be played."
                        ],
                        subdata: []
                    },
                    {
                        "title": "Sign in flow",
                        data: [
                            "Goto Settings -> Sign-in page",
                            "Enter your registered E-mail address.",
                            "Enter the Password -> click on the submit button.",
                            "Sign in Successful."
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/mobilephone_sunnxt.png"
                        ]
                    }
                ]
            } else {
                activationsteps = [];
                activationimages = [];
            }
        }
        else if (partner === "yupptv") {
            title = 'YuppTV Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/bsnl/20220308/yupptvicon.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "YuppTV: https://www.yupptv.com/",
                        data: [
                            "Download the app from the play store:https://play.google.com/store/apps/details?id=com.tru",
                            "Now open the app and sign in with your registered number mobile number / E-mail address.",
                            "If you choose Password to sign in, then enter your password.",
                            "If you choose to sign in with OTP, enter OTP sent to your registered E-mail address / Mobile number.",
                            "You will get the message “Sign in Successful”."
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/mobilephone_yupptv.png"
                        ]
                    }
                ]
            } else {
                activationsteps = []
                activationimages = []
            }
        }

        this.setState({
            partnertitle: title,
            title: title,
            partnerimage: image,
            activationdetails: activationsteps,
            activationimages: activationimages,
            showpartners: false,
            showactivation: true,
            gobackpage: 'partners'
        })
    }

    render() {
        return (
            <div>
                <Header active={"activationsteps"} showmenuitem={true}/>
                <div className='activation-container'>
                    <h2 className='back-headding'> <a onClick={this.goback.bind(this)} style={{ cursor: 'pointer' }}><img src='https://yuppstatic.akamaized.net/yupptv/turito/28apr2022/back-arrow-as.svg' /></a>
                        {this.state.gobackpage == '' && this.state.pageheader != '' &&
                            <>{this.state.pageheader}</>
                        }
                        {this.state.partnertitle}
                    </h2>
                    {
                        this.state.showpartners == true &&
                        <>
                            {/* <div className='smart-content-as'>
                                <p>Samsung, LG, Sony Bravia have an option to select apps. </p>
                                <p>Go to the apps option and search the app (like sonyLiv , Zee5 , Hotstar , Voot , Shemaroo , Liongate , Hungama ) in the search box
                                    and it will show on the result. Install the app and open it. Now do the one time registration for each App .</p>
                                <p>If your TV that doesn’t support apps, then open the browser and type the OTT app name like https.sonyliv.com  for Sony and similarly for others ..</p>
                            </div> */}
                            <div className='select-partner-main-block'>
                                <h2 className='back-headding text-center back-headding1'>{this.state.title}</h2>
                                <h3 className='select-cont-partner'>Select Content Partners</h3>
                                <ul className='select-partner-block'>
                                    {
                                        data.partners.filter(obj => obj.country == this.state.countryCode)[0].data.map((item, index) =>
                                            <li key={index}>
                                                <a onClick={this.partneractivationinfo.bind(this, item.title)}>
                                                    <img src={item.image} />
                                                    <p>{item.displaytitle}</p>
                                                </a>
                                            </li>

                                        )
                                    }
                                </ul>
                            </div>
                        </>
                    }
                    {
                        this.state.showactivation == true &&
                        <div>
                            {
                                !!this.state.partnerimage &&
                                <div className=' select-partner-main-block'>
                                    <div className='list-parent'>
                                        <img className="partnericon" src={this.state.partnerimage} />
                                        <ul className='device-points'>
                                            {
                                                this.state.activationdetails.length > 0 && this.state.activationdetails.map((item, index) =>
                                                    <>
                                                        <li key={index} className="device_points_head">{item.title}</li>
                                                        {
                                                            item.data.length > 0 && item.data.map((itm, indx) =>
                                                                <li key={indx}>{itm}</li>
                                                            )
                                                        }
                                                        {
                                                            item.subdata.length > 0 && item.subdata.map((obj, objIndex) =>
                                                                <li key={objIndex} className="list_type_none">{obj}</li>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </ul>
                                        {
                                            this.state.activationimages.length > 0 && this.state.activationimages.map((item, index) =>
                                                <>
                                                    {
                                                        item.title == "design1" &&
                                                        <div className='row' key={index}>
                                                            {
                                                                item.data.map((item, index) =>
                                                                    <div className='col-md-3' key={index}>
                                                                        <img src={item} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        item.title == "design2" &&
                                                        <div className={item.hasor == "true" ? "row justify-content-between or_text" : "row justify-content-between"}>
                                                            {
                                                                item.data.map((item, index) =>
                                                                    <div className='col-md-6' key={index}>
                                                                        <img src={item} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <p className='query-description'>For any further queries and assistance, contact our support team by saying ‘Hi’ in the chat or writing to us at support@yupptvscope.com.</p>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Mobile;