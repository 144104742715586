import React from 'react';
import './account.css';
import Header from '../header/header.js';
import Footer from '../footer/footer.js';
import Myprofile from "../profile/profile.js";
import ActivePartners from "../ActivePartners/ActivePartners.js";
import Subscriptions from "../subscription/subscription.js";
import Transactions from "../Transactions/Transaction.js";


class Myaccount extends React.Component{

    constructor(props)
    {
        super(props); 
        this.state = {   
          AccountPage:"",
      };
    } 
    
    
    getDetails(pageval)
    { 
      //setting page state, based on this value displaying  pages
       this.setState({
          AccountPage :pageval })
    }


    accountPages(device)
    {
      if((device==="web" && window.innerWidth>768) || (device==="mob" && window.innerWidth<=768))
      {
      if (this.state.AccountPage!=="")
      {
        //Removing and adding active class for left side menu
        var elems = document.getElementsByClassName("profilesubhead");
        Array.from(elems).forEach(item => item.classList.remove("profile-active"));

          Array.from(elems).forEach(element => {
          if(element.id.toString().includes(this.state.AccountPage))
          {
            element.classList.add("profile-active");
          }
        });


        document.getElementById("mobileHead").innerHTML= this.state.AccountPage;
        document.getElementById("mobULHead").style.display= 'none';
      }
             //displaying myaaccount components
             if(this.state.AccountPage==="Profile")
             {
               return (
                 <Myprofile></Myprofile>
               )
             }
             else if(this.state.AccountPage==="Subscription")
             {
              
               return (
                 <Subscriptions></Subscriptions>
               )
             }
             else if(this.state.AccountPage==="Transaction")
             {
               return (
                 <Transactions></Transactions>
               )
             }
             else if(this.state.AccountPage==="ActivePartners")
             {
               return (
                 <ActivePartners></ActivePartners>       
                  )
             }
             else 
             {
               return (
                 <Myprofile></Myprofile>
               )
             }
            }
    }

    render(){
    return( 
        <div>
            <Header showmenuitem={true}/>
            <div className="profile-container">
            <div className="row mob-mar account-nav-web">
            <div className="accounts-l ">

              <div className="col-md-12 profile-head"> My account</div>
              <div className="profile-block">
                <ul> 
                  <li className="profilesubhead profile-active" id="" onClick={this.getDetails.bind(this,"Profile")}><span>Profile</span> </li>
                  <li className="profilesubhead" id="Subscription"  onClick={this.getDetails.bind(this,"Subscription")}> <span>My Subscription</span> </li>
                  <li className="profilesubhead" id="Transaction" onClick={this.getDetails.bind(this,"Transaction")}> <span>Transactions</span> </li>
                  <li className="profilesubhead" id="ActivePartners"  onClick={this.getDetails.bind(this,"ActivePartners")}> <span>Actvie Partners</span> </li>
                </ul>
              </div>
            </div>
            <div className="accounts-r">
            <div>
                   {this.accountPages("web")} 
              </div>
              </div>
              </div>

            <div className="myaccount-mobile-nav">
            <div className="accounts-l">

            <div className="col-md-12 profile-head"> My account</div>
            <div className="profile-block">
              <div id="mobileHead" className="mobile-menu-headding" onClick={openProfile}>Profile</div>
                <ul id="mobULHead"> 
                <h6 className="ma-inner-headding">My Account</h6>
               <li className="profilesubhead profile-active" id="ProfileM"   onClick={this.getDetails.bind(this,"Profile")}><span>Profile</span> </li>
                <li className="profilesubhead" id="SubscriptionM" onClick={this.getDetails.bind(this,"Subscription")}> <span  >My Subscription</span> </li>
                <li className="profilesubhead" id="TransactionM"  onClick={this.getDetails.bind(this,"Transaction")}> <span>Transactions</span> </li>
                <li className="profilesubhead" id="ActivePartnersM"   onClick={this.getDetails.bind(this,"ActivePartners")}> <span>Actvie Partners</span> </li>
            </ul>
            </div>
            </div>
            <div className="accounts-r">
            <div>
             {this.accountPages("mob")}  
              </div>
            </div>
            </div>

            </div>
            <Footer/>
       </div>  
        )}
}
export default Myaccount; 

function openProfile()
{
  document.getElementById("mobULHead").style.display= 'block';
}

