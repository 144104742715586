import React from 'react';
import Config from '../shared/config.js';
import Header from '../header/header';
import Footer from '../footer/footer.js';
import data from '../shared/data/partners.json'

class Web extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Desktop Computer or Laptop",
            partnerimage: '',
            activationdetails: [],
            activationimages: [],
            showpartners: true,
            showactivation: false,
            gobackpage: '',
            pageheader: '',
            partnertitle: '',
            countryCode: "Others"
        }
    }

    componentDidMount() {
        let pageurl = window.location.pathname;
        if (pageurl.includes('web')) {
            this.setState({
                pageheader: 'Computer/Laptop Activation'
            })
        }
        fetch(Config.LocationAPI + "?tenant_code=bott&product=bott&client=Web",)
            .then((res) => res.json()).then((response) => {
                if (!!response) {
                    this.setState({ countryCode: response?.ipInfo?.countryCode == "IN" ? response?.ipInfo?.countryCode : "Others" })
                }
            })
    }

    goback() {
        if (this.state.gobackpage == '') {
            window.open(
                Config.BaseRootURL + 'help/activationsteps',
                '_self'
            );
        }
        else {
            this.setState({
                showpartners: true,
                showactivation: false,
                gobackpage: '',
                title: "Desktop Computer or Laptop",
                partnertitle: ''
            })
        }

    }
    partneractivationinfo(partner) {

        var image = '';
        var activationsteps = [];
        var activationimages = [];
        var title = '';
        if (partner === "sonyliv") {
            title = 'SonyLIV Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/sonyliv.png'
            activationsteps = [
                {
                    "title": "SonyLIV: https://www.sonyliv.com",
                    data: [
                        'By clicking on the above link you’ll be redirected to SonyLIV web browser.',
                        'Sign in with your registered mobile number (RMN) via OTP',
                        'Enjoy watching content'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design2",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_sony1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_sony2.png"
                    ]
                }
            ]
        }
        else if (partner === "zee5") {
            title = 'ZEE5 Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/zee5.png'
            activationsteps = [
                {
                    "title": "Zee5: https://www.zee5.com",
                    data: [
                        "By clicking on the above link you’ll be redirected to ZEE5 web browser.",
                        "Sign in with your registered mobile number (RMN) via OTP",
                        "Enjoy watching content."
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design2",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_zee51.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_zee52.png"
                    ]
                }
            ]
        }
        else if (partner === "hotstar") {
            title = 'Hotstar Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/hotstar.png'
            activationsteps = [
                {
                    "title": "Hotstar: https://www.hotstar.com",
                    data: [
                        "By clicking on the above link you’ll be redirected to Hotstar web browser.",
                        "Sign in with your registered mobile number (RMN) via OTP.",
                        "Enjoy watching content."
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design2",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_hotstar1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_hotstar2.png"
                    ]
                }
            ]
        }
        else if (partner === "voot") {
            title = 'Voot  Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/voot.png'
            activationsteps = [
                {
                    "title": "Voot: https://www.voot.com",
                    data: [
                        "By clicking on the above link you’ll be redirected to Voot web browser.",
                        "Sign in with your registered mobile number (RMN) via OTP.",
                        "Enjoy watching content."
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_voot1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_voot2.png"
                    ]
                }
            ]
        }
        else if (partner === "aha") {
            title = this.state.countryCode == "IN" ? 'Aha Activation Steps ( Only For South Pack users )' : "Aha Activation Steps"
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/aha.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "Aha: https://www.aha.video/",
                        data: [
                            "By clicking on the above link you’ll be redirected to aha in web browser.",
                            "Go to the profile icon and click on it, a sign-in option appears",
                            "Click on Sign-in",
                            "Enter the E-mail address and select ‘next.’",
                            "Then click on ‘forgot password.’"
                        ],
                        subdata: [
                            "- Enter the OTP sent to the given E-mail address and verify.",
                            "- Enter the new password.",
                            "- Re-enter the password to confirm.",
                            "- Click on save password."
                        ]
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/desktop_aha_step1.png",
                        ]
                    },
                    {
                        title: "design2",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/desktop_aha_step2.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/desktop_aha_step3.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/desktop_aha_step4.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/desktop_aha_step5.png"
                        ]
                    }
                ]
            } else {
                activationsteps = [
                    {
                        "title": "Aha: https://www.aha.video",
                        data: [
                            "By clicking on the above link you’ll be redirected to Aha web browser.",
                            "Sign in with your registered mobile number (RMN) via OTP.",
                            "Enjoy watching content."
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design2",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_aha1.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_aha2.png"
                        ]
                    }
                ]
            }
        }
        else if (partner === "lionsgateplay") {
            title = 'Lionsgate Play Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/lionsgate.png'
            activationsteps = [
                {
                    "title": "Lionsgate: https://www.lionsgateplay.com",
                    data: [
                        "By clicking on the above link you’ll be redirected to Lionsgateplay web browser.",
                        "Sign in with your registered mobile number (RMN),",
                        "Create your password.",
                        "Enter your activation code sent to your given number.",
                        "Enjoy watching content."
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design2",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_lionsgate1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_lionsgate2.png"
                    ]
                }
            ]
        }
        else if (partner === "shemaroo") {
            title = 'Shemaroo Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/shemaroo.png'
            activationsteps = [
                {
                    "title": "Shemaroo: https://www.shemaroome.com/",
                    data: [
                        "By clicking on the above link you’ll be redirected to Shemaroome web browser.",
                        "Sign in with your registered mobile number (RMN) via OTP.",
                        "Enjoy watching content."
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design2",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_shemaroo1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_shemaroo2.png"
                    ]
                }
            ]
        }
        else if (partner === "hungamaplay") {
            title = 'Hungama Play Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/hungamaplay.png'
            activationsteps = [
                {
                    "title": "Hungama Play: https://www.hungama.com",
                    data: [
                        "By clicking on the above link you’ll be redirected to Hungama web browser.",
                        "Sign in with your registered mobile number (RMN) via OTP.",
                        "Enjoy watching content."
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design2",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_hungama1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_hungama2.png"
                    ]
                }
            ]
        }
        else if (partner === "ypptvscope") {
            title = 'Scope Video Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/15Feb2023/scopevideonew.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "ScopeVideo: https://scopevideo.com",
                        data: [
                            "By clicking on the above link you’ll be redirected to ScopeVideo by YuppTV in web browser.",
                            "For first-time users : Registered Email/Mobile number (username) and a Password will be sent via Mail/WhatsApp/SMS.",
                            "Sign in with your E-mail / Mobile number and enter your password.",
                            "Enjoy watching content."
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/desktop_scopevideo_step1.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/desktop_scopevideo_step2.png"
                        ]
                    }
                ]
            } else {
                activationsteps = [
                    {
                        "title": "Scope Video: https://www.scopevideo.com/",
                        data: [
                            "YuppTV Scope app on your Android Smart TV (If not available, download from Google Play store on TV),",
                            "Click on Settings -> Click on Sign In -> Enter registered mobile number -> Click on Send OTP",
                            "Enter OTP -> Click on Submit OTP",
                            "You will get message “Login Successful”.",
                            "You can continue watching."
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design2",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_scopevideo1.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/desktop_in_scopevideo2.png"
                        ]
                    }
                ]
            }
        }
        else if (partner === "sunnxt") {
            title = 'Sun NXT Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/bsnl/20220308/sunnxticon.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "Sun NXT: https://www.Sun NXT.com/signin",
                        data: [
                            "By clicking on the above link you’ll be redirected to YuppTV in web browser."
                        ],
                        subdata: []
                    },
                    {
                        "title": "Single sign-on",
                        data: [
                            "With this feature users need not sign in again in Sun NXT app.",
                            "Users must sign in scope video app with a registered mobile number.",
                            "Once you click on any Sunnxt content in the scope app, you’ll be navigated to the player page in the Sunnxt app.",
                            "Next content will be played."
                        ],
                        subdata: []
                    },
                    {
                        "title": "Sign in flow",
                        data: [
                            "Enter your registered E-mail address.",
                            "Enter the Password -> click on the submit button.",
                            "Sign in Successful."
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/desktop_sunnxt_step1.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/desktop_sunnxt_step2.png"
                        ]
                    }
                ]
            } else {
                activationsteps = [];
                activationimages = [];
            }
        }
        else if (partner === "yupptv") {
            title = 'YuppTV Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/bsnl/20220308/yupptvicon.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "YuppTV: https://www.yupptv.com/",
                        data: [
                            "By clicking on the above link you’ll be redirected to YuppTV in web browser.",
                            "Sign in with your registered mobile number (RMN).",
                            "Sign in with your E-mail / Mobile number and enter your password.",
                            "Enjoy watching content."
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/desktop_yupptv_step1.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/desktop_yupptv_step2.png"
                        ]
                    }
                ]
            } else {
                activationsteps = []
                activationimages = []
            }
        }
        this.setState({
            partnertitle: title,
            title: title,
            partnerimage: image,
            activationdetails: activationsteps,
            activationimages: activationimages,
            showpartners: false,
            showactivation: true,
            gobackpage: 'partners'
        })
    }

    render() {
        return (
            <div>
                <Header active={"activationsteps"} showmenuitem={true}/>
                <div className='activation-container'>
                    <h2 className='back-headding'> <a onClick={this.goback.bind(this)} style={{ cursor: 'pointer' }}><img src='https://yuppstatic.akamaized.net/yupptv/turito/28apr2022/back-arrow-as.svg' /></a>
                        {this.state.gobackpage == '' && this.state.pageheader != '' &&
                            <>{this.state.pageheader}</>
                        }
                        {this.state.partnertitle}
                    </h2>
                    {
                        this.state.showpartners == true &&
                        <div className='select-partner-main-block'>
                            <h2 className='back-headding text-center back-headding1'>{this.state.title}</h2>
                            <h3 className='select-cont-partner'>Select Content Partners</h3>
                            <ul className='select-partner-block'>
                                {
                                    data.partners.filter(obj => obj.country == this.state.countryCode)[0].data.map((item, index) =>
                                        <li key={index}>
                                            <a onClick={this.partneractivationinfo.bind(this, item.title)}>
                                                <img src={item.image} />
                                                <p>{item.displaytitle}</p>
                                            </a>
                                        </li>

                                    )
                                }
                            </ul>
                        </div>
                    }
                    {
                        this.state.showactivation == true &&
                        <div>
                            {
                                !!this.state.partnerimage &&
                                <div className=' select-partner-main-block'>
                                    <div className='list-parent'>
                                        <img className="partnericon" src={this.state.partnerimage} />
                                        <ul className='device-points'>
                                            {
                                                this.state.activationdetails.length > 0 && this.state.activationdetails.map((item, index) =>
                                                    <>
                                                        <li key={index} className="device_points_head">{item.title}</li>
                                                        {
                                                            item.data.length > 0 && item.data.map((itm, indx) =>
                                                                <li key={indx}>{itm}</li>
                                                            )
                                                        }
                                                        {
                                                            item.subdata.length > 0 && item.subdata.map((obj, objIndex) =>
                                                                <li key={objIndex} className="list_type_none">{obj}</li>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </ul>
                                        {
                                            this.state.activationimages.length > 0 && this.state.activationimages.map((item, index) =>
                                                <>
                                                    {
                                                        item.title == "design1" &&
                                                        <div className='row' key={index}>
                                                            {
                                                                item.data.map((item, index) =>
                                                                    <div className='col-md-6' key={index}>
                                                                        <img src={item} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        item.title == "design2" &&
                                                        <div className={item.hasor == "true" ? "row justify-content-between or_text" : "row "}>
                                                            {
                                                                item.data.map((item, index) =>
                                                                    <div className='col-md-3' key={index}>
                                                                        <img src={item} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <p className='query-description'>For any further queries and assistance, contact our support team by saying ‘Hi’ in the chat or writing to us at support@yupptvscope.com.</p>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Web;