import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './shared/css/style.css';
import './shared/css/style_home.css';
import './shared/css/animate.min.css'
import './shared/css/magnific-popup.css'
import './shared/css/flaticon.css'
import './shared/css/aos.css'
// import './shared/css/owl.carousel.min.css'
import './shared/css/meanmenu.css'
import './shared/css/slick.css'
import './shared/css/default.css'
import './shared/css/responsive.css'
import './shared/css/custom-animation.css'
import './shared/fa/css/font-awesome.css'

import './App.css';
import Header from './header/header.js';
import Footer from './footer/footer.js';
import Home from './home/home.js';
import AuthenticationService from './shared/utils/auth.service';


class App extends React.Component {
  componentDidMount =async(ctx)=>{
    let service = new AuthenticationService();
    let sessioninfo = await service.getSessionID();
  }
  render() {
    return (
      <div className="App">
        <div id="mobbgclose"></div>
        <Header active={"home"} showmenuitem={true}/>
        <Home />
        <Footer />
      </div>
    );
  }
}

export default App;
