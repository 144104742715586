import React from 'react';
import Config from '../shared/config.js';
import Header from '../header/header';
import Footer from '../footer/footer.js';
import data from '../shared/data/partners.json'

class AndroidTV extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Smart TVs - Android TV, Amazon Firestick, Android STB (Set-Top-Box)",
            partnerimage: '',
            activationdetails: {},
            activationimages: [],
            showpartners: true,
            showactivation: false,
            gobackpage: '',
            pageheader: '',
            partnertitle: '',
            countryCode: "Others"
        }
    }
    componentDidMount() {
        let pageurl = window.location.pathname;
        if (pageurl.includes('androidtv')) {
            this.setState({
                pageheader: 'AndroidTV Activation'
            })
        }
        fetch(Config.LocationAPI + "?tenant_code=bott&product=bott&client=Web",)
            .then((res) => res.json()).then((response) => {
                if (!!response) {
                    this.setState({ countryCode: response?.ipInfo?.countryCode == "IN" ? response?.ipInfo?.countryCode : "Others" })
                }
            })
    }

    goback() {
        if (this.state.gobackpage == '') {
            window.open(
                Config.BaseRootURL + 'help/activationsteps',
                '_self'
            );
        }
        else {
            this.setState({
                showpartners: true,
                showactivation: false,
                gobackpage: '',
                title: "Smart TVs - Android TV, Amazon Firestick, Android STB (Set-Top-Box)",
                partnertitle: ''
            })
        }

    }
    partneractivationinfo(partner) {

        var image = '';
        var activationsteps = [];
        var activationimages = [];
        var title = '';
        if (partner === "sonyliv") {
            title = 'SonyLIV Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/sonyliv.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'Go to SonyLIV app in TV (If not available, download from Google Play store on TV)',
                        'For one time login for SonyLIV premium, Click on Sign in , it will ask for a code to enter in your account.',
                        'Please Visit https://www.sonyliv.com/device/activate in the web browser of your mobile/laptop.',
                        'Login using your registered ID(your RMN) and OTP.',
                        'Enter the code shown on your TV.',
                        'Tap ‘Continue’ and you will be able to stream our content on your Smart TV.'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_sony1.png"
                    ]
                }
            ]
        }
        else if (partner === "zee5") {
            title = 'ZEE5 Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/zee5.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'Go to Zee5 app in TV (If not available, download from Google Play store on TV).',
                        'For one time login for Zee5 premium, Click on Login to Zee5.',
                        'It will ask for a code to enter in your account',
                        'Visit https://www.zee5.com/device by typing the URL in the web browser of your mobile/laptop.',
                        'Login using your registered ID. Enter the code shown on your TV.',
                        'Tap ‘Continue’ and you will be able to stream our content on your Smart TV.'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design2",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/20230324/20230324-1/tvs_in_zee5_steps.png "
                       
                    ]
                },
                {
                    title: "design1",
                    hasor: "false",
                    data: [                        
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_zee52.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_zee53.png"
                    ]
                }
            ]
        }
        else if (partner === "hotstar") {
            title = 'Hotstar Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/hotstar.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'Go to Hotstar app in TV (If not available, download from Google Play store on TV)',
                        'For one time login for Hotstar , Scroll to My Account, Log in to continue,',
                        'Enter RMN, Enter the OTP Received on mobile, Verify,',
                        'Click on Start Watching to watch Disney+Hotstar on TV.'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_hotstar1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_hotstar2.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_hotstar3.png"
                    ]
                }
            ]
        }
        else if (partner === "voot") {
            title = 'Voot  Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/voot.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'Go to Voot app in TV (If not available, download from Google Play store on TV),',
                        'For one time login for Voot premium, Click on Login,',
                        'It will ask for a code to enter in your account',
                        'Visit https://www.voot.com/activate by typing the URL in the web browser of your mobile/laptop',
                        'Login using your registered ID. Enter the code shown on your TV.',
                        'Tap ‘Continue’ and you will be able to stream our content on your Smart TV.'
                    ],
                    subdata: []
                }
            ]
            activationimages = []
        }
        else if (partner === "aha") {
            title = this.state.countryCode == "IN" ? 'Aha Activation Steps ( Only For South Pack users )' : 'Aha Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/aha.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "Activation flow",
                        data: [
                            "The user needs to do the following on ‘mobile’ before going to TV Device.",
                            "Open the app and go to the menu.",
                            "Click on Sign-in",
                            "Enter the E-mail address and select ‘next.’",
                            "Then click on ‘forgot password.’"
                        ],
                        subdata: [
                            "- Enter the OTP sent to the given E-mail address and verify.",
                            "- Enter the new password.",
                            "- Re-enter the password to confirm.",
                            "- Click on save password."
                        ]
                    },
                    {
                        "title": "Sign-in flow",
                        data: [
                            "Open the aha mobile app.",
                            "Go to the menu and sign into your account.",
                            "Select the link device.",
                            "Scan the QR code displayed on the TV."
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/tvs_aha.png"
                        ]
                    }
                ]
            } else {
                activationsteps = [
                    {
                        "title": "",
                        data: [
                            'Visit https://www.aha.video/activate device by typing the URL in the web browser of your mobile/laptop,',
                            'Login using your registered ID. Enter the code shown on your TV,',
                            'Tap ‘Continue’ and you will be able to stream our content on your Smart TV.'
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_aha1.png"
                        ]
                    }
                ]
            }
        }
        else if (partner === "lionsgateplay") {
            title = 'Lionsgate Play Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/lionsgate.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'Go to Lionsgate Play app in TV (If not available, download from Google Play store on TV),',
                        'For one time login for Lionsgate Play,',
                        'Click on Login Visit lionsgateplay.com/in/activate by typing the URL in the web browser of your mobile/laptop',
                        'Enter Mobile Number with 91xxxxxxxxxx in field Email address - > Enter the password',
                        'If password is not available then -> Click on forget password',
                        'Enter mobile number like 91xxxxxxxxxx -> Submit -> Verify OTP',
                        'Click on three horizontal lines on mobile screen on top right,',
                        'Click on Activate TV, Enter Code Displayed on TV. Click on Activate,',
                        'You will get message LIONSGATE Activated Successfully. You can Start Watching on TV.'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_loinsgate1.png"
                    ]
                }
            ]
        }
        else if (partner === "shemaroo") {
            title = 'Shemaroo Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/shemaroo.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'Open ShemarooMe app on your Android Smart TV (If not available, download from Google Play store on TV).',
                        'Go to settings and enter mobile number.',
                        'Click on Get OTP and enter the OTP on registered mobile number.',
                        'Click on Verify OTP and you can start watching Shemaroo.'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_shemaroo1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_shemaroo2.png"
                    ]
                },
                {
                    title: "design2",
                    hasor: "false",
                    data: [                    
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/20230324/tvs_shemaroo_steps.png"
                    ]
                },
            ]
        }
        else if (partner === "hungamaplay") {
            title = 'Hungama Play Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/28apr2022/hungamaplay.png'
            activationsteps = [
                {
                    "title": "",
                    data: [
                        'Open Hungama Play app on your Android Smart TV (If not available, download from Google Play store on TV),',
                        'Scroll down to Profile -> Click on Profile -> Click on Login,',
                        'Visit https://www.hungama.com/active by typing the URL in the web browser of your mobile/laptop,',
                        'Click on Continue with Mobile Numer - > Enter Registered Mobile Number',
                        'Tick mark on I’m not robot -> Click on Signin',
                        'Enter verification code received on mobile -> Click on Verify Code ->  Enter the code shown on your TV.',
                        'You will get message You have logged in successfully!!',
                        'And you will be able to stream our content on your Smart TV.'
                    ],
                    subdata: []
                }
            ]
            activationimages = [
                {
                    title: "design1",
                    hasor: "false",
                    data: [
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_hungama1.png",
                        "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_hungama2.png"
                    ]
                }
            ]
        }
        else if (partner === "ypptvscope") {
            title = 'Scope Video Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/yupptvscope/15Feb2023/scopevideonew.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "",
                        data: [
                            'Open the Scope video app on your Android Smart TV (If not available, download it from the Google Play store on your TV)',
                            'For first-time users : Login with the Email/Mobile number (username) and a Password sent to you via Mail/WhatsApp/SMS.',
                            'Go to Settings -> Click on Sign In -> Enter registered mobile number/E-mail address.',
                            'Enter the Password -> click on the submit button.'
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/tvs_scopevideo.png"
                        ]
                    }
                ]
            } else {
                activationsteps = [
                    {
                        "title": "",
                        data: [
                            'Scope Video app on your Android Smart TV (If not available, download from Google Play store on TV),',
                            'Click on Settings -> Click on Sign In -> Enter registered mobile number -> Click on Send OTP',
                            'Enter OTP -> Click on Submit OTP',
                            'You will get message “Login Successful',
                            'You can continue watching'
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_scopevideo1.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/23mar2023/tvs_in_scopevideo2.png"
                        ]
                    }
                ]
            }
        }
        else if (partner === "sunnxt") {
            title = 'Sun NXT Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/bsnl/20220308/sunnxticon.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "Single sign-on",
                        data: [
                            "With this feature users need not sign in again in Sun NXT app.",
                            "Users must sign in scope video app with a registered mobile number.",
                            "Once you click on any Sunnxt content in the scope app, you’ll be navigated to the player page in the Sunnxt app.",
                            "Next content will be played."
                        ],
                        subdata: []
                    },
                    {
                        "title": "For Sign-in",
                        data: [
                            "Enter the E-mail address and password."
                        ],
                        subdata: []
                    },
                    {
                        "title": "Two ways to sign into TV devices",
                        data: [
                            "Sign in by email/mobile number and password. or",
                            "By scanning the QR code with a mobile app.",

                        ],
                        subdata: [
                            "- Go to settings and click on Manage Devices",
                            "- Next page “connect a device”.",
                            "- There enter code which is given on TV or",
                            "- Scan the code.",
                            "- Here the user will successfully sign in."
                        ]
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/tvs_sunnxt_step1.png",
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/tvs_sunnxt_step2.png"
                        ]
                    }
                ]
            } else {
                activationsteps = [];
                activationimages = [];
            }
        }
        else if (partner === "yupptv") {
            title = 'YuppTV Activation Steps'
            image = 'https://yuppstatic.akamaized.net/yupptv/bsnl/20220308/yupptvicon.png'
            if (this.state.countryCode == "Others") {
                activationsteps = [
                    {
                        "title": "",
                        data: [
                            "Open the YuppTV app on your Android Smart TV (If not available, download it from the Google Play store on your TV).",
                            "Click on Settings -> Click on Sign In -> Enter registered mobile number/E-mail address.",
                            "Enter the Password -> click on the submit button, Or,",
                            "If you choose OTP sign in, Enter your registered number.",
                            "Enter OTP -> Click on Submit OTP",
                            "You will get the message “Sign in Successful”"
                        ],
                        subdata: []
                    }
                ]
                activationimages = [
                    {
                        title: "design1",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/15mar2023/tvs_yupptv_step1.png"
                        ]
                    },
                 
                    {
                        title: "design3",
                        hasor: "false",
                        data: [
                            "https://yuppstatic.akamaized.net/yupptv/bsnl/20230324/tvs_yupptv_steps.png"
                        ]
                    }
                ]
            } else {
                activationsteps = []
                activationimages = []
            }
        }
        this.setState({
            partnertitle: title,
            partnerimage: image,
            activationdetails: activationsteps,
            activationimages: activationimages,
            showpartners: false,
            showactivation: true,
            gobackpage: 'partners'
        })
    }

    render() {
        return (
            <div>
                <Header active={"activationsteps"} showmenuitem={true}/>
                <div className='activation-container'>


                    <h2 className='back-headding'> <a onClick={this.goback.bind(this)} style={{ cursor: 'pointer' }}><img src='https://yuppstatic.akamaized.net/yupptv/turito/28apr2022/back-arrow-as.svg' /></a>
                        {this.state.gobackpage == '' && this.state.pageheader != '' &&
                            <>{this.state.pageheader}</>
                        }
                        {this.state.partnertitle}
                    </h2>
                    {
                        this.state.showpartners == true && this.state.countryCode != "" &&
                        <div className='select-partner-main-block'>
                            <h2 className='back-headding text-center back-headding1'>{this.state.title}</h2>
                            <h3 className='select-cont-partner'>Select Content Partners</h3>
                            <ul className='select-partner-block'>
                                {
                                    data.partners.filter(obj => obj.country == this.state.countryCode)[0].data.map((item, index) =>

                                        <li key={index}>
                                            <a onClick={this.partneractivationinfo.bind(this, item.title)}>
                                                <img src={item.image} />
                                                <p>{item.displaytitle}</p>
                                            </a>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    }
                    {
                        this.state.showactivation == true &&
                        <div>
                            {
                                !!this.state.partnerimage &&
                                <div className=' select-partner-main-block'>
                                    <div className='list-parent'>
                                        <img  className="partnericon" src={this.state.partnerimage} />
                                        <ul className='device-points'>
                                            {
                                                this.state.activationdetails.length > 0 && this.state.activationdetails.map((item, index) =>
                                                    <>
                                                        <li key={index} className="device_points_head">{item.title}</li>
                                                        {
                                                            item.data.length > 0 && item.data.map((itm, indx) =>
                                                                <li key={indx}>{itm}</li>
                                                            )
                                                        }
                                                        {
                                                            item.subdata.length > 0 && item.subdata.map((obj, objIndex) =>
                                                                <li key={objIndex} className="list_type_none">{obj}</li>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </ul>
                                        {
                                            this.state.activationimages.length > 0 && this.state.activationimages.map((item, index) =>
                                                <>
                                                    {
                                                        item.title == "design1" &&
                                                        <div className='row' key={index}>
                                                            {
                                                                item.data.map((item, index) =>
                                                                    <div className='col-md-6' key={index}>
                                                                        <img src={item} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        item.title == "design2" &&
                                                        <div className={item.hasor == "true" ? "row justify-content-between or_text" : "row justify-content-between"}>
                                                            {
                                                                item.data.map((item, index) =>
                                                                    <div className='col-md-6' key={index}>
                                                                        <img src={item} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                     {
                                                        item.title == "design3" &&
                                                        <div className={item.hasor == "true" ? "row justify-content-between or_text" : "row justify-content-between"}>
                                                            {
                                                                item.data.map((item, index) =>
                                                                    <div className='col-md-12' key={index}>
                                                                        <img src={item} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.showactivation == true &&
                        <p className='query-description'>For any further queries and assistance, contact our support team by saying ‘Hi’ in the chat or writing to us at support@yupptvscope.com.</p>
                    }
                </div>
                <Footer />
            </div >
        )
    }
}
export default AndroidTV;