import React from 'react';
import Config from '../shared/config';
import './Transaction.css';
import DataService from '../shared/service';

class Transactions extends React.Component{

    constructor(props)
    {
        super(props); 
        this.state = {   
            userTransactions:[],
            page:0,
            cnt:10,
        };
        this.dataService = new DataService();
    } 

    componentDidMount()  
    {
        this.getTransData('first');
    }
 

    //getting transactions page wise 
    getTransData(valNext)
    {   
        let pagecnt = this.state.page;


        if(valNext==="next")
        {
            pagecnt++;
        }
        else if(valNext==="previous")
        {
            if(pagecnt>=1)
            {
                pagecnt--;
            }
        }

        if (pagecnt==0)
        {
            document.getElementById("btnPrevious").style.display= "none";
            document.getElementById("btnNext").style.display= "block";
        }
        else
        {
            document.getElementById("btnPrevious").style.display= "block";
        }
        let domain = Config.APIURL;  
        console.log("pagecnt   " + pagecnt);
        console.log(domain + "service/api/auth/transaction/history?page=" + pagecnt +"&count=" + this.state.cnt);
        this.dataService.httpGetwithToken(domain + "service/api/auth/transaction/history?page=" + pagecnt +"&count=" + this.state.cnt)
            .then(responseData=> {
            if(responseData!== undefined)
            { 
               if(responseData.response.length<10)
                {
                   document.getElementById("btnNext").style.display= "none";
               }
               else{
                    document.getElementById("btnNext").style.display= "block";
                }
                if(responseData.response.length!==0 )
                {
                        this.setState({
                            userTransactions : responseData.response
                        })
                }
                else
                {
                    document.getElementById("btnNext").style.display= "none";
                    pagecnt--;

                } 
                this.setState({page: pagecnt}); 
            } 
            })
            
    }
openInvoice(strFile)
{
    window.open(strFile, '_blank', 'fullscreen=yes')
} 

getTransList(trans) 
    {
    if (trans!==null)
    {
        return(

           <tr key={trans.orderId}>
                <td>{trans.orderId}</td>
                <td>{trans.packageName}</td>
                <td>{dateFromEpoch(trans.purchaseTime)}</td>
                <td>{trans.gateway}</td>
                <td>{trans.currency}{trans.amount}</td>
                <td><span className="pactive">{trans.status=="S" ? "Success" : trans.status="P" ? "Pending" : "Failed"}   </span></td>
                <td><span className="stop-recorring">{trans.downloadLink===""? "" : <a href="#"
                onClick={this.openInvoice.bind(this,Config.downloadDomain +  trans.downloadLink)}>Download</a>}</span></td>
                
            </tr> 
    )}
}  

getTransListMob(trans) 
{
if (trans!==null)
{
    return(
           <div className="transaction-mobile" key={trans.orderId}>
        <div className="pack-details-mob"><span>Transaction ID</span> <span>{trans.orderId}</span></div>
        <div className="pack-details-mob"><span>Package Name</span> <span>{trans.packageName}</span></div>
        <div className="pack-details-mob"><span>Payment Date</span> <span>{dateFromEpoch(trans.purchaseTime)}</span></div>
        <div className="pack-details-mob"><span>Payment Mode</span> <span>{trans.gateway}</span></div>
        <div className="pack-details-mob"><span>Amount</span> <span>{trans.currency}{trans.amount}</span></div>
        <div className="pack-details-mob"><span>Status</span> <span className="pactive">{trans.status=="S" ? "Success" : trans.status="P" ? "Pending" : "Failed"}   </span></div>
        <div className="pack-details-mob"><span>Tax Invoice</span> <span className="stop-recorring">{trans.downloadLink===""? "" : <a href="#"
            onClick={this.openInvoice.bind(this,Config.downloadDomain +  trans.downloadLink)}>Download</a>}</span></div>

       </div> 
)}
}

// getpageNumbers()
// {
//     if(this.state.userTransactions!=null)
//     { 
//         let total= this.state.userTransactions.totalCount; 
//         total= 109; 
//         var rows=[];
//         var pages= Math.floor(total/this.state.cnt); 
//         var rem = total % this.state.cnt
//         if(rem>0) pages= pages+1; 
//         for(var i=1; i<=pages; i++)
//         { 
//             rows.push(<a onClick={this.getTransData.bind(this,i)}>{i}   </a>)
//         }
//         return(rows);
        
//     }
    

// }

    render(){
    return( 
        <div>
            <div className="detail-head">Transactions</div>
            <div className="detail-block-one"> 
            <table className="table mysubscription-table">
            <thead>
            <tr>
                <th>Transaction ID</th>
                <th>Package Name</th>
                <th>Payment Date</th>
                <th>Payment Mode</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Tax Invoice</th>
              </tr> 
              </thead>
              <tbody>
                    {this.state.userTransactions.map(subs=>( 
                            this.getTransList(subs)
                        ))
                        }
                </tbody>
            </table>
            {/* <div className="pagenation">
            {this.getpageNumbers()}
            </div> */}
         <div className="btn-aligns">
                <input type="button" style={{width:"120px"}}  className="form-control btn btn-danger" id="btnPrevious" value="<<Previous" onClick={this.getTransData.bind(this,"previous")}  />
                <input type="button" style={{width:"120px"}}  className="form-control btn btn-danger" id="btnNext" value="Next>>" onClick={this.getTransData.bind(this,"next")}  />
            </div> 
            </div>
            {this.state.userTransactions.map(subs=>( 
                     this.getTransListMob(subs)
                 ))
                 }
                 <div className="btn-aligns-mob">
                 <input type="button" style={{width:"100px"}}  className="form-control btn btn-danger" id="btnPrevious" value="<<Previous" onClick={this.getTransData.bind(this,"previous")}  />
                <input type="button" style={{width:"100px"}}  className="form-control btn btn-danger" id="btnNext" value="Next>>" onClick={this.getTransData.bind(this,"next")}  />
                </div>
            </div>  
        )}
}
export default Transactions; 

function dateFromEpoch(epoch)
{
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var f = new Date(epoch);

    var s = monthNames[f.getMonth()] + " ";
        s += prefix(f.getDate()) + ", ";
        s += prefix(f.getFullYear())
    return s;

}

function prefix(n) {
return n > 9 ? "" + n : "0" + n;
}