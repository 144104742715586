export const betaConfig = {
    BaseRootURL: "https://preprod-api.yupptvscope.com/service/api/v1/",
    BaseURL: "https://watch-beta.yupptvscope.com/",
    DomainURL: "https://watch-beta.yupptvscope.com",
    APIURL: "https://preprod-api.yupptvscope.com",
    deviceid: 5,
    TenentCode: "bott",
    versionKey: "bsnl-static-beta-",
    ispregisterapi:"/package/api/v1/isp/info",
    statesapi:"get/states?country_code=IN"
}

export const prodConfig = {
    BaseRootURL: "https://prod-api.scopevideo.com/service/api/v1/",
    BaseURL: "https://partners.scopevideo.com/",
    DomainURL: "https://partners.scopevideo.com",
    APIURL: "https://prod-api.scopevideo.com",
    deviceid: 5,
    TenentCode: "bott",
    versionKey: "bsnl-static-prod-",
    ispregisterapi:"/package/api/v1/isp/info",
    statesapi:"get/states?country_code=IN"
}