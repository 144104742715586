import React from 'react';
import Config from '../shared/config';
import DataService from '../shared/service';
import './subscription.css';

class Subcriptions extends React.Component{

    constructor(props)
    {
        super(props); 
        this.state = {   
            subsinfo:[],
            reasons:[],
            expiryDt:""
        };
        this.dataService = new DataService();
        this.packId= 0;
        this.canRes= "" ;
        this.cantxt =""; 
    } 
    componentDidMount()  
    {
        //active packages
        let domain = Config.APIURL;  
        this.dataService.httpGetwithToken(domain + "service/api/auth/user/activepackages")
            .then(responseData=> {
            if(responseData!== undefined)
            { 
                this.setState({
                    subsinfo : responseData.response
                })
            } 
            })

        //cancelling reasons 
        URL= Config.APIURL + "package/api/v1/get/cancel/reasons";
        this.dataService.httpGetwithToken(URL)
        .then(responseData=> {
        if(responseData!== undefined)
        { 
            this.setState({
                reasons : responseData.response
            })
        } 
        })            
    }

    //Get subscriptions for web
    getsubsList(subs) 
    {
    if (subs!==null)
    {
        return(

            <tr key={subs.id}>
            <td> {subs.name}</td>
            <td>{dateFromEpoch(subs.purchaseDate)}</td>
            <td> {dateFromEpoch(subs.expiryDate)}</td>
            <td> {subs.isRecurring===true ? "Recurring" :"Non Recurring" }</td>
            <td> {subs.cusomAttributes.CurrencySymbol}{subs.saleAmount}</td>
            <td><span className="pactive">Active</span></td>
            <td><span className="stop-recorring">{  
                              subs.isRecurring===true ? 
                              (subs.isUnSubscribed===true? "Recurring Cancelled" :<a onClick={this.profilePopup.bind(this,'stopRecPopup', subs.id, dateFromEpoch(subs.expiryDate) )}>Stop Recurring</a>)
                             : "" }</span></td>
            </tr>
    )}
}
//close popup 
closeClick(popup)
{
    document.body.classList.remove("modal-open"); 
    document.getElementById(popup).style.display= 'none'; 
} 
//showing cancel reasons
cancelReasonClick()
{
    document.getElementById("stopRecPopup").style.display= 'none'; 
    document.getElementById("cancelReasonPopup").style.display= 'block'; 
 } 

 //cancelling package with reasons 
 cancelPackClick()
 {
    let postData="";
    let res = "";
    if (this.canRes === "Other")
    {
        res= this.canRes +" " + this.cantxt;
    }
    else
    {
        res= this.canRes;
    } 
   
    //postData = JSON.stringify({package_id:this.packId.toString(),gateway:"razorpay",reason:res})
    postData = 'package_id=' + this.packId.toString() + '&gateway="razorpay"&reason="' + encodeURI(res) + '"' ;
    
    URL= Config.APIURL + "payment/api/v1/cancel/subscription";
    this.dataService.httpPostWithFormData(URL,postData)
    .then(responseData=> {
    if(responseData!== undefined)
    { 
       if(responseData.status=== true)
       {
        document.getElementById("cancelSuccessPopup").style.display= 'block'; 
        document.getElementById("cancelfinalPopup").style.display= 'none'; 
        document.getElementById("spnexpDt").innerHTML= this.state.expiryDt;
      
       }
    }
    })
 }
profilePopup(popup,id, expdt ) {
    document.body.classList.add("modal-open"); 
      document.getElementById(popup).style.display= 'block'; 
      document.getElementById("lblResnErr").innerHTML="";
      this.packId= id;
      this.state.expiryDt = expdt;
  }    
  
  //showing cancel popups 
  cnotinueCancelClick()
  {
    var cancelReason;
    for (var i=0; i<document.getElementsByName("rdoreasons").length; i++) 
    {
         if (document.getElementsByName("rdoreasons")[i].checked)  
         {
            cancelReason = document.getElementsByName("rdoreasons")[i].value;
         }
    }
    if(cancelReason ==="Other")
    {
        if(document.getElementById("txtOthe").value==="")
        {
            //error Please enter text
        }
        this.cantxt = document.getElementById("txtOthe").value;
    } 
    if (cancelReason==undefined)
    {
        document.getElementById("lblResnErr").innerHTML="Please select Reason";
        return; 
    } 
    this.canRes = cancelReason ;
    document.getElementById("cancelReasonPopup").style.display= 'none'; 
    document.getElementById("cancelfinalPopup").style.display= 'block'; 
} 

radioOnChange(itemName)
{
  if(itemName==="Other")
  { 
      document.getElementById("txtOther").style.display="block";
  }
  else
  {
    document.getElementById("txtOther").style.display="none"; 
  }
  document.getElementById("lblResnErr").innerHTML="";
}
//displaying cancel reasons 
  getReasons()
  {
      if(this.state.reasons.cancellingReasons!==undefined)
      return(
          <ul> 
              {this.state.reasons.cancellingReasons.map(item=>(
                <li className="round" key={item}>{item}
                <input type="radio" id={item} name="rdoreasons" value={item} onChange={this.radioOnChange.bind(this, item)} />
                <label htmlFor={item}></label>
                </li>
              ))
            }
            
            <li className="round">Other
              <input type="radio" id="other1" name="rdoreasons"  onChange={this.radioOnChange.bind(this, "Other")}/>
              <label htmlFor="other1"></label>

              </li>   
                  
          </ul>
      )
  } 

     //Get subscriptions for mobiles
     getsubsListforMob(subs) 
     {
     if (subs!==null)
     {
         return(
 
            <div className="detail-block-mobile" key={subs.id}> 
            <div className="pack-details-mob"><span>Plan Name</span> <span>{subs.name}</span></div>
            <div className="pack-details-mob"><span>Purchase Date</span> <span>{dateFromEpoch(subs.purchaseDate)}</span></div>
            <div className="pack-details-mob"><span>Next Renewal</span> <span>{dateFromEpoch(subs.expiryDate)}</span></div>
            <div className="pack-details-mob"><span>Billing Type</span> <span>{subs.isRecurring===true ? "Recurring" :"Non Recurring" }</span></div>
            <div className="pack-details-mob"><span>Amount</span> <span>{subs.cusomAttributes.CurrencySymbol}{subs.saleAmount}</span></div>
            <div className="pack-details-mob"><span>Status</span> <span className="pactive">Active</span></div>
            <div className="pack-details-mob"><span>Action</span> <span className="stop-recorring">{  
                          subs.isRecurring===true ? (subs.isUnSubscribed===true? "Recurring Cancelled" :<a onClick={this.profilePopup.bind(this,'stopRecPopup', subs.id, dateFromEpoch(subs.expiryDate) )}>Stop Recurring</a>)
                          : ""}</span></div>
            </div>
     )}
 }
  
    render(){
    return( 
        <div>
            <div className="detail-head">My Subscriptions</div>
            <div className="detail-block subscription-mob"> 
                <h2 className="active-plans">Active Plans</h2>
                <table className="table mysubscription-table">
                    <thead>
                <tr>
                    <th>Plan Name</th>
                    <th>Purchase Date</th>
                    <th>Next Renewal</th>
                    <th>Billing Type	</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr> 
                </thead>
                <tbody>
                {this.state.subsinfo.map(subs=>( 
                    this.getsubsList(subs)
                ))
                }
                </tbody>
            </table>
        </div>
        <div className="subscription-mobile">
        {this.state.subsinfo.map(subs=>( 
                    this.getsubsListforMob(subs)
                ))
                }
        </div>

<div className="stoprecorring-popup">
<div className="modal show" id="stopRecPopup" aria-modal="true">
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" onClick={this.closeClick.bind(this,"stopRecPopup")}></button>
            <h4 className="modal-title">{this.state.reasons.preTitle} </h4>
            <h6>{this.state.reasons.preSubTitle} </h6>
            </div>
            <div className="modal-body">
                <div className="form-group subscriptin-btns">
                    <input type="button"  className=" btn cancel-sub-btn" value={this.state.reasons.btnOK} onClick={this.cancelReasonClick.bind(this)}  />
                    <input type="button"  className="btn save-sub-btn" value={this.state.reasons.btnCanel} onClick={this.closeClick.bind(this,"stopRecPopup")} />
                </div>     
            </div>
        </div>
    </div>
</div> 
</div>
<div className="cancelrecon-popup">
<div className="modal show" id="cancelReasonPopup" aria-modal="true">
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" onClick={this.closeClick.bind(this,"cancelReasonPopup")} ></button>
                <h4 className="modal-title">{this.state.reasons.title}</h4>
                <h6>{this.state.reasons.subTitle }</h6>
            </div>
            <div className="modal-body">
                <ul></ul>
                    {this.getReasons()}
                
                     {/* <input type="text" ></input> */}
                     <div style={{padding:'0 26px'}}>
                     <textarea placeholder="Type your reason here" id="txtOther" name="txtOther" style={{display:"none",width:"100%",height:"110px",padding:"10px 15px"}}></textarea>
                     </div>
                     <label id="lblResnErr" className="error-msg"></label>
                <div className="cancle-btn-blcok">
                    <input type="button"  className="btn cancel-continue-btn" value="Continue to Cancel" onClick={this.cnotinueCancelClick.bind(this)}  />
                    
                </div>   
                 
            </div>
        </div>
    </div>
</div> 
</div>
<div className="cancelfinal-popup">
<div className="modal show" id="cancelfinalPopup" aria-modal="true">
    <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" onClick={this.closeClick.bind(this,"cancelfinalPopup")}></button>
                <h4 className="modal-title">{this.state.reasons.postTitle}</h4>
                <h6>{this.state.reasons.postSubTitle}  </h6>
            </div>
            <div className="modal-body">
                <div className="subscriptin-btns">
                    <input type="button"  className="btn cancel-sub-btn" value={this.state.reasons.btnOK} onClick={this.cancelPackClick.bind(this)}  />
                    <input type="button"  className="btn save-sub-btn" value={this.state.reasons.btnCanel} onClick={this.closeClick.bind(this,"cancelfinalPopup")} />
                </div>     
            </div>
        </div>
    </div>
    </div>
</div>  
<div className="cancelsucess-popup"> 
<div className="modal show" id="cancelSuccessPopup" aria-modal="true">
    <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" onClick={this.closeClick.bind(this,"cancelSuccessPopup")}></button>
                <h4 className="modal-title">Your subscription has been cancelled</h4>
                <h6>you will still have access to this plan until end of this billing cycle i.e. <span id="spnexpDt"></span> </h6>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <input type="button"  className="form-control btn btn-danger" value="Okay" onClick={this.closeClick.bind(this,"cancelSuccessPopup")}  />
                </div>     
            </div>
        </div>
    </div>
</div>
</div> 
</div>
)}
}
export default Subcriptions; 
 
function dateFromEpoch(epoch)
{
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var f = new Date(epoch);

    var s = monthNames[f.getMonth()] + " ";
        s += prefix(f.getDate()) + ", ";
        s += prefix(f.getFullYear())
    return s;

}

function prefix(n) {
return n > 9 ? "" + n : "0" + n;
}


var ModalFixMixin = {
    componentDidMount() {
        if (document && document.body) {
            var orig = document.body.className;
            document.body.className = orig + (orig ? ' ' : '') + 'modal-open';
        }
    },
    componentWillUnmount() {
        if (document && document.body) {
            document.body.className = document.body.className.replace(/ ?modal-open/, '');
        }
    }
};