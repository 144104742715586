import React from 'react';
import './popup.css';

class Popup extends React.Component{

    render(){
            return( 
             <div>
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal">
                Open modal
              </button>
              
           
              <div class="modal" id="myModal">
                <div class="modal-dialog">
                  <div class="modal-content">
              
                    
                    <div class="modal-header">
                      <h4 class="modal-title">Choose your Operator</h4>
                      <h5 className="popsub-headding">You selected “BSNL” Click on proceed to view plans</h5>
                      <button type="button" className="close" data-dismiss="modal" onClick={this.closeClick.bind(this,"stopRecPopup")}><img src={require('../images/close.svg')}/></button>
                    </div>
              
                   
                    <div class="modal-body">
                   <ul className="operator-btns-container">
                        <li className="round">BSNL
                        <input type="checkbox" id="checkbox" checked="checked" />
                        <label for="checkbox"></label>
                        </li>
                        <li className="round">ACT
                        <input type="checkbox" id="checkbox1" />
                        <label for="checkbox1"></label>
                        </li>
                        <li className="round">Hathway
                        <input type="checkbox" id="checkbox2" />
                        <label for="checkbox2"></label>
                        </li>
                        <li className="round">Airtel
                        <input type="checkbox" id="checkbox3" />
                        <label for="checkbox3"></label>
                        </li>
                        <li className="round">Tikona
                        <input type="checkbox" id="checkbox4" />
                        <label for="checkbox4"></label>
                        </li>
                        <li className="round">Others
                        <input type="checkbox" id="checkbox5" />
                        <label for="checkbox5"></label>
                        </li>

                   </ul>
                   <div className="form-group">
                          <input type="button"  className="form-control btn btn-danger" value="Proceed" />
                         
                      </div>
                    </div>
              
                
                              
                  </div>
                </div>
              </div>
              </div>
        )}
}
export default Popup;