const config = {
    //BaseRootURL: "https://partners.scopevideo.com/",
    BaseRootURL: "https://partners.scopevideo.com/",
    APIURL:"http://prod-api.scopevideo.com/",
    TenentCode: "bott",
    razorKey: "rzp_test_b9CstKDIkAfNcU",
    secreteKey: "key123BSNL",
    downloadDomain: "https://d96sp6add8n6h.cloudfront.net/",
    callbackURL: "https://partners.scopevideo.com/",
    domainUrl:"https://d229kpbsb5jevy.cloudfront.net/st-ak/yupptv/",
    cdnUrl:"https://yuppstatic.akamaized.net/yupptv/",
    EmailURL:"https://prod-api.scopevideo.com/service/api/v1/send/email",
    LocationAPI: "https://prod-api.yupptvscope.com/service/location/api/v1/locationinfo",

    // beta API -- "http://13.232.54.126:8080/",
    // UAt API- 13.232.228.15:8080/

};

module.exports = config; 